import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {SessionLockedForUserService} from '../session-locked-for-user.service';
import {ContextService} from '../../context/context.service';
import {Observable} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {CannotActivateReason, GuardResult} from '../team-decisions/guards/guard-result';

@Injectable({
    providedIn: 'root'
})
export class CanActivateMailBoxGuard implements CanActivate {
    constructor(
        private readonly sessionLockedForUserService: SessionLockedForUserService,
        private readonly contextService: ContextService,
        private readonly httpClient: HttpClient
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.contextService.context.pipe(
            filter(context => !!context && !!context.currentSessionId),
            take(1),
            map(context => context.currentSessionId),
            switchMap(currentSessionId => this.httpClient.get<GuardResult>('api/sessions/' + currentSessionId + '/canActivateMailbox')),
            map(guardResult => {
                if(!guardResult.canActivate && guardResult.reason === CannotActivateReason.SESSION_LOCKED) {
                    return this.sessionLockedForUserService.urlToSessionBlocked();
                }

                return guardResult.canActivate;
            })
        )
    }
}

import {Injectable} from '@angular/core';
import {AbstractSessionParametersLoaderService} from "../../../parameters/services/abstract-session-parameters-loader.service";
import {FinalMarkCriteria} from "./final-mark-criteria";

@Injectable({
  providedIn: 'root'
})
export class FinalMarkCriteriaLoaderService extends AbstractSessionParametersLoaderService<FinalMarkCriteria>{

  constructor() { super('evaluations/finalMark/criteria') }
}

export class DisplayStudy {
    constructor(
        public studyId: string,
        public monthly: boolean,
        public cumulative: boolean,
        public available: boolean,
        public price: number,
        public boughtByTeam: boolean
    ) {}
}

import {Component, OnInit} from '@angular/core';

@Component({
    templateUrl: './session-blocked.component.html',
    styleUrls: ['./session-blocked.component.scss'],
    host: {'class': 'cell auto cell-block-y grid-y align-center align-middle pageContainer'}
})
export class SessionBlockedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

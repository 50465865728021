import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {LocatorService} from "../../locator.service";
import {Context} from "../../context/context";
import {StudiesNames} from './studies-names';

export class StudyLoaderService<T> {
    private httpClient: HttpClient;
    private readonly isStudyAlwaysAvailable: boolean;

    constructor(
        private readonly category: string,
        private readonly studyId: string,
        private readonly periodicity?: string,
    ) {
        this.httpClient = LocatorService.injector.get(HttpClient);
        this.isStudyAlwaysAvailable = StudyLoaderService.computeStudyAlwaysAvailable(studyId);
    }

    private static computeStudyAlwaysAvailable(studyId: string): boolean {
        const alwaysAvailableStudies = [
            StudiesNames.COMMUNICATION_PARAMETERS,
            StudiesNames.DINING_ROOMS_PARAMETERS,
            StudiesNames.HUMAN_RESOURCES_PARAMETERS
        ];

        return alwaysAvailableStudies.includes(studyId as StudiesNames);
    }

    loadStudy(context: Context): Observable<T> {
        if (!this.isStudyAlwaysAvailable && context.currentPeriod === context.currentSession.currentPeriod) {
            return new Observable();
        }

        if(this.periodicity) {
            return this.httpClient.get<T>(
                '/api/sessions/' + context.currentSessionId +
                '/periods/' + context.currentPeriod +
                '/teams/' + context.currentTeam.teamNumber +
                '/studies/' + this.category +
                '/' + this.studyId +
                '/' + this.periodicity);
        } else {
            return this.httpClient.get<T>(
                '/api/sessions/' + context.currentSessionId +
                '/periods/' + context.currentPeriod +
                '/teams/' + context.currentTeam.teamNumber +
                '/studies/' + this.category +
                '/' + this.studyId);
        }
    }
}

import {Component} from '@angular/core';
import {ExternalLoadsParametersLoaderService} from './external-loads-parameters-loader.service';
import {ExternalLoadsParametersUpdaterService} from './external-loads-parameters-updater.service';
import {GenericPeriodParametersComponent} from '../../generic-parameters/generic-period-parameters.component';
import {ExternalLoadsParameters} from '../../services/period/external-loads/external-loads-parameters';
import {genericParametersHost} from '../../generic-parameters/generic-parameters.component';

@Component({
    templateUrl: './external-loads-parameters.component.html',
    styleUrls: [
        './../../generic-parameters/generic-parameters.component.scss',
        './../../generic-parameters/generic-parameters.component.scss'
    ],
    host: genericParametersHost
})
export class ExternalLoadsParametersComponent extends GenericPeriodParametersComponent<ExternalLoadsParameters> {

    constructor(externalLoadsParametersLoaderService: ExternalLoadsParametersLoaderService,
                externalLoadsParametersUpdaterService: ExternalLoadsParametersUpdaterService) {
        super(
            externalLoadsParametersLoaderService,
            externalLoadsParametersUpdaterService,
            'toast.parameters.financial.external.loads.saved'
        );
    }
}

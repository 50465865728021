import {Component} from '@angular/core';

@Component({
    selector: 'kiwi-customer-satisfaction-mark-criteria',
    templateUrl: './customer-satisfaction-criteria.component.html',
    styleUrls: ['./customer-satisfaction-criteria.component.scss'],
    host: {'class': 'cell auto tabsContainer-content'}
})
export class CustomerSatisfactionCriteriaComponent {
}

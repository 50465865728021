import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-positioning',
    templateUrl: './positioning.component.html',
    styleUrls: ['./positioning.component.scss'],
    host: {'class': 'cell auto grid-y cell-block-y blockContent'}
})
export class PositioningComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}

import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {RankedTeam} from "./ranked-team";

@Injectable({
  providedIn: 'root'
})
export class RankingLoaderService {
    constructor(private readonly httpClient: HttpClient) { }

    public loadRanking(sessionId: string): Observable<RankedTeam[]> {
        return this.httpClient.get<RankedTeam[]>('/api/sessions/' + sessionId + '/ranking');
    }
}

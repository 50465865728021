import { Injectable } from '@angular/core';
import {Player, PlayerUpdateDto, User} from "../../login/user";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {NotificationService} from "../../notification.service";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserUpdaterService {

  constructor(
      private httpClient: HttpClient,
      private notificationService: NotificationService
  ) { }

    savePlayer(playerId: string, user: PlayerUpdateDto): Observable<Player> {
        return this.httpClient.put<Player>('/api/players/' + playerId, user)
            .pipe(tap(
                () => this.notificationService.success('toast.account.save.title',
                    'toast.account.save.success'),
                () => this.notificationService.error('toast.save.error')
    ));
    }
}

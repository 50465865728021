import {RestaurantEquipment} from './dining-rooms-decision';

export class ComputeLayoutPurchaseCostUtils {
    private static computeRestaurantEquipmentPurchaseCost(restaurantEquipment: RestaurantEquipment, roomsToRenew: number) {
        if (!RestaurantEquipment.hasQualityChanged(restaurantEquipment)) {
            return 0;
        }

        return restaurantEquipment.newValuePerRoom * roomsToRenew;
    }

    static computeLayoutPurchaseCost(restaurantEquipment: RestaurantEquipment, currentRoomsNumber: number, previousRoomsNumber: number) {
        let layoutPurchaseCost = 0;

        const roomsDifference = previousRoomsNumber - currentRoomsNumber;

        if (roomsDifference < 0) {
            layoutPurchaseCost = layoutPurchaseCost - (roomsDifference * restaurantEquipment.newValuePerRoom);
        }

        const roomsToRenew = previousRoomsNumber < currentRoomsNumber ? previousRoomsNumber : currentRoomsNumber;
        layoutPurchaseCost = layoutPurchaseCost + ComputeLayoutPurchaseCostUtils.computeRestaurantEquipmentPurchaseCost(restaurantEquipment, roomsToRenew);

        return layoutPurchaseCost;
    }
}

import {Component} from '@angular/core';
import {Context} from '../../../context/context';
import {PeriodUtils} from '../../../app-main/studies/study-header/period-utils';
import {Observable} from 'rxjs';
import {SimulatedPeriodsLoaderService} from '../../../app-main/team-decisions/period-completion/revert-period/simulated-periods-loader.service';
import {map} from 'rxjs/operators';
import {AbstractPeriodsSelectorComponent} from '../abstract-periods-selector.component';

@Component({
    selector: 'kiwi-simulated-periods-selector',
    templateUrl: '../abstract-periods-selector.component.html'
})
export class SimulatedPeriodsSelectorComponent extends AbstractPeriodsSelectorComponent {
    constructor(
        private readonly simulatedPeriodsLoaderService: SimulatedPeriodsLoaderService
    ) {
        super();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        // When leaving studies to go elsewhere (decisions or parameters), set current period to current session current period
        // when current period was last simulated period
        const context = this.contextService.context.getValue();
        const currentSession = context.currentSession;
        if (context.currentPeriod === SimulatedPeriodsSelectorComponent.computePreviousPeriod(currentSession.currentPeriod, currentSession.baseMetaScenario.periodicity)) {
            this.contextService.updateCurrentPeriodId(currentSession.currentPeriod);
        }

    }

    retrievePeriods(context: Context): Observable<string[]> {
        return this.simulatedPeriodsLoaderService.loadSimulatedPeriods(context.currentSessionId)
            .pipe(map(simulatedPeriods => simulatedPeriods
                .map(simulatedPeriod => {
                    return simulatedPeriod.period
                })
            ));
    }

    computeSelectedPeriod(context: Context): string {
        if (context.currentPeriod === context.currentSession.currentPeriod) {
            return SimulatedPeriodsSelectorComponent.computePreviousPeriod(context.currentPeriod, context.currentSession.baseMetaScenario.periodicity);
        }

        return context.currentPeriod;
    }

    private static computePreviousPeriod(period: string, periodicity: string): string {
        const month = PeriodUtils.computeMonthFromPeriod(period, periodicity);
        return PeriodUtils.computePeriodFromMonth(month - 1, periodicity);
    }
}

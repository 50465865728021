import {Component} from '@angular/core';
import {AbstractStudyComponent} from '../../abstract-study.component';
import {TurnoverDetails} from '../turnover/turnover-details';
import {CategoryName} from '../../category-name';
import {of} from 'rxjs';
import {StudiesNames} from '../../studies-names';

@Component({
    templateUrl: './purchases-study.component.html',
    styleUrls: ['./purchases-study.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class PurchasesStudyComponent extends AbstractStudyComponent<TurnoverDetails> {
    constructor() {
        super(CategoryName.ACCOUNTS_DETAIL, of(StudiesNames.PURCHASES));
    }
}

import {Component, Input} from '@angular/core';
import {TeamData} from '../team-data';

@Component({
    selector: 'kiwi-teams-data',
    templateUrl: './teams-data.component.html'
})
export class TeamsDataComponent {
    @Input()
    teamsData: TeamData[];

    @Input()
    teamValueLabelKey: string;
}

import {Component, OnInit} from '@angular/core';
import {Tab} from '../../app-main/tabs/tab';
import {LocatorService} from '../../locator.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

export class TabParentComponent implements OnInit {
    protected router: Router;

    constructor(
        public readonly tabs: Observable<Tab[]>) {
        this.router = LocatorService.injector.get(Router);
    }

    ngOnInit(): void {
        this.tabs.subscribe(tabs => {
            for (let tab of tabs)
                if (this.router.url.includes(tab.url)) {
                    tab.isSelected = true;
                }
        });
    }
}

export const tabParentHost = {'class': 'cell auto grid-y'};

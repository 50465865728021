import { Injectable } from '@angular/core';
import {AbstractSessionParametersUpdaterService} from "../../../parameters/services/abstract-session-parameters-updater-service";
import {TreasuryEvaluationParameters} from "./treasury-evaluation-parameters";

@Injectable({
  providedIn: 'root'
})
export class TreasuryEvaluationParametersUpdaterService extends AbstractSessionParametersUpdaterService<TreasuryEvaluationParameters>{

  constructor() { super('evaluations/TREASURY_MARK/parameters') }
}

import {Injectable, Type} from '@angular/core';
import {ConfirmationPopupComponent} from './confirmation-popup.component';
import {TranslateService} from "@ngx-translate/core";
import {AbstractPopupService} from "../abstract-popup.service";
import {LocatorService} from "../locator.service";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export abstract class ConfirmationPopupService<U extends ConfirmationPopupComponent> extends AbstractPopupService<U> {
    protected translateService: TranslateService;

    protected constructor(
        popupClass: Type<U>
    ) {
        super(popupClass);
        this.translateService = LocatorService.injector.get(TranslateService);
    }

    protected prepareConfirmationPopup(
        title: string,
        message: string | Observable<string>,
        confirmButtonAction: Function,
        confirmButtonMessage?: string,
        cancelButtonMessage?: string
    ): U {
        const popupComponent = new ConfirmationPopupComponent() as U;

        popupComponent.confirmed.subscribe(() => confirmButtonAction());

        // Set the message
        popupComponent.title = title;
        popupComponent.message = message;

        if (confirmButtonMessage) {
            popupComponent.confirmButtonMessage = confirmButtonMessage;
        } else {
            this.translateService.get('common.action.confirm').subscribe(message => popupComponent.confirmButtonMessage = message);
        }

        if (cancelButtonMessage) {
            popupComponent.cancelButtonMessage = cancelButtonMessage;
        } else {
            this.translateService.get('common.action.cancel').subscribe(message => popupComponent.cancelButtonMessage = message);
        }

        return popupComponent;
    }

    protected fillPopupInstance(popupInstance: U, preparedPopup: U) {
        popupInstance.title = preparedPopup.title;
        popupInstance.cancelButtonMessage = preparedPopup.cancelButtonMessage;
        popupInstance.confirmButtonMessage = preparedPopup.confirmButtonMessage;
        popupInstance.message = preparedPopup.message;
        popupInstance.confirmed = preparedPopup.confirmed;
        popupInstance.closed = preparedPopup.closed;
        this.fillConfirmationPopupInstance(popupInstance, preparedPopup);
    }

    protected abstract fillConfirmationPopupInstance(confirmationPopupInstance: U, preparedPopup: U);

}

import {HttpClient} from "@angular/common/http";
import {LocatorService} from "../../../locator.service";
import {Context} from "../../../context/context";
import {Observable} from "rxjs";
import {AbstractParametersUpdaterService} from './abstract-parameters-updater.service';

export abstract class AbstractSessionParametersUpdaterService<T> implements AbstractParametersUpdaterService<T>{
    private httpClient: HttpClient;

    protected constructor(
        private parametersType: string
    ) {
        this.httpClient = LocatorService.injector.get(HttpClient);
    }

    updateParameters(context: Context, parameters: T): Observable<T> {
        return this.updateSessionParameters(context, parameters);
    }

    private updateSessionParameters(context: Context, sessionParameters: T): Observable<T> {
        return this.httpClient.put<T>('/api/sessions/' + context.currentSessionId + '/parameters/' + this.parametersType, sessionParameters);
    }
}

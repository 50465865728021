import {Component} from '@angular/core';
import {AbstractPopupComponent} from "../abstract-popup-component";
import {NotificationService} from "../notification.service";
import {PasswordChangeService} from "./password-change.service";
import {PasswordChange} from "./password-change";

@Component({
    selector: 'kiwi-password-change-popup',
    templateUrl: './password-change-popup.component.html',
    styleUrls: ['./password-change-popup.component.scss'],
    host: {'class': 'popupContainer'}
})
export class PasswordChangePopupComponent extends AbstractPopupComponent {
    private currentPassword = '';
    private newPassword = '';
    public userId: string;
    public descriptionKey: string;

    constructor(
        private notificationService: NotificationService,
        private passwordChangeService: PasswordChangeService
    ) {
        super();
    }

    onConfirm(): void {
        this.passwordChangeService.changePassword(this.userId, new PasswordChange(this.currentPassword, this.newPassword))
            .subscribe(changed => {
                    if (changed) this.close()
                },
                err => {
                    this.notificationService.error(err.error);
                });
    }
}

import {GenericsModule} from '../generics/generics.module';
import {PipesModule} from '../../../pipes/pipes.module';
import {ComponentsModule} from '../../../components/components.module';
import {ContextModule} from '../../../context/context.module';
import {TabsModule} from '../../tabs/tabs.module';
import {CanActivateTeamDecisionsGuard} from '../guards/can-activate-team-decisions.guard';
import {DecisionConfirmationPopupComponent} from '../check-restaurant-type/decision-confirmation-popup/decision-confirmation-popup.component';
import {SaveBeforeDeactivatePopupComponent} from '../../../components/save-before-deactivate-popup/save-before-deactivate-popup.component';
import {AnimatorCommunicationTaskMarkDecisionComponent} from './animator-communication-task-mark-decision/animator-communication-task-mark-decision.component';
import {PlayerCommunicationDecisionComponent} from './player-communication-decision/player-communication-decision.component';
import {CommunicationDecisionComponent} from './communication-decision.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [
        AnimatorCommunicationTaskMarkDecisionComponent,
        PlayerCommunicationDecisionComponent,
        CommunicationDecisionComponent
    ],
    imports: [
        TranslateModule,
        FormsModule,
        CommonModule,
        GenericsModule,
        PipesModule,
        ComponentsModule,
        ContextModule,
        TabsModule,
        RouterModule
    ],
    providers: [
        CanActivateTeamDecisionsGuard
    ],
    entryComponents: [
        DecisionConfirmationPopupComponent,
        SaveBeforeDeactivatePopupComponent
    ]
})
export class CommunicationDecisionModule {

}

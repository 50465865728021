import {Component} from '@angular/core';
import {AssignmentsParameters} from './assignments-parameters';
import {AssignmentsParametersLoaderService} from './assignments-parameters-loader.service';
import {AssignmentsParametersUpdaterService} from './assignments-parameters-updater.service';
import {genericParametersHost} from '../../generic-parameters/generic-parameters.component';
import {GenericPeriodParametersComponent} from '../../generic-parameters/generic-period-parameters.component';

@Component({
    templateUrl: './assignments-weight-parameters.component.html',
    styleUrls: ['./../../generic-parameters/generic-parameters.component.scss'],
    host: genericParametersHost
})
export class AssignmentsWeightsParametersComponent extends GenericPeriodParametersComponent<AssignmentsParameters> {

    constructor(assignmentsParametersLoaderService: AssignmentsParametersLoaderService,
                assignmentsParametersUpdaterService: AssignmentsParametersUpdaterService) {
        super(assignmentsParametersLoaderService, assignmentsParametersUpdaterService, 'toast.parameters.quality.assignments.weight.saved');
    }
}

import {User} from "../../login/user";

export class UsersList {
    constructor(
        public readonly name: string,
        public readonly users: User[]
    ) {

    }
}

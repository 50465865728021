import { Injectable } from '@angular/core';
import {AbstractTeamResultLoader} from "../../../abstract-team-result-loader";

@Injectable({
  providedIn: 'root'
})
export class WinesQualitiesLoaderService extends AbstractTeamResultLoader<Object>{

  constructor() { super('quality/wines'); }
}

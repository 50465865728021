import { Injectable } from '@angular/core';
import {AbstractSessionParametersLoaderService} from '../../services/abstract-session-parameters-loader.service';
import {SalesRepartitionParameters} from './sales-repartition-parameters';

@Injectable({
  providedIn: 'root'
})
export class SalesRepartitionParametersLoaderService extends AbstractSessionParametersLoaderService<SalesRepartitionParameters>{

  constructor() {
      super('salesRepartition');
  }
}

export enum CategoryName {
    SYNTHESIS = 'SYNTHESIS',
    ACCOUNTS_DETAIL = 'ACCOUNTS_DETAIL',
    SALES_HISTORY = 'SALES_HISTORY',
    POTENTIAL_MARKET_AND_SALES = 'POTENTIAL_MARKET_AND_SALES',
    COMMUNICATION_PARAMETERS = 'COMMUNICATION_PARAMETERS',
    HUMAN_RESOURCES_PARAMETERS = 'HUMAN_RESOURCES_PARAMETERS',
    DINING_ROOMS_PARAMETERS = 'DINING_ROOMS_PARAMETERS',
    COMPETITIVE_INTELLIGENCE = 'COMPETITIVE_INTELLIGENCE',
    FINANCIAL_COMPARISONS = 'FINANCIAL_COMPARISONS',
    SALES_DETAILS = 'SALES_DETAIL',
    QUALITY = 'QUALITY'
}

import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, map, switchMap, take} from "rxjs/operators";
import {HttpClient} from '@angular/common/http';
import {ContextService} from '../../../context/context.service';
import {CannotActivateReason, GuardResult} from './guard-result';
import {SessionLockedForUserService} from '../../session-locked-for-user.service';
import {Context} from '../../../context/context';

@Injectable({
    providedIn: 'root'
})
export class CanActivateTeamDecisionsGuard implements CanActivate, CanActivateChild {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly contextService: ContextService,
        private readonly sessionLockedForUserService: SessionLockedForUserService,
        private readonly router: Router
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.checkCanActivate();
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.checkCanActivate();
    }

    private checkCanActivate(): Observable<boolean | UrlTree> {
        return this.contextService.context
            .pipe(
                filter(context => !!context && !!context.currentTeam),
                take(1),
                switchMap(context => this.httpClient.get<GuardResult>(CanActivateTeamDecisionsGuard.buildGuardResourceUrl(context))),
                map(guardResult => {
                    if (!guardResult.canActivate) {
                        if (guardResult.reason === CannotActivateReason.SESSION_LOCKED) {
                            return this.sessionLockedForUserService.urlToSessionBlocked();
                        }

                        if (guardResult.reason === CannotActivateReason.AREA_NOT_CHOSEN) {
                            return this.router.parseUrl('/session/decisions/area');
                        }

                        if (guardResult.reason === CannotActivateReason.ARCHETYPE_NOT_CHOSEN) {
                            return this.router.parseUrl('/session/decisions/archetypes');
                        }
                    }

                    return guardResult.canActivate;
                })
            )
    }

    private static buildGuardResourceUrl(context: Context): string {
        return 'api/sessions/' + context.currentSessionId +
            '/periods/' + context.currentPeriod +
            '/teams/' + context.currentTeam.teamNumber +
            '/guards/canActivateTeamDecisions';
    }
}

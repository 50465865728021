import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RestaurantStandingInformation} from './restaurant-standing-information';
import {Context} from './context';

@Injectable({
    providedIn: 'root'
})
export class RestaurantStandingInformationLoaderService {

    constructor(
        private readonly httpClient: HttpClient
    ) {
    }

    retrieveData(team: number, period: string, session: string): Observable<RestaurantStandingInformation> {
        return this.httpClient.get<RestaurantStandingInformation>('/api/sessions/' + session +
            '/periods/' + period +
            '/result/teams/' + team +
            '/restaurantStandingInformation');
    }

    loadRestaurantStandingInformation(context: Context): Observable<RestaurantStandingInformation> {
        return this.retrieveData(context.currentTeam.teamNumber, context.currentPeriod, context.currentSessionId);
    }
}

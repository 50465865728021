import {Component} from '@angular/core';
import {CommunicationDecision} from "./communication-decision";
import {CommunicationDecisionUpdaterService} from "./communication-decision-updater.service";
import {CommunicationDecisionLoaderService} from "./loaders/communication-decision-loader.service";
import {Context} from "../../../../context/context";
import {forkJoin, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {EffectiveAdvertisementBudgetLoaderService} from "./loaders/effective-advertisement-budget-loader.service";
import {PeriodUtils} from "../../../studies/study-header/period-utils";
import {StandingParameters} from "../../../parameters/services/standing/standing-parameters";
import {MenuStandingLoaderService} from "./loaders/menu-standing-loader.service";
import {
    StandingParametersLoaderService
} from "../../../parameters/services/standing/standing-parameters-loader.service";
import {CommunicationParameters} from "../../../parameters/services/communication/communication-parameters";
import {
    CommunicationParametersLoaderService
} from "../../../parameters/services/communication/communication-parameters-loader.service";
import {Score} from "../../generics/score-image/score-image.component";
import {
    CheckRestaurantTypeDecisionComponent
} from "../../check-restaurant-type/check-restaurant-type-decision-component";
import {computeAdvertisementQuality} from './compute-communication-quality';

export class CommunicationDecisionComponentParameters {
    constructor(
        public readonly communicationParameters: CommunicationParameters,
        public readonly previousPeriodEffectiveBudget: number,
        public readonly menuStanding: number,
        public readonly standingParameters: StandingParameters
    ) {
    }
}

@Component({
    templateUrl: './player-communication-decision.component.html',
    styleUrls: ['./player-communication-decision.component.scss'],
    host: {'class': 'cell auto grid-y'}
})

export class PlayerCommunicationDecisionComponent extends CheckRestaurantTypeDecisionComponent<CommunicationDecision, CommunicationDecisionComponentParameters> {
    constructor(
        private communicationParametersLoaderService: CommunicationParametersLoaderService,
        private effectiveAdvertisementBudgetLoader: EffectiveAdvertisementBudgetLoaderService,
        private menuStandingLoaderService: MenuStandingLoaderService,
        private standingParametersLoaderService: StandingParametersLoaderService,
        communicationDecisionLoaderService: CommunicationDecisionLoaderService,
        communicationDecisionUpdaterService: CommunicationDecisionUpdaterService
    ) {
        super(
            communicationDecisionLoaderService,
            communicationDecisionUpdaterService,
            [new Score('decisions.communication.quality', 0)],
            'communication',
            'toast.communication.decision.saved.message'
        );
    }

    protected loadParameters(context: Context): Observable<CommunicationDecisionComponentParameters> {
        return forkJoin(
            this.communicationParametersLoaderService.loadParameters(context),
            this.effectiveAdvertisementBudgetLoader.loadTeamResultFromContext(
                context,
                PeriodUtils.computePreviousPeriod(
                    context.currentPeriod,
                    context.currentSession.baseMetaScenario.periodicity)),
            this.menuStandingLoaderService.loadMenuStanding(context),
            this.standingParametersLoaderService.loadParameters(context)
        )
            .pipe(map(([communicationParameters, previousPeriodEffectiveAdvertisementBudget, menuStanding, standingParameters]) => {
                return new CommunicationDecisionComponentParameters(
                    communicationParameters,
                    previousPeriodEffectiveAdvertisementBudget,
                    menuStanding,
                    standingParameters
                )
            }));
    }

    protected computeDecisionQuality(scores: Score[], parameters: CommunicationDecisionComponentParameters): number {
        return scores[0].value;
    }

    protected updateScores(decision: CommunicationDecision, parameters: CommunicationDecisionComponentParameters): void {
        this.scores[0].value = computeAdvertisementQuality(decision, parameters);
    }
}

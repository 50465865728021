import {Component, OnInit} from '@angular/core';
import {ForgottenPasswordPopupService} from "./forgotten-password-popup/forgotten-password-popup.service";
import {ConnectedUserService} from "../context/connected-user.service";
import {ContextService} from "../context/context.service";
import {NotificationService} from '../notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: {'class': 'grid-y align-center align-middle grid-frame'}
})
export class LoginComponent implements OnInit {
    private login: string;
    private password: string;
    public isPasswordClear = false;

      constructor(
        private connectedUserService: ConnectedUserService,
        private context: ContextService,
        private forgottenPasswordPopupService: ForgottenPasswordPopupService,
        private readonly notificationService: NotificationService
      ) {
      }

    ngOnInit() {
    }

    authenticate(login: string, password: string) {
        this.connectedUserService.authenticate(login, password)
            .subscribe(
                () => {},
                (error) => {
                    let errorLabelKey: string;
                    switch (error.error) {
                        case 'LOGIN_DOES_NOT_EXIST':
                            errorLabelKey = 'errors.password.change.unknown.login';
                            break;
                        case 'WRONG_PASSWORD':
                            errorLabelKey = 'errors.authentication.failed';
                            break;
                        case 'LOCKED_CUSTOMER_ACCOUNT':
                            errorLabelKey = 'errors.locked.customerAccount';
                            break;
                    }
                    this.notificationService.error(errorLabelKey);
                }
            );
    }

    showForgottenPasswordPopup(username: string) {
        this.forgottenPasswordPopupService.showForgottenPasswordPopup(username);
    }

    togglePassword(): void {
        this.isPasswordClear = !this.isPasswordClear;
    }
}

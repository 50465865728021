import {Component, OnDestroy, OnInit} from '@angular/core';
import {SeverityDispersionParameters} from "./severity-dispersion-parameters";
import {SeverityDispersionParametersLoaderService} from "./severity-dispersion-parameters-loader.service";
import {ContextService} from "../../../../context/context.service";
import {filter, switchMap, tap} from "rxjs/operators";
import {SeverityDispersionParametersUpdaterService} from "./severity-dispersion-parameters-updater.service";
import {NotationInformation} from "./notation-information";
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {SeverityDispersionParametersApplier} from './severity-dispersion-parameters-applier';
import {AbstractModificationComponent} from '../../../../components/abstract-modification.component';

@Component({
    selector: 'kiwi-evaluation-severity',
    templateUrl: './evaluation-severity.component.html',
    styleUrls: ['./evaluation-severity.component.scss'],
    host: {'class': 'cell auto tabsContainer-content'}
})
export class EvaluationSeverityComponent extends AbstractModificationComponent<SeverityDispersionParameters> implements OnInit, OnDestroy {
    public markType: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private severityDispersionParameters: SeverityDispersionParameters;
    private _initialMarks: number[];
    private marksBeforeChanges: NotationInformation;
    private marksAfterChanges: NotationInformation;
    private subscription: Subscription;
    public dirty = false;
    public maxMark: number;

    private set initialMarks(initialMarks: number[]) {
        this._initialMarks = initialMarks;
        this.marksBeforeChanges = SeverityDispersionParametersApplier.applySeverityAndDispersion(
            SeverityDispersionParameters.buildNoMarksChangeParameters(this.severityDispersionParameters),
            initialMarks,
            this.maxMark
        );
        this.marksAfterChanges = SeverityDispersionParametersApplier.applySeverityAndDispersion(
            this.severityDispersionParameters,
            initialMarks,
            this.maxMark
        );
    }

    constructor(
        private severityDispersionParametersLoaderService: SeverityDispersionParametersLoaderService,
        private severityDispersionParametersUpdaterService: SeverityDispersionParametersUpdaterService,
        private contextService: ContextService
    ) {
        super(
            'toast.parameters.saved.title',
            'evaluations.severity.dispersion.parameters.saved.successfully'
        );
    }

    ngOnInit(): void {
        this.subscription = combineLatest(this.contextService.context, this.markType)
            .pipe(
                filter(([context, markType]) => !!context && !!context.currentSessionId && markType !== ''),
                switchMap(([context, markType]) =>
                    this.severityDispersionParametersLoaderService.loadSeverityDispersionParameters(markType, context.currentSessionId)
                )
            ).subscribe(dto => {
                this.severityDispersionParameters = dto.severityDispersionParameters;
                this.maxMark = dto.maxMark;
                this.initialMarks = dto.marks;
            });
    }

    onSave(): void {
        this.save();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onSeverityOrDispersionChange() {
        this.dirty = true;
        this.marksAfterChanges = SeverityDispersionParametersApplier.applySeverityAndDispersion(
            this.severityDispersionParameters,
            this._initialMarks,
            this.maxMark
        );
    }

    protected callSave(): Observable<SeverityDispersionParameters> {
        return this.severityDispersionParametersUpdaterService
            .updateSeverityDispersionParameters(
                this.markType.getValue(),
                this.contextService.context.getValue().currentSessionId,
                this.severityDispersionParameters
            )
            .pipe(
                tap((severityDispersionParameters) => {
                    this.severityDispersionParameters = severityDispersionParameters;
                })
            );
    }
}

import { OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {Tab} from '../../app-main/tabs/tab';
import { Subscription } from 'rxjs';

export class MenuComponent implements OnInit, OnDestroy {
    private eventsSubscription: Subscription;

    constructor(
        private readonly router:Router,
        public readonly tabs: Tab[],
        public readonly tabsLabelPrefix: string
    ) {
    }

    ngOnInit(): void {
        this.updateTabsSelection();
        this.eventsSubscription = this.router.events.subscribe(_ => {
            this.updateTabsSelection();
        })
    }

    private updateTabsSelection(): void {
        const url = this.router.url;
        this.tabs.forEach(tab => tab.isSelected = url.includes(tab.url));
    }

    ngOnDestroy(): void {
        if(this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
        }
    }
}

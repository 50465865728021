import {Component} from '@angular/core';
import {AbstractDecisionComponent} from "../abstract-decision-component";
import {DiningRoomsDecisionUpdaterService} from "./dining-rooms-decision-updater.service";
import {DiningRoomsDecision} from "./dining-rooms-decision";
import {DiningRoomsDecisionLoaderService} from "./dining-rooms-decision-loader.service";
import {Context} from "../../../context/context";
import {forkJoin, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AreaDecisionLoaderService} from "../area-decision/area-decision-loader.service";
import {QualityParametersLoaderService} from "../../parameters/services/quality/quality-parameters-loader.service";
import {
    MaintenanceParametersLoaderService
} from "../../parameters/services/maintenance/maintenance-parameters-loader.service";
import {EffectiveMaintenanceBudgetLoaderService} from "./effective-maintenance-budget-loader.service";
import {PeriodUtils} from "../../studies/study-header/period-utils";
import {
    DiningRoomsParametersLoaderService
} from "../../parameters/services/dining-rooms/dining-rooms-parameters-loader.service";
import {DiningRoomsParameters} from "../../parameters/services/dining-rooms/dining-rooms-parameters";
import {Score} from "../generics/score-image/score-image.component";
import {RentParametersLoaderService} from '../../parameters/services/period/rent/rent-parameters-loader.service';
import {ComputeLayoutPurchaseCostUtils} from './compute-layout-purchase-cost-utils';
import {ComputeLayoutSellingIncomeUtils} from './compute-layout-selling-income-utils';
import {computeLayoutTotalQuality, computeMaintenanceQuality} from './compute-dining-rooms-quality';
import {computePurchasingPrice} from '../compute-purchasing-price';

export class DiningRoomsDecisionComponentParameters {
    constructor(
        public readonly diningRoomsParameters: DiningRoomsParameters,
        public readonly layoutQualityWeight: number,
        public readonly maintenanceQualityWeight: number,
        public readonly standardMaintenanceBudgetPerRoom: number,
        public readonly previousPeriodEffectiveMaintenanceBudgetPerRoom: number,
        public readonly previousPeriodDiningRoomsDecision: DiningRoomsDecision
    ) {
    }
}

@Component({
    selector: 'kiwi-dining-rooms-decision',
    templateUrl: './dining-rooms-decision.component.html',
    styleUrls: ['./dining-rooms-decision.component.scss'],
    host: {'class': 'cell auto grid-y decisionContainer _diningRooms'}
})
export class DiningRoomsDecisionComponent extends AbstractDecisionComponent<DiningRoomsDecision, DiningRoomsDecisionComponentParameters> {
    private areaMonthlyRent: number;
    public totalFurniturePurchaseCost: number;
    public totalFurnitureSellingIncome: number;
    public totalTablewarePurchaseCost: number;
    public totalTablewareSellingIncome: number;

    constructor(
        private readonly rentParametersLoaderService: RentParametersLoaderService,
        private readonly diningRoomParametersLoaderService: DiningRoomsParametersLoaderService,
        private areaDecisionLoaderService: AreaDecisionLoaderService,
        private qualityParametersLoaderService: QualityParametersLoaderService,
        private maintenanceParametersLoaderService: MaintenanceParametersLoaderService,
        private effectiveMaintenanceBudgetPerRoomLoaderService: EffectiveMaintenanceBudgetLoaderService,
        diningRoomsDecisionUpdaterService: DiningRoomsDecisionUpdaterService,
        diningRoomsDecisionLoaderService: DiningRoomsDecisionLoaderService
    ) {
        super(
            diningRoomsDecisionLoaderService,
            diningRoomsDecisionUpdaterService,
            [new Score('decisions.dining.rooms.total.layout.quality', 0),
                new Score('decisions.dining.rooms.maintenance.quality', 0)],
            'toast.dining.rooms.decision.saved.message',
            (diningRoomsDecision) => {
                this.recomputeFurnitureLayoutSellingIncome();
                this.recomputeFurnitureLayoutPurchaseCost();
                this.recomputeTablewareLayoutSellingIncome();
                this.recomputeTablewareLayoutPurchaseCost();
            }
        );
    }

    protected loadParameters(context: Context): Observable<DiningRoomsDecisionComponentParameters> {
        const previousPeriod = PeriodUtils.computePreviousPeriod(context.currentPeriod, context.currentSession.baseMetaScenario.periodicity);
        return forkJoin(
            this.rentParametersLoaderService.loadParameters(context),
            this.diningRoomParametersLoaderService.loadParameters(context),
            this.areaDecisionLoaderService.loadDecisionFromContext(context),
            this.qualityParametersLoaderService.loadParameters(context),
            this.maintenanceParametersLoaderService.loadParameters(context),
            this.effectiveMaintenanceBudgetPerRoomLoaderService.loadTeamResultFromContext(context, previousPeriod),
            this.decisionLoader.loadDecisionFromContext(context, previousPeriod)
        ).pipe(map(([
                        rentParameters,
                        diningRoomsParameters,
                        areaDecision,
                        qualityParameters,
                        maintenanceParameters,
                        effectiveMaintenanceBudgetPerRoom,
                        previousPeriodDiningRoomsDecision
                    ]) => {
            if (areaDecision.area === areaDecision.averageArea)
                this.areaMonthlyRent = rentParameters.averageAreaDiningRoomRent;
            if (areaDecision.area === areaDecision.workingClassArea)
                this.areaMonthlyRent = rentParameters.workingClassAreaDiningRoomRent;
            if (areaDecision.area === areaDecision.wealthyArea)
                this.areaMonthlyRent = rentParameters.wealthyAreaDiningRoomRent;

            return new DiningRoomsDecisionComponentParameters(
                diningRoomsParameters,
                qualityParameters.layoutQualityWeight,
                qualityParameters.maintenanceQualityWeight,
                maintenanceParameters.standardMaintenanceBudgetPerRoom,
                effectiveMaintenanceBudgetPerRoom,
                previousPeriodDiningRoomsDecision
            );
        }));
    }

    onDiningRoomChange(layoutChangingCostsAndIncomes: number[]) {
        this.updateTotalLayoutQuality();
    }

    onMaintenanceBudgetPerRoomChange() {
        this.onValueUpdate();
        this.updateTotalMaintenanceBudget();
    }

    private updateTotalMaintenanceBudget() {
        this.updateScoreImage();
    }

    private updateSeatingCapacity() {
        this.decision.seatingCapacity =
            this.parameters.diningRoomsParameters.diningRoomSeatingCapacity * this.decision.roomsNumber;
    }

    private updateTotalLayoutQuality() {
        const layoutDecision = this.decision.layoutDecision;
        this.decision.layoutDecision.layoutTotalQuality = computeLayoutTotalQuality(layoutDecision);
        this.updateScoreImage();
    }

    protected computeDecisionQuality(scores: Score[], parameters: DiningRoomsDecisionComponentParameters): number {
        const totalWeight = parameters.layoutQualityWeight + parameters.maintenanceQualityWeight;
        return (scores[0].value * parameters.layoutQualityWeight / totalWeight) +
            (scores[1].value * parameters.maintenanceQualityWeight / totalWeight);
    }

    protected updateScores(decision: DiningRoomsDecision, parameters: DiningRoomsDecisionComponentParameters): void {
        this.scores[0].value = decision.layoutDecision.layoutTotalQuality;
        this.scores[1].value = computeMaintenanceQuality(decision.maintenanceDecision, parameters);
    }

    public updateFurnitureCurrentQuality() {
        this.decision.layoutDecision.furniture.newValuePerRoom = computePurchasingPrice(
            this.decision.layoutDecision.furniture.newQuality,
            this.parameters.diningRoomsParameters.standardFurniturePrice,
            this.decision.layoutDecision.furniture.currentQuality,
            this.decision.layoutDecision.furniture.currentValuePerRoom
        );
        this.recomputeFurnitureLayoutPurchaseCost();
        this.recomputeFurnitureLayoutSellingIncome();
        this.updateTotalLayoutQuality();
    }

    public updateTablewareCurrentQuality() {
        this.decision.layoutDecision.tableware.newValuePerRoom = computePurchasingPrice(
            this.decision.layoutDecision.tableware.newQuality,
            this.parameters.diningRoomsParameters.standardSilverwarePrice,
            this.decision.layoutDecision.tableware.currentQuality,
            this.decision.layoutDecision.tableware.currentValuePerRoom
        );
        this.recomputeTablewareLayoutPurchaseCost();
        this.recomputeTablewareLayoutSellingIncome();
        this.updateTotalLayoutQuality();
    }

    private recomputeFurnitureLayoutPurchaseCost(): void {
        this.totalFurniturePurchaseCost = ComputeLayoutPurchaseCostUtils.computeLayoutPurchaseCost(
            this.decision.layoutDecision.furniture,
            this.decision.roomsNumber,
            this.parameters.previousPeriodDiningRoomsDecision.roomsNumber
        );
    }

    private recomputeTablewareLayoutPurchaseCost(): void {
        this.totalTablewarePurchaseCost = ComputeLayoutPurchaseCostUtils.computeLayoutPurchaseCost(
            this.decision.layoutDecision.tableware,
            this.decision.roomsNumber,
            this.parameters.previousPeriodDiningRoomsDecision.roomsNumber
        );
    }

    private recomputeFurnitureLayoutSellingIncome(): void {
        this.totalFurnitureSellingIncome = ComputeLayoutSellingIncomeUtils.computeLayoutSellingIncome(
            this.decision.layoutDecision.furniture,
            this.decision.roomsNumber,
            this.decision.previousPeriodRoomsResults,
            (roomResult) => roomResult.furniture
        );
    }

    private recomputeTablewareLayoutSellingIncome(): void {
        this.totalTablewareSellingIncome = ComputeLayoutSellingIncomeUtils.computeLayoutSellingIncome(
            this.decision.layoutDecision.tableware,
            this.decision.roomsNumber,
            this.decision.previousPeriodRoomsResults,
            (roomResult) => roomResult.tableware
        );
    }

    public onRoomsNumberUpdate() {
        this.onValueUpdate();
        this.recomputeFurnitureLayoutPurchaseCost();
        this.recomputeFurnitureLayoutSellingIncome();
        this.recomputeTablewareLayoutPurchaseCost();
        this.recomputeTablewareLayoutSellingIncome();
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Message} from "../message";
import {User} from "../../../login/user";

@Component({
    selector: 'kiwi-message-content',
    templateUrl: './message-content.component.html',
    styleUrls: ['./message-content.component.scss']
})
export class MessageContentComponent {
    @Input()
    displayActions: boolean;

    @Input()
    message: Message;

    @Output()
    messageDeleted = new EventEmitter<Message>();

    @Output()
    answerTo = new EventEmitter<User>();

    onDeleteMessage() {
        this.messageDeleted.emit(this.message);
    }

    onAnswerTo() {
        this.answerTo.emit(this.message.author);
    }
}

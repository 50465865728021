import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {LocatorService} from "../../../locator.service";
import {RestaurantTypeChange} from './restaurant-type-change.model';

export class CheckRestaurantTypeService<T> {
    private readonly httpClient: HttpClient;

    constructor(
        private decisionType: string
    ) {
        this.httpClient = LocatorService.injector.get(HttpClient);
    }

    public checkRestaurantTypeChange(sessionId: string, currentPeriod: string, teamId: number, decision: T): Observable<RestaurantTypeChange> {
        return this.httpClient.put<RestaurantTypeChange>(
            '/api/sessions/' + sessionId +
            '/periods/' + currentPeriod +
            '/teamDecisions/' + teamId +
            '/' + this.decisionType + '/restaurantTypeChange',
            decision);
    }
}

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-rooms',
    templateUrl: './rooms.component.html',
    styleUrls: ['./rooms.component.scss'],
    host: {'class': 'cell auto grid-y cell-block-y blockContent'}
})
export class RoomsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

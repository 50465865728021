import {Component} from '@angular/core';

@Component({
    selector: 'kiwi-area-competition-header',
    templateUrl: './area-competition-header.component.html',
    styleUrls: ['./area-competition-header.component.scss'],
    host: {'class': 'cell shrink grid-x align-middle align-justify'}
})
export class AreaCompetitionHeaderComponent {
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StudyComponent} from "./study.component";
import {StudyHeaderComponent} from "./study-header/study-header.component";
import {ProfitLossAccountsComponent} from "./synthesis/profit-loss-accounts/profit-loss-accounts.component";
import {StudyDetailComponent} from "./study-detail/study-detail.component";
import {BalanceSheetComponent} from "./synthesis/balance-sheet/balance-sheet.component";
import {PipesModule} from "../../pipes/pipes.module";
import {Route, RouterModule, Routes} from "@angular/router";
import {HttpClientModule} from "@angular/common/http";
import {TranslateModule} from "@ngx-translate/core";
import {ComponentsModule} from "../../components/components.module";
import {CashFlowStatementComponent} from './synthesis/cash-flow/cash-flow-statement.component';
import {ExternalLoadsDetailComponent} from './accounts-detail/external-loads-detail/external-loads-detail.component';
import {SalesDetailsComponent} from './internal-data/sales-details/sales-details.component';
import {
    AdvertisementBudgetComparisonComponent
} from './competitive-intelligence/advertisement-budget-comparison/advertisement-budget-comparison.component';
import {CompetitiveIntelligenceComponent} from './competitive-intelligence/competitive-intelligence.component';
import {ChartsModule} from "ng2-charts";
import {
    MaintenanceQualityComparisonComponent
} from './competitive-intelligence/maintenance-quality-comparison/maintenance-quality-comparison.component';
import {
    LayoutQualityComparisonComponent
} from './competitive-intelligence/layout-quality-comparison/layout-quality-comparison.component';
import {
    CookingQualityComparisonComponent
} from './competitive-intelligence/cooking-quality-comparison/cooking-quality-comparison.component';
import {
    CustomerCareQualityComparisonComponent
} from './competitive-intelligence/customer-care-quality-comparison/customer-care-quality-comparison.component';
import {
    DishesSalesPricesComparisonComponent
} from './competitive-intelligence/dishes-sales-prices-comparison/dishes-sales-prices-comparison.component';
import {TabsModule} from "../tabs/tabs.module";
import {
    DishSalesPricesComparisonComponent
} from './competitive-intelligence/dishes-sales-prices-comparison/dish-sales-prices-comparison/dish-sales-prices-comparison.component';
import {
    DishesQualityComparisonComponent
} from './competitive-intelligence/dishes-quality-comparison/dishes-quality-comparison.component';
import {
    DishQualityComparisonComponent
} from './competitive-intelligence/dishes-quality-comparison/dish-quality-comparison/dish-quality-comparison.component';
import {
    WinesQualityComparisonComponent
} from './competitive-intelligence/wines-quality-comparison/wines-quality-comparison.component';
import {
    WineQualityComparisonComponent
} from './competitive-intelligence/wines-quality-comparison/wine-quality-comparison/wine-quality-comparison.component';
import {MarketSharesComponent} from './competitive-intelligence/market-shares/market-shares.component';
import {
    ProductMarketSharesComponent
} from './competitive-intelligence/market-shares/product-market-shares/product-market-shares.component';
import {ChartDataSetDataPipe} from './competitive-intelligence/market-shares/chart-data-set-data.pipe';
import {ChartDataSetLabelPipe} from './competitive-intelligence/market-shares/chart-data-set-label.pipe';
import {SpecimenComponent} from './specimen/specimen.component';
import {accountsDetailsChildrenRoutes} from './accounts-detail/accounts-details-routes';
import {communicationStudyChildrenRoutes} from './routing/communication-study-routes';
import {competitiveIntelligenceChildrenRoutes} from './routing/competitive-intelligence-routes';
import {diningRoomsParametersChildrenRoutes} from './routing/dining-rooms-parameters-routes';
import {humanResourcesParametersChildrenRoutes} from './routing/human-resources-parameters-routes';
import {potentialMarketAndSalesChildrenRoutes} from './routing/potential-market-and-sales-routes';
import {salesDetailsChildrenRoutes} from './routing/sales-details-routes';
import {salesHistoryChildrenRoutes} from './routing/sales-history-routes';
import {synthesisChildrenRoutes} from './routing/synthesis-routes';
import {CategoryName} from './category-name';
import {TurnoverComponent} from './accounts-detail/turnover/turnover.component';
import {PurchasesStudyComponent} from './accounts-detail/purchases-study/purchases-study.component';
import {HumanResourcesParametersComponent} from "./human-resources-parameters/human-resources-parameters.component";
import {HumanResourcesStudyComponent} from './accounts-detail/human-resources/human-resources-study.component';
import {QualityComponent} from './internal-data/quality/quality.component';
import {qualityChildrenRoutes} from "./routing/quality-routes";
import {
    PotentialMarketAndSalesStudyComponent
} from './internal-data/potential-market-and-sales-study/potential-market-and-sales-study.component';
import {ExceptionalResultComponent} from './accounts-detail/exceptional-result/exceptional-result.component';
import {FinancialResultComponent} from './accounts-detail/financial-result/financial-result.component';
import {TaxesStudyComponent} from './accounts-detail/taxes-study/taxes-study.component';
import {TeamsDataComponent} from './competitive-intelligence/teams-data/teams-data.component';
import {SalesHistoryComponent} from './internal-data/sales-history/sales-history.component';
import {
    DepreciationChargesStudyComponent
} from './accounts-detail/depreciation-charges-study/depreciation-charges-study.component';
import {CommunicationStudyComponent} from './communication-study/communication-study.component';
import {DiningRoomParametersComponent} from './dining-room-parameters/dining-room-parameters.component';
import {WineStocksComponent} from './accounts-detail/wine-stocks/wine-stocks.component';
import {
    EquityComparisonComponent
} from './competitive-intelligence/financial-comparisons/equity-comparison/equity-comparison.component';
import {financialComparisonsChildrenRoutes} from './routing/financial-comparisons-children-routes';
import {
    AccumulatedNetResultComparisonComponent
} from './competitive-intelligence/financial-comparisons/accumulated-net-result-comparison/accumulated-net-result-comparison.component';
import {NumberOrNothingComponent} from './internal-data/quality/number-or-nothing/number-or-nothing.component';
import {StudyRouteDefinition} from './study-route-definition';

function buildStudiesRoutes(childrenRoutes: StudyRouteDefinition[], categoryName: CategoryName): Routes {
    return childrenRoutes.map(studyRouteDefinition => {
        return {
            path: categoryName + '/' + studyRouteDefinition.path,
            component: SpecimenComponent,
            data: {
                categoryId: categoryName,
                studyId: studyRouteDefinition.studyId
            },
            children: studyRouteDefinition.routes
        }
    });
}

export const studiesRoutes: Routes =
    [
        {
            path: ':category',
            component: StudyComponent
        } as Route,
    ]
        .concat(buildStudiesRoutes(accountsDetailsChildrenRoutes, CategoryName.ACCOUNTS_DETAIL))
        .concat(buildStudiesRoutes(communicationStudyChildrenRoutes, CategoryName.COMMUNICATION_PARAMETERS))
        .concat(buildStudiesRoutes(competitiveIntelligenceChildrenRoutes, CategoryName.COMPETITIVE_INTELLIGENCE))
        .concat(buildStudiesRoutes(financialComparisonsChildrenRoutes, CategoryName.FINANCIAL_COMPARISONS))
        .concat(buildStudiesRoutes(diningRoomsParametersChildrenRoutes, CategoryName.DINING_ROOMS_PARAMETERS))
        .concat(buildStudiesRoutes(humanResourcesParametersChildrenRoutes, CategoryName.HUMAN_RESOURCES_PARAMETERS))
        .concat(buildStudiesRoutes(potentialMarketAndSalesChildrenRoutes, CategoryName.POTENTIAL_MARKET_AND_SALES))
        .concat(buildStudiesRoutes(salesDetailsChildrenRoutes, CategoryName.SALES_DETAILS))
        .concat(buildStudiesRoutes(salesHistoryChildrenRoutes, CategoryName.SALES_HISTORY))
        .concat(buildStudiesRoutes(qualityChildrenRoutes, CategoryName.QUALITY))
        .concat(buildStudiesRoutes(synthesisChildrenRoutes, CategoryName.SYNTHESIS));

@NgModule({
    declarations: [
        StudyComponent,
        StudyHeaderComponent,
        StudyDetailComponent,

        ProfitLossAccountsComponent,
        BalanceSheetComponent,
        CashFlowStatementComponent,
        ExternalLoadsDetailComponent,
        SalesDetailsComponent,
        AdvertisementBudgetComparisonComponent,
        CompetitiveIntelligenceComponent,
        MaintenanceQualityComparisonComponent,
        LayoutQualityComparisonComponent,
        CookingQualityComparisonComponent,
        CustomerCareQualityComparisonComponent,
        DishesSalesPricesComparisonComponent,
        DishSalesPricesComparisonComponent,
        DishesQualityComparisonComponent,
        DishQualityComparisonComponent,
        WinesQualityComparisonComponent,
        WineQualityComparisonComponent,
        MarketSharesComponent,
        ProductMarketSharesComponent,
        ChartDataSetDataPipe,
        ChartDataSetLabelPipe,
        SpecimenComponent,
        TurnoverComponent,
        PurchasesStudyComponent,
        HumanResourcesParametersComponent,
        CommunicationStudyComponent,
        HumanResourcesStudyComponent,
        QualityComponent,
        PotentialMarketAndSalesStudyComponent,
        ExceptionalResultComponent,
        FinancialResultComponent,
        TaxesStudyComponent,
        TeamsDataComponent,
        SalesHistoryComponent,
        DepreciationChargesStudyComponent,
        DiningRoomParametersComponent,
        WineStocksComponent,
        EquityComparisonComponent,
        AccumulatedNetResultComparisonComponent,
        NumberOrNothingComponent
    ],
    imports: [
        CommonModule,
        PipesModule,
        RouterModule,
        HttpClientModule,
        TranslateModule,
        ComponentsModule,
        ChartsModule,
        TabsModule
    ],
    exports: [
        StudyComponent
    ]
})
export class StudiesModule {
}

import {Component} from '@angular/core';
import {GenericSessionParametersComponent} from '../../generic-parameters/generic-session-parameters.component';
import {MarketParameters} from '../../services/period/market/market-parameters';
import {MarketParametersLoaderService} from '../../services/period/market/market-parameters-loader.service';
import {MarketParametersUpdaterService} from './market-parameters-updater.service';
import {genericParametersHost} from '../../generic-parameters/generic-parameters.component';

@Component({
    templateUrl: './potential-market-parameters.component.html',
    styleUrls: [
        './../../generic-parameters/generic-parameters.component.scss',
        './potential-market-parameters.component.scss'
    ],
    host: genericParametersHost
})
export class PotentialMarketParametersComponent extends GenericSessionParametersComponent<MarketParameters> {

    constructor(marketParametersLoaderService: MarketParametersLoaderService,
                marketParametersUpdaterService: MarketParametersUpdaterService) {
        super(marketParametersLoaderService, marketParametersUpdaterService, 'toast.parameters.standing.potential.market');
    }
}

import {Routes} from '@angular/router';
import {PerceivedQualityParametersComponent} from './perceived-quality-parameters/perceived-quality-parameters.component';
import {AssignmentsWeightsParametersComponent} from './assignments-weight/assignments-weights-parameters.component';
import {StaffQualityParametersComponent} from './staff-quality-parameters/staff-quality-parameters.component';

export const qualityParametersChildrenRoutes: Routes = [
    {
        path: 'perceived-quality',
        component: PerceivedQualityParametersComponent
    },
    {
        path: 'assignments-weights',
        component: AssignmentsWeightsParametersComponent
    },
    {
        path: 'staff',
        component: StaffQualityParametersComponent
    }
];

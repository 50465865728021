import {CommunicationDecision} from './communication-decision';
import {StandingParameters} from '../../../parameters/services/standing/standing-parameters';
import {CommunicationDecisionComponentParameters} from './player-communication-decision.component';
import {roundFloat} from '../../math-utils';

export function computeAdvertisementQuality(decision: CommunicationDecision, parameters: CommunicationDecisionComponentParameters): number {
    const standing = computeStanding(
        parameters.menuStanding,
        decision,
        parameters.communicationParameters.communicationAxes,
        parameters.communicationParameters.mainCommunicationAxisWeight,
        parameters.communicationParameters.secondaryCommunicationAxisWeight,
        parameters.standingParameters
    );

    const qualityInducedByAxes = computeQualityInducedByAxes(
        decision.mainCommunicationAxis,
        decision.secondaryCommunicationAxis,
        parameters.communicationParameters.communicationAxes,
        parameters.communicationParameters.mainCommunicationAxisWeight,
        parameters.communicationParameters.secondaryCommunicationAxisWeight,
        standing
    );

    const qualityInducedByBudget = computeQualityInducedByBudget(
        parameters.previousPeriodEffectiveBudget,
        decision.advertisementBudget,
        parameters.communicationParameters.standardAdvertisementBudget
    );

    return roundFloat(Math.sqrt(qualityInducedByAxes * qualityInducedByBudget), 2);
}

export function computeStanding(
    menuStanding: number,
    communicationDecision: CommunicationDecision,
    communicationAxes: Object,
    mainCommunicationAxisWeight: number,
    secondaryCommunicationAxisWeight: number,
    standingParameters: StandingParameters
): number {
    const standingInducedByAxes =
        (communicationAxes[communicationDecision.mainCommunicationAxis] * mainCommunicationAxisWeight +
            communicationAxes[communicationDecision.secondaryCommunicationAxis] * secondaryCommunicationAxisWeight)
        / (mainCommunicationAxisWeight + secondaryCommunicationAxisWeight);
    const totalWeight = standingParameters.menuWeight + standingParameters.targetWeight + standingParameters.communicationAxesWeight;
    return roundFloat((menuStanding * standingParameters.menuWeight +
        communicationDecision.targetAudience * standingParameters.targetWeight +
        standingInducedByAxes * standingParameters.communicationAxesWeight) / totalWeight, 2);
}

export function computeQualityInducedByAxes(
    mainCommunicationAxis: string,
    secondaryCommunicationAxis: string,
    communicationAxesList: Object,
    mainCommunicationAxisWeight: number,
    secondaryCommunicationAxisWeight: number,
    standing: number
): number {
    const totalAxesWeight = mainCommunicationAxisWeight + secondaryCommunicationAxisWeight;

    if (totalAxesWeight === 0) {
        return 0;
    }

    const axesMeanStanding =
        (communicationAxesList[mainCommunicationAxis] * mainCommunicationAxisWeight +
            communicationAxesList[secondaryCommunicationAxis] * secondaryCommunicationAxisWeight) /
        totalAxesWeight;
    return Math.max(roundFloat(100 - 4 * Math.abs(axesMeanStanding - standing), 2), 0);
}

export function computeQualityInducedByBudget(
    previousPeriodEffectiveAdvertisementBudget: number,
    currentPeriodAdvertisementBudget: number,
    standardAdvertisementBudget: number
): number {
    return roundFloat(Math.max(0,
        100 - 100 * standardAdvertisementBudget /
        (previousPeriodEffectiveAdvertisementBudget + currentPeriodAdvertisementBudget)), 2);
}

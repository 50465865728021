import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent {

    @Input()
    public label = '';

    @Input()
    public isClosable = true;

    @Output()
    public click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    public onClick(event: MouseEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
        this.click.emit(event);
    }
}

import {Component} from '@angular/core';
import {GenericSessionParametersComponent} from '../../generic-parameters/generic-session-parameters.component';
import {HumanResourcesAndTaxesParameters} from './human-resources-and-taxes-parameters';
import {HumanResourcesAndTaxesParametersLoaderService} from './human-resources-and-taxes-parameters-loader.service';
import {HumanResourcesAndTaxesParametersUpdaterService} from './human-resources-and-taxes-parameters-updater.service';
import {genericParametersHost} from '../../generic-parameters/generic-parameters.component';

@Component({
    templateUrl: './human-resources-and-taxes-parameters.component.html',
    styleUrls: [
        './../../generic-parameters/generic-parameters.component.scss',
        './human-resources-and-taxes-parameters.component.scss'
    ],
    host: genericParametersHost
})
export class HumanResourcesAndTaxesParametersComponent extends GenericSessionParametersComponent<HumanResourcesAndTaxesParameters> {

    constructor(humanResourcesAndTaxesParametersLoaderService: HumanResourcesAndTaxesParametersLoaderService,
        humanResourcesAndTaxesParametersUpdaterService: HumanResourcesAndTaxesParametersUpdaterService) {
        super(humanResourcesAndTaxesParametersLoaderService, humanResourcesAndTaxesParametersUpdaterService, 'toast.parameters.financial.human.resources.and.taxes.saved')
    }

}

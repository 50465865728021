export enum CannotActivateReason {
    SESSION_LOCKED = 'SESSION_LOCKED',
    USER_IS_PLAYER = 'USER_IS_PLAYER',
    USER_IS_ANIMATOR = 'USER_IS_ANIMATOR',

    // Decisions
    AREA_NOT_CHOSEN = 'AREA_NOT_CHOSEN',
    ARCHETYPE_NOT_CHOSEN = 'ARCHETYPE_NOT_CHOSEN',

    // Ranking
    RANKING_DISABLED_BY_ANIMATOR = 'RANKING_DISABLED_BY_ANIMATOR'
}

export interface GuardResult {
    readonly canActivate: boolean;
    readonly reason: CannotActivateReason;
}

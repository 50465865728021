import {Injectable} from '@angular/core';
import {ConfirmationPopupService} from '../../../../confirmation-popup/confirmation-popup.service';
import {SimulationLaunchPopupComponent} from './simulation-launch-popup/simulation-launch-popup.component';
import {NotificationService} from '../../../../notification.service';

@Injectable({
    providedIn: 'root'
})
export class SimulationLaunchPopupService extends ConfirmationPopupService<SimulationLaunchPopupComponent> {

    constructor(
        private readonly notificationService: NotificationService
    ) {
        super(SimulationLaunchPopupComponent);
    }

    showSimulationLaunchPopup(
        period: string,
        animatorTokensNumber: number,
        simulationTokensCost: number,
        sessionId: string,
        sessionIsDemonstration: boolean,
        confirmButtonAction: Function
    ) {
        const preparedPopup = this.prepareConfirmationPopup(
            '',
            '',
            () => {
                confirmButtonAction().subscribe(
                    () => console.log('simulation launched'),
                    err => {
                        this.notificationService.error(err.error.errorCode);
                    });
            },
            'simulation.launch.start'
        );

        preparedPopup.period = period;
        preparedPopup.animatorTokensNumber = animatorTokensNumber;
        preparedPopup.simulationTokensCost = simulationTokensCost;
        preparedPopup.sessionIsDemonstration = sessionIsDemonstration;
        this.showPopup(preparedPopup);
    }

    protected fillConfirmationPopupInstance(
        confirmationPopupInstance: SimulationLaunchPopupComponent,
        preparedPopup: SimulationLaunchPopupComponent
    ) {
        confirmationPopupInstance.period = preparedPopup.period;
        confirmationPopupInstance.animatorTokensNumber = preparedPopup.animatorTokensNumber;
        confirmationPopupInstance.simulationTokensCost = preparedPopup.simulationTokensCost;
        confirmationPopupInstance.sessionIsDemonstration = preparedPopup.sessionIsDemonstration;
    }
}

import {Component} from '@angular/core';
import {GenericSessionParametersComponent} from '../../generic-parameters/generic-session-parameters.component';
import {SalesRepartitionParameters} from './sales-repartition-parameters';
import {SalesRepartitionParametersLoaderService} from './sales-repartition-parameters-loader.service';
import {SalesRepartitionParametersUpdaterService} from './sales-repartition-parameters-updater.service';
import {genericParametersHost} from '../../generic-parameters/generic-parameters.component';

@Component({
    templateUrl: './sales-repartition-parameters.component.html',
    styleUrls: ['./../../generic-parameters/generic-parameters.component.scss'],
    host: genericParametersHost
})
export class SalesRepartitionParametersComponent extends GenericSessionParametersComponent<SalesRepartitionParameters> {
    constructor(
        salesRepartitionParametersLoaderService: SalesRepartitionParametersLoaderService,
        salesRepartitionParametersUpdaterService: SalesRepartitionParametersUpdaterService
    ) {
        super(
            salesRepartitionParametersLoaderService,
            salesRepartitionParametersUpdaterService,
            'toast.parameters.restaurant.sales.repartition.saved'
        );
    }
}

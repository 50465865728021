import {Injectable} from '@angular/core';
import {MenuDecisionFormatterService} from "../menu-decision-formatter.service";
import {AbstractDecisionLoaderService} from "../../abstract-decision-loader.service";
import {WinesPurchases} from "./wines-purchases";

@Injectable({
    providedIn: 'root'
})
export class WinesPurchasesLoaderService extends AbstractDecisionLoaderService<WinesPurchases> {
    constructor() {
        super('winesPurchases');
    }
}

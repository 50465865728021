import {ChartDataSets} from 'chart.js';

export class ChartUtils {
    public static customizeColor(chartData: ChartDataSets, color:string) {
        chartData.borderColor = color;
        chartData.pointBorderColor = color;
        chartData.pointHoverBorderColor = color;
        chartData.backgroundColor = color;
        chartData.pointBackgroundColor =  color;
        chartData.pointHoverBackgroundColor = color;
    }
}

import {ConnectedUser} from '../context/connected-user';

export class User {
    constructor(
        public _id: string,
        public person: Person,
        public animator: boolean,
        public role: string,
        public tokenBalance: number,
        public player: boolean,
        public language: string,
        public passwordGenerated: boolean,
        public displayChangePassword: boolean
    ) {
    }
}

export class Person {
    constructor(
        public firstName: string,
        public lastName: string,
        public contact: Contact
    ) {
    }
}

export class Contact {
    constructor(
        public address: Address,
        public fixPhone: string,
        public mobilePhone: string,
        public mail: string,
        public mailOptional: string,
    ) {
    }
}

export class Address {
    constructor(
        public line1: string,
        public line2: string,
        public postalCode: string,
        public city: string,
        public country: string,
    ) {
    }
}

export class Player extends User {

    constructor(
        id: string,
        person: Person,
        animator: boolean,
        role: string,
        tokenBalance: number,
        player: boolean,
        language: string,
        passwordGenerated: boolean,
        displayChangePassword: boolean,
        public teamNumber: number,
        public teamRef: string
    ) {
        super(id, person, animator, role, tokenBalance, player, language, passwordGenerated, displayChangePassword);
    }
}

export class PlayerUpdateDto {
    constructor(
        public person: Person,
        public language: string
    ) {
    }

    static fromConnectedUser(connectedUser: ConnectedUser): PlayerUpdateDto {
        return this.fromUser(connectedUser.user);
    }

    static fromUser(user: User): PlayerUpdateDto {
        return new PlayerUpdateDto(
            user.person,
            user.language
        )
    }
}

import {Component} from '@angular/core';
import {AbstractStudyComponent} from "../../abstract-study.component";
import {CategoryName} from "../../category-name";
import {StudiesNames} from "../../studies-names";
import {of} from "rxjs";

class BalanceSheet {
    constructor(
        private readonly intangibleAsset: number,
        private readonly capital: number,

        private readonly grossFurniture: number,
        private readonly furnitureAmortization: number,
        private readonly netFurniture: number,
        private readonly legalReserve: number,

        private readonly grossTableware: number,
        private readonly tablewareAmortization: number,
        private readonly netTableware: number,
        private readonly optionalReserve: number,

        private readonly fixedAssets: number,
        private readonly retainedEarnings: number,

        private readonly cellar: number,
        private readonly equity: number,

        private readonly vatInput: number,
        private readonly loan: number,

        private readonly paymentTax: number,
        private readonly vatOutput: number,

        private readonly currentAssets: number,
        private readonly incomeTax: number,

        private readonly investment: number,
        private readonly overdraft: number,

        private readonly bank: number,
        private readonly debts: number,

        private readonly saleAssets: number,

        private readonly assetsTotal: number,
        private readonly liabilitiesTotal: number,
        private readonly netResult: number
    ) {
    }
}

@Component({
    selector: 'kiwi-balance-sheet',
    templateUrl: './balance-sheet.component.html',
    styleUrls: ['./balance-sheet.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class BalanceSheetComponent extends AbstractStudyComponent<BalanceSheet> {

    constructor() {
        super(CategoryName.SYNTHESIS, of(StudiesNames.BALANCE_SHEET));
    }
}

import {Component} from '@angular/core';
import {AbstractStudyComponent} from "../../abstract-study.component";
import {CompetitiveIntelligenceStudy} from "../competitive-intelligence-study";
import {CategoryName} from "../../category-name";
import {StudiesNames} from "../../studies-names";
import {of} from "rxjs";
import {MarketSharesStudy} from './market-shares-study';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'kiwi-market-shares',
    templateUrl: './market-shares.component.html',
    styleUrls: ['./market-shares.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class MarketSharesComponent extends AbstractStudyComponent<MarketSharesStudy> {
    constructor(route: ActivatedRoute) {
        super(CategoryName.COMPETITIVE_INTELLIGENCE, of(StudiesNames.MARKET_SHARES), true, route.params);
    }
}

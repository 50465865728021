import {Component} from '@angular/core';
import {Tab} from "../../tabs/tab";
import {TabParentDecisionComponent} from '../generics/tab-parent-decision/tab-parent-decision.component';

@Component({
    selector: 'kiwi-financial-decision',
    templateUrl: '../generics/tab-parent-decision/tab-parent-decision.component.html',
    host: {'class': 'cell auto grid-y decisionContainer _financial'}
})
export class FinancialDecisionComponent extends TabParentDecisionComponent {
    constructor() {
        super([
                new Tab(
                    'decisions.dashboard.financial.decision',
                    '/session/decisions/financial',
                    false),
                new Tab('decisions.financial.insurances',
                    '/session/decisions/financial/insurances',
                    false),
            ], 'decisions.dashboard.financial.decision',
            'decisions.dashboard.financial.decision.animator.products.and.charges',
            '/session/decisions/financial/animatorProductsAndCharges'
        );
    }
}

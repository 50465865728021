import {Component} from '@angular/core';
import {AbstractStudyComponent} from '../../abstract-study.component';
import {of} from 'rxjs';
import {CategoryName} from '../../category-name';
import {RestaurantEquipmentDepreciationStudy} from './restaurant-equipment-depreciation-study';

class DepreciationStudy {
    constructor(
        public readonly furnitureDepreciation: RestaurantEquipmentDepreciationStudy,
        public readonly tablewareDepreciation: RestaurantEquipmentDepreciationStudy,
        public readonly totalPeriodDepreciationCharges: number,
        public readonly totalDepreciationCharges: number
    ) {
    }
}

@Component({
    templateUrl: './depreciation-charges-study.component.html',
    styleUrls: ['./depreciation-charges-study.component.scss'],
    host: {'class': 'cell auto grid-y'}

})
export class DepreciationChargesStudyComponent extends AbstractStudyComponent<DepreciationStudy>{
    constructor() {
        super(CategoryName.ACCOUNTS_DETAIL, of('DEPRECIATION_CHARGES'));
    }
}

import {Injectable} from '@angular/core';
import {StaffDecision} from "./staff-decision";
import {AbstractDecisionUpdaterService} from "../../abstract-decision-updater.service";

@Injectable({
    providedIn: 'root'
})
export class StaffDecisionUpdaterService extends AbstractDecisionUpdaterService<StaffDecision> {

    constructor() {
        super('staff');
    }
}

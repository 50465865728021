import {CanActivateTeamDecisionsGuard} from '../guards/can-activate-team-decisions.guard';
import {CanDeactivateModificationComponentGuard} from '../../guards/can-deactivate-modification-component.guard';
import {PlayerStaffDecisionComponent} from './player-staff-decision/player-staff-decision.component';
import {StaffDecisionComponent} from './staff-decision.component';
import {AnimatorStaffMarksComponent} from './animator-staff-marks/animator-staff-marks.component';
import {CanActivateAnimatorGuard} from '../guards/can-activate-animator.guard';
import {IsNotSimulationOrRevertStartedGuard} from '../guards/is-not-simulation-or-revert-started-guard.service';

export const staffDecisionRoute = {
    path: 'staff',
    component: StaffDecisionComponent,
    canActivate: [CanActivateTeamDecisionsGuard, IsNotSimulationOrRevertStartedGuard],
    canDeactivate: [CanDeactivateModificationComponentGuard],
    children: [
        {
            path: '',
            component: PlayerStaffDecisionComponent,
            canDeactivate: [CanDeactivateModificationComponentGuard]
        },
        {
            path: 'mark',
            component: AnimatorStaffMarksComponent,
            canActivate: [CanActivateAnimatorGuard],
            canDeactivate: [CanDeactivateModificationComponentGuard]
        }
    ]
};

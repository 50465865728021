import {MenuSalesPrices} from "./menu-sales-prices";
import {StandingMenuParameters} from "../../../parameters/services/standing/standing-menu-parameters";
import {roundFloat} from '../../math-utils';

export function computeMenuStanding(decision: MenuSalesPrices, parameters: StandingMenuParameters): number {
    const menuAveragePrice = computeMenuAveragePrice(decision, parameters);

    if (menuAveragePrice < parameters.priceMinimalStanding)
        return 0;

    if (menuAveragePrice < parameters.priceStandardStanding)
        return roundFloat(70 * (menuAveragePrice - parameters.priceMinimalStanding) / (parameters.priceStandardStanding - parameters.priceMinimalStanding), 2);

    if (menuAveragePrice < parameters.priceMaximalStanding)
        return 70 + roundFloat(30 * (menuAveragePrice - parameters.priceStandardStanding) / (parameters.priceMaximalStanding - parameters.priceStandardStanding), 2);

        return 100;
    }

export function computeMenuAveragePrice(decision: MenuSalesPrices, parameters: StandingMenuParameters): number {
    let totalPrices = 0;
    totalPrices += computeTotalSalesPrices(decision.firstCoursesSalesPrices);
    totalPrices += computeTotalSalesPrices(decision.meatDishesSalesPrices);
    totalPrices += computeTotalSalesPrices(decision.fishDishesSalesPrices);
    totalPrices += computeTotalSalesPrices(decision.dessertsSalesPrices);
    totalPrices += computeTotalSalesPrices(decision.winesSalesPrices);

    if (decision.firstCourseMainCourseMenu.inMenu) {
        totalPrices += decision.firstCourseMainCourseMenu.salePriceIncludingTax;
    }

    if (decision.mainCourseDessertMenu.inMenu) {
        totalPrices += decision.mainCourseDessertMenu.salePriceIncludingTax;
    }

    if (decision.fullCourse.inMenu) {
        totalPrices += decision.fullCourse.salePriceIncludingTax;
    }

    const totalWeight = computeTotalWeight(decision, parameters);

    return roundFloat(totalPrices / totalWeight, 4);
}

function computeTotalSalesPrices(salesPrices: Object): number {
    let result = 0;

    for (let salePrice in salesPrices) {
        const menuItem = salesPrices[salePrice];
        if (menuItem.inMenu) {
            result += menuItem.salePriceIncludingTax;
        }
    }

    return result;
}

function computeTotalWeight(decision: MenuSalesPrices, parameters: StandingMenuParameters) {
    let totalDishesWeight = 0;
    totalDishesWeight += computeDishesWeight(parameters.firstCoursesWeight, decision.firstCoursesSalesPrices);
    totalDishesWeight += computeDishesWeight(parameters.mainCoursesWeight, decision.meatDishesSalesPrices);
    totalDishesWeight += computeDishesWeight(parameters.mainCoursesWeight, decision.fishDishesSalesPrices);
    totalDishesWeight += computeDishesWeight(parameters.dessertsWeight, decision.dessertsSalesPrices);
    totalDishesWeight += computeDishesWeight(parameters.winesWeight, decision.winesSalesPrices);

    if (decision.firstCourseMainCourseMenu.inMenu) {
        totalDishesWeight += parameters.firstCourseMainCourseMenuWeight;
    }

    if (decision.mainCourseDessertMenu.inMenu) {
        totalDishesWeight += parameters.mainCourseDessertMenuWeight;
    }

    if (decision.fullCourse.inMenu) {
        totalDishesWeight += parameters.fullCourseWeight;
    }

    return totalDishesWeight;
}

function computeDishesWeight(weight: number, salesPrices: Object) {
    let result = 0;

    for (let salePrice in salesPrices) {
        if (salesPrices[salePrice].inMenu) {
            result += weight;
        }
    }

    return result;
}

import {Component, OnInit} from '@angular/core';
import {AbstractPopupComponent} from "../../abstract-popup-component";
import {ContextService} from "../../context/context.service";
import {SessionEvent, SessionEventsLoaderService} from "../../session-events-loader.service";
import {filter, flatMap, map, tap} from "rxjs/operators";

@Component({
    selector: 'kiwi-schedule',
    templateUrl: './schedule-popup.component.html',
    styleUrls: ['./schedule-popup.component.scss'],
    host: {'class': 'popupContainer'}
})
export class SchedulePopupComponent extends AbstractPopupComponent implements OnInit {
    private eventsScheduled: SessionEvent[][];
    private daysScheduled: Date[];

    constructor(
        private readonly contextService: ContextService,
        private readonly sessionEventsLoaderService: SessionEventsLoaderService
    ) {
        super();
    }

    ngOnInit() {
        this.contextService.context
            .pipe(
                filter(context => context !== undefined && context.currentSessionId !== undefined),
                flatMap(context => this.sessionEventsLoaderService.loadSessionEvents(context)),
                map(sessionEvents => sessionEvents.sort((e1, e2) => {
                    if(e1.startDate < e2.startDate)
                        return -1;

                    if(e1.startDate === e2.startDate)
                        return 0;

                    return 1;
                })),
                map(sessionEvents => this.gatherSameDayEvents(sessionEvents)),
                tap(eventsScheduled => this.eventsScheduled = eventsScheduled),
                map(eventsScheduled => eventsScheduled.map(events => events[0].startDate)),
                tap(daysScheduled => this.daysScheduled = daysScheduled)
            ).subscribe()
    }

    private gatherSameDayEvents(sessionEvents: SessionEvent[]): SessionEvent[][] {
        if (sessionEvents.length == 0)
            return [];

        const event = sessionEvents[0];
        let sameDayEvents = new Array<SessionEvent>();
        sameDayEvents = sameDayEvents.concat(sessionEvents.filter(e => this.isSameDay(event, e)));
        const remainingEvents = sessionEvents.filter(e => !this.isSameDay(event, e));

        return [sameDayEvents].concat(this.gatherSameDayEvents(remainingEvents))
    }

    private isSameDay(event1: SessionEvent, event2: SessionEvent): boolean {
        const startDate1 = new Date(event1.startDate);
        const startDate2 = new Date(event2.startDate);

        return startDate1.getFullYear() === startDate2.getFullYear() &&
            startDate1.getMonth() === startDate2.getMonth() &&
            startDate1.getDate() === startDate2.getDate();
    }
}

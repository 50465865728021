import { Injectable } from '@angular/core';
import {AbstractSessionParametersUpdaterService} from '../../services/abstract-session-parameters-updater-service';
import {MarketParameters} from '../../services/period/market/market-parameters';

@Injectable({
  providedIn: 'root'
})
export class MarketParametersUpdaterService extends AbstractSessionParametersUpdaterService<MarketParameters>{

  constructor() {
      super('market');
  }
}

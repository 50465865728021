import {Component} from '@angular/core';
import {AbstractStudyComponent} from "../../abstract-study.component";
import {CompetitiveIntelligenceStudy} from "../competitive-intelligence-study";
import {CategoryName} from "../../category-name";
import {StudiesNames} from "../../studies-names";
import {of} from "rxjs";

@Component({
    selector: 'app-layout-quality-comparison',
    templateUrl: './layout-quality-comparison.component.html',
    styleUrls: ['./layout-quality-comparison.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class LayoutQualityComparisonComponent extends AbstractStudyComponent<CompetitiveIntelligenceStudy> {

    constructor() {
        super(CategoryName.COMPETITIVE_INTELLIGENCE, of(StudiesNames.LAYOUT_QUALITY_COMPARISON))
    }

}

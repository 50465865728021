import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SeverityDispersionParameters} from "./severity-dispersion-parameters";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SeverityDispersionParametersUpdaterService {

  constructor(private httpClient: HttpClient) { }

  public updateSeverityDispersionParameters(
      markType: string,
      sessionId: string,
      severityDispersionParameters: SeverityDispersionParameters
  ): Observable<SeverityDispersionParameters> {
      return this.httpClient.put<SeverityDispersionParameters>(
          'api/sessions/' + sessionId +
          '/parameters/evaluations/' + markType +
          '/severityDispersion',
          severityDispersionParameters);
  }
}

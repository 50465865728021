import {Injectable} from '@angular/core';
import {SaveBeforeDeactivatePopupComponent} from './save-before-deactivate-popup.component';
import {ConfirmationPopupService} from '../../confirmation-popup/confirmation-popup.service';
import {Router} from '@angular/router';
import {AbstractModificationComponent} from '../abstract-modification.component';

@Injectable({
    providedIn: 'root'
})
export class SaveBeforeDeactivatePopupService extends ConfirmationPopupService<SaveBeforeDeactivatePopupComponent> {

    constructor(private readonly router: Router) {
        super(SaveBeforeDeactivatePopupComponent)
    }

    protected fillConfirmationPopupInstance(
        confirmationPopupInstance: SaveBeforeDeactivatePopupComponent,
        preparedPopup: SaveBeforeDeactivatePopupComponent
    ) {
        confirmationPopupInstance.cancelUrl = preparedPopup.cancelUrl;
    }

    public showConfirmationPopup(
        component: AbstractModificationComponent<any>,
        cancelUrl: string
    ): void {
        const saveBeforeDeactivatePopup = this.prepareConfirmationPopup(
            '',
            '',
            () => (
                component.callSaveAndResetDirtyAndDisplayNotifications().subscribe(() => {
                        this.router.navigateByUrl(cancelUrl).catch();
                    }
                )
            ),
            cancelUrl
        );
        saveBeforeDeactivatePopup.cancelUrl = cancelUrl;
        saveBeforeDeactivatePopup.closed.subscribe(closed => {
            component.dirty = false;
        })
        this.showPopup(saveBeforeDeactivatePopup);
    }
}

import {Injectable} from '@angular/core';
import {ConfirmationPopupService} from "../../../confirmation-popup/confirmation-popup.service";
import {ArchetypeConfirmationPopupComponent} from "./archetype-confirmation-popup/archetype-confirmation-popup.component";
import {forkJoin} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ArchetypeConfirmationPopupService extends ConfirmationPopupService<ArchetypeConfirmationPopupComponent>{
    constructor() {
        super(ArchetypeConfirmationPopupComponent);
    }

    protected fillConfirmationPopupInstance(
        confirmationPopupInstance: ArchetypeConfirmationPopupComponent,
        preparedPopup: ArchetypeConfirmationPopupComponent
    ) {
        confirmationPopupInstance.chosenArchetype = preparedPopup.chosenArchetype;
    }

    showConfirmationPopup(
        chosenArchetypeLabel: string,
        confirmButtonAction: Function
    ) {
        let archetypeConfirmationPopupComponent = this.prepareConfirmationPopup(
            'decisions.archetype.popup.title',
            forkJoin(this.translateService.get('decisions.archetype.popup.confirmation.message.1'),
                this.translateService.get('decisions.archetype.popup.' + chosenArchetypeLabel),
                this.translateService.get('decisions.archetype.popup.confirmation.message.2'))
                .pipe(map(([message1, chosenArchetype, message2]) => { return message1 + chosenArchetype + message2})),
            confirmButtonAction
        );
        archetypeConfirmationPopupComponent.chosenArchetype = chosenArchetypeLabel;

        this.showPopup(archetypeConfirmationPopupComponent);
    }
}

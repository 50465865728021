import {StudiesNames} from '../studies-names';
import {SalesHistoryComponent} from '../internal-data/sales-history/sales-history.component';
import {StudyRouteDefinition} from '../study-route-definition';

export const salesHistoryChildrenRoutes: StudyRouteDefinition[] = [
    new StudyRouteDefinition(
        StudiesNames.SALES_HISTORY,
        [{
            path: '',
            component: SalesHistoryComponent
        }]
    )
];

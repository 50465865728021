import {Component} from '@angular/core';
import {MenuDishesPurchases} from "./menu-dishes-purchases";
import {MenuDishesPurchasesUpdaterService} from "./menu-dishes-purchases-updater.service";
import {Context} from "../../../../context/context";
import {Observable} from "rxjs";
import {MenuDishesPurchasesLoaderService} from "./menu-dishes-purchases-loader.service";
import {DishesParameters} from "../../../parameters/services/dishes/dishes-parameters";
import {DishesParametersLoaderService} from "../../../parameters/services/dishes/dishes-parameters-loader.service";
import {Score} from "../../generics/score-image/score-image.component";
import {AbstractDecisionComponent} from "../../abstract-decision-component";
import {computePurchasingPrice} from '../../compute-purchasing-price';
import {computeDishesAverageQuality} from './compute-dishes-quality';

@Component({
    selector: 'kiwi-ingredients-purchasing',
    templateUrl: './ingredients-purchasing.component.html',
    styleUrls: ['./ingredients-purchasing.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class IngredientsPurchasingComponent extends AbstractDecisionComponent<MenuDishesPurchases, DishesParameters> {
    constructor(
        private dishesParametersLoaderService: DishesParametersLoaderService,
        menuDishesPurchasesUpdater: MenuDishesPurchasesUpdaterService,
        menuDishesPurchasesLoader: MenuDishesPurchasesLoaderService
    ) {
        super(
            menuDishesPurchasesLoader,
            menuDishesPurchasesUpdater,
            [new Score('decisions.menu.ingredients.quality', 0)],
            'toast.menu.dishes.purchases.decision.saved.message'
        )
    }

    protected loadParameters(context: Context): Observable<DishesParameters> {
        return this.dishesParametersLoaderService.loadParameters(context);
    }

    onSave(): void {
        this.save();
    }

    updateFirstCoursePurchasingPrice(firstCourseName: string): void {
        this.updatePurchasingPrice(
            firstCourseName,
            this.parameters.firstCoursesStandardPurchasingPrices[firstCourseName],
            this.decision.firstCoursesPurchases
        );
    }

    updateMeatDishPurchasingPrice(meatDishName: string): void {
        this.updatePurchasingPrice(
            meatDishName,
            this.parameters.mainCoursesStandardPurchasingPrices[meatDishName],
            this.decision.meatDishesPurchases
        );
    }

    updateFishDishPurchasingPrice(fishDishName: string): void {
        this.updatePurchasingPrice(
            fishDishName,
            this.parameters.mainCoursesStandardPurchasingPrices[fishDishName],
            this.decision.fishDishesPurchases
        );
    }

    updateDessertPurchasingPrice(dessertName: string): void {
        this.updatePurchasingPrice(
            dessertName,
            this.parameters.dessertsStandardPurchasingPrices[dessertName],
            this.decision.dessertsPurchases
        );
    }

    private updatePurchasingPrice(dishName: string, standardPurchasingPrice: number, dishes: Object): void {
        dishes[dishName].purchasingPriceExcludingTax = computePurchasingPrice(dishes[dishName].quality, standardPurchasingPrice);
        this.updateScoreImage();
    }

    protected computeDecisionQuality(scores: Score[], parameters: DishesParameters): number {
        return scores[0].value;
    }

    protected updateScores(decision: MenuDishesPurchases, parameters: DishesParameters): void {
        this.scores[0].value = computeDishesAverageQuality(decision);
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UsersList} from "../users-list";
import {Message} from "../message";

@Component({
    selector: 'kiwi-new-message',
    templateUrl: './new-message.component.html',
    styleUrls: ['./new-message.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class NewMessageComponent {
    @Input()
    recipients: UsersList[];

    @Output()
    usersListDeleted = new EventEmitter<UsersList>();

    @Output()
    messageSent = new EventEmitter<Message>();

    message = new Message('', '', undefined, undefined, '');

    readonly ckeditorConfigToolbarGroups = [
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'insert', groups: [ 'insert' ] },
        '/',
        { name: 'styles', groups: [ 'styles' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'others', groups: [ 'others' ] },
        { name: 'about', groups: [ 'about' ] }
    ];

    readonly ckeditorConfigRemoveButtons = 'Source,Templates,Save,NewPage,Preview,Print,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,RemoveFormat,CopyFormatting,Blockquote,CreateDiv,Language,BidiRtl,BidiLtr,About,Maximize,ShowBlocks,Anchor,Unlink,Link,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,Iframe,PageBreak,Styles';
    readonly ckeditorConfig = {'toolbarGroups': this.ckeditorConfigToolbarGroups, 'removeButtons': this.ckeditorConfigRemoveButtons};

    constructor() {
    }

    onUsersListDeleted(usersList: UsersList) {
        this.usersListDeleted.emit(usersList);
    }

    sendMessage() {
        this.messageSent.emit(this.message);
        this.message = new Message('', '', undefined, undefined, '');
    }
}

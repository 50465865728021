import {Component} from '@angular/core';
import {GenericSessionParametersComponent} from '../../generic-parameters/generic-session-parameters.component';
import {VATParameters} from '../../services/vat/vat-parameters';
import {VatParametersLoaderService} from './vat-parameters-loader.service';
import {VatParametersUpdaterService} from './vat-parameters-updater.service';
import {genericParametersHost} from '../../generic-parameters/generic-parameters.component';

@Component({
    templateUrl: './vat-parameters.component.html',
    styleUrls: [
        './../../generic-parameters/generic-parameters.component.scss',
        './vat-parameters.component.scss'
    ],
    host: genericParametersHost
})
export class VatParametersComponent extends GenericSessionParametersComponent<VATParameters>{
    constructor(vatParametersLoaderService: VatParametersLoaderService,
                vatParametersUpdaterService: VatParametersUpdaterService) {
        super(vatParametersLoaderService, vatParametersUpdaterService, 'toast.parameters.financial.vat.saved');
    }
}

import { Injectable } from '@angular/core';
import {AbstractSessionParametersLoaderService} from "../../../parameters/services/abstract-session-parameters-loader.service";
import {ProfitabilityEvaluationParameters} from "./profitability-evaluation-parameters";

@Injectable({
  providedIn: 'root'
})
export class ProfitabilityEvaluationParametersLoaderService extends AbstractSessionParametersLoaderService<ProfitabilityEvaluationParameters>{

  constructor() {
      super('evaluations/PROFITABILITY_MARK/parameters');
  }
}

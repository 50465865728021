import {Component} from '@angular/core';
import {TabParentComponent, tabParentHost} from '../../../components/tab-parent/tab-parent.component';
import {Tab} from '../../tabs/tab';
import {GenericStudiesPricesComponent} from './generic-studies-prices/generic-studies-prices.component';
import {of} from 'rxjs';

@Component({
    templateUrl: './../../../components/tab-parent/tab-parent.component.html',
    styleUrls: [],
    host: tabParentHost
})
export class StudiesParametersComponent extends TabParentComponent {
    constructor() {
        super(of(
            Object.keys(GenericStudiesPricesComponent.STUDIES_NAMES_BY_TYPE)
                .map(studyType => new Tab('studies.' + studyType, 'parameters/studies/' + studyType))
        ));
    }
}

import {StudyLoaderService} from "./study-loader.service";
import {OnDestroy, OnInit} from "@angular/core";
import {ContextService} from "../../context/context.service";
import {LocatorService} from "../../locator.service";
import {Observable, of, Subscription} from "rxjs";
import {filter, flatMap, map} from "rxjs/operators";
import {Params} from "@angular/router";
import {CategoryName} from './category-name';
import {StudiesNames} from "./studies-names";

export class AbstractStudyComponent<T> implements OnInit, OnDestroy {
    protected contextService: ContextService;
    private studyLoader: Observable<StudyLoaderService<T>>;
    public isSpecimen: boolean = false;

    public study: T;
    private periodicitySubscription: Subscription;
    private contextSubscription: Subscription;


    constructor(public category: CategoryName, public studyId: Observable<string>, public loadStudy = true, public routeParams: Observable<Params> | undefined = null) {
        let periodicity = routeParams? routeParams.pipe(map(params => params.periodicity)): of(null);
        this.periodicitySubscription = periodicity.subscribe(p => {
            this.studyLoader = studyId.pipe(map(id => {
                return new StudyLoaderService<T>(category, this.ignoreAdBlockers(id), p)
            }));
            this.contextService = LocatorService.injector.get(ContextService);
        })
    }

    ngOnInit(): void {
        if(this.loadStudy) {
            this.contextSubscription = this.contextService.context
                .pipe(filter(context =>  !!context.currentTeam && !!context.currentTeam.teamNumber))
                .subscribe(
                context => {
                        this.studyLoader.pipe(
                            flatMap(studyLoader => {
                                return studyLoader.loadStudy(context)
                            }))
                            .subscribe(study => {
                                this.study = study;
                                this.afterStudyLoad(study);
                            });
                }
            );
        }
    }

    protected afterStudyLoad(study: T) {

    }

    public labelKeyOrLoremIpsum(labelKey: string): string {
        if (this.isSpecimen) {
            return 'common.lorem.ipsum';
        }

        return labelKey;
    }

    private ignoreAdBlockers(id: string): string {
        if(id === StudiesNames.ADVERTISEMENT_BUDGET_COMPARISON) {
            return "ADS_BUDGET_COMPARISON";
        }
        else {
            return id;
        }
    }

    ngOnDestroy(): void {
        this.periodicitySubscription.unsubscribe();
        if(!!this.contextSubscription) {
            this.contextSubscription.unsubscribe();
        }
    }
}

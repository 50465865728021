import {Component} from '@angular/core';
import {ConfirmationPopupComponent} from "../../../../confirmation-popup/confirmation-popup.component";

@Component({
    selector: 'kiwi-archetype-confirmation-popup',
    templateUrl: './archetype-confirmation-popup.component.html',
    styleUrls: ['./archetype-confirmation-popup.component.scss'],
    host: {'class': 'popupContainer'}
})
export class ArchetypeConfirmationPopupComponent extends ConfirmationPopupComponent {
    public chosenArchetype: string;
}

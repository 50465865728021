import {Component} from '@angular/core';
import {AbstractDecisionComponent} from "../../abstract-decision-component";
import {StaffDecisionUpdaterService} from "./staff-decision-updater.service";
import {StaffDecision} from "./staff-decision";
import {StaffDecisionLoaderService} from "./resolvers/staff-decision-loader.service";
import {RestaurantTypeLoaderService} from "./resolvers/restaurant-type-loader.service";
import {Context} from "../../../../context/context";
import {forkJoin, Observable} from "rxjs";
import {StaffQualityParameters} from "../../../parameters/services/quality/staff-quality-parameters";
import {map} from "rxjs/operators";
import {ComputeType1RestaurantStaffQuality} from "./compute-type-1-restaurant-staff-quality";
import {ComputeType2RestaurantStaffQuality} from "./compute-type-2-restaurant-staff-quality";
import {ComputeType3RestaurantStaffQuality} from "./compute-type-3-restaurant-staff-quality";
import {ComputeAbstractTypeRestaurantQuality} from "./compute-abstract-type-restaurant-quality";
import {QualityParametersLoaderService} from "../../../parameters/services/quality/quality-parameters-loader.service";
import {ActivityLoaderService} from "./activity-loader.service";
import {PeriodUtils} from "../../../studies/study-header/period-utils";
import {Score} from "../../generics/score-image/score-image.component";

class StaffDecisionComponentParameters {
    constructor(
        public readonly staffQualityParameters: StaffQualityParameters,
        public readonly restaurantType: string,
        public readonly previousPeriodCoversNumber: number,
        public readonly cookingQualityWeight: number,
        public readonly customerCareQualityWeight: number
    ) {
    }
}

@Component({
    templateUrl: './player-staff-decision.component.html',
    styleUrls: ['./player-staff-decision.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class PlayerStaffDecisionComponent extends AbstractDecisionComponent<StaffDecision, StaffDecisionComponentParameters> {
    constructor(
        private restaurantTypeLoaderService: RestaurantTypeLoaderService,
        private activityLoaderService: ActivityLoaderService,
        private qualityParametersLoaderService: QualityParametersLoaderService,
        staffDecisionLoaderService: StaffDecisionLoaderService,
        staffDecisionUpdaterService: StaffDecisionUpdaterService
    ) {
        super(
            staffDecisionLoaderService,
            staffDecisionUpdaterService,
            [new Score('decisions.staff.cooking.quality', 0),
                new Score('decisions.staff.customer.care.quality', 0)],
            'toast.staff.decision.saved.message',
            () => {
                this.updateCooks(true);
                this.updateWaiters(true);
            }
        );
    }

    protected loadParameters(context: Context): Observable<StaffDecisionComponentParameters> {
        return forkJoin(
            this.restaurantTypeLoaderService.loadRestaurantType(context),
            this.qualityParametersLoaderService.loadParameters(context),
            this.activityLoaderService.loadTeamResultFromContext(context,
                PeriodUtils.computePreviousPeriod(context.currentPeriod, context.currentSession.baseMetaScenario.periodicity))
        ).pipe(map(
            ([restaurantType, qualityParameters, previousPeriodCoversNumber]) => {
                if (previousPeriodCoversNumber === null)
                    previousPeriodCoversNumber = qualityParameters.staffQualityParameters.standardCoversNumber;

                return new StaffDecisionComponentParameters(
                    qualityParameters.staffQualityParameters,
                    restaurantType,
                    previousPeriodCoversNumber,
                    qualityParameters.cookingQualityWeight,
                    qualityParameters.customerCareQualityWeight
                );
            }
        ));
    }

    protected computeDecisionQuality(scores: Score[], parameters: StaffDecisionComponentParameters): number {
        const totalWeight = parameters.cookingQualityWeight + parameters.customerCareQualityWeight;

        return scores[0].value * parameters.cookingQualityWeight / totalWeight +
            scores[1].value * parameters.customerCareQualityWeight / totalWeight;

    }

    protected updateScores(decision: StaffDecision, parameters: StaffDecisionComponentParameters): void {
        let computeRestaurantStaffQuality: ComputeAbstractTypeRestaurantQuality;

        if (parameters.restaurantType === 'TYPE_1')
            computeRestaurantStaffQuality = new ComputeType1RestaurantStaffQuality(
                decision,
                parameters.staffQualityParameters,
                parameters.previousPeriodCoversNumber
            );
        else if (parameters.restaurantType === 'TYPE_2')
            computeRestaurantStaffQuality = new ComputeType2RestaurantStaffQuality(
                decision,
                parameters.staffQualityParameters,
                parameters.previousPeriodCoversNumber
            );
        else
            computeRestaurantStaffQuality = new ComputeType3RestaurantStaffQuality(
                decision,
                parameters.staffQualityParameters,
                parameters.previousPeriodCoversNumber
            );

        const cookingQuality = computeRestaurantStaffQuality.computeCookingQuality();
        const customerCareQuality = computeRestaurantStaffQuality.computeCustomerCareQuality();

        this.scores[0].value = cookingQuality;
        this.scores[1].value = customerCareQuality;
    }

    private updateCooks(isInit: boolean = false) {
        this.updateCooksWageBill();
        this.updateCooksWagesStandardDeviation();
        if(isInit) {
            this.initScoreImage();
        } else {
            this.updateScoreImage();
        }
    }

    private updateWaiters(isInit: boolean = false) {
        this.updateWaitersWageBill();
        this.updateWaitersWagesStandardDeviation();
        if(isInit) {
            this.initScoreImage();
        } else {
            this.updateScoreImage();
        }
    }

    private updateCooksWageBill() {
        this.decision.cooksWageBill = PlayerStaffDecisionComponent.computeEmployeesWageBill(
            this.decision.headChefSalary,
            this.decision.headChefNumber,
            this.decision.cookSalary,
            this.decision.cookNumber,
            this.decision.commisChefSalary,
            this.decision.commisChefNumber
        );
    }

    private updateWaitersWageBill() {
        this.decision.waitersWageBill = PlayerStaffDecisionComponent.computeEmployeesWageBill(
            this.decision.maitreDHotelSalary,
            this.decision.maitreDHotelNumber,
            this.decision.headWaiterSalary,
            this.decision.headWaiterNumber,
            this.decision.waiterSalary,
            this.decision.waiterNumber
        );
    }

    private static computeEmployeesWageBill(
        aCategoryEmployeeSalary: number,
        aCategoryEmployeeNumber: number,
        bCategoryEmployeeSalary: number,
        bCategoryEmployeeNumber: number,
        cCategoryEmployeeSalary: number,
        cCategoryEmployeeNumber: number
    ): number {
        return aCategoryEmployeeNumber * aCategoryEmployeeSalary +
            bCategoryEmployeeNumber * bCategoryEmployeeSalary +
            cCategoryEmployeeNumber * cCategoryEmployeeSalary;
    }

    private updateCooksWagesStandardDeviation() {
        this.decision.cooksWagesStandardDeviation = PlayerStaffDecisionComponent.computeEmployeesWagesStandardDeviation(
            this.decision.headChefSalary,
            this.decision.headChefNumber,
            this.decision.cookSalary,
            this.decision.cookNumber,
            this.decision.commisChefSalary,
            this.decision.commisChefNumber
        );
    }

    private updateWaitersWagesStandardDeviation() {
        this.decision.waitersWagesStandardDeviation = PlayerStaffDecisionComponent.computeEmployeesWagesStandardDeviation(
            this.decision.maitreDHotelSalary,
            this.decision.maitreDHotelNumber,
            this.decision.headWaiterSalary,
            this.decision.headWaiterNumber,
            this.decision.waiterSalary,
            this.decision.waiterNumber
        );
    }

    private static computeEmployeesWagesStandardDeviation(
        aCategoryEmployeeSalary: number,
        aCategoryEmployeeNumber: number,
        bCategoryEmployeeSalary: number,
        bCategoryEmployeeNumber: number,
        cCategoryEmployeeSalary: number,
        cCategoryEmployeeNumber: number
    ): number {
        const mean = PlayerStaffDecisionComponent.computeMean(
            aCategoryEmployeeSalary,
            aCategoryEmployeeNumber,
            bCategoryEmployeeSalary,
            bCategoryEmployeeNumber,
            cCategoryEmployeeSalary,
            cCategoryEmployeeNumber
        );

        const squaredMean = Math.pow(mean, 2);

        const squaresMean = PlayerStaffDecisionComponent.computeMean(
            aCategoryEmployeeSalary * aCategoryEmployeeSalary,
            aCategoryEmployeeNumber,
            bCategoryEmployeeSalary * bCategoryEmployeeSalary,
            bCategoryEmployeeNumber,
            cCategoryEmployeeSalary * cCategoryEmployeeSalary,
            cCategoryEmployeeNumber
        );

        return Math.sqrt(squaresMean - squaredMean);

    }

    private static computeMean(
        aCategoryEmployeeSalary: number,
        aCategoryEmployeeNumber: number,
        bCategoryEmployeeSalary: number,
        bCategoryEmployeeNumber: number,
        cCategoryEmployeeSalary: number,
        cCategoryEmployeeNumber: number
    ) {
        const totalNumber = aCategoryEmployeeNumber + bCategoryEmployeeNumber + cCategoryEmployeeNumber;

        if (totalNumber == 0)
            return 0;

        return (aCategoryEmployeeSalary * aCategoryEmployeeNumber +
            bCategoryEmployeeSalary * bCategoryEmployeeNumber +
            cCategoryEmployeeSalary + cCategoryEmployeeNumber) / totalNumber;
    }
}

import {Component} from '@angular/core';

@Component({
    selector: 'kiwi-evaluations',
    templateUrl: './evaluations.component.html',
    styleUrls: ['./evaluations.component.scss'],
    host: {'class': 'cell auto pageContainer grid-y'}
})
export class EvaluationsComponent {

    constructor() {
    }

}

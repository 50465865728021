import {Component} from '@angular/core';
import {AbstractStudyComponent} from '../../abstract-study.component';
import {CategoryName} from '../../category-name';
import {StudiesNames} from '../../studies-names';
import {of} from 'rxjs';
import {TeamPotentialMarketAndSalesStudy} from './team-potential-market-and-sales-study';
import {Label} from 'ng2-charts';
import {ChartOptions, ChartType} from 'chart.js';
import {TranslateService} from '@ngx-translate/core';

@Component({
    templateUrl: './potential-market-and-sales-study.component.html',
    styleUrls: ['./potential-market-and-sales-study.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class PotentialMarketAndSalesStudyComponent extends AbstractStudyComponent<TeamPotentialMarketAndSalesStudy> {
    public barChartLabels: Label[] = [];
    public barChartType: ChartType = 'bar';
    public chartColors:Array<any> =[
        {
            backgroundColor: [
                'rgba(250, 240, 220, 0.6)',
                'rgba(247, 227, 175, 1)',
                'rgba(218, 143, 107, 1)',
                'rgba(192, 117, 91, 1)',
                'rgba(145, 79, 56, 1)',
                'rgba(98, 48, 24, 1)'
            ]
        }];
    public barChartPlugins = [];
    public barChartData: number[];

    public barChartOptions: ChartOptions;

    constructor(private readonly translateService: TranslateService) {
        super(CategoryName.POTENTIAL_MARKET_AND_SALES, of(StudiesNames.POTENTIAL_MARKET_AND_SALES), true);
    }

    ngOnInit() {
        super.ngOnInit();
        this.translateService.get([
            'studies.potential.market.and.sales.area.market.graph',
            'studies.potential.market.and.sales.potential.market.graph',
            'studies.potential.market.and.sales.real.market.graph',
            'studies.potential.market.and.sales.demands.graph',
            'studies.potential.market.and.sales.sales.graph'
        ]).subscribe(labelsObject => {
            const labels = [];
            for(let key in labelsObject) {
                labels.push(labelsObject[key])
            }
            this.barChartLabels = labels;
        });
        this.translateService.get('studies.potential.market.and.sales.covers.number').subscribe(label => {
            this.barChartOptions = {
                legend: {
                    labels: {
                        fontSize: 14
                    }
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            fontSize: 14
                        }
                    }],
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: label
                        },
                        ticks: {
                            fontSize: 14,
                            callback: function (value) {
                                return value.toLocaleString();
                            }
                        }
                    }]
                }
            };
        })
    }

    private studyToChartData(study: TeamPotentialMarketAndSalesStudy) {
        this.barChartData = [study.areaMarketSize, study.teamPotentialMarket, study.teamRealMarket, study.teamTotalDemands, study.teamTotalSales];
    }

    protected afterStudyLoad(study: TeamPotentialMarketAndSalesStudy) {
        this.studyToChartData(study);
    }
}

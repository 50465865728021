import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {StudiesParametersComponent} from './studies-parameters.component';
import {GenericStudiesPricesComponent} from './generic-studies-prices/generic-studies-prices.component';
import {StudiesPricesComponent} from './studies-prices/studies-prices.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {TabsModule} from '../../tabs/tabs.module';
import {ParametersComponentsModule} from '../components/parameters-components.module';
import {ComponentsModule} from '../../../components/components.module';

export const studiesParametersRoutes: Routes = [
    {
        path: '',
        component: StudiesParametersComponent,
        children: [
            {
                path: '',
                redirectTo: 'SYNTHESIS',
                pathMatch: 'full'
            },
            {
                path: ':studyType',
                component: GenericStudiesPricesComponent
            }
        ]
    }
]

@NgModule({
    declarations: [StudiesParametersComponent, StudiesPricesComponent, GenericStudiesPricesComponent],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        RouterModule,
        TabsModule,
        ParametersComponentsModule,
        ComponentsModule
    ]
})
export class StudiesParametersModule {
}

import { Injectable } from '@angular/core';
import {AbstractSessionParametersLoaderService} from '../../services/abstract-session-parameters-loader.service';
import {HumanResourcesAndTaxesParameters} from './human-resources-and-taxes-parameters';

@Injectable({
  providedIn: 'root'
})
export class HumanResourcesAndTaxesParametersLoaderService extends AbstractSessionParametersLoaderService<HumanResourcesAndTaxesParameters>{

  constructor() {
      super('humanResourcesAndTaxes')
  }
}

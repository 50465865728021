import {Component, Input} from '@angular/core';
import {ChartDataSets, ChartType} from "chart.js";
import {CompetitiveIntelligenceStudy} from "./competitive-intelligence-study";
import {TeamData} from "./team-data";
import {ChartUtils} from '../chart-utils';

@Component({
    selector: 'kiwi-competitive-intelligence',
    templateUrl: './competitive-intelligence.component.html',
    styleUrls: ['./competitive-intelligence.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class CompetitiveIntelligenceComponent {
    @Input()
    comparisonTitleLabel: string;

    @Input()
    barChartLabel: string = '';

    @Input()
    teamValueLabelKey: string;

    @Input()
    set comparisonStudy(study: CompetitiveIntelligenceStudy) {
        if (study) {
            this.barChartData = study.chartDataSets;
            this.customizeColors();
            this.teamsData = study.teamsData;
        }
    };

    private customizeColors(): void {
        this.barChartData.forEach((barChartData, index) => {
            ChartUtils.customizeColor(barChartData, this.chartColors[index % this.chartColors.length])
        });
    }

    public barChartType: ChartType = 'horizontalBar';
    public barChartLegend = true;
    public barChartPlugins = [];
    public barChartOptions = {
        scales: {
            xAxes: [{
                ticks: {
                    beginAtZero: true,
                    callback: function (value) {
                        return value.toLocaleString();
                    }
                }
            }]
        },
        legend: {
            position: 'bottom',
            labels: {
                fontSize: 14
            }
        }
    };

    public chartColors: string[] = [
        'rgba(145, 79, 56, 1)',
        'rgba(192, 117, 91, 1)',
        'rgba(180,173,173, 1)',
        'rgba(90, 94, 107, 1)',
        'rgba(218, 143, 107, 1)',
        'rgba(192, 197, 193, 1)',
        'rgba(247, 227, 175, 1)',
        'rgba(39, 40, 56, 1)',
        'rgba(209, 157, 137, 1)',
        'rgba(98, 48, 24, 1)',
    ];

    public barChartData: ChartDataSets[] = [{data: null}];
    public teamsData: TeamData[];
}

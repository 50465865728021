import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Message} from "../message";
import {MessagesLoader} from "../messages-loader";
import {ContextService} from "../../../context/context.service";
import {tap} from "rxjs/operators";
import {BehaviorSubject, Subscription} from "rxjs";
import {Context} from "../../../context/context";

@Component({
    selector: 'kiwi-messages-list',
    templateUrl: './messages-list.component.html',
    styleUrls: ['./messages-list.component.scss']
})
export class MessagesListComponent implements OnInit {
    @Output()
    public messageSelected = new EventEmitter<Message>();

    @Output()
    public indexSelected = new EventEmitter<number>();

    @Input()
    public messagesLoader: MessagesLoader;

    @Input()
    public reload: BehaviorSubject<any>;

    @Input()
    displayUnreadStatus: boolean = false;

    private messages: Message[];
    private selectedIndex: number;
    private contextServiceSubscription: Subscription = new Subscription();

    constructor(
        private contextService: ContextService
    ) {
    }

    ngOnInit() {
        if (this.reload)
            this.reload.subscribe(() => {
                this.contextServiceSubscription.unsubscribe();
                this.contextServiceSubscription = this.contextService.context.subscribe(
                    context => {
                        this.loadMessages(context)
                    }
                )
            });
        else
            this.contextService.context.subscribe(
                context => {
                    this.loadMessages(context)
                }
            );
    }

    private loadMessages(context: Context): void {
        if (context && context.currentSessionId)
            this.messagesLoader.loadMessages(context)
                .pipe(tap(messages => this.messages = messages))
                .subscribe();
    }

    onSelect(index: number): void {
        this.selectedIndex = index;
        this.messageSelected.emit(this.messages[index]);
        this.indexSelected.emit(this.messages.length - index - 1);
    }
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ProfitabilityEvaluationParameters} from "./profitability-evaluation-parameters";
import {AbstractEvaluationComponent} from '../../generic-evaluation/abstract-evaluation-component';
import {AbstractModificationComponent} from '../../../../components/abstract-modification.component';
import {ProfitabilityEvaluationParametersLoaderService} from './profitability-evaluation-parameters-loader.service';
import {ProfitabilityEvaluationParametersUpdaterService} from './profitability-evaluation-parameters-updater.service';
import {Observable, Subscription} from 'rxjs';
import {ContextService} from '../../../../context/context.service';
import {flatMap, filter} from 'rxjs/operators';
import {NgForm} from '@angular/forms';

@Component({
    selector: 'kiwi-profitability-mark-criteria',
    templateUrl: './profitability-mark-criteria.component.html',
    styleUrls: ['./profitability-mark-criteria.component.scss'],
    host: {'class': 'cell auto tabsContainer-content'}
})
export class ProfitabilityMarkCriteriaComponent extends AbstractModificationComponent<ProfitabilityEvaluationParameters> implements OnInit, OnDestroy {
    private contextSubscription: Subscription;
    profitabilityEvaluationParameters: ProfitabilityEvaluationParameters;

    @ViewChild('profitabilityMarkCriteriaForm', {read: NgForm})
    profitabilityMarkCriteriaForm: NgForm;

    getForm(): NgForm {
        return this.profitabilityMarkCriteriaForm;
    }

    constructor(private readonly profitabilityEvaluationParametersLoaderService: ProfitabilityEvaluationParametersLoaderService,
                private readonly profitabilityEvaluationParametersUpdaterService: ProfitabilityEvaluationParametersUpdaterService,
                private readonly contextService: ContextService) {
        super(
            'evaluations.criteria.save.toast.title',
            'evaluations.criteria.saved.successfully'
        );
    }

    protected callSave(): Observable<ProfitabilityEvaluationParameters> {
        return this.profitabilityEvaluationParametersUpdaterService.updateParameters(
            this.contextService.context.getValue(),
            this.profitabilityEvaluationParameters
        );
    }

    ngOnDestroy(): void {
        this.contextSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.contextSubscription = this.contextService.context.pipe(
            filter(context => !!context && !!context.currentSessionId),
            flatMap(context => this.profitabilityEvaluationParametersLoaderService.loadParameters(context))
        ).subscribe(profitabilityEvaluationParameters => this.profitabilityEvaluationParameters = profitabilityEvaluationParameters);
    }
}

import {ContextService} from '../../context/context.service';
import {LocatorService} from '../../locator.service';
import {Context} from '../../context/context';
import {OnDestroy, OnInit} from '@angular/core';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';

export abstract class AbstractPeriodsSelectorComponent implements OnInit, OnDestroy {
    protected contextService: ContextService;
    protected currentPeriod: string;
    protected periods: string[] = [];
    private contextSubscription: Subscription;

    protected constructor() {
        this.contextService = LocatorService.injector.get(ContextService);
    }

    ngOnInit() {
        this.contextSubscription = this.contextService.context.pipe(
            filter(context => !!context.currentSessionId),
            switchMap(context => forkJoin([of(context), this.retrievePeriods(context)])),
            filter(([_, periods]) => periods.length !== this.periods.length && periods.length > 0)
        ).subscribe(([context, periods]) => {
            this.periods = periods;
            this.currentPeriod = this.computeSelectedPeriod(context);
            if(this.currentPeriod !== this.contextService.context.getValue().currentPeriod) {
                this.contextService.updateCurrentPeriodId(this.currentPeriod);
            }
        })
    }

    abstract retrievePeriods(context: Context): Observable<string[]>;

    abstract computeSelectedPeriod(context: Context): string;

    ngOnDestroy(): void {
        if (this.contextSubscription) {
            this.contextSubscription.unsubscribe();
        }
    }
}

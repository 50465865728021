import {Component} from '@angular/core';
import {TabParentComponent, tabParentHost} from '../../../components/tab-parent/tab-parent.component';
import {Tab} from '../../tabs/tab';
import {of} from 'rxjs';

@Component({
    templateUrl: './../../../components/tab-parent/tab-parent.component.html',
    styleUrls: [],
    host: tabParentHost
})
export class RestaurantParametersComponent extends TabParentComponent {
    constructor() {
        super(of(['dining-rooms', 'sales-repartition', 'ingredients-purchase']
            .map(param => new Tab('parameters.restaurant.' + param, 'parameters/restaurant/' + param))));
    }
}

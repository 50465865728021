import {Component} from '@angular/core';
import {of} from 'rxjs';
import {WineStocks} from "./wine-stocks";
import {StudiesNames} from "../../studies-names";
import {AbstractStudyComponent} from "../../abstract-study.component";
import {CategoryName} from "../../category-name";

@Component({
    selector: 'kiwi-wine-stocks-study',
    templateUrl: './wine-stocks.component.html',
    styleUrls: ['./wine-stocks.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class WineStocksComponent extends AbstractStudyComponent<WineStocks> {
    constructor() {
        super(CategoryName.ACCOUNTS_DETAIL, of(StudiesNames.WINE_STOCKS));
    }
}

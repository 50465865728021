import {SeverityDispersionParameters} from './severity-dispersion-parameters';
import {DispersionApplier} from './dispersion-applier';

export class SeverityDispersionParametersApplier {
    static applySeverityAndDispersion(severityDispersionParameters: SeverityDispersionParameters,
                                      marks: number[], maxMark: number) {

        return DispersionApplier.applyDispersion(
            marks.map(mark => this.applySeverity(mark, severityDispersionParameters.severity, maxMark)),
            severityDispersionParameters.dispersion,
            maxMark
        );
    }

    private static applySeverity(mark: number, severity: number, maxMark: number): number {
        if (severity === 0) {
            return maxMark;
        }

        if (severity === 100) {
            return 0;
        }

        let exponent: number;

        if (severity >= 50) {
            exponent = 50 / (100 - severity);
        } else {
            exponent = severity / 50;
        }

        return Math.pow(mark / maxMark, exponent) * maxMark;
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Context} from "../../context/context";
import {Message} from "./message";
import {Observable} from "rxjs";
import {SendMessageRequest} from "./send-message-request";

@Injectable({
    providedIn: 'root'
})
export class MessageSenderService {

    constructor(private readonly httpClient: HttpClient) {
    }

    public sendMessage(context: Context, message: SendMessageRequest): Observable<Message> {
        return this.httpClient.post<Message>('api/sessions/' + context.currentSessionId + '/messages/send', message)
    }
}

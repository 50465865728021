import { Injectable } from '@angular/core';
import {AbstractTeamResultLoader} from '../../../../abstract-team-result-loader';

@Injectable({
  providedIn: 'root'
})
export class EffectiveAdvertisementBudgetLoaderService extends AbstractTeamResultLoader<number>{
  constructor() {
      super('quality/effectiveAdvertisementBudget')
  }
}

import {Component} from '@angular/core';
import {CommunicationParameters} from '../../services/communication/communication-parameters';
import {CommunicationParametersLoaderService} from '../../services/communication/communication-parameters-loader.service';
import {GenericSessionParametersComponent} from '../../generic-parameters/generic-session-parameters.component';
import {CommunicationParametersUpdaterService} from '../../services/communication/communication-parameters-updater.service';
import {genericParametersHost} from '../../generic-parameters/generic-parameters.component';

@Component({
    templateUrl: './communication-parameters.component.html',
    styleUrls: [
        './../../generic-parameters/generic-parameters.component.scss',
        './communication-parameters.component.scss'
    ],
    host: genericParametersHost
})
export class CommunicationParametersComponent extends GenericSessionParametersComponent<CommunicationParameters> {
    constructor(communicationParametersLoaderService: CommunicationParametersLoaderService,
                communicationParametersUpdaterService: CommunicationParametersUpdaterService) {
        super(communicationParametersLoaderService, communicationParametersUpdaterService, 'toast.parameters.standing.communication');
    }
}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TeamsLoaderService} from "../../team-decisions/period-completion/animator-decisions-closure/teams-loader.service";
import {AnimatorsLoaderService} from "../animators-loader.service";
import {User} from "../../../login/user";
import {Team} from "../../../login/team";
import {ContextService} from "../../../context/context.service";
import {forkJoin} from "rxjs";
import {tap} from "rxjs/operators";
import {UsersList} from "../users-list";

@Component({
    selector: 'kiwi-address-book',
    templateUrl: './address-book.component.html',
    styleUrls: ['./address-book.component.scss']
})
export class AddressBookComponent implements OnInit {
    @Output()
    addRecipients: EventEmitter<UsersList> = new EventEmitter<UsersList>();

    mainAnimator: User;
    coAnimators: User[];
    teams: Team[];
    displayPlayers: boolean[];

    constructor(
        private teamsLoaderService: TeamsLoaderService,
        private animatorsLoaderService: AnimatorsLoaderService,
        private contextService: ContextService
    ) {
    }

    ngOnInit() {
        this.contextService.context
            .subscribe(context => {
                if (context && context.currentSessionId) {
                    forkJoin(
                        this.teamsLoaderService.loadTeams(context),
                        this.animatorsLoaderService.loadMainAnimator(context),
                        this.animatorsLoaderService.loadCoAnimators(context)
                    ).pipe(
                        tap(([teams, mainAnimator, coAnimators]) => {
                            this.teams = teams;
                            this.displayPlayers = new Array(teams.length).map(() => false);
                            this.mainAnimator = mainAnimator;
                            this.coAnimators = coAnimators;
                        })
                    ).subscribe();
                }
            })
    }

    toggleDisplayPlayers(index: number) {
        this.displayPlayers[index] = !this.displayPlayers[index];
    }

    onAddAnimators() {
        this.onAddUser(this.mainAnimator);
        this.coAnimators.forEach(animator => this.onAddUser(animator));
    }

    onAddAllTeams() {
        this.teams.forEach(team => this.onAddTeam(team));
    }

    onAddTeam(team: Team) {
        this.onAddRecipients(new UsersList(team.name, team.players));
    }

    onAddUser(user: User) {
        this.onAddRecipients(new UsersList(user.person.firstName + ' ' + user.person.lastName, [user]));
    }

    private onAddRecipients(usersList: UsersList) {
        this.addRecipients.emit(usersList);
    }
}

import {Component, OnInit} from '@angular/core';
import {Tab} from "../../tabs/tab";
import {TabParentComponent} from "../../../components/tab-parent/tab-parent.component";
import {of} from "rxjs";

@Component({
    selector: 'kiwi-period-completion',
    templateUrl: './period-completion.component.html',
    styleUrls: ['./period-completion.component.scss'],
    host: {'class': 'cell auto grid-y decisionContainer _decision'}
})
export class PeriodCompletionComponent extends TabParentComponent{
    constructor() {
        super(of(new Array<Tab>(
            new Tab('decisions.period.completion.closure.tab', '/session/decisions/period-completion/animator-decisions-closure', false),
            new Tab('decisions.period.completion.simulation.tab', '/session/decisions/period-completion/simulation-launch', false),
            new Tab('decisions.period.completion.revert.period', '/session/decisions/period-completion/revert-period', false)
        )));
    }
}

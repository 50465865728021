import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Context} from '../../../../../context/context';

@Injectable({
  providedIn: 'root'
})
export class MenuStandingLoaderService {
    constructor(
        private httpClient: HttpClient
    ) {
    }

    retrieveData(team: number, period: string, session: string): Observable<number> {
        return this.httpClient.get<number>('/api/sessions/' + session +
            '/periods/' + period +
            '/result/teams/' + team +
            '/menuStanding');
    }

    loadMenuStanding(context: Context): Observable<number> {
        return this.retrieveData(context.currentTeam.teamNumber, context.currentPeriod, context.currentSessionId);
    }
}

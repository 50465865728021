import { Injectable } from '@angular/core';
import {AbstractPeriodParametersLoaderService} from "../abstract-period-parameters-loader.service";
import {InsurancesParameters} from "./insurances-parameters";
import {AbstractSessionParametersLoaderService} from '../abstract-session-parameters-loader.service';

@Injectable({
  providedIn: 'root'
})
export class InsurancesParametersLoaderService extends AbstractSessionParametersLoaderService<InsurancesParameters>{

  constructor() { super('insurances') }
}

import {Component, Input} from '@angular/core';
import {SimulatedPeriodsLoaderService} from "../../team-decisions/period-completion/revert-period/simulated-periods-loader.service";
import {Location} from '@angular/common';

@Component({
    selector: 'kiwi-study-header',
    templateUrl: './study-header.component.html',
    styleUrls: ['./study-header.component.scss'],
    host: {'class': 'cell shrink grid-x grid-margin-x align-justify align-middle'}
})
export class StudyHeaderComponent {
    @Input() title: string;
    @Input() hideCloseButton: boolean;
    @Input() category: string;
    @Input() public hideSelects: boolean = false;
    @Input() public displayTitle: boolean = true;

    constructor(
        private location: Location
    ) {

    }

    back() {
        this.location.back()
    }
}

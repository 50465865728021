import {Injectable} from '@angular/core';
import {AbstractPeriodParametersLoaderService} from '../abstract-period-parameters-loader.service';

@Injectable({
    providedIn: 'root'
})
export class AreaPortraitLoaderService extends AbstractPeriodParametersLoaderService<Object> {

    constructor() {
        super(
            'areaPortraits'
        );
    }
}

import { Injectable } from '@angular/core';
import {AbstractDecisionLoaderService} from "../../abstract-decision-loader.service";
import {AnimatorProductsAndCharges} from "./animator-products-and-charges";

@Injectable({
  providedIn: 'root'
})
export class AnimatorProductsAndChargesDecisionLoaderService extends AbstractDecisionLoaderService<AnimatorProductsAndCharges>{

  constructor() {
      super('financial/animatorProductsAndCharges')
  }
}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'kiwi-number-or-nothing',
  templateUrl: './number-or-nothing.component.html'
})
export class NumberOrNothingComponent {
    @Input()
    valueToDisplay: number;

    @Input()
    displayValue: boolean;
}

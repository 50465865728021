import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {Md5} from 'ts-md5';
import {filter, map, take, tap} from 'rxjs/operators';
import {ConnectedUser, notConnectedUser} from './connected-user';
import {User} from '../login/user';
import {ContextService} from './context.service';
import {ConfigService} from '../config.service';
import {AblyRealtimeService} from '../ably/ably-realtime.service';

@Injectable()
export class ConnectedUserService {

    public userEvents: BehaviorSubject<ConnectedUser> = new BehaviorSubject(undefined);
    public animatorTokenBalance: BehaviorSubject<number> = new BehaviorSubject(0);
    public readonly ably: BehaviorSubject<any> = new BehaviorSubject(undefined);
    public readonly isSimulatedStarted: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public readonly isRevertStarted: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private contextService: ContextService,
        private http: HttpClient,
        private router: Router,
        private readonly configService: ConfigService,
        private readonly ablyService: AblyRealtimeService,
    ) {
        this.userEvents.subscribe((connectedUser) => {
            if (connectedUser && connectedUser.name !== 'NOT_CONNECTED') {
                if (connectedUser.user.animator) {
                    this.contextService.loadAnimatorCurrentContext(connectedUser);
                    this.loadAnimatorTokenBalance(connectedUser.user);
                } else {
                    // Connected user is a player
                    this.contextService.loadPlayerCurrentContext(connectedUser);
                }
                this.loadAblyConnexion(connectedUser);
            }
        });
    }

    loadAnimatorTokenBalance(user: User) {
        this.retrieveAnimatorTokenBalance(user)
            .subscribe(animatorAccount => this.animatorTokenBalance.next(animatorAccount['tokenBalance']));
    }

    loadConnectedUser() {
        this.http.get('/api/sessions/current')
            .subscribe(response => {
                this.userEvents.next(this.retrieveUserData(response['principal']));
            }, error => {
                this.userEvents.next(notConnectedUser);
            });
    }

    authenticate(login: string, password: string): Observable<ConnectedUser> {
        return this.http.post('/api/sessions',
            {principal: {name: login, passwordHash: Md5.hashStr(password)}},
            {withCredentials: true})
            .pipe(
                map(response => response['principal']),
                map((response) => this.retrieveUserData(response)),
                tap(() => this.router.navigateByUrl('session/decisions').catch())
            );
    }

    logout(): void {
        this.http.delete('/api/sessions/current')
            .subscribe(() => {
                const disconnectedUser = this.userEvents.getValue();
                this.userEvents.next(undefined);
                this.contextService.updateCurrentTeam(null);

                if (disconnectedUser.user.player) {
                    this.router.navigate(['login']).catch();
                } else {
                    this.configService.loadSessionsManagerUrl().subscribe(sessionsManagerUrl => {
                        window.location.href = sessionsManagerUrl;
                    });
                }
            });
        // TODO - 1f1efa6f-da7d-48c9-b39a-4187d10c7e98 Gérer la dé souscription à ably lors de la déconnexion
        // this.ablyService.closeAuthentication(this.ably.getValue());
        // this.ably.next(undefined);
    }

    private retrieveUserData(connectedUser: ConnectedUser): ConnectedUser {
        this.userEvents.next(connectedUser);
        return connectedUser;
    }

    private retrieveAnimatorTokenBalance(user: User): Observable<number> {
        return this.http.get<number>('/api/users/animators/' + user._id + '/tokenBalance');
    }

    isUserConnected(): Observable<boolean> {
        return this.userEvents.pipe(
            filter(user => user != null),
            take(1),
            map(user => user !== notConnectedUser)
        );
    }

    private loadAblyConnexion(connectedUser: ConnectedUser) {
        // TODO - 1f1efa6f-da7d-48c9-b39a-4187d10c7e98 : Voir pour ne pas ouvrir de double connexion lors du refresh
        //  de la page en modifiant le cycle de vie de la page.
        //  Ce n'est pas normal de passer deux fois dans la méthode lors du refresh
        if (this.ably.getValue() === undefined) {
            this.ably.next(this.ablyService.generateAuthentication(connectedUser.name));
        }
    }

    isSimulationActivated(): Observable<boolean> {
        return this.isSimulatedStarted;
    }

    isRevertActivated(): Observable<boolean> {
        return this.isRevertStarted;
    }
}

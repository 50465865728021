import { Injectable } from '@angular/core';
import {AbstractSessionParametersUpdaterService} from '../../services/abstract-session-parameters-updater-service';
import {StandingParameters} from '../../services/standing/standing-parameters';

@Injectable({
  providedIn: 'root'
})
export class StandingParametersUpdaterService extends AbstractSessionParametersUpdaterService<StandingParameters> {

  constructor() {
      super('standing');
  }
}

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    host: {'class': 'grid-x align-right align-middle'}
})
export class FooterComponent implements OnInit {
    private appVersion = 'appVersion';

    constructor() {
    }

    ngOnInit() {
    }

}

import {TabParentComponent} from '../../../../components/tab-parent/tab-parent.component';
import {Observable} from 'rxjs';
import {Tab} from '../../../tabs/tab';
import {StudiesNames} from '../../studies-names';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {Context} from '../../../../context/context';
import {ContextService} from '../../../../context/context.service';
import {LocatorService} from '../../../../locator.service';
import {PeriodUtils} from '../../study-header/period-utils';

export abstract class StudyTabParentComponent<T> extends TabParentComponent {
    private readonly contextService: ContextService;

    protected constructor(tabs: Observable<Tab[]>, public readonly studyId: StudiesNames) {
        super(tabs);
        this.contextService = LocatorService.injector.get(ContextService);
    }

    ngOnInit() {
        this.contextService.context.pipe(
            filter(context => !!context && !!context.currentSession && !!context.currentTeam && !!context.currentTeam.teamNumber),
            tap(context => {
                if (context.currentPeriod === context.currentSession.currentPeriod) {
                    this.contextService.updateCurrentPeriodId(PeriodUtils.computePreviousPeriod(context.currentPeriod, 'MONTHLY'));
                }
            }),
            filter(context => context.currentPeriod !== context.currentSession.currentPeriod),
            switchMap(context => {
                return this.loadStudy(context);
            }),
            filter(study => !!study)
        ).subscribe((study: T) => {
            this.fillTabs(study);
            super.ngOnInit();
            this.goToFirstTabIfNeeded();
        });
    }

    protected abstract loadStudy(context: Context): Observable<T>;

    protected goToFirstTabIfNeeded() {
        if (this.router.url.endsWith(this.studyId)) {
            this.tabs.subscribe(tabs => {
                    tabs[0].isSelected = true;
                    this.router.navigateByUrl(tabs[0].url).catch();
                }
            )
        }
    }

    protected abstract fillTabs(study: T): void;

    protected fillTabsWith(object: Object) {
        const currentUrl = this.router.url;
        for (const dishName in object)
            this.tabs.pipe(map(tabs => {
                const tab = new Tab('menu.' + dishName,
                    currentUrl.replace(new RegExp("(.*" + this.studyId + ")(.*)"), "$1/" + dishName),
                    false);
                if (tabs.findIndex(t => t.key == tab.key) == -1) {
                    tabs.push(tab);
                }
            })).subscribe();
    }
}

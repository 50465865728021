import {Injectable} from '@angular/core';
import {AbstractDecisionUpdaterService} from "../abstract-decision-updater.service";
import {DiningRoomsDecision} from "./dining-rooms-decision";

@Injectable({
    providedIn: 'root'
})
export class DiningRoomsDecisionUpdaterService extends AbstractDecisionUpdaterService<DiningRoomsDecision> {

    constructor() {
        super('diningRooms');
    }
}

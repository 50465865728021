import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AbstractModificationComponent} from '../../components/abstract-modification.component';
import {SaveBeforeDeactivatePopupService} from '../../components/save-before-deactivate-popup/save-before-deactivate-popup.service';

@Injectable({
    providedIn: 'root'
})
export class CanDeactivateModificationComponentGuard implements CanDeactivate<AbstractModificationComponent<any>> {
    constructor(
        private readonly saveBeforeDeactivatePopupService: SaveBeforeDeactivatePopupService
    ) {
    }

    canDeactivate(
        component: AbstractModificationComponent<any>,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (component.dirty && !component.isFormInvalid()) {
            const url = nextState.url;
            this.saveBeforeDeactivatePopupService.showConfirmationPopup(component, url);
            return false;
        }

        return true;
    }
}

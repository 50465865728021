import {Component} from '@angular/core';
import {StudiesPrices} from '../studies-prices';
import {StudiesPricesLoaderService} from '../studies-prices-loader.service';
import {StudiesPricesUpdaterService} from '../studies-prices-updater.service';
import {ActivatedRoute} from '@angular/router';
import {GenericPeriodParametersComponent} from '../../generic-parameters/generic-period-parameters.component';
import {map, tap} from 'rxjs/operators';

@Component({
    templateUrl: './generic-studies-prices.component.html',
    styleUrls: ['./generic-studies-prices.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class GenericStudiesPricesComponent extends GenericPeriodParametersComponent<StudiesPrices> {
    public static readonly STUDIES_NAMES_BY_TYPE = {
        'SYNTHESIS': [
            'BALANCE_SHEET',
            'PROFIT_LOSS_ACCOUNTS',
            'CASH_FLOW_STATEMENT'
        ],
        'ACCOUNTS_DETAIL': [
            'TURNOVER',
            'PURCHASES',
            'HUMAN_RESOURCES',
            'EXTERNAL_LOADS_DETAILS',
            'DEPRECIATION_CHARGES',
            'FINANCIAL_RESULT',
            'EXCEPTIONAL_RESULT',
            'TAXES'
        ],
        'SALES_HISTORY': ['SALES_HISTORY'],
        'POTENTIAL_MARKET_AND_SALES': ['POTENTIAL_MARKET_AND_SALES'],
        'COMMUNICATION_PARAMETERS': ['COMMUNICATION_PARAMETERS'],
        'HUMAN_RESOURCES_PARAMETERS': ['HUMAN_RESOURCES_PARAMETERS'],
        'DINING_ROOMS_PARAMETERS': ['DINING_ROOMS_PARAMETERS'],
        'COMPETITIVE_INTELLIGENCE': [
            'MARKET_SHARES',
            'ADVERTISEMENT_BUDGET_COMPARISON',
            'LAYOUT_QUALITY_COMPARISON',
            'MAINTENANCE_QUALITY_COMPARISON',
            'COOKING_QUALITY_COMPARISON',
            'CUSTOMER_CARE_QUALITY_COMPARISON',
            'SALES_PRICES_COMPARISON',
            'DISHES_QUALITY_COMPARISON',
            'WINES_QUALITY_COMPARISON'
        ],
        'SALES_DETAIL': [
            'SALES_DETAIL'
        ],
        'QUALITY': [
            'QUALITY'
        ]
    }

    public studyType = '';
    public studiesNames: Array<string> = [];

    constructor(
        studiesPricesLoaderService: StudiesPricesLoaderService,
        studiesPricesUpdaterService: StudiesPricesUpdaterService,
        route: ActivatedRoute
    ) {
        super(studiesPricesLoaderService, studiesPricesUpdaterService, 'toast.parameters.studies.prices.saved');

        route.paramMap.pipe(
            map(paramMap => paramMap.get('studyType')),
            tap(studyType => this.studyType = studyType),
            map(studyType => GenericStudiesPricesComponent.STUDIES_NAMES_BY_TYPE[studyType])
        ).subscribe(studiesNames => this.studiesNames = studiesNames);
    }
}

import {Component} from '@angular/core';
import {WinesPurchases} from "./wines-purchases";
import {WinesPurchasesUpdaterService} from "./wines-purchases-updater.service";
import {WinesParametersLoaderService} from "../../../parameters/services/wines-parameters-loader.service";
import {Context} from "../../../../context/context";
import {forkJoin, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {WinesPurchasesLoaderService} from "./wines-purchases-loader.service";
import {WinesQualitiesLoaderService} from "./wines-qualities-loader.service";
import {PeriodUtils} from "../../../studies/study-header/period-utils";
import {Score} from "../../generics/score-image/score-image.component";
import {AbstractDecisionComponent} from "../../abstract-decision-component";
import {computePurchasingPrice} from '../../compute-purchasing-price';
import { computeWineStockQuality } from './compute-wine-stock-quality';

export class WinesPurchasingComponentParameters {
    constructor(public readonly winesParameters: Object, public readonly winesQualities: Object) {
    }
}

@Component({
    selector: 'kiwi-wines-purchasing',
    templateUrl: './wines-purchasing.component.html',
    styleUrls: ['./wines-purchasing.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class WinesPurchasingComponent extends AbstractDecisionComponent<WinesPurchases, WinesPurchasingComponentParameters> {
    constructor(
        private winesParametersLoaderService: WinesParametersLoaderService,
        private winesQualitiesLoaderService: WinesQualitiesLoaderService,
        winesPurchasesLoader: WinesPurchasesLoaderService,
        winesPurchasesUpdater: WinesPurchasesUpdaterService,
    ) {
        super(
            winesPurchasesLoader,
            winesPurchasesUpdater,
            [new Score('decisions.menu.wines.quality', 0)],
            'toast.menu.wines.purchases.decision.saved.message'
        );
    }

    protected loadParameters(context: Context): Observable<WinesPurchasingComponentParameters> {
        return forkJoin(
            this.winesParametersLoaderService.loadParameters(context),
            this.winesQualitiesLoaderService.loadTeamResultFromContext(context, PeriodUtils
                .computePreviousPeriod(context.currentPeriod, context.currentSession.baseMetaScenario.periodicity)))
            .pipe(map(([winesParameters, winesQualities]) =>
                new WinesPurchasingComponentParameters(winesParameters.winesStandardPurchasingPrices, winesQualities)));
    }

    onSave(): void {
        this.save();
    }

    onQualityChange(wineName: string): void {
        this.decision.winesPurchases[wineName].purchasingPriceExcludingTax =
            computePurchasingPrice(
                this.decision.winesPurchases[wineName].quality,
                this.parameters.winesParameters[wineName]
            );

        this.updateWACU(wineName);
        this.updateScoreImage();
    }

    onPurchasedNumberChange(wineName: string): void {
        this.updateStock(wineName);
        this.updateWACU(wineName);
        this.updateScoreImage();
    }

    private updateStock(wineName: string) {
        this.decision.winesPurchases[wineName].stock =
            this.decision.winesPurchases[wineName].purchasedNumber +
            this.decision.winesPurchases[wineName].initialStock;
    }

    private updateWACU(wineName: string) {
        let wacu;
        const winesPurchase = this.decision.winesPurchases[wineName];
        if (winesPurchase.initialStock == null) {
            winesPurchase.initialStock = 0
        }
        if (winesPurchase.initialWacu == null) {
            winesPurchase.initialWacu = 0
        }
        if (winesPurchase.purchasedNumber == 0 && winesPurchase.initialStock == 0) {
            wacu = 0;
        } else {
            wacu = Number.parseFloat(((winesPurchase.initialWacu *
                winesPurchase.initialStock +
                winesPurchase.purchasedNumber *
                winesPurchase.purchasingPriceExcludingTax) /
                winesPurchase.stock).toFixed(2));
        }

        winesPurchase.wacu = wacu;
    }

    protected computeDecisionQuality(scores: Score[], parameters: WinesPurchasingComponentParameters): number {
        return scores[0].value;
    }

    protected updateScores(decision: WinesPurchases, parameters: WinesPurchasingComponentParameters): void {
        this.scores[0].value = computeWineStockQuality(decision, parameters.winesQualities);
    }
}

import {Component} from '@angular/core';
import {MenuDishesPurchasesLoaderService} from "../../../team-decisions/menu-decision/ingredients-purchasing/menu-dishes-purchases-loader.service";
import {StudiesNames} from "../../studies-names";
import {Observable, of} from "rxjs";
import {MenuDishesPurchases} from "../../../team-decisions/menu-decision/ingredients-purchasing/menu-dishes-purchases";
import {StudyTabParentComponent} from '../study-tab-parent/study-tab-parent.component';
import {Context} from '../../../../context/context';

@Component({
    templateUrl: '../study-tab-parent/study-tab-parent.component.html',
    styleUrls: ['../study-tab-parent/study-tab-parent.component.scss'],
    host: {'class': 'cell auto pageContainer grid-y'}
})
export class DishesQualityComparisonComponent extends StudyTabParentComponent<MenuDishesPurchases> {

    constructor(private menuDishesPurchasesLoaderService: MenuDishesPurchasesLoaderService) {
        super(of([]), StudiesNames.DISHES_QUALITY_COMPARISON);
    }

    protected fillTabs(study: MenuDishesPurchases): void {
        this.fillTabsWith(study.firstCoursesPurchases);
        this.fillTabsWith(study.meatDishesPurchases);
        this.fillTabsWith(study.fishDishesPurchases);
        this.fillTabsWith(study.dessertsPurchases);
    }

    protected loadStudy(context: Context): Observable<MenuDishesPurchases> {
        return this.menuDishesPurchasesLoaderService.loadDecisionFromContext(context)
    }
}

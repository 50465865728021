import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {YearTeamsMarks} from "./year-teams-marks";
import {Context} from "../../../../context/context";

@Injectable({
    providedIn: 'root'
})
export class YearsTeamsMarksLoaderService {

    constructor(
        private readonly httpClient: HttpClient
    ) {
    }

    public loadYearsTeamsMarks(context: Context, markType: string): Observable<YearTeamsMarks[]> {
        return this.httpClient.get<YearTeamsMarks[]>('/api/sessions/' + context.currentSessionId + '/evaluations/' + markType + '/marks');
    }
}

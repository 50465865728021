import {Injectable} from '@angular/core';
import {DecisionStatusData, DecisionStatusLog, TeamDecisions} from "./team-decisions";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class TeamDecisionsClosureService {

    constructor(
        private httpClient: HttpClient
    ) {
    }

    public completeClosureLogs(statusData: DecisionStatusData): DecisionStatusData {
        const closureStatuses = statusData.closureStatuses;
        const statusLogs = statusData.statusLogs;

        statusData.teamClosureLog = this.findTeamClosureLog(closureStatuses, statusLogs);
        statusData.animatorClosureLog = this.findAnimatorClosureLog(closureStatuses, statusLogs);

        return statusData;
    }

    public updateOpenStatus(
        sessionId: string,
        periodId: string,
        teamNumber: number,
        openStatus: string
    ): Observable<TeamDecisions> {
        return this.httpClient.put<TeamDecisions>(
            '/api/sessions/' + sessionId +
            '/periods/' + periodId +
            '/teamDecisions/' + teamNumber +
            '/open/' + openStatus, null);
    }

    public insertClosureStatus(
        sessionId: string,
        periodId: string,
        teamNumber: number,
        closureStatus: string
    ): Observable<TeamDecisions> {
        return this.httpClient.put<TeamDecisions>(
            '/api/sessions/' + sessionId +
            '/periods/' + periodId +
            '/teamDecisions/' + teamNumber +
            '/close/' + closureStatus, null);
    }

    private findAnimatorClosureLog(closureStatuses: string[], statusLogs: DecisionStatusLog[]): DecisionStatusLog {
        return this.findClosureLog(closureStatuses, statusLogs, 'ANIMATOR_CLOSURE');
    }

    private findTeamClosureLog(closureStatuses: string[], statusLogs: DecisionStatusLog[]): DecisionStatusLog {
        return this.findClosureLog(closureStatuses, statusLogs, 'TEAM_CLOSURE');
    }

    private findClosureLog(closureStatuses: string[], statusLogs: DecisionStatusLog[], closureStatus: string): DecisionStatusLog {
        if (!closureStatuses.includes(closureStatus))
            return null;

        let closureLog = null;
        let i = statusLogs.length - 1;

        while (i > 0 && closureLog == null) {
            if (statusLogs[i].status == closureStatus)
                closureLog = statusLogs[i];
            i--;
        }

        return closureLog;
    }
}

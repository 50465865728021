import {StudiesNames} from "../studies-names";
import {DiningRoomParametersComponent} from "../dining-room-parameters/dining-room-parameters.component";
import {StudyRouteDefinition} from '../study-route-definition';

export const diningRoomsParametersChildrenRoutes: StudyRouteDefinition[] = [
    new StudyRouteDefinition(
        StudiesNames.DINING_ROOMS_PARAMETERS,
        [{
            path: '',
            component: DiningRoomParametersComponent
        }]
    )
];

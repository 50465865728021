import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {Team} from "../../../../login/team";
import {HttpClient} from "@angular/common/http";
import {Context} from "../../../../context/context";
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TeamsLoaderService {
    private teams: Team[] = [];

    constructor(private httpClient: HttpClient) {
    }

    loadTeams(context: Context): Observable<Team[]> {
        if (this.teams.length > 0) {
            return of(this.teams);
        }

        return this.httpClient.get<Team[]>('/api/sessions/' + context.currentSessionId + '/teams')
            .pipe(tap(teams => this.teams = teams));
    }

    loadTeam(teamId: string): Observable<Team> {
        return this.httpClient.get<Team>('/api/teams/' + teamId);
    }
}

import {Component} from '@angular/core';
import {ConfirmationPopupComponent} from "../../../../confirmation-popup/confirmation-popup.component";
import {RestaurantTypeChange} from '../restaurant-type-change.model';

@Component({
    selector: 'kiwi-decision-confirmation-popup',
    templateUrl: './decision-confirmation-popup.component.html',
    styleUrls: ['./decision-confirmation-popup.component.scss'],
    host: {'class': 'popupContainer'}
})
export class DecisionConfirmationPopupComponent extends ConfirmationPopupComponent {
    public restaurantTypeChange: RestaurantTypeChange;
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ConnectedUserService} from "../../../context/connected-user.service";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class CanActivateAnimatorGuard implements CanActivate {
    constructor(private connectedUserService: ConnectedUserService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean>{
        return this.connectedUserService.userEvents.pipe(map(user => {
            return user.user.animator
        }));
    }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {ParametersTabContentHeaderComponent} from './parameters-tab-content-header/parameters-tab-content-header.component';
import {ComponentsModule} from '../../../components/components.module';

const exportedComponents: any[] = [
        ParametersTabContentHeaderComponent
    ]
;

@NgModule({
    exports: exportedComponents,
    declarations: exportedComponents,
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,
        FormsModule,
        ComponentsModule
    ]
})
export class ParametersComponentsModule {

}

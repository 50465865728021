import {StaffDecision} from "./staff-decision";
import {max} from "rxjs/operators";

export abstract class ComputeAbstractTypeRestaurantQuality {
    protected constructor(
        private readonly standardCoversNumber: number,
        private readonly previousPeriodCoversNumber: number,
        private readonly standardQuality: number,
        protected readonly staffDecision: StaffDecision,
        private readonly standardCookWageBillStandardQuality: number,
        private readonly standardWaiterWageBillStandardQuality: number,
        private readonly maximalCookStaffQuality: number,
        private readonly maximalWaiterStaffQuality: number
    ) {

    }

    abstract computeCookingQuality(): number;

    abstract computeCustomerCareQuality(): number;

    protected computeCookStaffQuality(): number {
        return this.computeStaffQuality(
            this.computeCooksIdealWageBill(),
            this.staffDecision.cooksWageBill,
            this.maximalCookStaffQuality);
    }

    protected computeWaiterStaffQuality(): number {
        return this.computeStaffQuality(
            this.computeWaitersIdealWageBill(),
            this.staffDecision.waitersWageBill,
            this.maximalWaiterStaffQuality
        );
    }

    private computeStaffQuality(
        standardWageBill: number,
        realWageBill: number,
        maximalValue: number
    ): number {
        if(standardWageBill === 0)
            return 0;

        return Number.parseFloat(Math.min((Number.parseFloat((this.standardQuality / standardWageBill).toFixed(8)) * realWageBill), maximalValue).toFixed(4));
    }

    private computeCooksIdealWageBill(): number {
        return this.computeIdealWageBill(this.standardCookWageBillStandardQuality);
    }

    private computeWaitersIdealWageBill(): number {
        return this.computeIdealWageBill(this.standardWaiterWageBillStandardQuality);
    }

    private computeIdealWageBill(
        standardWageBill: number
    ): number {
        return standardWageBill * this.previousPeriodCoversNumber / this.standardCoversNumber;
    }
}

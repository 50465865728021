import {Injectable} from '@angular/core';
import {Context} from "../../../context/context";
import {Observable} from "rxjs";
import {AreaDecisionLoaderService} from "../area-decision/area-decision-loader.service";
import {TeamCacheService} from '../../../team-cache.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HasChosenAreaService extends TeamCacheService<boolean> {
    constructor(
        private areaDecisionLoaderService: AreaDecisionLoaderService
    ) {
        super();
    }

    public hasChosenArea(): Observable<boolean> {
        return this.getFromCache(hasChosenArea => !hasChosenArea);
    }

    public setHasChosenArea(teamNumber: number, hasChosenArea: boolean) {
        this.cache.set(teamNumber, hasChosenArea);
    }

    protected loadFromBack(context: Context): Observable<boolean> {
        return this.areaDecisionLoaderService.loadDecisionFromContext(context).pipe(map(areaDecision =>
            areaDecision.area !== undefined && areaDecision.area !== areaDecision.missingArea));
    }
}

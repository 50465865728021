import {HttpClient} from "@angular/common/http";
import {DisplayStudy} from "./display-study";
import {Observable, of} from "rxjs";
import {Context} from "../../context/context";
import {Injectable} from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class DisplayStudiesLoaderService {

    constructor(
        private httpClient: HttpClient
    ) {

    }

    loadDisplayStudies(context: Context, category: string): Observable<DisplayStudy[]> {
        return this.httpClient.get<DisplayStudy[]>(
            '/api/sessions/' + context.currentSessionId +
            '/periods/' + context.currentPeriod +
            '/teams/' + context.currentTeam.teamNumber +
            '/studies/' + category);
    }

    loadDisplayStudy(context: Context, category: string, studyId: string): Observable<DisplayStudy> {
        return this.httpClient.get<DisplayStudy>(
            '/api/sessions/' + context.currentSessionId +
            '/periods/' + context.currentPeriod +
            '/teams/' + context.currentTeam.teamNumber +
            '/studies/' + category +
            '/' + studyId +
            '/display'
        );
    }

    isDisplayStudyAccessible(context: Context, category: string, studyId: string): Observable<boolean> {
        if(context.currentPeriod === context.currentSession.currentPeriod)
            return of(false);

        return this.httpClient.get<boolean>(
            '/api/sessions/' + context.currentSessionId +
            '/periods/' + context.currentPeriod +
            '/teams/' + context.currentTeam.teamNumber +
            '/studies/' + category +
            '/' + studyId +
            '/available')
    }
}

import {Injectable} from '@angular/core';
import {AbstractDecisionLoaderService} from "./abstract-decision-loader.service";
import {TeamDecisions} from "./team-decisions";
import {TeamDecisionsClosureService} from "./team-decisions-closure.service";

@Injectable({
    providedIn: 'root'
})
export class TeamDecisionsLoaderService extends AbstractDecisionLoaderService<TeamDecisions> {

    constructor(
        teamDecisionsClosureService: TeamDecisionsClosureService
    ) {
        super('',
            (jsonData) => {
                let teamDecisions = jsonData as TeamDecisions;
                teamDecisions.statusData = teamDecisionsClosureService.completeClosureLogs(teamDecisions.statusData);
                return teamDecisions;
            });
    }
}

import {Injectable} from '@angular/core';
import {TeamDecisions} from './team-decisions/team-decisions';
import {TeamDecisionsLoaderService} from './team-decisions/team-decisions-loader.service';
import {ContextService} from '../context/context.service';
import {ConnectedUserService} from '../context/connected-user.service';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {AblyRealtimeService} from '../ably/ably-realtime.service';
import {AblyClosureEventType} from 'custom-ably-types';
import {filter} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AreDecisionsClosedService {
    public readonly decisionsClosed = new BehaviorSubject<boolean>(false);

    private subscription: Subscription;

    constructor(
        private readonly teamDecisionsLoaderService: TeamDecisionsLoaderService,
        private readonly contextService: ContextService,
        private readonly connectedUserService: ConnectedUserService,
        ablyRealtimeService: AblyRealtimeService
    ) {
        const closedByAnimator: AblyClosureEventType = 'CLOSED_BY_ANIMATOR';
        const closedByTeam: AblyClosureEventType = 'CLOSED_BY_TEAM';
        const unclosed: AblyClosureEventType = 'UNCLOSED';

        this.subscription = combineLatest(
            this.contextService.context,
            this.connectedUserService.userEvents
        ).pipe(
            filter(([context, user]) =>
                !!context &&
                !!context.currentSessionId &&
                !!context.currentPeriod &&
                !!context.currentTeam &&
                !!user &&
                !!user.user
            )
        ).subscribe(
            ([context, user]) => {
                // Read first value from backend
                teamDecisionsLoaderService.loadDecisionFromContext(context)
                    .subscribe(teamDecisions => {
                        this.decisionsClosed.next(AreDecisionsClosedService.areDecisionsClosed(teamDecisions, user.user.player));
                    });

                // Subscribe to updates using Ably
                connectedUserService.ably.subscribe(ably => {
                        // if (ably !== undefined) {
                        ablyRealtimeService.unsubscribeToClosureDecisions(ably, context.currentTeam.teamNumber.toString());
                        ablyRealtimeService.subscribeToClosureDecisions(
                            ably, context.currentSessionId, context.currentTeam.teamNumber.toString(), (message: any) => {
                                const currentContext = contextService.context.getValue();
                                if (user.user.player && currentContext.currentPeriod === currentContext.currentSession.currentPeriod) {
                                    switch (message.data) {
                                        case closedByTeam:
                                        case closedByAnimator :
                                            this.decisionsClosed.next(true);
                                            break;
                                        case unclosed:
                                            this.decisionsClosed.next(false);
                                            break;
                                    }
                                } else if (user.user.animator
                                    && currentContext.currentPeriod === currentContext.currentSession.currentPeriod
                                    && message.name === currentContext.currentTeam.teamNumber.toString()) {
                                    switch (message.data) {
                                        case closedByAnimator :
                                            this.decisionsClosed.next(true);
                                            break;
                                        case closedByTeam:
                                        case unclosed:
                                            this.decisionsClosed.next(false);
                                            break;
                                    }
                                }
                            });
                        // TODO - 1f1efa6f-da7d-48c9-b39a-4187d10c7e98 : Gestion de la déconnexion à ably si nécessaire
                        // } else {
                        //     this.connectedUserService.ably.unsubscribe();
                        // }
                    }
                );
            }
        );
    }

    private static areDecisionsClosed(teamDecisions: TeamDecisions, currentUserIsPlayer: boolean): boolean {
        if (currentUserIsPlayer) {
            return teamDecisions.statusData.currentStatus === 'CLOSED';
        }
        return teamDecisions.statusData.closureStatuses.includes('ANIMATOR_CLOSURE');
    }

    public unsubscribeFromContexteAndUserEvents() {
        // TODO - 1f1efa6f-da7d-48c9-b39a-4187d10c7e98 : Gérer la dé souscription
        this.subscription.unsubscribe();
    }
}

import {Component, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractInputWithUnitComponent} from './abstract-input-with-unit.component';

@Component({
    selector: 'kiwi-mark-input',
    templateUrl: './input-with-unit.component.html',
    styleUrls: ['./input-with-unit.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MarkInputComponent),
            multi: true
        }
    ]
})
export class MarkInputComponent extends AbstractInputWithUnitComponent {
    constructor() {
        super('/ 100', 0.01);
    }
}

import {Injectable} from '@angular/core';
import {AbstractDecisionLoaderService} from "../../abstract-decision-loader.service";
import {FinancialDecisionInformation} from './financial-decision-information.model';

@Injectable({
    providedIn: 'root'
})
export class FinancialDecisionInformationLoaderService extends AbstractDecisionLoaderService<FinancialDecisionInformation> {
    constructor() {
        super('financial/info');
    }
}

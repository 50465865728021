import {Component} from '@angular/core';
import {AbstractStudyComponent} from "../../abstract-study.component";
import {CompetitiveIntelligenceStudy} from "../competitive-intelligence-study";
import {CategoryName} from "../../category-name";
import {StudiesNames} from "../../studies-names";
import {of} from "rxjs";

@Component({
    selector: 'kiwi-cooking-quality-comparison',
    templateUrl: './cooking-quality-comparison.component.html',
    styleUrls: ['./cooking-quality-comparison.component.scss'],
    host: {'class': 'cell auto grid-y'}

})
export class CookingQualityComparisonComponent extends AbstractStudyComponent<CompetitiveIntelligenceStudy> {
    constructor() {
        super(CategoryName.COMPETITIVE_INTELLIGENCE, of(StudiesNames.COOKING_QUALITY_COMPARISON));
    }
}

import {Component} from '@angular/core';
import {TabParentComponent, tabParentHost} from '../../../components/tab-parent/tab-parent.component';
import {of} from 'rxjs';
import {standingParametersChildrenRoutes} from './standing-parameters-children-routes';
import {Tab} from '../../tabs/tab';

@Component({
    templateUrl: './../../../components/tab-parent/tab-parent.component.html',
    styleUrls: [],
    host: tabParentHost
})
export class StandingParametersComponent extends TabParentComponent {
    constructor() {
        super(of(standingParametersChildrenRoutes
            .map(route => route.path)
            .map(tabName => new Tab('parameters.standing.' + tabName, 'parameters/standing/' + tabName))))
    }
}

import {OnDestroy, OnInit} from "@angular/core";
import {Team} from "../../login/team";
import {ContextService} from "../../context/context.service";
import {Context} from "../../context/context";
import {combineLatest, Observable, Subscription} from "rxjs";
import {AbstractDecisionLoaderService} from "./abstract-decision-loader.service";
import {AbstractDecisionUpdaterService} from "./abstract-decision-updater.service";
import {filter, map, switchMap, tap} from "rxjs/operators";
import {Score} from "./generics/score-image/score-image.component";
import {LocatorService} from "../../locator.service";
import {AbstractModificationComponent} from '../../components/abstract-modification.component';
import {AreDecisionsClosedService} from '../are-decisions-closed.service';

export abstract class AbstractDecisionComponent<T, U> extends AbstractModificationComponent<T> implements OnInit, OnDestroy {
    protected period: string;
    protected team: Team;
    protected session: string;
    protected decisionsReadonly: boolean;
    protected imgClass: string;
    private _decision: T;
    protected parameters: U;
    private _quality: number;
    protected readonly contextService: ContextService;
    private readonly areDecisionsClosedService: AreDecisionsClosedService;
    private contextSubscription: Subscription;

    get quality(): number {
        return this._quality;
    }

    set quality(value: number) {
        this._quality = value;
        if (value < 33)
            this.imgClass = '_bad';
        else if (value < 66)
            this.imgClass = '_medium';
        else
            this.imgClass = '_good';
    }

    get decision(): T {
        return this._decision;
    }

    set decision(value: T) {
        this._decision = value;
        this.initScoreImage();
    }

    protected constructor(
        protected decisionLoader: AbstractDecisionLoaderService<T>,
        private decisionUpdater: AbstractDecisionUpdaterService<T>,
        public scores: Score[],
        protected readonly savedSuccessfulKey: string,
        private doAfterDecisionLoaded?: Function
    ) {
        super();
        this.contextService = LocatorService.injector.get(ContextService);
        this.areDecisionsClosedService = LocatorService.injector.get(AreDecisionsClosedService);
    }

    ngOnInit(): void {
        this.contextSubscription = this.contextService.context.pipe(
            filter(context => !!context.currentSession && !!context.currentTeam),
            tap(context => {
                this.session = context.currentSessionId;
                this.period = context.currentPeriod;
                this.team = context.currentTeam;
            }),
            switchMap(context =>
                combineLatest(
                    this.loadDecision(context),
                    this.loadParameters(context),
                    this.areDecisionsClosedService.decisionsClosed
                )),
        ).subscribe(([decision, parameters, areDecisionsReadonly]) => {
            this.parameters = parameters;
            this.decision = decision;
            this.decisionsReadonly = areDecisionsReadonly;
            if (this.doAfterDecisionLoaded)
                this.doAfterDecisionLoaded(decision);
        });
    }

    public callSave(): Observable<T> {
        return this.decisionUpdater.saveDecision(
            this.session,
            this.period,
            this.team.teamNumber,
            this._decision).pipe(
            map((decision) => this._decision = decision)
        );
    }

    protected onSuccessfulSave() {
        this.notificationService.decisionsSavedSuccessfully(this.savedSuccessfulKey);
    }

    loadDecision(context: Context): Observable<T> {
        return this.decisionLoader.loadDecisionFromContext(context);
    }

    protected abstract loadParameters(context: Context): Observable<U>;

    protected initScoreImage(): void {
        this.updateScores(this.decision, this.parameters);
        this.updateQuality();
    }

    protected updateScoreImage() : void {
        this.onValueUpdate();
        this.initScoreImage();
    }

    private updateQuality(): void {
        console.debug("Load score image component with the following scores: \n" +
            this.scores.reduce((strScores, score) => strScores + "label: " + score.label + "\nvalue: " + score.value + "\n\n", ''));
        this.quality = Math.round(this.computeDecisionQuality(this.scores, this.parameters) * 100) / 100;
    }

    protected abstract updateScores(decision: T, parameters: U): void;

    protected abstract computeDecisionQuality(scores: Score[], parameters: U): number;

    ngOnDestroy(): void {
        this.contextSubscription.unsubscribe();
    }
}


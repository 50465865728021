import {NgModule} from '@angular/core';
import {ContextService} from "./context.service";
import {ConnectedUserService} from "./connected-user.service";
import {HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";

@NgModule({
    declarations: [],
    imports: [
        HttpClientModule,
        RouterModule
    ],
    providers: [
        ContextService,
        ConnectedUserService
    ]
})
export class ContextModule {
}

import {Component} from '@angular/core';
import {GenericReadonlyParametersComponent} from '../../generic-parameters/generic-readonly-parameters.component';
import {StandingMenuParameters} from '../../services/standing/standing-menu-parameters';
import {StandingMenuParametersLoaderService} from '../../services/standing/standing-menu-parameters-loader.service';
import {genericParametersHost} from '../../generic-parameters/generic-parameters.component';

@Component({
    templateUrl: './menu-standing-parameters.component.html',
    styleUrls: [
        './../../generic-parameters/generic-parameters.component.scss',
        './menu-standing-parameters.component.scss'
    ],
    host: genericParametersHost
})
export class MenuStandingParametersComponent extends GenericReadonlyParametersComponent<StandingMenuParameters> {

    constructor(standingMenuParametersLoaderService: StandingMenuParametersLoaderService) {
        super(standingMenuParametersLoaderService)
    }
}

import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AreaCompetitionLoaderService {

  constructor(private readonly httpClient: HttpClient) { }

  public loadAreaCompetition(sessionId: string, period: string, teamId: number): Observable<Object> {
      return this.httpClient.get<Object>(
          '/api/sessions/' + sessionId + '/periods/' + period + '/areaCompetition/' + teamId);
  }
}

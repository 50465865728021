import {StudiesNames} from '../studies-names';
import {BalanceSheetComponent} from '../synthesis/balance-sheet/balance-sheet.component';
import {ProfitLossAccountsComponent} from '../synthesis/profit-loss-accounts/profit-loss-accounts.component';
import {CashFlowStatementComponent} from '../synthesis/cash-flow/cash-flow-statement.component';
import {StudyRouteDefinition} from '../study-route-definition';

export const synthesisChildrenRoutes: StudyRouteDefinition[] = [
    new StudyRouteDefinition(
        StudiesNames.BALANCE_SHEET,
        [{
            path: '',
            component: BalanceSheetComponent
        }]
    ),
    new StudyRouteDefinition(
        StudiesNames.PROFIT_LOSS_ACCOUNTS,
        [{
            path: '',
            redirectTo: 'monthly',
            pathMatch: 'full'
        }, {
            path: ':periodicity',
            component: ProfitLossAccountsComponent
        }]
    ),
    new StudyRouteDefinition(
        StudiesNames.CASH_FLOW_STATEMENT,
        [{
            path: '',
            redirectTo: 'monthly',
            pathMatch: 'full'
        }, {
            path: ':periodicity',
            component: CashFlowStatementComponent
        }]
    )
];

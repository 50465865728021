import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SeverityDispersionParametersDto} from './severity-dispersion-parameters-dto';

@Injectable({
    providedIn: 'root'
})
export class SeverityDispersionParametersLoaderService {

    constructor(private httpClient: HttpClient) {
    }

    public loadSeverityDispersionParameters(type: string, sessionId: string): Observable<SeverityDispersionParametersDto> {
        return this.httpClient.get<SeverityDispersionParametersDto>('api/sessions/' + sessionId + '/parameters/evaluations/' + type + '/severityDispersion');
    }
}

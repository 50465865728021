import {Injectable} from '@angular/core';
import {AbstractMarkWeightLoaderService} from '../../marks/abstract-mark-weight-loader.service';
import {AssignmentType} from '../../marks/assignement-type';

@Injectable({
  providedIn: 'root'
})
export class MenuMarkWeightLoaderService extends AbstractMarkWeightLoaderService {

  constructor() {
      super(AssignmentType.INGREDIENTS_PURCHASES);
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Context} from "./context/context";
import {map} from "rxjs/operators";
import {PeriodUtils} from "./app-main/studies/study-header/period-utils";

export class SessionEvent {
    constructor(
        public readonly eventId: number,
        public readonly startDate: Date,
        public readonly endDate: Date,
        public periodId: string
    ) {

    }
}

@Injectable({
    providedIn: 'root'
})
export class SessionEventsLoaderService {
    constructor(
        private readonly httpClient: HttpClient
    ) {
    }

    public loadSessionEvents(context: Context): Observable<SessionEvent[]> {
        return this.httpClient.get<SessionEvent[]>('/api/sessions/' + context.currentSessionId + '/events')
            .pipe(map(sessionEvents => {
                sessionEvents.forEach(sessionEvent =>
                    sessionEvent.periodId = PeriodUtils
                        .computePeriodFromMonth(
                            sessionEvent.eventId,
                            context.currentSession.baseMetaScenario.periodicity));
                return sessionEvents;
            }));
    }
}

import {Injectable} from '@angular/core';
import {AbstractPeriodParametersLoaderService} from '../../services/abstract-period-parameters-loader.service';
import {ExternalLoadsParameters} from '../../services/period/external-loads/external-loads-parameters';

@Injectable({
    providedIn: 'root'
})
export class ExternalLoadsParametersLoaderService extends AbstractPeriodParametersLoaderService<ExternalLoadsParameters> {

    constructor() {
        super('externalLoads')
    }
}

import { Injectable } from '@angular/core';
import {AbstractDecisionLoaderService} from '../../abstract-decision-loader.service';
import {StaffMarks} from './staff-marks';

@Injectable({
  providedIn: 'root'
})
export class StaffMarksLoaderService extends AbstractDecisionLoaderService<StaffMarks> {
  constructor() {
      super('assignments/staff');
  }
}

export function computePurchasingPrice(
    quality: number,
    standardPurchasingPrice: number,
    currentQuality?: number,
    currentPurchasingPrice?: number
): number {
    if (!!currentQuality && !!currentPurchasingPrice && currentQuality === quality) {
        return currentPurchasingPrice;
    }

    if (quality < 50)
        return Number.parseFloat((standardPurchasingPrice * quality / 50).toFixed(2));

    return Number.parseFloat((standardPurchasingPrice * quality * quality / 2500).toFixed(2));
}

import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Context} from "../../../context/context";
import {LocatorService} from "../../../locator.service";
import {AbstractParametersLoaderService} from './abstract-parameters-loader.service';

export class AbstractPeriodParametersLoaderService<T> implements AbstractParametersLoaderService<T>{
    private readonly httpClient: HttpClient;

    constructor(
        private parametersType: string
    ) {
        this.httpClient = LocatorService.injector.get(HttpClient);
    }

    loadParameters(context: Context): Observable<T> {
        return this.loadPeriodParameters(context);
    }

    private loadPeriodParameters(context: Context): Observable<T> {
        return this.httpClient.get<T>(
            '/api/sessions/' + context.currentSessionId +
            '/periods/' + context.currentPeriod +
            '/parameters/' + this.parametersType
        );
    }

}

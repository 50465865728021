import {Injectable} from '@angular/core';
import {AbstractSessionParametersLoaderService} from '../../parameters/services/abstract-session-parameters-loader.service';

@Injectable({
    providedIn: 'root'
})
export class ArchetypeDecisionParametersLoaderService extends AbstractSessionParametersLoaderService<ArchetypeDecisionParameters> {

    constructor() {
        super('archetypes')
    }
}

import {HttpClient} from "@angular/common/http";
import {LocatorService} from "../../../locator.service";
import {Context} from "../../../context/context";
import {Observable} from "rxjs";
import {AbstractParametersUpdaterService} from './abstract-parameters-updater.service';

export class AbstractPeriodParametersUpdaterService<T> implements AbstractParametersUpdaterService<T>{
    private readonly httpClient: HttpClient;

    constructor(
        private parametersType: string
    ) {
        this.httpClient = LocatorService.injector.get(HttpClient);
    }

    updateParameters(context: Context, parameters: T): Observable<T> {
        return this.updatePeriodParameters(context, parameters);
    }

    private updatePeriodParameters(context: Context, periodParameters: T): Observable<T> {
        return this.httpClient.put<T>('/api/sessions/' + context.currentSessionId +
            '/periods/' + context.currentSession.currentPeriod +
            '/parameters/' + this.parametersType,
            periodParameters);
    }
}

import {DiningRoomsDecision, RestaurantEquipment} from './dining-rooms-decision';

export class ComputeLayoutSellingIncomeUtils {
    private static computeRestaurantEquipmentSellingIncome(restaurantEquipment: RestaurantEquipment, roomsToRenew: number) {
        if (!RestaurantEquipment.hasQualityChanged(restaurantEquipment)) {
            return 0;
        }

        return restaurantEquipment.currentValuePerRoom * roomsToRenew;
    }

    static computeLayoutSellingIncome(restaurantEquipment: RestaurantEquipment, currentRoomsNumber: number, previousRoomsNumber: number) {
        let layoutSellingIncome = 0;
        const roomsDifference = previousRoomsNumber - currentRoomsNumber;

        if (roomsDifference > 0) {
            layoutSellingIncome = layoutSellingIncome + (roomsDifference * (restaurantEquipment.currentValuePerRoom));
        }

        const roomsToRenew = previousRoomsNumber < currentRoomsNumber ? previousRoomsNumber : currentRoomsNumber;
        layoutSellingIncome = layoutSellingIncome + ComputeLayoutSellingIncomeUtils.computeRestaurantEquipmentSellingIncome(restaurantEquipment, roomsToRenew);

        return layoutSellingIncome;
    }
}

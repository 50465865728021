import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StandingParametersComponent} from './standing-parameters.component';
import {GlobalStandingParametersComponent} from './global-standing-parameters/global-standing-parameters.component';
import {PotentialMarketParametersComponent} from './potential-market-parameters/potential-market-parameters.component';
import {CommunicationParametersComponent} from './communication-parameters/communication-parameters.component';
import {MenuStandingParametersComponent} from './menu-standing-parameters/menu-standing-parameters.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {ComponentsModule} from '../../../components/components.module';
import {ParametersComponentsModule} from '../components/parameters-components.module';
import {RouterModule, Routes} from '@angular/router';
import {standingParametersChildrenRoutes} from './standing-parameters-children-routes';
import {TabsModule} from '../../tabs/tabs.module';

export const standingParametersRoutes: Routes = [
    {
        path: '',
        component: StandingParametersComponent,
        children: standingParametersChildrenRoutes.concat({
            path: '',
            redirectTo: standingParametersChildrenRoutes[0].path,
            pathMatch: 'full'
        })
    }
]

@NgModule({
    declarations: [
        StandingParametersComponent,
        GlobalStandingParametersComponent,
        PotentialMarketParametersComponent,
        CommunicationParametersComponent,
        MenuStandingParametersComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ComponentsModule,
        ParametersComponentsModule,
        RouterModule,
        TabsModule
    ]
})
export class StandingParametersModule { }

import {Component} from '@angular/core';
import {CategoryName} from "../../category-name";
import {StudiesNames} from "../../studies-names";
import {AbstractStudyComponent} from "../../abstract-study.component";
import {of} from "rxjs";
import {ActivatedRoute} from "@angular/router";

class CashIn {
    constructor(
        public readonly dishesTurnoverIncludingTaxes: number,
        public readonly winesTurnoverIncludingTaxes: number,
        public readonly dishesTurnoverExcludingTaxes: number,
        public readonly winesTurnoverExcludingTaxes: number,
        public readonly dishesTurnoverTaxes: number,
        public readonly winesTurnoverTaxes: number,
        public readonly otherOperatingProductsIncludingTaxes: number,
        public readonly otherOperatingProductsExcludingTaxes: number,
        public readonly otherOperatingProductsTaxes: number,
        public readonly refundVat: number,
        public readonly incomePaymentInAdvanceReimbursement: number,
        public readonly layoutSellingIncomeIncludingTaxes: number,
        public readonly layoutSellingIncomeExcludingTaxes: number,
        public readonly layoutSellingIncomeTaxes: number,
        public readonly investmentInterest: number,
        public readonly otherFinancialProducts: number,
        public readonly otherExceptionalIncomeIncludingTaxes: number,
        public readonly amountNewlyBorrowed: number,
        public readonly amountWithdrawnFromSavings: number,
        public readonly incomeTotalExcludingTaxes: number,
        public readonly incomeTotalTaxes: number,
        public readonly totalCashIn: number
    ) {
    }
}

class CashOut {
    constructor(
        public readonly winesPurchasesIncludingTaxes: number,
        public readonly ingredientsPurchasesIncludingTaxes: number,
        public readonly winesPurchasesExcludingTaxes: number,
        public readonly ingredientsPurchasesExcludingTaxes: number,
        public readonly winesPurchasesTaxes: number,
        public readonly ingredientsPurchasesTaxes: number,
        public readonly layoutPurchaseCostIncludingTaxes: number,
        public readonly layoutPurchaseCostExcludingTaxes: number,
        public readonly layoutPurchaseCostTaxes: number,
        public readonly externalLoadsIncludingTaxes: number,
        public readonly externalLoadsExcludingTaxes: number,
        public readonly externalLoadsTaxes: number,
        public readonly vatPaid: number,
        public readonly wageBills: number,
        public readonly socialCharges: number,
        public readonly loanInterest: number,
        public readonly advanceTaxPayment: number,
        public readonly incomeTax: number,
        public readonly loanAmountRepaid: number,
        public readonly amountNewlyInvested: number,
        public readonly overdraftCharges: number,
        public readonly otherOperatingChargesIncludingTaxes: number,
        public readonly otherOperatingChargesExcludingTaxes: number,
        public readonly otherOperatingChargesTaxes: number,
        public readonly otherFinancialExpensesIncludingTaxes: number,
        public readonly otherNonRecurringChargeIncludingTaxes: number,
        public readonly chargesTotalExcludingTaxes: number,
        public readonly chargesTotalTaxes: number,
        public readonly totalCashOut: number,
    ) {
    }
}

class CashFlowStatement {
    constructor(
        public readonly cashIn: CashIn,
        public readonly cashOut: CashOut,
        public readonly initialTreasury: number,
        public readonly finalTreasury: number
    ) {
    }
}

@Component({
    selector: 'kiwi-cash-flow-statement',
    templateUrl: './cash-flow-statement.component.html',
    styleUrls: ['./cash-flow-statement.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class CashFlowStatementComponent extends AbstractStudyComponent<CashFlowStatement> {

    constructor(private route: ActivatedRoute) {
        super(CategoryName.SYNTHESIS, of(StudiesNames.CASH_FLOW_STATEMENT), true, route.params)
    }
}

import {Component, EventEmitter, Input, OnInit, Output, OnDestroy, ViewChild} from '@angular/core';
import {FinalMarkCriteria} from "./final-mark-criteria";
import {AbstractEvaluationComponent} from '../../generic-evaluation/abstract-evaluation-component';
import {AbstractModificationComponent} from '../../../../components/abstract-modification.component';
import {FinalMarkCriteriaLoaderService} from './final-mark-criteria-loader.service';
import {FinalMarkCriteriaUpdaterService} from './final-mark-criteria-updater.service';
import {ContextService} from '../../../../context/context.service';
import {Observable, Subscription} from 'rxjs';
import {filter, flatMap} from 'rxjs/operators';
import {NgForm} from '@angular/forms';

@Component({
    selector: 'kiwi-final-mark-criteria',
    templateUrl: './final-mark-criteria.component.html',
    styleUrls: ['./final-mark-criteria.component.scss'],
    host: {'class': 'cell auto tabsContainer-content'}
})
export class FinalMarkCriteriaComponent extends AbstractModificationComponent<FinalMarkCriteria> implements OnInit, OnDestroy {
    public finalMarkCriteria: FinalMarkCriteria;
    private contextSubscription: Subscription;

    @ViewChild('finalMarkCriteriaForm', {read: NgForm})
    finalMarkCriteriaForm: NgForm;

    getForm(): NgForm {
        return this.finalMarkCriteriaForm;
    }

    constructor(
        private readonly loader: FinalMarkCriteriaLoaderService,
        private readonly updater: FinalMarkCriteriaUpdaterService,
        private readonly contextService: ContextService
    ) {
        super(
            'evaluations.criteria.save.toast.title',
            'evaluations.criteria.saved.successfully'
        );
    }

    protected callSave(): Observable<FinalMarkCriteria> {
        return this.updater.updateParameters(this.contextService.context.getValue(), this.finalMarkCriteria);
    }

    ngOnDestroy(): void {
        this.contextSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.contextSubscription = this.contextService.context.pipe(
            filter(context => !!context && !!context.currentSessionId),
            flatMap(context =>
               this.loader.loadParameters(context)
            )
        ).subscribe(finalMarkCriteria => this.finalMarkCriteria = finalMarkCriteria)
    }
}

import {Injectable} from '@angular/core';
import {CommunicationParameters} from "./communication-parameters";
import {AbstractSessionParametersUpdaterService} from '../abstract-session-parameters-updater-service';

@Injectable({
    providedIn: 'root'
})
export class CommunicationParametersUpdaterService extends AbstractSessionParametersUpdaterService<CommunicationParameters> {

    constructor() {
        super('communication');
    }
}

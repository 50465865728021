import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChartOptions, ChartType} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {AreaConfirmationPopupService} from '../area-confirmation-popup.service';
import {AreaPortrait} from '../../../parameters/services/area-portrait/area-portrait';

@Component({
    selector: 'kiwi-area-portrait',
    templateUrl: './area-portrait.component.html',
    styleUrls: ['./area-portrait.component.scss']
})
export class AreaPortraitComponent {
    @Input()
    public area: string;

    @Input()
    public areDecisionsReadonly: boolean;

    @Input()
    public areaPortrait: AreaPortrait;

    @Output()
    public chosenArea = new EventEmitter<string>();

    constructor(
        private confirmationPopupService: AreaConfirmationPopupService
    ) {
    }

    onSave() {
        this.confirmationPopupService.showConfirmationPopup(this.area, () => this.emitChosenArea());
    }

    emitChosenArea() {
        this.chosenArea.emit(this.area);
    }
}

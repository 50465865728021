import {WinesPurchases} from './wines-purchases';
import {roundFloat} from '../../math-utils';

export function computeWineStockQuality(decision: WinesPurchases, previousPeriodWinesQualities: Object): number {
    let result = 0;
    let totalStock = 0;

    for (const wineName in decision.winesPurchases) {
        const wine = decision.winesPurchases[wineName];
        result += wine.quality * wine.purchasedNumber;

        if (wine.initialStock > 0)
            result += wine.initialStock * previousPeriodWinesQualities[wineName];

        totalStock += wine.stock;
    }

    return roundFloat(result / totalStock, 2);
}

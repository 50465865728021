import { Injectable } from '@angular/core';
import {AbstractDecisionLoaderService} from "../abstract-decision-loader.service";
import {AreaDecision} from "./area-decision";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AreaDecisionLoaderService extends AbstractDecisionLoaderService<AreaDecision>{

  constructor() {
    super( 'area')
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {filter, map, switchMap, take} from "rxjs/operators";
import {ContextService} from '../../context/context.service';
import {HttpClient} from '@angular/common/http';
import {CannotActivateReason, GuardResult} from '../team-decisions/guards/guard-result';
import {SessionLockedForUserService} from '../session-locked-for-user.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateAccountGuard implements CanActivate {
    constructor(
        private readonly contextService: ContextService,
        private readonly httpClient: HttpClient,
        private readonly sessionLockedForUserService: SessionLockedForUserService
        ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree>{
        return this.contextService.context
            .pipe(
                filter(context => !!context && !!context.currentSessionId),
                take(1),
                map(context => context.currentSessionId),
                switchMap(currentSessionId => this.httpClient.get<GuardResult>('api/sessions/' + currentSessionId + '/canActivateAccount')),
                map(guardResult => {
                    if(!guardResult.canActivate && guardResult.reason === CannotActivateReason.SESSION_LOCKED) {
                        return this.sessionLockedForUserService.urlToSessionBlocked();
                    }

                    return guardResult.canActivate;
                })
            )
    }
}

import { Injectable } from '@angular/core';
import {AbstractSessionParametersUpdaterService} from '../../services/abstract-session-parameters-updater-service';
import {HumanResourcesAndTaxesParameters} from './human-resources-and-taxes-parameters';

@Injectable({
  providedIn: 'root'
})
export class HumanResourcesAndTaxesParametersUpdaterService extends AbstractSessionParametersUpdaterService<HumanResourcesAndTaxesParameters>{

  constructor() {
      super('humanResourcesAndTaxes');
  }
}

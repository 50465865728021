import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RankingMenuDisabledLoaderService {
  constructor(private readonly httpClient: HttpClient) { }

  public loadRankingMenuDisabled(sessionId: string): Observable<boolean> {
      return this.httpClient.get<boolean>('/api/sessions/' + sessionId +
          '/configuration/rankingMenuDisabled');
  }
}

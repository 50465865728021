import {StudiesNames} from "../studies-names";
import {HumanResourcesParametersComponent} from "../human-resources-parameters/human-resources-parameters.component";
import {StudyRouteDefinition} from '../study-route-definition';

export const humanResourcesParametersChildrenRoutes: StudyRouteDefinition[] = [
    new StudyRouteDefinition(
        StudiesNames.HUMAN_RESOURCES_PARAMETERS,
        [{
            path: '',
            component: HumanResourcesParametersComponent
        }]
    )
];

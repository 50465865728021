import {Component} from '@angular/core';
import {DiningRoomsAndRentParameters} from './dining-rooms-and-rent-parameters';
import {DiningRoomsAndRentParametersLoaderService} from './dining-rooms-and-rent-parameters-loader.service';
import {DiningRoomsAndRentParametersUpdaterService} from './dining-rooms-and-rent-parameters-updater.service';
import {GenericSessionAndPeriodParametersComponent} from '../../generic-parameters/generic-session-and-period-parameters.component';
import {genericParametersHost} from '../../generic-parameters/generic-parameters.component';

@Component({
    templateUrl: './dining-rooms-parameters.component.html',
    styleUrls: ['./../../generic-parameters/generic-parameters.component.scss'],
    host: genericParametersHost
})
export class DiningRoomsParametersComponent extends GenericSessionAndPeriodParametersComponent<DiningRoomsAndRentParameters> {
    constructor(diningRoomsAndRentParametersLoaderService: DiningRoomsAndRentParametersLoaderService,
                diningRoomsAndRentParametersUpdaterService: DiningRoomsAndRentParametersUpdaterService) {
        super(
            diningRoomsAndRentParametersLoaderService,
            diningRoomsAndRentParametersUpdaterService,
            'toast.parameters.restaurant.dining.rooms.saved'
        );
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ParametersMenuComponent} from './parameters-menu/parameters-menu.component';
import {ParametersComponent} from './parameters.component';
import {ParametersHeaderComponent} from './parameters-header/parameters-header.component';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {ComponentsModule} from '../../components/components.module';
import {StandingParametersModule, standingParametersRoutes} from './standing-parameters/standing-parameters.module';
import {FinancialParametersModule, financialParametersRoutes} from './financial-parameters/financial-parameters.module';
import {QualityParametersModule, qualityParametersRoutes} from './quality-parameters/quality-parameters.module';
import {
    RestaurantParametersModule,
    restaurantParametersRoutes
} from './restaurant-parameters/restaurant-parameters.module';
import {FinancialParametersComponent} from './financial-parameters/financial-parameters.component';
import {StandingParametersComponent} from './standing-parameters/standing-parameters.component';
import {QualityParametersComponent} from './quality-parameters/quality-parameters.component';
import {StudiesParametersModule, studiesParametersRoutes} from './studies-parameters/studies-parameters.module';

export const parametersRoutes: Routes = [
    {
        path: '',
        component: ParametersComponent,
        children: [
            {
                path: '',
                redirectTo: 'studies',
                pathMatch: 'full'
            },
            {
                path: 'studies',
                children: studiesParametersRoutes
            },
            {
                path: 'financial',
                children: financialParametersRoutes
            },
            {
                path: 'standing',
                children: standingParametersRoutes
            },
            {
                path: 'quality',
                children: qualityParametersRoutes
            },
            {
                path: 'restaurant',
                children: restaurantParametersRoutes
            }
        ]
    }
];

@NgModule({
    declarations: [ParametersMenuComponent, ParametersComponent, ParametersHeaderComponent],
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,
        FormsModule,
        ComponentsModule,
        StandingParametersModule,
        FinancialParametersModule,
        QualityParametersModule,
        RestaurantParametersModule,
        StudiesParametersModule
    ]
})
export class ParametersModule {
}

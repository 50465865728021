import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {PeriodCompletionComponent} from "./period-completion.component";
import {AnimatorDecisionsClosureComponent} from "./animator-decisions-closure/animator-decisions-closure.component";
import {SimulationLaunchComponent} from "./simulation-launch/simulation-launch.component";
import {RevertPeriodComponent} from "./revert-period/revert-period.component";

export const periodCompletionRoutes: Routes = [
    {
        path: '',
        component: PeriodCompletionComponent,
        children: [
            {
                path: '',
                redirectTo: 'animator-decisions-closure',
                pathMatch: 'full'
            },
            {
                path: 'animator-decisions-closure',
                component: AnimatorDecisionsClosureComponent,
            },

            {
                path: 'simulation-launch',
                component: SimulationLaunchComponent,
            },

            {
                path: 'revert-period',
                component: RevertPeriodComponent
            }
        ]
    }
];

@NgModule({
    exports: [RouterModule]
})

export class PeriodCompletionRoutingModule {
}

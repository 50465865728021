import {Component} from '@angular/core';
import {AbstractDecisionComponent} from "../abstract-decision-component";
import {AreaDecisionLoaderService} from "./area-decision-loader.service";
import {AreaDecision} from "./area-decision";
import {AreaDecisionUpdaterService} from "./area-decision-updater.service";
import {Context} from "../../../context/context";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {Score} from "../generics/score-image/score-image.component";
import {TeamDecisions} from '../team-decisions';
import {AreaPortraitLoaderService} from '../../parameters/services/area-portrait/area-portrait-loader.service';
import {AreaPortrait} from '../../parameters/services/area-portrait/area-portrait';
import {HasChosenAreaService} from '../guards/has-chosen-area.service';
import {HasChosenArchetypeService} from '../guards/has-chosen-archetype.service';
import {take} from 'rxjs/operators';

@Component({
    selector: 'kiwi-area-decision',
    templateUrl: './area-decision.component.html',
    styleUrls: ['./area-decision.component.scss'],
    host: {'class': 'cell auto cell-block-y grid-x'}
})
export class AreaDecisionComponent extends AbstractDecisionComponent<AreaDecision, Object> {
    selectedArea: string;
    selectedAreaPortrait: AreaPortrait;
    decisionsReadonly = false;

    constructor(
        private router: Router,
        private areaPortraitService: AreaPortraitLoaderService,
        private readonly hasChosenAreaService: HasChosenAreaService,
        private readonly hasChosenArchetypeService: HasChosenArchetypeService,
        areaDecisionLoaderService: AreaDecisionLoaderService,
        areaDecisionUpdaterService: AreaDecisionUpdaterService,
    ) {
        super(
            areaDecisionLoaderService,
            areaDecisionUpdaterService,
            [],
            'toast.area.decision.saved.message',
            (areaDecision => {
                if (areaDecision.area !== areaDecision.missingArea)
                    this.selectArea(areaDecision.area);
            })
        );
    }

    onSave(chosenArea: string) {
        this.decision.area = chosenArea;
        this.callSave()
            .subscribe(() => {
                    this.notificationService.decisionsSavedSuccessfully(this.savedSuccessfulKey);
                    this.contextService.updateCurrentArea(chosenArea);
                    this.hasChosenAreaService.setHasChosenArea(this.contextService.context.getValue().currentTeam.teamNumber, true);
                    this.hasChosenArchetypeService.hasChosenArchetype()
                        .pipe(take(1))
                        .subscribe(hasChosenArchetype => {
                            const redirectTo = hasChosenArchetype ? 'session/decisions' : 'session/decisions/archetypes';
                            this.router.navigateByUrl(redirectTo).catch();
                        })
                },
                err => this.notificationService.error(err.error)
            );
    }

    selectArea(area: string) {
        if (this.selectedArea === area)
            this.selectedArea = null;
        else {
            this.selectedArea = area;
            this.selectedAreaPortrait = this.parameters[area] as AreaPortrait;
        }
    }

    protected loadParameters(context: Context): Observable<Object> {
        return this.areaPortraitService.loadParameters(context);
    }

    protected computeDecisionQuality(scores: Score[], parameters: Object): number {
        return 0;
    }

    protected updateScores(decision: AreaDecision, parameters: Object): void {
    }

}

import {Component, Input} from '@angular/core';

class Rival {
    public name: string;
    public teamNumber: number;
}

@Component({
    selector: 'kiwi-restaurant-type-rivals',
    templateUrl: './restaurant-type-rivals.component.html',
    styleUrls: ['./restaurant-type-rivals.component.scss']
})
export class RestaurantTypeRivalsComponent {
    @Input()
    restaurantType: string;

    @Input()
    rivals: Rival[];
}

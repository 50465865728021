import {Component} from '@angular/core';
import {AbstractStudyComponent} from '../../abstract-study.component';
import {HumanResourcesStudy} from './human-resources-study';
import {CategoryName} from '../../category-name';
import {StudiesNames} from '../../studies-names';
import {of} from 'rxjs';

@Component({
    templateUrl: './human-resources-study.component.html',
    styleUrls: ['./human-resources-study.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class HumanResourcesStudyComponent extends AbstractStudyComponent<HumanResourcesStudy> {

    constructor() {
        super(CategoryName.ACCOUNTS_DETAIL, of(StudiesNames.HUMAN_RESOURCES));
    }
}

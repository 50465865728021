import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {User} from "../../login/user";
import {ConnectedUserService} from "../../context/connected-user.service";
import {ContextService} from "../../context/context.service";
import {Context} from "../../context/context";
import {HasChosenArchetypeService} from "../team-decisions/guards/has-chosen-archetype.service";
import {LanguagesLoaderService} from "../account/languages-loader.service";
import {TranslateService} from "@ngx-translate/core";
import {RestaurantStandingInformation} from "../../context/restaurant-standing-information";
import {AreaDecisionLoaderService} from "../team-decisions/area-decision/area-decision-loader.service";
import {HasChosenAreaService} from "../team-decisions/guards/has-chosen-area.service";
import {AreaDecision} from '../team-decisions/area-decision/area-decision';

@Component({
    selector: 'kiwi-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
    private static readonly TYPE_1_STARS_NUMBER = 1;
    private static readonly TYPE_2_STARS_NUMBER = 2;
    private static readonly TYPE_3_STARS_NUMBER = 3;

    public sessionName: string;
    public user: User;
    public animatorTokenBalance: number;
    public menuVisible = true;
    public teamName: string;
    public restaurantType: string;
    public standing: number;
    public area: string;
    public starsNumber: number;
    private context: Context;
    public displayRestaurantStandingInformation: boolean = false;
    public displayRestaurantAreaInformation: boolean = false;

    public langPickerVisible: boolean = false;

    public currentLanguage: string;
    public languages: string[];


    @Output() menuToggled = new EventEmitter<boolean>();

    constructor(
        private connectedUserService: ConnectedUserService,
        private contextService: ContextService,
        private languageService: LanguagesLoaderService,
        private translateService: TranslateService,
        private areaDecisionLoaderService: AreaDecisionLoaderService,
        private hasChosenAreaService: HasChosenAreaService,
        private hasChosenArchetypeService: HasChosenArchetypeService
    ) {
    }

    ngOnInit() {
        this.connectedUserService.userEvents.subscribe(connectedUser => {
            if (connectedUser)
                this.user = connectedUser.user;
        });

        this.connectedUserService.animatorTokenBalance.subscribe(
            tokenBalance => {
                if (tokenBalance)
                    this.animatorTokenBalance = tokenBalance;
            }
        );

        this.languageService.loadLanguages().subscribe(languages => {
            this.languages = languages
        });

        this.contextService.context.subscribe(
            context => {
                if(!!context.currentSessionName) {
                    this.sessionName = context.currentSessionName;
                }

                if (context.currentTeam) {
                    this.context = context;
                    this.teamName = context.currentTeam.name;
                    this.currentLanguage = context.currentLanguage;

                    if(context.currentRestaurantStandingInformation) {
                        this.updateStandingInformation(context.currentRestaurantStandingInformation)
                    }

                    this.area = context.currentTeamArea;

                    this.hasChosenAreaService.hasChosenArea().subscribe(
                        hasChosenArea =>
                            this.displayRestaurantAreaInformation = hasChosenArea
                    );
                }
            }
        );

        this.hasChosenArchetypeService.hasChosenArchetype().subscribe(
            hasChosenArchetype => {
                this.displayRestaurantStandingInformation = hasChosenArchetype;
            }
        )
    }

    private updateStandingInformation(currentRestaurantStandingInformation: RestaurantStandingInformation): void {
        this.restaurantType = currentRestaurantStandingInformation.restaurantType;
        this.standing = currentRestaurantStandingInformation.standing;

        if (this.restaurantType === 'TYPE_1')
            this.starsNumber = HeaderComponent.TYPE_1_STARS_NUMBER;

        else if (this.restaurantType === 'TYPE_2')
            this.starsNumber = HeaderComponent.TYPE_2_STARS_NUMBER;

        else
            this.starsNumber = HeaderComponent.TYPE_3_STARS_NUMBER;
    }

    toggleMenuVisible() {
        this.menuVisible = !this.menuVisible;
        this.menuToggled.emit(this.menuVisible);
    }

    logout() {
        this.connectedUserService.logout();
    }

    toggleLangPicker() {
        this.langPickerVisible = !this.langPickerVisible
    }

    isLangSelected(lang) {
        return lang == this.currentLanguage
    }

    changeLang(lang) {
        const formattedLang = lang ? (lang.indexOf('_') !== -1 ? lang.split('_')[0] : lang ) : '';
        this.translateService.use(formattedLang).subscribe();
        this.contextService.updateCurrentLanguage(lang)
    }
}

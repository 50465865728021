import { Injectable } from '@angular/core';
import {AbstractSessionParametersLoaderService} from '../../services/abstract-session-parameters-loader.service';
import {PerceivedQualityParameters} from './perceived-quality-parameters';

@Injectable({
  providedIn: 'root'
})
export class PerceivedQualityParametersLoaderService extends AbstractSessionParametersLoaderService<PerceivedQualityParameters>{

  constructor() {
      super('perceivedQuality');
  }
}

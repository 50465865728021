import { Injectable } from '@angular/core';
import {AbstractSessionParametersUpdaterService} from '../../services/abstract-session-parameters-updater-service';
import {SalesRepartitionParameters} from './sales-repartition-parameters';

@Injectable({
  providedIn: 'root'
})
export class SalesRepartitionParametersUpdaterService extends AbstractSessionParametersUpdaterService<SalesRepartitionParameters> {

  constructor() {
      super('salesRepartition')
  }
}

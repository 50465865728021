import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PeriodUtils} from '../../app-main/studies/study-header/period-utils';

@Component({
    selector: 'kiwi-period',
    templateUrl: './period.component.html',
    styleUrls: ['./period.component.scss']
})
export class PeriodComponent {
    @Input()
    set period(period: string) {
        this.updateDisplayedPeriod(period);
    }

    displayedPeriod: string;

    constructor(private readonly translateService: TranslateService) {
    }

    private updateDisplayedPeriod(period) {
        if(!!period) {
            PeriodUtils.computeDisplayedPeriod(period, this.translateService).subscribe(
                period => this.displayedPeriod = period
            );
        }
    }
}

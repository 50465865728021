import {Injectable} from '@angular/core';
import {AbstractPeriodParametersUpdaterService} from '../services/abstract-period-parameters-updater-service';
import {StudiesPrices} from './studies-prices';

@Injectable({
    providedIn: 'root'
})
export class StudiesPricesUpdaterService extends AbstractPeriodParametersUpdaterService<StudiesPrices> {
    constructor() {
        super('studiesPrices');
    }
}

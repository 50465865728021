import {Component} from '@angular/core';
import {AbstractStudyComponent} from "../../abstract-study.component";
import {CompetitiveIntelligenceStudy} from "../competitive-intelligence-study";
import {CategoryName} from "../../category-name";
import {StudiesNames} from "../../studies-names";
import {of} from "rxjs";

@Component({
    selector: 'kiwi-customer-care-quality-comparison',
    templateUrl: './customer-care-quality-comparison.component.html',
    styleUrls: ['./customer-care-quality-comparison.component.scss'],
    host: {'class': 'cell auto grid-y'}

})
export class CustomerCareQualityComparisonComponent extends AbstractStudyComponent<CompetitiveIntelligenceStudy> {

    constructor() {
        super(CategoryName.COMPETITIVE_INTELLIGENCE, of(StudiesNames.CUSTOMER_CARE_QUALITY_COMPARISON));
    }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DecisionContentComponent} from "./decision-content/decision-content.component";
import {DecisionHeaderComponent} from "./decision-header/decision-header.component";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {ComponentsModule} from "../../../components/components.module";
import { ScoreImageComponent } from './score-image/score-image.component';
import { AnimatorMarkExplanationComponent } from './animator-mark-explanation/animator-mark-explanation.component';
import {ClosedDecisionsBannerComponent} from './decision-header/closed-decisions-banner/closed-decisions-banner.component';

const components =  [
    DecisionContentComponent,
    DecisionHeaderComponent,
    ScoreImageComponent,
    AnimatorMarkExplanationComponent,
    ClosedDecisionsBannerComponent
];

@NgModule({
    declarations: components,
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        ComponentsModule
    ],
    exports: components
})
export class GenericsModule {
}

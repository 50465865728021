import {Injectable} from '@angular/core';
import {AbstractDecisionUpdaterService} from "../../abstract-decision-updater.service";
import {MenuSalesPrices} from "./menu-sales-prices";

@Injectable({
    providedIn: 'root'
})
export class SalesPricesUpdaterService extends AbstractDecisionUpdaterService<MenuSalesPrices> {

    constructor() {
        super('menuSalesPrices')
    }
}

import {Component, ElementRef, ViewChild} from '@angular/core';
import {FinancialDecision} from "../financial-decision";
import {Context} from "../../../../context/context";
import {Observable, Subscription} from "rxjs";
import {Score} from "../../generics/score-image/score-image.component";
import {FinancialDecisionUpdaterService} from "./financial-decision-updater.service";
import {FinancialDecisionLoaderService} from "./financial-decision-loader.service";
import {FinancialDecisionInformationLoaderService} from "./financial-decision-information-loader.service";
import {AbstractDecisionComponent} from "../../abstract-decision-component";
import {ConnectedUserService} from '../../../../context/connected-user.service';
import {FinancialDecisionInformation} from './financial-decision-information.model';
import {Form, FormControl} from '@angular/forms';
import { ControlContainer } from "@angular/forms";
import { NgForm } from "@angular/forms";

@Component({
    selector: 'kiwi-player-financial-decision',
    templateUrl: './player-financial-decision.component.html',
    styleUrls: ['./player-financial-decision.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class PlayerFinancialDecisionComponent extends AbstractDecisionComponent<FinancialDecision, FinancialDecisionInformation> {
    private relationLoanOwnEquity: number;
    public isAnimator: boolean = false;
    private userSubscription: Subscription;

    @ViewChild('playerFinancialDecisionForm', {read: NgForm})
    form: NgForm;

    constructor(
        financialDecisionUpdaterService: FinancialDecisionUpdaterService,
        financialDecisionLoaderService: FinancialDecisionLoaderService,
        private readonly financialDecisionInformationLoaderService: FinancialDecisionInformationLoaderService,
        private readonly userService: ConnectedUserService
    ) {
        super(
            financialDecisionLoaderService,
            financialDecisionUpdaterService,
            [],
            'toast.financial.decision.saved.message'
        );
    }

    getForm(): NgForm {
        return this.form;
    }

    ngOnInit() {
        super.ngOnInit();
        this.userSubscription = this.userService.userEvents.subscribe(user => {
            this.isAnimator = !!user && !!user.user && user.user.animator;
        })
    }

    protected loadParameters(context: Context): Observable<FinancialDecisionInformation> {
        return this.financialDecisionInformationLoaderService.loadDecisionFromContext(context);
    }

    protected computeDecisionQuality(scores: Score[], parameters: FinancialDecisionInformation): number {
        return 0;
    }

    protected updateScores(decision: FinancialDecision, parameters: FinancialDecisionInformation): void {
        this.relationLoanOwnEquity = decision.loanAmount / parameters.ownEquity;
    }

    onLoanAmountUpdate() {
        this.updateScores(this.decision, this.parameters);
        this.onValueUpdate();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.userSubscription.unsubscribe();
    }
}

import {Injectable} from '@angular/core';
import {AblyChannelName} from 'custom-ably-types';

declare var Ably: any;

function buildChannelName(channelPrefix: AblyChannelName, sessionId: string) {
    return 'kiwi:' + channelPrefix + ':' + sessionId;
}


@Injectable({
    providedIn: 'root'
})
export class AblyRealtimeService {
    constructor() {
    }

    private closureChannel: AblyChannelName = 'CLOSURE';
    private simulationChannel: AblyChannelName = 'SIMULATION';
    private sessionCurrentPeriodUpdateChannel: AblyChannelName = 'SESSION_CURRENT_PERIOD_UPDATE';

    generateAuthentication(userLogin: string) {
        return new Ably.Realtime({authUrl: '/api/ably/tokenRequest/' + userLogin});
    }


    closeAuthentication(ably: any, sessionId: string) {
        this.unsubscribeToClosureDecisions(ably, sessionId);
        this.unsubscribeToSimulationSteps(ably, sessionId);
        this.unsubscribeToSessionCurrentPeriodUpdateChannel(ably, sessionId);
        ably.close();
    }

    subscribeToClosureDecisions(ably: any, sessionId: string, teamId: string, onEvent: (message: string) => void) {
        ably.channels.get(buildChannelName('CLOSURE', sessionId)).subscribe(teamId, (message: any) => {
            if (AblyRealtimeService.isMessageConcernOnlyOneTeamInSession(message.name, teamId)) {
                onEvent(message);
            }
        });
    }

    unsubscribeToClosureDecisions(ably: any, sessionId: string) {
        ably.channels.get(buildChannelName('CLOSURE', sessionId)).unsubscribe();
    }

    subscribeToSimulationSteps(ably: any, sessionId: string, onEvent: (message: string) => void) {
        ably.channels.get(buildChannelName('SIMULATION', sessionId)).subscribe('SIMULATION', (message: any) => {
            onEvent(message);
        });
    }

    unsubscribeToSimulationSteps(ably: any, sessionId: string) {
        ably.channels.get(buildChannelName('SIMULATION', sessionId)).unsubscribe();
    }

    subscribeToSessionCurrentPeriodUpdateChannel(ably: any, sessionId: string, onEvent: (message: string) => void) {
        ably.channels.get(buildChannelName('SESSION_CURRENT_PERIOD_UPDATE', sessionId)).subscribe('SESSION_CURRENT_PERIOD_UPDATE', (message: any) => {
            onEvent(message);
        });
    }

    unsubscribeToSessionCurrentPeriodUpdateChannel(ably: any, sessionId: string) {
        ably.channels.get(buildChannelName('SESSION_CURRENT_PERIOD_UPDATE', sessionId)).unsubscribe();
    }

    private static isMessageConcernOnlyOneTeamInSession(message: string, teamId: string): boolean {
        return message === teamId;
    }
}

import {Component, Input} from '@angular/core';

@Component({
    selector: 'kiwi-parameters-header',
    templateUrl: './parameters-header.component.html',
    styleUrls: ['./parameters-header.component.scss']
})
export class ParametersHeaderComponent {
    @Input()
    title: string;
}

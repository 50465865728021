import {Component} from '@angular/core';
import {AbstractStudyComponent} from '../../abstract-study.component';
import {TurnoverDetails} from './turnover-details';
import {CategoryName} from '../../category-name';
import {of} from 'rxjs';
import {StudiesNames} from '../../studies-names';

@Component({
    templateUrl: './turnover.component.html',
    styleUrls: ['./turnover.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class TurnoverComponent extends AbstractStudyComponent<TurnoverDetails> {
    constructor() {
        super(CategoryName.ACCOUNTS_DETAIL, of(StudiesNames.TURNOVER));
    }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {filter, take} from "rxjs/operators";
import {PeriodUtils} from "../../../studies/study-header/period-utils";
import {TranslateService} from "@ngx-translate/core";
import {ContextService} from "../../../../context/context.service";
import {ActivatedRoute} from '@angular/router';

@Component({
    templateUrl: './revert-page.component.html',
    styleUrls: ['./revert-page.component.scss'],
    host: {'class': 'cell auto'}
})
export class RevertPageComponent implements OnInit {
    period: string;

    constructor(private readonly activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => this.period = params.period);
    }
}

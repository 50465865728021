import {Component} from '@angular/core';
import {MenuSalesPrices} from "./menu-sales-prices";
import {SalesPricesUpdaterService} from "./sales-prices-updater.service";
import {Context} from "../../../../context/context";
import {SalesPricesLoaderService} from "./sales-prices-loader.service";
import {Observable} from "rxjs";
import {StandingMenuParameters} from "../../../parameters/services/standing/standing-menu-parameters";
import {
    StandingMenuParametersLoaderService
} from "../../../parameters/services/standing/standing-menu-parameters-loader.service";
import {Score} from "../../generics/score-image/score-image.component";
import {
    CheckRestaurantTypeDecisionComponent
} from "../../check-restaurant-type/check-restaurant-type-decision-component";
import {MenuItem} from '../menu-item';
import { computeMenuStanding } from './compute-menu-standing';

@Component({
    selector: 'kiwi-sales-prices',
    templateUrl: './sales-prices.component.html',
    styleUrls: ['./sales-prices.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class SalesPricesComponent extends CheckRestaurantTypeDecisionComponent<MenuSalesPrices, StandingMenuParameters> {
    constructor(
        private standingMenuParametersLoaderService: StandingMenuParametersLoaderService,
        salesPricesLoader: SalesPricesLoaderService,
        salesPricesUpdater: SalesPricesUpdaterService
    ) {
        super(
            salesPricesLoader,
            salesPricesUpdater,
            [new Score('decisions.menu.standing', 0)],
            'menuSalesPrices',
            'toast.menu.sales.prices.decision.saved.message'
        );
    }

    protected loadParameters(context: Context): Observable<StandingMenuParameters> {
        return this.standingMenuParametersLoaderService.loadParameters(context);
    }

    protected computeDecisionQuality(scores: Score[], parameters: StandingMenuParameters): number {
        return scores[0].value;
    }

    protected updateScores(decision: MenuSalesPrices, parameters: StandingMenuParameters): void {
        this.scores[0].value = SalesPricesComponent.computeMenuStanding(decision, parameters);
    }

    private static computeMenuStanding(decision: MenuSalesPrices, parameters: StandingMenuParameters): number {
        return computeMenuStanding(decision, parameters);
    }

    toggleInMenu(menuItem: MenuItem) {
        menuItem.inMenu = !menuItem.inMenu;
        this.updateScoreImage();
    }

    public isLastDish(dishes: any) {
        return Object.values(dishes).filter(dish => (<any>dish).inMenu).length <= 1;
    }
}

import {Component, Input, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'kiwi-studies-prices',
    templateUrl: './studies-prices.component.html',
    styleUrls: ['./studies-prices.component.scss'],
    host: {'class': 'cell auto cell-block-y grid-y blockContent'}
})
export class StudiesPricesComponent {
    @Input()
    titleLabelKey = '';

    @Input()
    studiesPrices: StudiesPricesComponent = undefined;

    @Input()
    studiesNames: Array<string> = [];

    @Input()
    arePricesReadonly: boolean = false;

    @Output()
    saved = new EventEmitter<boolean>()

    onSave(): void {
        this.saved.emit(true);
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DecisionConfirmationPopupComponent} from '../check-restaurant-type/decision-confirmation-popup/decision-confirmation-popup.component';
import {AnimatorStaffMarksComponent} from './animator-staff-marks/animator-staff-marks.component';
import {GenericsModule} from '../generics/generics.module';
import {PipesModule} from '../../../pipes/pipes.module';
import {ComponentsModule} from '../../../components/components.module';
import {ContextModule} from '../../../context/context.module';
import {TabsModule} from '../../tabs/tabs.module';
import {CanActivateTeamDecisionsGuard} from '../guards/can-activate-team-decisions.guard';
import {SaveBeforeDeactivatePopupComponent} from '../../../components/save-before-deactivate-popup/save-before-deactivate-popup.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {StaffDecisionComponent} from './staff-decision.component';
import {PlayerStaffDecisionComponent} from './player-staff-decision/player-staff-decision.component';

@NgModule({
    declarations: [
        PlayerStaffDecisionComponent,
        AnimatorStaffMarksComponent,
        StaffDecisionComponent
    ],
    imports: [
        TranslateModule,
        FormsModule,
        CommonModule,
        GenericsModule,
        PipesModule,
        ComponentsModule,
        ContextModule,
        TabsModule,
        RouterModule
    ],
    providers: [
        CanActivateTeamDecisionsGuard
    ],
    entryComponents: [
        DecisionConfirmationPopupComponent,
        SaveBeforeDeactivatePopupComponent
    ]
})
export class StaffDecisionModule {
}

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {TeamDecisionsClosureService} from "../../team-decisions-closure.service";

@Injectable({
    providedIn: 'root'
})
export class TeamsDecisionsLoaderService {

    constructor(
        private httpClient: HttpClient,
        private decisionsClosureService: TeamDecisionsClosureService
    ) {
    }

    loadTeamsDecisions(
        currentSession: string,
        currentPeriod: string
    ): Observable<Object> {
        return this.httpClient.get<Object>(
            '/api/sessions/' + currentSession + '/periods/' + currentPeriod + '/teamDecisions'
        ).pipe(map(teamsDecisions => {
            for (let teamName in teamsDecisions) {
                teamsDecisions[teamName].statusData = this.decisionsClosureService.completeClosureLogs(teamsDecisions[teamName].statusData);
            }
            return teamsDecisions;
        }));
    }
}

import {Component} from '@angular/core';
import {GenericSessionParametersComponent} from '../../generic-parameters/generic-session-parameters.component';
import {InsurancesParameters} from '../../services/insurances/insurances-parameters';
import {InsurancesParametersLoaderService} from '../../services/insurances/insurances-parameters-loader.service';
import {InsurancesParametersUpdaterService} from './insurances-parameters-updater.service';
import {genericParametersHost} from '../../generic-parameters/generic-parameters.component';

@Component({
    templateUrl: './insurances-parameters.component.html',
    styleUrls: [
        './../../generic-parameters/generic-parameters.component.scss',
        './insurances-parameters.component.scss'
    ],
    host: genericParametersHost
})
export class InsurancesParametersComponent extends GenericSessionParametersComponent<InsurancesParameters> {

    constructor(
        insurancesParametersLoaderService: InsurancesParametersLoaderService,
        insurancesParametersUpdaterService: InsurancesParametersUpdaterService
    ) {
        super(insurancesParametersLoaderService, insurancesParametersUpdaterService, 'toast.parameters.financial.insurances.saved')
    }
}

import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {Context} from "../../context/context";
import {LocatorService} from "../../locator.service";

export abstract class AbstractDecisionLoaderService<T> {
    private readonly httpClient: HttpClient;

    protected constructor(
        private decisionType: string,
        private formatter?: Function
    ) {
        this.httpClient = LocatorService.injector.get(HttpClient);
    }

    loadDecision(sessionId: string, period: string, teamNumber: number): Observable<T> {
        const url = '/api/sessions/' + sessionId +
            '/periods/' + period +
            '/teamDecisions/' + teamNumber + (this.decisionType !== '' ? '/' + this.decisionType : '');

        return this.httpClient.get<T>(url).pipe(
            map(result => {
                if (this.formatter)
                    return this.formatter(result);
                return result as T;
            })
        );
    }

    loadDecisionFromContext(context: Context, period?: string): Observable<T> {
        return this.loadDecision(context.currentSessionId, !!period? period: context.currentPeriod, context.currentTeam.teamNumber);
    }
}


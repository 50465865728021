import { Injectable } from '@angular/core';
import {AbstractSessionParametersLoaderService} from '../../services/abstract-session-parameters-loader.service';
import {IngredientsPurchaseParameters} from './ingredients-purchase-parameters';

@Injectable({
  providedIn: 'root'
})
export class IngredientsPurchaseParametersLoaderService extends AbstractSessionParametersLoaderService<IngredientsPurchaseParameters>{

  constructor() {
      super('ingredientsPurchase');
  }
}

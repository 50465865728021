import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContextService} from '../../../context/context.service';
import {Subscription} from 'rxjs';
import {TeamsTotalMarksLoaderService} from './teams-total-marks-loader.service';
import {flatMap, filter} from 'rxjs/operators';
import {TeamsTotalMarks} from './teams-total-marks.model';
import {AbstractEvaluationComponent} from '../generic-evaluation/abstract-evaluation-component';

@Component({
    templateUrl: './final-mark-marks.component.html',
    styleUrls: ['./final-mark-marks.component.scss'],
    host: {'class': 'cell auto grid-y tabsContainer-content'}
})
export class FinalMarkMarksComponent extends AbstractEvaluationComponent implements OnInit, OnDestroy {
    private contextSubscription: Subscription;
    teamsTotalMarks: TeamsTotalMarks;

    constructor(private readonly contextService: ContextService,
                private readonly teamsTotalMarksLoaderService: TeamsTotalMarksLoaderService) {
        super();
        this.markType.next('FINAL_MARK');
    }

    ngOnInit() {
        this.contextSubscription = this.contextService.context.pipe(
            filter(context =>
                !!context &&
                !!context.currentSessionId &&
                !!context.currentPeriod &&
                context.currentPeriod !== context.currentSession.currentPeriod
            ),
            flatMap(context =>
            this.teamsTotalMarksLoaderService.loadTeamsTotalMarks(context)
        )).subscribe(teamsTotalMarks => {
            this.teamsTotalMarks = teamsTotalMarks;
        })
    }

    ngOnDestroy(): void {
        this.contextSubscription.unsubscribe();
    }

    displayTreasuryMark() {
        return this.isNotNullNorUndefined(this.teamsTotalMarks.teamMarks[0].marks.treasuryMark);
    }

    displayProfitabilityMark() {
        return this.isNotNullNorUndefined(this.teamsTotalMarks.teamMarks[0].marks.profitabilityMark);
    }

    displayCustomerSatisfactionMark() {
        return this.isNotNullNorUndefined(this.teamsTotalMarks.teamMarks[0].marks.customerSatisfactionMark);
    }

    isNotNullNorUndefined(value) {
        return value !== null && value !== undefined;
    }
}

import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ConnectedUserService} from './context/connected-user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'new-kiwi-ui';

    constructor(
        private readonly connectedUserService: ConnectedUserService,
        translateService: TranslateService
    ) {
        this.connectedUserService.loadConnectedUser();

        translateService.setDefaultLang('fr');
        translateService.use('fr');
    }
}

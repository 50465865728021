import {Injectable} from '@angular/core';
import {AbstractPopupService} from "../../abstract-popup.service";
import {SchedulePopupComponent} from "./schedule-popup.component";
import {ContextService} from "../../context/context.service";
import {SessionEventsLoaderService} from "../../session-events-loader.service";

@Injectable({
    providedIn: 'root'
})
export class SchedulePopupService extends AbstractPopupService<SchedulePopupComponent> {
    constructor(private readonly contextService: ContextService,
                private readonly sessionEventsLoaderService: SessionEventsLoaderService) {
        super(SchedulePopupComponent);
    }

    showSchedulePopup() {
        this.showPopup(new SchedulePopupComponent(this.contextService, this.sessionEventsLoaderService));
    }

    protected fillPopupInstance(popupInstance: SchedulePopupComponent, preparedPopup: SchedulePopupComponent) {
    }
}

import { Injectable } from '@angular/core';
import {AbstractSessionParametersLoaderService} from "../abstract-session-parameters-loader.service";
import {MaintenanceParameters} from "./maintenance-parameters";

@Injectable({
  providedIn: 'root'
})
export class MaintenanceParametersLoaderService extends AbstractSessionParametersLoaderService<MaintenanceParameters>{

  constructor() { super('maintenance') }
}

import {Injectable} from '@angular/core';
import {IngredientsPurchaseParameters} from './ingredients-purchase-parameters';
import {AbstractPeriodParametersUpdaterService} from "../../services/abstract-period-parameters-updater-service";

@Injectable({
  providedIn: 'root'
})
export class IngredientsPurchaseParametersUpdaterService extends AbstractPeriodParametersUpdaterService<IngredientsPurchaseParameters>{

  constructor() {
      super('ingredientsPurchase');
  }
}

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-menus',
    templateUrl: './menus.component.html',
    styleUrls: ['./menus.component.scss'],
    host: {'class': 'cell auto grid-y cell-block-y blockContent'}
})
export class MenusComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}

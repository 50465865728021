import {Component} from '@angular/core';
import {TabParentComponent, tabParentHost} from '../../../components/tab-parent/tab-parent.component';
import {Tab} from '../../tabs/tab';
import {of} from 'rxjs';
import {qualityParametersChildrenRoutes} from './quality-parameters-children-routes';

@Component({
    templateUrl: './../../../components/tab-parent/tab-parent.component.html',
    styleUrls: [],
    host: tabParentHost
})
export class QualityParametersComponent extends TabParentComponent {
    constructor() {
        super(
            of(qualityParametersChildrenRoutes
                .map(route => route.path)
                .map(tabName => new Tab('parameters.quality.' + tabName, 'parameters/quality/' + tabName, false))));
    }
}

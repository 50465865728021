import {Component, OnDestroy, OnInit} from '@angular/core';
import {DisplayStudiesLoaderService} from "./display-studies-loader.service";
import {ActivatedRoute} from "@angular/router";
import {DisplayStudy} from "./display-study";
import {Context} from "../../context/context";
import {ContextService} from "../../context/context.service";
import {ConnectedUserService} from '../../context/connected-user.service';
import {Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Component({
    selector: 'app-study',
    templateUrl: './study.component.html',
    styleUrls: ['./study.component.scss'],
    host: {'class': 'cell auto pageContainer grid-y'}
})
export class StudyComponent implements OnInit, OnDestroy {
    displayStudies: DisplayStudy[] = undefined;
    selectedStudy: DisplayStudy;
    category: string;
    areStudiesUnavailable: boolean = true;
    public userIsAnimator = false;

    public connectedUserSubscription: Subscription;

    private contextSubscription: Subscription;

    constructor(
        private studyLoaderService: DisplayStudiesLoaderService,
        private route: ActivatedRoute,
        private contextService: ContextService,
        private readonly connectedUserService: ConnectedUserService
    ) {

    }

    ngOnInit() {
        this.route.paramMap.subscribe(
            params => {
                this.category = params.get('category');
                this.contextSubscription = this.contextService.context
                    .pipe(filter(context => !!context.currentSessionId && !!context.currentTeam && !!context.currentSession.currentPeriod))
                    .subscribe(context => {
                        this.loadDisplayStudies(context);
                    });
            }
        );
        this.connectedUserSubscription = this.connectedUserService.userEvents.pipe(
            filter(connectedUser => connectedUser.user != null),
            map(connectedUser => {
                return connectedUser.user.animator
            })
        ).subscribe(isAnimator => this.userIsAnimator = isAnimator);
    }

    loadDisplayStudies(context: Context): void {
        this.studyLoaderService.loadDisplayStudies(context, this.category)
            .subscribe(displayStudies => {
                this.displayStudies = displayStudies as DisplayStudy[];
                this.areStudiesUnavailable = displayStudies.length === 0;
                if (displayStudies.length > 0) {
                    if (!this.selectedStudy)
                        this.selectedStudy = displayStudies[0];
                    else
                        this.selectedStudy = displayStudies.find(study => study.studyId === this.selectedStudy.studyId);
                }
            });
    }

    select(study: DisplayStudy) {
        this.selectedStudy = study;
    }

    selectNextStudy() {
        const currentIndex = this.displayStudies.indexOf(this.selectedStudy);
        const nextIndex = (currentIndex == (this.displayStudies.length - 1) ? 0 : currentIndex + 1);
        this.select(this.displayStudies[nextIndex]);
    }

    selectPreviousStudy() {
        const currentIndex = this.displayStudies.indexOf(this.selectedStudy);
        const previousIndex = (currentIndex == 0 ? (this.displayStudies.length - 1) : currentIndex - 1);
        this.select(this.displayStudies[previousIndex]);
    }

    onStudyPurchased() {
        this.loadDisplayStudies(this.contextService.context.getValue());
    }

    ngOnDestroy(): void {
        this.connectedUserSubscription.unsubscribe();
        this.contextSubscription.unsubscribe();
    }
}

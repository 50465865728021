import { Injectable } from '@angular/core';
import {AbstractDecisionUpdaterService} from "../abstract-decision-updater.service";
import {AreaDecision} from "./area-decision";
import {HttpClient} from "@angular/common/http";
import {NotificationService} from "../../../notification.service";

@Injectable({
  providedIn: 'root'
})
export class AreaDecisionUpdaterService extends AbstractDecisionUpdaterService<AreaDecision>{

  constructor() {
    super( 'area');
  }
}

import {Injectable} from '@angular/core';
import {Router, UrlTree} from '@angular/router';

export const sessionBlockedRoute = 'sessionBlocked';

@Injectable({
    providedIn: 'root'
})
export class SessionLockedForUserService {
    constructor(private readonly router: Router) {
    }

    urlToSessionBlocked(): UrlTree {
        return this.router.parseUrl(sessionBlockedRoute);
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

class SessionsManagerUrlDto {
    readonly sessionsManagerUrl: string;
}

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    constructor(private readonly httpClient: HttpClient) {
    }

    loadSessionsManagerUrl(): Observable<string> {
        return this.httpClient.get<SessionsManagerUrlDto>('/api/config/sessionsManagerUrl').pipe(map(
            sessiosManagerUrlDto => sessiosManagerUrlDto.sessionsManagerUrl
        ));
    }
}

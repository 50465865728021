import {AbstractParametersLoaderService} from '../services/abstract-parameters-loader.service';
import {AbstractParametersUpdaterService} from '../services/abstract-parameters-updater.service';
import {LocatorService} from '../../../locator.service';
import {NotificationService} from '../../../notification.service';
import {GenericReadonlyParametersComponent} from './generic-readonly-parameters.component';

export const genericParametersHost = {'class': 'cell auto grid-y blockContent'};

export abstract class GenericParametersComponent<T> extends GenericReadonlyParametersComponent<T> {
    private readonly notificationService: NotificationService;

    protected constructor(
        parametersLoader: AbstractParametersLoaderService<T>,
        private readonly parametersUpdater: AbstractParametersUpdaterService<T>,
        private readonly saveSuccessKey: string
    ) {
        super(parametersLoader);
        this.notificationService = LocatorService.injector.get(NotificationService);
    }

    updateParameters() {
        this.parametersUpdater.updateParameters(this.context, this.parameters)
            .subscribe(parameters => {
                this.parameters = parameters;
                this.notificationService.success('toast.parameters.saved.title', this.saveSuccessKey);
            }, err => {
                this.notificationService.error(err.error);
            });
    }
}

import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {AreDecisionsClosedService} from '../../../are-decisions-closed.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'kiwi-decision-header',
    templateUrl: './decision-header.component.html',
    styleUrls: ['./decision-header.component.scss'],
    host: {'class': 'cell shrink grid-y'}
})
export class DecisionHeaderComponent implements OnInit, OnChanges, OnDestroy {
    @Output() saved = new EventEmitter();
    @Input() title: string;
    @Input() decisionIsInvalid: boolean;
    decisionsClosed: boolean;

    private isUpdatedDecisionsClosedOnly = false;

    private areDecisionsClosedSubscription: Subscription;

    constructor(
        private readonly areDecisionsClosedService: AreDecisionsClosedService,
        private cdRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.areDecisionsClosedSubscription = this.areDecisionsClosedService.decisionsClosed.subscribe(areDecisionsClosed => {
            this.decisionsClosed = areDecisionsClosed;
            this.isUpdatedDecisionsClosedOnly = !this.decisionsClosed;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        // If we remove the closure and the form is considered invalid (not possible) then the form is considered valid
        if (this.isUpdatedDecisionsClosedOnly
            && changes.decisionIsInvalid
            && changes.decisionIsInvalid.currentValue
            && !changes.decisionIsInvalid.previousValue
            && this.decisionIsInvalid !== undefined) {
            this.decisionIsInvalid = false;
            // Forcing change detection explicitly
            this.cdRef.detectChanges();
            this.isUpdatedDecisionsClosedOnly = false;
        }
    }

    isDisabledSaveButton() {
        return this.decisionIsInvalid || this.decisionsClosed;
    }

    onSave() {
        this.saved.emit();
    }

    ngOnDestroy() {
        this.areDecisionsClosedSubscription.unsubscribe();
    }
}

import {Injectable} from '@angular/core';
import {AbstractPopupService} from "../../abstract-popup.service";
import {ForgottenPasswordPopupComponent} from "./forgotten-password-popup.component";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ForgottenPasswordPopupService extends AbstractPopupService<ForgottenPasswordPopupComponent> {
    constructor(
        private httpClient: HttpClient
    ) {
        super(ForgottenPasswordPopupComponent);
    }

    public showForgottenPasswordPopup(
        username: string
    ): void {
        let popupComponent = new ForgottenPasswordPopupComponent();
        popupComponent.username = username;
        popupComponent = this.showPopup(popupComponent);
        popupComponent.passwordRegenerated.subscribe(username => this.regeneratePassword(
            username,
            popupComponent
        ));
    }

    private regeneratePassword(username: string, popupComponent: ForgottenPasswordPopupComponent) {
        this.httpClient.post('/api/password/recover', {login: username})
            .subscribe(
                result => {
                    console.log('result !');
                    console.log(result);
                    popupComponent.close();
                },
                error => {
                    popupComponent.showError()
                });
    }

    protected fillPopupInstance(
        popupInstance: ForgottenPasswordPopupComponent,
        preparedPopup: ForgottenPasswordPopupComponent
    ) {
        popupInstance.username = preparedPopup.username;
        popupInstance.passwordRegenerated = preparedPopup.passwordRegenerated;
    }
}

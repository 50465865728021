import { Pipe, PipeTransform } from '@angular/core';
import {ChartDataSets} from "chart.js";

@Pipe({
  name: 'chartDataSetLabel'
})
export class ChartDataSetLabelPipe implements PipeTransform {

    transform(chartDataSets: ChartDataSets[]): any {
        return chartDataSets.map(chartDataSet => {return chartDataSet.label});
    }
}

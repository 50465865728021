import { Injectable } from '@angular/core';
import {AbstractSessionParametersLoaderService} from '../../abstract-session-parameters-loader.service';
import {MarketParameters} from './market-parameters';

@Injectable({
  providedIn: 'root'
})
export class MarketParametersLoaderService extends AbstractSessionParametersLoaderService<MarketParameters>{

  constructor() {
      super('market');
  }
}

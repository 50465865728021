import {Component} from '@angular/core';
import {CategoryName} from '../category-name';
import {StudiesNames} from '../studies-names';
import {of} from 'rxjs';
import {AbstractStudyComponent} from "../abstract-study.component";
import {CommunicationParameters} from '../../parameters/services/communication/communication-parameters';

@Component({
    selector: 'kiwi-communication-study',
    templateUrl: './communication-study.component.html',
    styleUrls: ['./communication-study.component.scss'],
    host: {'class': 'cell auto pageContainer grid-y'}
})
export class CommunicationStudyComponent extends AbstractStudyComponent<CommunicationParameters> {
    constructor() {
        super(CategoryName.COMMUNICATION_PARAMETERS, of(StudiesNames.COMMUNICATION_PARAMETERS));
    }
}

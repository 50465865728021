import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SpacerComponent} from './spacer/spacer.component';
import {PeriodComponent} from './period/period.component';
import {TranslateModule} from '@ngx-translate/core';
import {SelectPeriodComponent} from './period-selectors/select-period/select-period.component';
import {SelectTeamComponent} from './animator-select-team/select-team/select-team.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {AnimatorSelectTeamComponent} from './animator-select-team/animator-select-team.component';
import {PercentageInputComponent} from './inputs/number-input/input-with-unit/percentage-input.component';
import {CurrencyInputComponent} from './inputs/number-input/input-with-unit/currency-input.component';
import {NumberInputComponent} from './inputs/number-input/number-input.component';
import {SaveBeforeDeactivatePopupComponent} from './save-before-deactivate-popup/save-before-deactivate-popup.component';
import {MarkInputComponent} from './inputs/number-input/input-with-unit/mark-input';
import {AllPeriodsSelectorComponent} from './period-selectors/all-periods-selector/all-periods-selector.component';
import {SimulatedPeriodsSelectorComponent} from './period-selectors/simulated-periods-selector/simulated-periods-selector.component';
import {KiwiMinValidator} from './inputs/number-input/validators/kiwi-min-validator';
import {KiwiMaxValidator} from './inputs/number-input/validators/kiwi-max-validator';
import {AddButtonComponent} from "./add-button/add-button.component";
import {ChipComponent} from "./chip/chip.component";

const exportedComponents: any[] = [
        PeriodComponent,
        SpacerComponent,
        SelectPeriodComponent,
        AnimatorSelectTeamComponent,
        CheckboxComponent,
        PercentageInputComponent,
        CurrencyInputComponent,
        NumberInputComponent,
        SaveBeforeDeactivatePopupComponent,
        MarkInputComponent,
        AllPeriodsSelectorComponent,
        SimulatedPeriodsSelectorComponent,
        KiwiMinValidator,
        KiwiMaxValidator,
        AddButtonComponent,
        ChipComponent
    ]
;

@NgModule({
    exports: exportedComponents,
    declarations: exportedComponents.concat(SelectTeamComponent),
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class ComponentsModule {

}

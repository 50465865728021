import {Injectable, Injector} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocatorService {
    static injector: Injector;

    constructor() {
    }
}

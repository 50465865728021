import {StaffDecision} from "./staff-decision";
import {StaffQualityParameters} from "../../../parameters/services/quality/staff-quality-parameters";
import {ComputeHierarchyRestaurantQuality} from "./compute-hierarchy-restaurant-quality";

export class ComputeType2RestaurantStaffQuality extends ComputeHierarchyRestaurantQuality {
    private readonly commisChefNumber: number;
    private readonly cookNumber: number;
    private readonly waiterNumber: number;
    private readonly headWaiterNumber: number;

    constructor(
        staffDecision: StaffDecision,
        staffQualityParameters: StaffQualityParameters,
        previousPeriodCoversNumber: number
    ) {
        super(
            staffQualityParameters.standardCoversNumber,
            previousPeriodCoversNumber,
            50,
            staffDecision,
            staffQualityParameters.standardCookWageBillQuality50,
            staffQualityParameters.standardWaiterWageBillQuality50,
            staffQualityParameters.maximalCookingStaffQualityType2Restaurant,
            staffQualityParameters.maximalCustomerCareStaffQualityType2Restaurant
        );

        this.commisChefNumber = staffDecision.commisChefNumber;
        this.cookNumber = staffDecision.cookNumber;
        this.waiterNumber = staffDecision.waiterNumber;
        this.headWaiterNumber = staffDecision.headWaiterNumber;
    }

    protected computeCooksHierarchyQuality(): number {
        return this.computeHierarchyQualityBetweenTwoLevels(this.cookNumber, this.commisChefNumber);
    }

    protected computeWaitersHierarchyQuality(): number {
        return this.computeHierarchyQualityBetweenTwoLevels(this.headWaiterNumber, this.waiterNumber);
    }
}

import {Injectable} from '@angular/core';
import {AbstractDecisionLoaderService} from "../../abstract-decision-loader.service";
import {MenuSalesPrices} from "./menu-sales-prices";
import {MenuDecisionFormatterService} from "../menu-decision-formatter.service";

@Injectable({
    providedIn: 'root'
})
export class SalesPricesLoaderService extends AbstractDecisionLoaderService<MenuSalesPrices> {

    constructor() {
        super('menuSalesPrices')
    }
}

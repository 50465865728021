import {Component, OnDestroy} from '@angular/core';
import {TeamDecisionsComponent} from './team-decisions/team-decisions.component';
import {PasswordChangePopupService} from '../password-change-popup/password-change-popup.service';
import {ConnectedUserService} from '../context/connected-user.service';
import {AblyRealtimeService} from '../ably/ably-realtime.service';
import {combineLatest, Subscription, timer} from 'rxjs';
import {ContextService} from '../context/context.service';
import {AblySessionCurrentPeriodUpdateEventType} from 'custom-ably-types';
import {Router} from '@angular/router';
import {Session} from '../login/session';
import {filter, take} from 'rxjs/operators';

@Component({
    selector: 'app-main',
    templateUrl: './app-main.component.html',
    styleUrls: ['./app-main.component.scss'],
    host: {'class': 'grid-y grid-frame'}
})
export class AppMainComponent implements OnDestroy {
    public menuVisible = true;
    public showFullMenu = false;
    private subscriptionForAblyEvents: Subscription;
    private subscriptionForReloadDuringSimulation: Subscription;

    private handleSessionCurrentPeriodUpdateEvent(message: any): void {
        const splitMessage = message.data.split('@');
        const eventType: AblySessionCurrentPeriodUpdateEventType = splitMessage[0];
        const newPeriod: string = splitMessage[1];

        switch (eventType) {
            case 'START_SIMULATION' :
                this.connectedUserService.isSimulatedStarted.next(true);
                this.router.navigate(['/simulation/']).catch();
                break;
            case 'END_SIMULATION' :
                timer(1000).subscribe(next => {
                        this.connectedUserService.isSimulatedStarted.next(false);
                        this.contextService.updateCurrentSessionCurrentPeriod(newPeriod);
                        this.router.navigate(['/session/decisions/']).catch();
                    }
                );
                break;
            case 'START_REVERT_SIMULATION' :
                this.connectedUserService.isRevertStarted.next(true);
                this.router.navigate(['/revert', newPeriod]).catch();
                break;
            case 'END_REVERT_SIMULATION' :
                timer(2000).subscribe(next => {
                        this.connectedUserService.isRevertStarted.next(false);
                        this.contextService.updateCurrentSessionCurrentPeriod(newPeriod);
                        this.router.navigate(['/session/decisions/']).catch();
                    }
                );
                break;
        }
    }

    constructor(passwordChangePopupService: PasswordChangePopupService,
                private readonly router: Router,
                private readonly contextService: ContextService,
                private readonly connectedUserService: ConnectedUserService,
                private readonly ablyRealtimeService: AblyRealtimeService) {
        passwordChangePopupService.showPasswordChangePopupIfNeeded();

        this.subscriptionForReloadDuringSimulation = this.contextService.context.pipe(
            filter((context) =>
                !!context
                && !!context.currentSessionId
                && !!context.currentSession
                && !!context.currentSession.simulationData
            ),
            take(1)
        ).subscribe(
            (context) => {
                if (context.currentSession.simulationData.simulationInProgress) {
                    this.connectedUserService.isSimulatedStarted.next(true);
                    this.router.navigate(['/session/decisions/simulation/']).catch();
                }
            });

        this.subscriptionForAblyEvents = this.contextService.context.pipe(
            filter((context) => !!context && !!context.currentSessionId),
            take(1)
        ).subscribe(
            (context) => {
                connectedUserService.ably.subscribe(ably => {
                        ablyRealtimeService.subscribeToSessionCurrentPeriodUpdateChannel(
                            ably,
                            context.currentSessionId,
                            (message: any) => this.handleSessionCurrentPeriodUpdateEvent(message)
                        );
                    }
                );
            }
        );
    }

    toggleMenu(menuVisible: boolean) {
        this.menuVisible = menuVisible;
    }

    onRouterActivate(component) {
        this.showFullMenu = component instanceof TeamDecisionsComponent;
    }

    ngOnDestroy(): void {
        this.subscriptionForAblyEvents.unsubscribe();
        this.subscriptionForReloadDuringSimulation.unsubscribe();
        this.connectedUserService.ably.subscribe(ably => {
            this.ablyRealtimeService.unsubscribeToSessionCurrentPeriodUpdateChannel(
                ably,
                this.contextService.context.getValue().currentSessionId
            );
        })
    }
}

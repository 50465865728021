import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {Tab} from '../../tabs/tab';
import {MenuComponent} from '../../../components/menu/menu.component';

const PARAMETERS_TABS = [
    'studies',
    'financial',
    'standing',
    'quality',
    'restaurant'
].map(tabName => new Tab(tabName, tabName));

@Component({
    selector: 'kiwi-parameters-menu',
    templateUrl: './../../../components/menu/menu.component.html',
    styleUrls: ['./../../../components/menu/menu.component.scss']
})
export class ParametersMenuComponent extends MenuComponent {
    constructor(router: Router) {
        super(router, PARAMETERS_TABS , 'parameters.tab')
    }
}

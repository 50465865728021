import {Injectable} from '@angular/core';
import {AbstractSessionParametersUpdaterService} from "../../../parameters/services/abstract-session-parameters-updater-service";
import {FinalMarkCriteria} from "./final-mark-criteria";

@Injectable({
  providedIn: 'root'
})
export class FinalMarkCriteriaUpdaterService extends AbstractSessionParametersUpdaterService<FinalMarkCriteria>{

  constructor() { super('evaluations/finalMark/criteria') }
}

import {Observable, of} from 'rxjs';
import {Context} from './context/context';
import {filter, flatMap, tap} from 'rxjs/operators';
import {ContextService} from './context/context.service';
import {LocatorService} from './locator.service';

export abstract class TeamCacheService<T> {
    protected cache = new Map<Number, T>();
    private readonly contextService: ContextService;

    protected constructor() {
        this.contextService = LocatorService.injector.get(ContextService);
    }

    protected invalidateCacheValue(teamNumber: number) {
        this.cache.delete(teamNumber);
    }

    protected getFromCache(invalidateCondition: Function | null = null): Observable<T | null> {
        return this.contextService.context.pipe(
            filter(currentContext =>
                !!currentContext &&
                !!currentContext.currentSessionId &&
                !!currentContext.currentPeriod &&
                !!currentContext.currentTeam),
            flatMap(currentContext => {
                const teamNumber = currentContext.currentTeam.teamNumber;

                const valueFromCache = this.cache.get(teamNumber);

                if (invalidateCondition && invalidateCondition(valueFromCache)) {
                    this.invalidateCacheValue(teamNumber);
                } else {
                    if (!!valueFromCache) {
                        return of(valueFromCache);
                    }
                }

                return this.loadFromBack(currentContext)
                    .pipe(tap(value => this.cache.set(teamNumber, value)));
            }));
    }

    protected abstract loadFromBack(context: Context): Observable<T>;
}

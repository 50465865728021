import {Component, Input} from '@angular/core';
import {ChartType} from "chart.js";
import {MarketSharesStudy} from '../market-shares-study';

@Component({
    selector: 'kiwi-product-market-shares',
    templateUrl: './product-market-shares.component.html',
    styleUrls: ['./product-market-shares.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class ProductMarketSharesComponent {
    @Input()
    marketShares: MarketSharesStudy;

    public pieChartType: ChartType = 'pie';
    public pieChartLegend = true;
    public pieChartPlugins = [];
    public chartColors: Array<any> = [
        {
            backgroundColor: [
                'rgba(145, 79, 56, 1)',
                'rgba(192, 117, 91, 1)',
                'rgba(180,173,173, 1)',
                'rgba(90, 94, 107, 1)',
                'rgba(218, 143, 107, 1)',
                'rgba(192, 197, 193, 1)',
                'rgba(247, 227, 175, 1)',
                'rgba(39, 40, 56, 1)',
                'rgba(209, 157, 137, 1)',
                'rgba(98, 48, 24, 1)',
            ]
        }];

    public pieChartOptions = {
        legend: {
            position: 'bottom',
            labels: {
                fontSize: 14
            }
        }
    };
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {RankingLoaderService} from "./ranking-loader.service";
import {RankedTeam} from "./ranked-team";
import {filter, tap} from "rxjs/operators";
import {ContextService} from "../../context/context.service";
import {RankingMenuDisabledLoaderService} from "./ranking-menu-disabled-loader.service";
import {RankingMenuDisabledUpdaterService} from "./ranking-menu-disabled-updater.service";
import {forkJoin, Subscription} from "rxjs";
import {ConnectedUserService} from "../../context/connected-user.service";
import {NotificationService} from '../../notification.service';

@Component({
    selector: 'kiwi-ranking',
    templateUrl: './ranking.component.html',
    styleUrls: ['./ranking.component.scss'],
    host: {'class': 'cell auto pageContainer grid-y'}
})
export class RankingComponent implements OnInit, OnDestroy {
    private showRankingForm: boolean = false;
    private ranking: RankedTeam[];
    private rankingMenuDisabled: boolean;

    private contextSubscription: Subscription;
    private connectedUserServiceSubscription: Subscription;

    constructor(
        private readonly contextService: ContextService,
        private readonly rankingLoaderService: RankingLoaderService,
        private readonly rankingMenuDisabledLoaderService: RankingMenuDisabledLoaderService,
        private readonly rankingMenuDisabledUpdaterService: RankingMenuDisabledUpdaterService,
        private readonly connectedUserService: ConnectedUserService,
        private readonly notificationService: NotificationService
    ) {
    }

    ngOnInit() {
        this.contextSubscription = this.contextService.context
            .pipe(filter(context => !!context && !!context.currentSessionId && !!context.currentSession))
            .subscribe(context => {
                forkJoin(
                    this.rankingLoaderService.loadRanking(context.currentSessionId),
                    this.rankingMenuDisabledLoaderService.loadRankingMenuDisabled(context.currentSessionId)
                ).pipe(tap(([ranking, rankingMenuDisabled]) => {
                    this.ranking = ranking;
                    this.rankingMenuDisabled = rankingMenuDisabled;
                })).subscribe();
            });

        this.connectedUserServiceSubscription = this.connectedUserService.userEvents
            .pipe(tap(user => this.showRankingForm = user.user.animator))
            .subscribe();
    }

    toggleRankingMenuDisabled(rankingMenuDisabled: boolean): void {
        this.rankingMenuDisabled = rankingMenuDisabled;
    }

    updateRankingMenuDisabled(): void {
        this.rankingMenuDisabledUpdaterService
            .updateRankingMenuDisabled(
                this.contextService.context.getValue().currentSessionId,
                this.rankingMenuDisabled)
            .subscribe(() => {
                this.notificationService.success('ranking.title', 'ranking.show.player.saved.successfully');
            });
    }

    ngOnDestroy(): void {
        this.contextSubscription.unsubscribe();
        this.connectedUserServiceSubscription.unsubscribe();
    }
}

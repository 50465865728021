import {Component, Input} from '@angular/core';
import {Label} from 'ng2-charts';
import {AbstractChartParentComponent} from '../chart-component/abstract-chart-parent.component';

@Component({
    selector: 'kiwi-customer-distribution-parameters',
    templateUrl: './customer-distribution-parameters.component.html'
})
export class CustomerDistributionParametersComponent extends AbstractChartParentComponent {
    public readonly barChartOptions = {
        tooltips: {
            callbacks: {
                title: function (context) {
                    const standingCeiling = context[0].label;
                    const standingFloor = parseInt(standingCeiling) - 5;
                    return ']' + standingFloor + ' ; ' + standingCeiling + ']';
                }
            }
        }
    };

    @Input()
    set customersDistribution(customersDistribution: Object) {
        let labels: Label[] = [];
        let data: number[] = [];

        for (let label in customersDistribution) {
            labels.push(label);
            data.push(customersDistribution[label]);
        }

        this.barChartLabels = labels;
        this.barChartData = data;
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Message} from "../message";

@Component({
    selector: 'kiwi-message-card',
    templateUrl: './message-card.component.html',
    styleUrls: ['./message-card.component.scss']
})
export class MessageCardComponent  {
    public static readonly PREVIEW_MAX_CHARACTERS = 150;
    private preview: string;
    private _message: Message;

    @Input()
    set message(message: Message) {
        this._message = message;
        this.preview = this.computePreview(message.content);
    };

    @Input()
    displayUnreadStatus: boolean;

    private computePreview(content: string): string {
        let maxCharactersReached = false;
        const words = content.split(' ');
        let preview = words[0];
        let totalCharacters = words[0].length;
        let i = 1;

        while(i < words.length && !maxCharactersReached){
            const word = words[i];
            const wordCharactersNumber = word.length;

            if(totalCharacters + wordCharactersNumber > MessageCardComponent.PREVIEW_MAX_CHARACTERS)
                maxCharactersReached = true;
            else {
                preview += ' ' + word;
                totalCharacters += wordCharactersNumber + 1;
                i++;
            }
        }

        if(i == words.length)
            return preview;

        return preview + '...';
    }

    @Output()
    displayMessage = new EventEmitter<Message>();

    onDisplayMessage() {
        this.displayMessage.emit(this._message);
    }
}

import {Component, OnDestroy} from '@angular/core';
import {ContextService} from '../../../../context/context.service';
import {ConnectedUserService} from '../../../../context/connected-user.service';
import {AblyRealtimeService} from '../../../../ably/ably-realtime.service';
import {combineLatest, Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {AblySimulationEventType} from 'custom-ably-types';
import {PeriodUtils} from '../../../studies/study-header/period-utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-simulation-page',
    templateUrl: './simulation-page.component.html',
    styleUrls: ['./simulation-page.component.scss'],
    host: {'class': 'cell auto'}
})
export class SimulationPageComponent implements OnDestroy {
    currentStep: number;
    currentSubStep: number;
    displayedPeriod: string;
    readonly subSteps = [1, 2, 3, 4, 5, 6, 7, 8]

    private subscription: Subscription;

    constructor(private readonly contextService: ContextService,
                private readonly connectedUserService: ConnectedUserService,
                private readonly ablyRealtimeService: AblyRealtimeService,
                translateService: TranslateService) {
        this.currentStep = 0;
        this.currentSubStep = 0;

        this.subscription = contextService.context.pipe(
            filter((context) =>
                !!context
                && !!context.currentSession
                && !!context.currentSession.simulationData),
            take(1)
        ).subscribe(
            (context) => {
                PeriodUtils.computeDisplayedPeriod(context.currentPeriod, translateService).subscribe(
                    period => this.displayedPeriod = period
                );
                this.currentStep = context.currentSession.simulationData.currentSimulationStep;
                this.currentSubStep = context.currentSession.simulationData.currentSimulationSubStep;

                connectedUserService.ably.subscribe(ably => {
                        ablyRealtimeService.subscribeToSimulationSteps(
                            ably, context.currentSessionId, (message: any) => {
                                this.updateStepFromEventType(message.data);
                                this.updateSubStepFromEventType(message.data);
                            });
                    }
                );
            }
        );
    }

    private updateStepFromEventType(eventType: string) {
        const workingClassSteps = [
            'WORKING_CLASS_SIMULATION_STEP1',
            'WORKING_CLASS_SIMULATION_STEP2',
            'WORKING_CLASS_SIMULATION_STEP3',
            'WORKING_CLASS_SIMULATION_STEP4',
            'WORKING_CLASS_SIMULATION_STEP5',
            'WORKING_CLASS_SIMULATION_STEP6',
            'WORKING_CLASS_SIMULATION_STEP7',
            'WORKING_CLASS_SIMULATION_STEP8', 'WORKING_CLASS_SIMULATION_END'];

        const averageSteps = [
            'AVERAGE_SIMULATION_STEP1',
            'AVERAGE_SIMULATION_STEP2',
            'AVERAGE_SIMULATION_STEP3',
            'AVERAGE_SIMULATION_STEP4',
            'AVERAGE_SIMULATION_STEP5',
            'AVERAGE_SIMULATION_STEP6',
            'AVERAGE_SIMULATION_STEP7',
            'AVERAGE_SIMULATION_STEP8',
            'AVERAGE_SIMULATION_END'];

        const wealthySteps = [
            'WEALTHY_SIMULATION_STEP1',
            'WEALTHY_SIMULATION_STEP2',
            'WEALTHY_SIMULATION_STEP3',
            'WEALTHY_SIMULATION_STEP4',
            'WEALTHY_SIMULATION_STEP5',
            'WEALTHY_SIMULATION_STEP6',
            'WEALTHY_SIMULATION_STEP7',
            'WEALTHY_SIMULATION_STEP8',
            'WEALTHY_SIMULATION_END'];

        const totalSoldStep = 'TOTAL_SOLD_SIMULATION';

        if (averageSteps.includes(eventType)) {
            this.currentStep = 1;
        } else if (wealthySteps.includes(eventType)) {
            this.currentStep = 2;
        } else if (workingClassSteps.includes(eventType)) {
            this.currentStep = 3;
        } else if ([totalSoldStep].includes(eventType)) {
            this.currentStep = 4;
        }
    }

    private updateSubStepFromEventType(eventType: AblySimulationEventType) {
        switch (eventType) {
            case 'WEALTHY_SIMULATION_STEP1' :
            case 'WORKING_CLASS_SIMULATION_STEP1':
            case 'AVERAGE_SIMULATION_STEP1':
                this.currentSubStep = 1;
                break;
            case 'WEALTHY_SIMULATION_STEP2':
            case 'WORKING_CLASS_SIMULATION_STEP2':
            case 'AVERAGE_SIMULATION_STEP2':
                this.currentSubStep = 2;
                break;
            case 'WEALTHY_SIMULATION_STEP3' :
            case 'WORKING_CLASS_SIMULATION_STEP3':
            case 'AVERAGE_SIMULATION_STEP3':
                this.currentSubStep = 3;
                break;
            case 'WEALTHY_SIMULATION_STEP4' :
            case 'WORKING_CLASS_SIMULATION_STEP4':
            case 'AVERAGE_SIMULATION_STEP4':
                this.currentSubStep = 4;
                break;
            case 'WEALTHY_SIMULATION_STEP5' :
            case 'WORKING_CLASS_SIMULATION_STEP5':
            case 'AVERAGE_SIMULATION_STEP5':
                this.currentSubStep = 5;
                break;
            case 'WEALTHY_SIMULATION_STEP6' :
            case 'WORKING_CLASS_SIMULATION_STEP6':
            case 'AVERAGE_SIMULATION_STEP6':
                this.currentSubStep = 6;
                break;
            case 'WEALTHY_SIMULATION_STEP7' :
            case 'WORKING_CLASS_SIMULATION_STEP7':
            case 'AVERAGE_SIMULATION_STEP7':
                this.currentSubStep = 7;
                break;
            case 'WEALTHY_SIMULATION_STEP8' :
            case 'WORKING_CLASS_SIMULATION_STEP8':
            case 'AVERAGE_SIMULATION_STEP8':
                this.currentSubStep = 8;
                break;
            case 'WEALTHY_SIMULATION_END' :
            case 'WORKING_CLASS_SIMULATION_END':
            case 'AVERAGE_SIMULATION_END':
                this.currentSubStep = 0;
                this.currentStep = this.currentStep + 1;
                break;
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.connectedUserService.ably.subscribe(ably => {this.ablyRealtimeService.unsubscribeToSimulationSteps(
            ably,
            this.contextService.context.getValue().currentSessionId
        )});
        this.currentStep = 0;
        this.currentSubStep = 0;
    }
}

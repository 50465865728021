import {MenuDishesPurchases} from "./menu-dishes-purchases";
import {roundFloat} from '../../math-utils';

export function computeDishesAverageQuality(menuDishesPurchases: MenuDishesPurchases): number {
    let result = 0;
    let menuDishesPurchasesNumber = 0;

    for (const dishPurchases in menuDishesPurchases) {
        const dishesPurchases = menuDishesPurchases[dishPurchases];
        for (const dish in dishesPurchases) {
            result += dishesPurchases[dish].quality;
            menuDishesPurchasesNumber++;
        }
    }

    return roundFloat(result / menuDishesPurchasesNumber, 2);
}

import { Injectable } from '@angular/core';
import {AbstractTeamResultLoader} from "../../../abstract-team-result-loader";

@Injectable({
  providedIn: 'root'
})
export class ActivityLoaderService extends AbstractTeamResultLoader<number>{

  constructor() {
      super('sales/activity');
  }
}

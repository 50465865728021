import {Injectable} from '@angular/core';
import {MessagesLoader} from "./messages-loader";
import {Context} from "../../context/context";
import {Observable} from "rxjs";
import {Message} from "./message";
import {ReceivedMessage} from "./received-message";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ReceivedMessagesLoaderService extends MessagesLoader {

    constructor() {
        super('received');
    }


    loadMessages(context: Context): Observable<Message[]> {
        return super.loadMessages(context).pipe(map(messages => messages.filter(message =>
            (message as ReceivedMessage).messageReceptionStatus !== 'DELETED'
        )));
    }
}

import {Component} from '@angular/core';
import {TabParentDecisionComponent} from '../generics/tab-parent-decision/tab-parent-decision.component';
import {Tab} from '../../tabs/tab';

@Component({
    templateUrl: '../generics/tab-parent-decision/tab-parent-decision.component.html',
    host: {'class': 'cell auto grid-y decisionContainer _communication'}
})
export class CommunicationDecisionComponent extends TabParentDecisionComponent {
    constructor() {
        super([
                new Tab(
                    'decisions.dashboard.communication.decision',
                    '/session/decisions/communication',
                    false)
            ], 'decisions.dashboard.communication.decision',
            'decisions.animator.mark',
            '/session/decisions/communication/mark'
        );
    }
}

import {Component} from '@angular/core';
import {AbstractStudyComponent} from "../../abstract-study.component";
import {CompetitiveIntelligenceStudy} from "../competitive-intelligence-study";
import {CategoryName} from "../../category-name";
import {StudiesNames} from "../../studies-names";
import {of} from "rxjs";

@Component({
        selector: 'kiwi-advertisement-budget-comparison',
        templateUrl: './advertisement-budget-comparison.component.html',
        styleUrls: ['./advertisement-budget-comparison.component.scss'],
        host: {'class': 'cell auto grid-y'}
    }
)
export class AdvertisementBudgetComparisonComponent extends AbstractStudyComponent<CompetitiveIntelligenceStudy> {
    constructor() {
        super(CategoryName.COMPETITIVE_INTELLIGENCE, of(StudiesNames.ADVERTISEMENT_BUDGET_COMPARISON));
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Tab} from "./tab";

@Component({
    selector: 'kiwi-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
    @Input() tabs: Tab[];

    constructor(
        private router: Router
    ) {
    }

    ngOnInit() {
        this.tabs.forEach(tab => {
            tab.isSelected = this.router.url.endsWith(tab.url)
        });
    }

    navigateTo(selectedTab: Tab) {
        selectedTab.isSelected = true;
        this.tabs.filter(tab => tab.url != selectedTab.url).forEach(tab => tab.isSelected = false);
        this.router.navigateByUrl(selectedTab.url).catch();
    }
}

import {User} from "../login/user";

export class ConnectedUser {
  constructor(
    public readonly name: string,
    public user: User
  ) {}
}

export const notConnectedUser = new ConnectedUser(
    "NOT_CONNECTED",
    new User(
        "",
        null,
        false,
        "NOT_CONNECTED",
        0,
        false,
        "",
        false,
        false
    )
) ;

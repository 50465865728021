import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Context} from "../context/context";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PeriodsLoaderService {

  constructor(private httpClient: HttpClient) { }

  public retrievePeriods(context: Context): Observable<string[]> {
      return this.httpClient.get<string[]>('/api/sessions/' + context.currentSessionId + '/periods');
  }
}

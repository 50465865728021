import { Injectable } from '@angular/core';
import {AbstractSessionParametersUpdaterService} from '../abstract-session-parameters-updater-service';
import {InitialBalanceSheetParameters} from './initial-balance-sheet-parameters';

@Injectable({
  providedIn: 'root'
})
export class InitialBalanceSheetParametersUpdaterService extends AbstractSessionParametersUpdaterService<InitialBalanceSheetParameters>{

  constructor() {
      super('initialBalanceSheet')
  }
}

import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {ConnectedUserService} from '../../../context/connected-user.service';
import {map, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IsNotSimulationOrRevertStartedGuard implements CanActivate {
    constructor(
        private readonly connectedUserService: ConnectedUserService
    ) {
    }

    canActivate(): Observable<boolean | UrlTree> {
        return combineLatest(
            this.connectedUserService.isSimulationActivated(),
            this.connectedUserService.isRevertActivated()
        ).pipe(
            take(1),
            map(([simulationIsActivated, revertActivated]) => {
                return !simulationIsActivated && !revertActivated;
            })
        );
    }
}

import {CommunicationDecisionComponent} from './communication-decision.component';
import {CanActivateTeamDecisionsGuard} from '../guards/can-activate-team-decisions.guard';
import {CanDeactivateModificationComponentGuard} from '../../guards/can-deactivate-modification-component.guard';
import {
    PlayerCommunicationDecisionComponent
} from './player-communication-decision/player-communication-decision.component';
import {
    AnimatorCommunicationTaskMarkDecisionComponent
} from './animator-communication-task-mark-decision/animator-communication-task-mark-decision.component';
import {CanActivateAnimatorGuard} from '../guards/can-activate-animator.guard';
import {IsNotSimulationOrRevertStartedGuard} from '../guards/is-not-simulation-or-revert-started-guard.service';

export const communicationDecisionRoute = {
    path: 'communication',
    component: CommunicationDecisionComponent,
    canActivate: [CanActivateTeamDecisionsGuard, IsNotSimulationOrRevertStartedGuard],
    canDeactivate: [CanDeactivateModificationComponentGuard],
    children: [
        {
            path: '',
            component: PlayerCommunicationDecisionComponent,
            canDeactivate: [CanDeactivateModificationComponentGuard]
        },
        {
            path: 'mark',
            component: AnimatorCommunicationTaskMarkDecisionComponent,
            canActivate: [CanActivateAnimatorGuard],
            canDeactivate: [CanDeactivateModificationComponentGuard]
        }
    ]
};

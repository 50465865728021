import {Message} from "./message";

export class SendMessageRequest {
    constructor(
        private readonly message: Message,
        private readonly recipients: String[]
    ) {

    }
}

import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Session} from "../../login/session";

@Injectable({
  providedIn: 'root'
})
export class RankingMenuDisabledUpdaterService {
  constructor(private readonly httpClient: HttpClient) { }

  public updateRankingMenuDisabled(sessionId: string, rankingMenuDisabled: boolean): Observable<Session> {
      return this.httpClient.put<Session>('/api/sessions/' + sessionId +
          '/arkheSessions/rankingMenuDisabled/' + rankingMenuDisabled, null);
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DisplayStudy} from "../display-study";
import {StudyPurchaseDecisionUpdaterService} from "./study-purchase-decision-updater.service";
import {ContextService} from "../../../context/context.service";
import {NotificationService} from '../../../notification.service';

@Component({
    selector: 'kiwi-study-detail',
    templateUrl: './study-detail.component.html',
    styleUrls: ['./study-detail.component.scss']
})
export class StudyDetailComponent  {
    @Input() study: DisplayStudy;
    @Output() previousStudy = new EventEmitter();
    @Output() nextStudy = new EventEmitter();
    @Output() studyPurchased = new EventEmitter();

    constructor(
        private contextService: ContextService,
        private studyPurchaseDecisionUpdater: StudyPurchaseDecisionUpdaterService,
        private readonly notificationService: NotificationService
    ) {
    }

    selectPreviousStudy(): void {
        this.previousStudy.emit();
    }

    selectNextStudy(): void {
        this.nextStudy.emit();
    }

    purchaseStudy(study: DisplayStudy): void {
        const context = this.contextService.context.getValue();
        this.studyPurchaseDecisionUpdater.purchaseStudy(context, study).subscribe(
            () => this.studyPurchased.emit(),
            (errorMessage) => this.notificationService.error(errorMessage.error)
        );
    }

}

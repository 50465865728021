import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Context} from "../../../../../context/context";

@Injectable({
    providedIn: 'root'
})
export class RestaurantTypeLoaderService {

    constructor(
        private httpClient: HttpClient
    ) {
    }

    retrieveData(team: number, period: string, session: string): Observable<string> {
        return this.httpClient.get<string>('/api/sessions/' + session +
            '/periods/' + period +
            '/result/teams/' + team +
            '/restaurantType');
    }

    loadRestaurantType(context: Context): Observable<string> {
        return this.retrieveData(context.currentTeam.teamNumber, context.currentPeriod, context.currentSessionId);
    }
}

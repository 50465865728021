import {Component} from '@angular/core';
import {AbstractStudyComponent} from "../../abstract-study.component";
import {CategoryName} from "../../category-name";
import {of} from "rxjs";
import {StudiesNames} from "../../studies-names";


class FinancialResult {
    constructor(
        public readonly investmentInterest: number,
        public readonly investmentAmount: number,
        public readonly investmentPercentageRate: number,
        public readonly otherFinancialProducts: number,
        public readonly totalFinancialProducts: number,

        public readonly loanInterest: number,
        public readonly loanAmount: number,
        public readonly loanPercentageRate: number,
        public readonly overdraftCharges: number,
        public readonly otherFinancialExpenses: number,
        public readonly totalFinancialExpenses: number,
    ) {
    }
}

@Component({
    templateUrl: './financial-result.component.html',
    styleUrls: ['./financial-result.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class FinancialResultComponent extends AbstractStudyComponent<FinancialResult> {

    constructor() {
        super(CategoryName.ACCOUNTS_DETAIL, of(StudiesNames.FINANCIAL_RESULT));
    }
}

import {StudiesNames} from '../studies-names';
import {
    PotentialMarketAndSalesStudyComponent
} from '../internal-data/potential-market-and-sales-study/potential-market-and-sales-study.component';
import {StudyRouteDefinition} from '../study-route-definition';

export const potentialMarketAndSalesChildrenRoutes: StudyRouteDefinition[] = [
    new StudyRouteDefinition(
        StudiesNames.POTENTIAL_MARKET_AND_SALES,
        [{
            path: '',
            component: PotentialMarketAndSalesStudyComponent

        }]
    )
];

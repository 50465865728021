import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Context} from "../../../context/context";
import {LocatorService} from "../../../locator.service";
import {AbstractParametersLoaderService} from './abstract-parameters-loader.service';

export abstract class AbstractSessionParametersLoaderService<T> implements AbstractParametersLoaderService<T>{
    private readonly httpClient: HttpClient;

    protected constructor(
        private parametersType: string
    ) {
        this.httpClient = LocatorService.injector.get(HttpClient);
    }

    loadParameters(context: Context): Observable<T> {
        return this.loadSessionParameters(context);
    }

    private loadSessionParameters(context: Context): Observable<T> {
        return this.httpClient.get<T>('/api/sessions/' + context.currentSessionId + '/parameters/' + this.parametersType);
    }
}

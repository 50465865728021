import {Component, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, Observable, of, Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ContextService} from '../../../context/context.service';
import {Team} from '../../../login/team';
import {filter, switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'kiwi-select-team',
    templateUrl: './select-team.component.html',
    styleUrls: ['./select-team.component.scss']
})
export class SelectTeamComponent implements OnInit {
    public teams: Team[];
    public currentTeamNumber: number;
    private contextSubscription: Subscription;

    constructor(
        private contextService: ContextService,
        private http: HttpClient
    ) {
    }

    ngOnInit() {
        this.contextSubscription = this.contextService.context.pipe(
            tap(_ => {
                if (!!this.teams) {
                    this.contextSubscription.unsubscribe()
                }
            }),
            filter(context => !!context && !this.teams && !!context.currentSessionId),
            switchMap(context => forkJoin([of(context), this.retrieveTeams(context.currentSessionId)]))
        ).subscribe(
            ([context, teams]) => {
                this.teams = teams;
                if (context.currentTeam) {
                    this.currentTeamNumber = context.currentTeam.teamNumber;
                } else {
                    this.currentTeamNumber = teams[0].teamNumber;
                }
            }
        );
    }

    onTeamChange(newTeamNumber: number) {
        if (newTeamNumber)
            this.contextService.updateCurrentTeam(this.teams.find(team => team.teamNumber === newTeamNumber));
    }

    retrieveTeams(sessionId: string): Observable<Team[]> {
        return this.http.get<Team[]>("/api/sessions/" + sessionId + "/teams");
    }
}

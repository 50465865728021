import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ConnectedUserService} from '../../../context/connected-user.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IsRevertStartedGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly connectedUserService: ConnectedUserService
    ) {
    }

    canActivate(): Observable<boolean | UrlTree> {
        return this.connectedUserService.isRevertActivated().pipe(
            map(revertIsActivated => {
                if (revertIsActivated) {
                    return true;
                } else {
                    return this.router.parseUrl('/session/decisions/revert/');
                }
            })
        );
    }
}

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'kiwi-parameters',
    templateUrl: './parameters.component.html',
    styleUrls: ['./parameters.component.scss'],
    host: {'class': 'cell auto pageContainer grid-y'}
})
export class ParametersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

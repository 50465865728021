import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {CanActivateTeamDecisionsGuard} from './can-activate-team-decisions.guard';
import {ConnectedUserService} from '../../../context/connected-user.service';
import {filter, flatMap, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CanActivateTeamDecisionsHomeGuard implements CanActivate {
    constructor(
        private canActivateTeamDecisionsGuard: CanActivateTeamDecisionsGuard,
        private connectedUserService: ConnectedUserService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.connectedUserService.userEvents.pipe(
            filter(connectedUser => !!connectedUser && !!connectedUser.user),
            map(connectedUser => connectedUser.user.animator),
            flatMap(isAnimator => {
                if(isAnimator) {
                    return of(true);
                }

                return this.canActivateTeamDecisionsGuard.canActivate(route, state);
            })
        );
    }
}

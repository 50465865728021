import {NotationInformation} from './notation-information';

export class DispersionApplier {
    static applyDispersion(marks: number[], dispersion: number, maxMark: number): NotationInformation {
        const initialNotationInformation = this.computeNotationInformation(marks);
        return new NotationInformation(
            this.computeNewMin(initialNotationInformation.minimum, initialNotationInformation.average, dispersion),
            initialNotationInformation.average,
            this.computeNewMax(initialNotationInformation.maximum, initialNotationInformation.average, dispersion, maxMark)
        );
    }

    private static computeNotationInformation(marks: number[]): NotationInformation {
        let minimum = 100;
        let maximum = 0;
        let sum = 0;

        for(const mark of marks) {
            if(mark > maximum) {
                maximum = mark;
            }

            if(mark < minimum) {
                minimum = mark;
            }

            sum += mark;
        }

        return new NotationInformation(
            minimum,
            sum / marks.length,
            maximum
        );
    }

    private static computeNewMin(min: number, average: number, dispersion: number): number {

        if(dispersion < 50) {
            return average + 0.02 * dispersion * (min - average);
        }

        return 0.02 * min * (100 - dispersion);
    }

    private static computeNewMax(max: number, average: number, dispersion: number, maxMark: number): number {
        if(dispersion < 50) {
            return average + (0.02 * dispersion * (max - average))
        }

        return max + 0.02 * (dispersion - 50) * (maxMark - max);
    }
}

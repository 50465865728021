import {Component} from '@angular/core';
import {ArchetypeUpdaterService} from './archetype-updater.service';
import {AbstractDecisionComponent} from '../abstract-decision-component';
import {Context} from '../../../context/context';
import {Router} from '@angular/router';
import {flatMap, take, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Score} from '../generics/score-image/score-image.component';
import {ArchetypeDecisionLoaderService} from './archetype-decision-loader.service';
import {RestaurantType} from '../staff-decision/player-staff-decision/restaurant-type';
import {ArchetypeConfirmationPopupService} from './archetype-confirmation-popup.service';
import {HasChosenArchetypeService} from '../guards/has-chosen-archetype.service';
import {RestaurantStandingInformationLoaderService} from '../../../context/restaurant-standing-information-loader.service';
import {ArchetypeDecisionParametersLoaderService} from './archetype-decision-parameters-loader.service';

@Component({
    templateUrl: './archetype-decision.component.html',
    styleUrls: ['./archetype-decision.component.scss'],
    host: {'class': 'cell auto cell-block-y grid-x align-center align-middle archetypeContainer'}
})
export class ArchetypeDecisionComponent extends AbstractDecisionComponent<string, ArchetypeDecisionParameters> {
    public selectedArchetype: string;

    title = 'ngSlick';

    slideConfig = {
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'nextArrow': '<i class="archetypeCarousel-icon _right fas fa-angle-right"></i>',
        'prevArrow': '<i class="archetypeCarousel-icon _left fas fa-angle-left"></i>',
        'dots': false,
        'infinite': true,
        'arrows': true
    };

    constructor(
        private archetypeDecisionParametersLoaderService: ArchetypeDecisionParametersLoaderService,
        archetypeUpdater: ArchetypeUpdaterService,
        private confirmationPopupService: ArchetypeConfirmationPopupService,
        private hasChosenArchetypeService: HasChosenArchetypeService,
        private router: Router,
        private readonly restaurantStandingInformationLoaderService: RestaurantStandingInformationLoaderService,
        archetypeDecisionLoaderService: ArchetypeDecisionLoaderService
    ) {
        super(
            archetypeDecisionLoaderService,
            archetypeUpdater,
            [],
            'toast.archetype.decision.saved.message',
            (selectedArchetype) => {
                if (selectedArchetype !== RestaurantType.NONE) {
                    this.parameters.archetypes = ArchetypeDecisionComponent.reorganiseWithSelectedArchetypeAsFirst(
                        this.parameters.archetypes,
                        selectedArchetype
                    );
                    this.restaurantStandingInformationLoaderService.loadRestaurantStandingInformation(this.contextService.context.getValue())
                        .subscribe(currentType => {
                            if (currentType === selectedArchetype) {
                                this.selectedArchetype = selectedArchetype;
                            }
                        });
                }
                this.onSelect(this.parameters.archetypes[0]);
            }
        );
    }

    private static compareArchetype(archetype1: string, archetype2: string) {
        const order = ['LOW_STANDING_ARCHETYPE', 'AVERAGE_STANDING_ARCHETYPE', 'HIGH_STANDING_ARCHETYPE'];

        const index1 = order.indexOf(archetype1);
        const index2 = order.indexOf(archetype2);

        if (index1 < index2)
            return -1;

        if (index2 < index1)
            return 1;

        return 0;
    }

    afterChange(carouselSlideEvent) {
        const selectedArchetypeIndex = carouselSlideEvent.currentSlide;
        this.onSelect(this.parameters.archetypes[selectedArchetypeIndex]);
    }

    onSelect(archetypeName: string): void {
        this.selectedArchetype = archetypeName;
        this.decision = archetypeName;
    }

    onSave(): void {
        this.hasChosenArchetypeService.hasChosenArchetype()
            .pipe(
                take(1)
            ).subscribe(hasChosenArchetype => {
            if (hasChosenArchetype) {
                this.confirmationPopupService
                    .showConfirmationPopup(this.selectedArchetype, () => this.updateArchetype());
            } else {
                this.updateArchetype();
            }
        });

    }

    updateArchetype(): void {
        this.callSave().pipe(
            flatMap(() => this.restaurantStandingInformationLoaderService.retrieveData(this.team.teamNumber, this.period, this.session))
        ).subscribe(restaurantStandingInformation => {
                this.contextService.updateCurrentRestaurantStandingInformation(restaurantStandingInformation);
                this.router.navigateByUrl('session/decisions').catch();
            }
        );
    }

    protected loadParameters(context: Context): Observable<ArchetypeDecisionParameters> {
        return this.archetypeDecisionParametersLoaderService.loadParameters(context)
            .pipe(tap(archetypeDecisionParameters => {
                archetypeDecisionParameters.archetypes = archetypeDecisionParameters.archetypes.sort(ArchetypeDecisionComponent.compareArchetype)
            }));
    }

    protected computeDecisionQuality(scores: Score[], parameters: ArchetypeDecisionParameters): number {
        return 0;
    }

    protected updateScores(decision: any, parameters: ArchetypeDecisionParameters): void {
    }

    private static reorganiseWithSelectedArchetypeAsFirst(archetypes: string[], selectedArchetype: string) {
        const selectedArchetypeIndex = archetypes.indexOf(selectedArchetype);
        return [0, 1, 2].map(index => archetypes[(index + selectedArchetypeIndex) % 3]);
    }
}

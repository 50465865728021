import { Directive } from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
    selector: '[kiwiDaysNumberValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: TreasuryCriteriaDaysNumberValidatorDirective, multi:true}]
})
export class TreasuryCriteriaDaysNumberValidatorDirective implements Validator{
    validate(control: AbstractControl): ValidationErrors | null {
        const daysNumberForMaximalMark = control.get('daysNumberForMaximalMark');
        const daysNumberForAverageMark = control.get('daysNumberForAverageMark');
        const daysNumberForMinimalMark = control.get('daysNumberForMinimalMark');

        if (!daysNumberForMaximalMark || !daysNumberForAverageMark || !daysNumberForMinimalMark) {
            return null;
        }

        if (daysNumberForMaximalMark.value < daysNumberForAverageMark.value && daysNumberForAverageMark.value < daysNumberForMinimalMark.value) {
            return null;
        }

        return { daysNumberOrder: true };
    }
}

import {Component, OnInit} from '@angular/core';
import {EvaluationTab} from './evaluation-tab.model';
import {Router} from '@angular/router';
import {MenuComponent} from '../../../components/menu/menu.component';

const EVALUATIONS_TABS = [
    'GENERAL_INFORMATION',
    'TREASURY_MARK',
    'PROFITABILITY_MARK',
    'CUSTOMER_SATISFACTION_MARK',
    'FINAL_MARK'
].map(tabName => new EvaluationTab(tabName));

@Component({
  selector: 'kiwi-evaluations-menu',
  templateUrl: './../../../components/menu/menu.component.html',
  styleUrls: ['./../../../components/menu/menu.component.scss']
})
export class EvaluationsMenuComponent extends MenuComponent {
    constructor(router:Router) {
        super(router, EVALUATIONS_TABS, 'evaluations.tabs')
    }
}


import {Component} from '@angular/core';
import {ConfirmationPopupComponent} from '../../confirmation-popup/confirmation-popup.component';
import {Router} from '@angular/router';

@Component({
    templateUrl: './save-before-deactivate-popup.component.html',
    styleUrls: ['./save-before-deactivate-popup.component.scss'],
    host: {'class': 'popupContainer'}
})
export class SaveBeforeDeactivatePopupComponent extends ConfirmationPopupComponent {
    cancelUrl: string;

    constructor(private readonly router: Router) {
        super();
    }

    cancel() {
        this.router.navigateByUrl(this.cancelUrl).catch();
        this.close();
    }
}

import {Component} from '@angular/core';
import {SentMessagesLoaderService} from "./sent-messages-loader.service";
import {ReceivedMessagesLoaderService} from "./received-messages-loader.service";
import {Message} from "./message";
import {User} from "../../login/user";
import {UsersList} from "./users-list";
import {MessageSenderService} from "./message-sender.service";
import {ContextService} from "../../context/context.service";
import {SendMessageRequest} from "./send-message-request";
import {ReceivedMessageUpdaterService} from "./received-message-updater.service";
import {BehaviorSubject} from "rxjs";
import {UnreadMessagesCountService} from '../menu/unread-messages-count.service';

@Component({
    selector: 'kiwi-mail-box',
    templateUrl: './mail-box.component.html',
    styleUrls: ['./mail-box.component.scss'],
    host: {'class': 'cell auto pageContainer grid-y'}
})
export class MailBoxComponent {
    public newMessage: boolean = false;
    public displaySentMessages: boolean = false;
    public displayReceivedMessages: boolean = true;
    public selectedMessage: Message;

    public recipients: UsersList[] = [];
    public recipientsIds: String[] = [];

    public reloadReceivedMessages = new BehaviorSubject<any>(true);

    constructor(
        private readonly sentMessagesLoaderService: SentMessagesLoaderService,
        private readonly receivedMessagesLoaderService: ReceivedMessagesLoaderService,
        private readonly messageSenderService: MessageSenderService,
        private readonly contextService: ContextService,
        private readonly receivedMessageUpdaterService: ReceivedMessageUpdaterService,
        private readonly unreadMessagesCountService: UnreadMessagesCountService
    ) {

    }

    write(): void {
        this.newMessage = !this.newMessage;
    }

    onSelectReceivedMessage(messageIndex: number): void {
        this.receivedMessageUpdaterService.markMessageAsRead(this.contextService.context.getValue(), messageIndex).subscribe(() => {
            this.unreadMessagesCountService.reloadUnreadMessagesCount();
        });
    }

    onSelectMessage(message: Message): void {
        this.selectedMessage = message;
    }

    onSelectSentMessages(): void {
        this.newMessage = false;
        this.displayReceivedMessages = false;
        this.displaySentMessages = true;
        this.selectedMessage = undefined;
    }

    onSelectNewMessage(): void {
        this.newMessage = true;
        this.displayReceivedMessages = false;
        this.displaySentMessages = false;
        this.selectedMessage = undefined;
    }

    onSelectReceivedMessages(): void {
        this.newMessage = false;
        this.displayReceivedMessages = true;
        this.displaySentMessages = false;
        this.selectedMessage = undefined;
    }

    onNewRecipients(newRecipients: UsersList): void {
        let allRecipientsAreNew = true;
        const usersToAdd = new Array<User>();

        for (const user of newRecipients.users) {
            if (this.recipientsIds.includes(user._id))
                allRecipientsAreNew = false;
            else {
                this.recipientsIds.push(user._id);
                usersToAdd.push(user);
            }
        }

        if (allRecipientsAreNew)
            this.recipients.push(newRecipients);
        else
            usersToAdd.forEach(user => this.recipients.push(new UsersList(user.person.firstName + ' ' + user.person.lastName, [user])));
    }

    onRecipientsDeleted(recipientsDeleted: UsersList): void {
        recipientsDeleted.users.forEach(deletedUser =>
            this.recipientsIds = this.recipientsIds.filter(userId => userId !== deletedUser._id));
        this.recipients = this.recipients.filter(userList => userList !== recipientsDeleted);
    }

    onMessageSent(message: Message): void {
        const context = this.contextService.context.getValue();
        this.messageSenderService.sendMessage(context, new SendMessageRequest(message, this.recipientsIds))
            .subscribe(() => {
                this.recipients = [];
                this.recipientsIds = [];
                this.onSelectSentMessages();
            });
    }

    onDeleteMessage(message: Message): void {
        const context = this.contextService.context.getValue();
        this.receivedMessageUpdaterService.deleteMessage(
            context,
            message._id
        ).subscribe(() => {
            this.reloadReceivedMessages.next(true);
            this.selectedMessage = undefined;
        });
    }

    onAnswerTo(recipient: User): void {
        this.onNewRecipients(new UsersList(recipient.person.firstName + ' ' + recipient.person.lastName, [recipient]));
        this.onSelectNewMessage();
    }
}

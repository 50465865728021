import {ApplicationRef, ComponentFactoryResolver, Injector, Type} from '@angular/core';
import {AbstractPopupComponent} from "./abstract-popup-component";
import {LocatorService} from "./locator.service";

export abstract class AbstractPopupService<T extends AbstractPopupComponent> {
    private readonly injector: Injector;
    private readonly applicationRef: ApplicationRef;
    private readonly componentFactoryResolver: ComponentFactoryResolver;

    protected constructor(
        private popupClass: Type<T>
    ) {
        this.injector = LocatorService.injector;
        this.componentFactoryResolver = LocatorService.injector.get(ComponentFactoryResolver);
        this.applicationRef = LocatorService.injector.get(ApplicationRef);
    }

    protected showPopup(preparedPopup: T): T {
        // Create element
        const popup = document.createElement('popup-component');

        // Create the component and wire it up with the element
        const factory = this.componentFactoryResolver.resolveComponentFactory(this.popupClass);
        const popupComponentRef = factory.create(this.injector, [], popup);

        // Attach to the view so that the change detector knows to run
        this.applicationRef.attachView(popupComponentRef.hostView);

        // Listen to the close event
        this.fillPopupInstance(popupComponentRef.instance, preparedPopup);

        popupComponentRef.instance.closed.subscribe(() => {
            document.body.removeChild(popup);
            this.applicationRef.detachView(popupComponentRef.hostView);
        });

        // Add to the DOM
        document.body.appendChild(popup);

        return popupComponentRef.instance;
    }

    protected abstract fillPopupInstance(popupInstance: T, preparedPopup: T);
}

import {Component, Input} from '@angular/core';

export class Score {
    constructor(
        public readonly label: string,
        public value: number
    ) {}
}

@Component({
    selector: 'kiwi-score-image',
    templateUrl: './score-image.component.html',
    styleUrls: ['./score-image.component.scss'],
    host: {'class': 'decisionImg'}
})
export class ScoreImageComponent {
    private _scores: Score[];

    @Input()
    set scores(scores: Score[]) {
        this._scores = scores;
    }

    get scores(): Score[] {
        return this._scores;
    }

    @Input()
    imgQualityClass: string;

    @Input()
    imgType: string;
}

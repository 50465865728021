import {Injectable} from '@angular/core';
import {CanActivateChild, Router, UrlTree} from '@angular/router';
import {ConnectedUserService} from '../context/connected-user.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable()
export class IsLoggedInGuard implements CanActivateChild {
    constructor(
        private readonly router: Router,
        private readonly connectedUserService: ConnectedUserService
    ) {
    }

    public canActivateChild(): Observable<boolean | UrlTree> {
        return this.connectedUserService.isUserConnected().pipe(
            map(userIsConnected => {
                if (userIsConnected) {
                    return true;
                } else {
                    return this.router.parseUrl('/login')
                }
            })
        )
    }
}

import {Component} from '@angular/core';
import {Tab} from "../../tabs/tab";
import {TabParentDecisionComponent} from '../generics/tab-parent-decision/tab-parent-decision.component';

@Component({
    selector: 'kiwi-menu-decision',
    templateUrl: '../generics/tab-parent-decision/tab-parent-decision.component.html',
    host: {'class': 'cell auto grid-y decisionContainer _menu'}
})
export class MenuDecisionComponent extends TabParentDecisionComponent {
    constructor() {
        super(new Array<Tab>(
            new Tab('decisions.menu.sales.prices.tab', '/session/decisions/menu/sales-prices', false),
            new Tab('decisions.menu.ingredients.purchasing.tab', '/session/decisions/menu/ingredients-purchasing', false),
            new Tab('decisions.menu.wines.purchasing.tab', '/session/decisions/menu/wines-purchasing', false)
        ), 'decisions.menu.mainBlock.title',
            'decisions.animator.mark',
            '/session/decisions/menu/mark');
    }
}

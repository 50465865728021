import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AllDecisionsAreClosedLoaderService {

  constructor(private readonly httpClient: HttpClient) { }

  public loadAllDecisionsAreClosed(sessionId: string, period: string): Observable<boolean> {
      return this.httpClient.get<boolean>(
          '/api/sessions/' + sessionId + '/periods/' + period +'/allDecisionsAreClosed')
  }
}

import { Injectable } from '@angular/core';
import {QualityParameters} from "./quality-parameters";
import {AbstractSessionParametersLoaderService} from "../abstract-session-parameters-loader.service";

@Injectable({
  providedIn: 'root'
})
export class QualityParametersLoaderService extends AbstractSessionParametersLoaderService<QualityParameters>{

  constructor() { super('quality'); }
}

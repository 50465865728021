import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FinancialParametersComponent} from './financial-parameters.component';
import {InitialBalanceSheetParametersComponent} from './initial-balance-sheet-parameters/initial-balance-sheet-parameters.component';
import {VatParametersComponent} from './vat-parameters/vat-parameters.component';
import {InsurancesParametersComponent} from './insurances-parameters/insurances-parameters.component';
import {ExternalLoadsParametersComponent} from './external-loads-parameters/external-loads-parameters.component';
import {HumanResourcesAndTaxesParametersComponent} from './human-resources-and-taxes-parameters/human-resources-and-taxes-parameters.component';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {TabsModule} from '../../tabs/tabs.module';
import {ParametersComponentsModule} from '../components/parameters-components.module';
import {ComponentsModule} from '../../../components/components.module';

export const financialParametersRoutes: Routes = [
    {
        path: '',
        component: FinancialParametersComponent,
        children: [
            {
                path: '',
                redirectTo: 'initial-balance-sheet',
                pathMatch: 'full'
            },
            {
                path: 'initial-balance-sheet',
                component: InitialBalanceSheetParametersComponent
            },
            {
                path: 'human-resources-and-taxes',
                component: HumanResourcesAndTaxesParametersComponent
            },
            {
                path: 'external-loads',
                component: ExternalLoadsParametersComponent
            },
            {
                path: 'vat',
                component: VatParametersComponent
            },
            {
                path: 'insurances',
                component: InsurancesParametersComponent
            }
        ]
    }
]

@NgModule({
    declarations: [
        FinancialParametersComponent,
        InitialBalanceSheetParametersComponent,
        VatParametersComponent,
        InsurancesParametersComponent,
        ExternalLoadsParametersComponent,
        HumanResourcesAndTaxesParametersComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        TabsModule,
        ParametersComponentsModule,
        ComponentsModule
    ]
})
export class FinancialParametersModule { }

import {Component} from '@angular/core';
import {GenericSessionParametersComponent} from '../../generic-parameters/generic-session-parameters.component';
import {StaffQualityParameters} from '../../services/quality/staff-quality-parameters';
import {StaffQualityParametersLoaderService} from '../../services/quality/staff-quality-parameters-loader.service';
import {StaffQualityParametersUpdaterService} from './staff-quality-parameters-updater.service';
import {genericParametersHost} from '../../generic-parameters/generic-parameters.component';

@Component({
    templateUrl: './staff-quality-parameters.component.html',
    styleUrls: ['./../../generic-parameters/generic-parameters.component.scss'],
    host: genericParametersHost
})
export class StaffQualityParametersComponent extends GenericSessionParametersComponent<StaffQualityParameters> {

    constructor(staffQualityParametersLoaderService: StaffQualityParametersLoaderService,
                staffQualityParametersUpdaterService: StaffQualityParametersUpdaterService) {
        super(staffQualityParametersLoaderService, staffQualityParametersUpdaterService, 'toast.parameters.quality.staff.saved');
    }
}

import { Injectable } from '@angular/core';
import {AbstractSessionParametersLoaderService} from '../abstract-session-parameters-loader.service';
import {InitialBalanceSheetParameters} from './initial-balance-sheet-parameters';

@Injectable({
  providedIn: 'root'
})
export class InitialBalanceSheetParametersLoaderService extends AbstractSessionParametersLoaderService<InitialBalanceSheetParameters>{
  constructor() {
      super('initialBalanceSheet')
  }
}

import {Injectable} from '@angular/core';
import {DishesParameters} from "./dishes-parameters";
import {AbstractSessionParametersLoaderService} from '../abstract-session-parameters-loader.service';

@Injectable({
    providedIn: 'root'
})
export class DishesParametersLoaderService extends AbstractSessionParametersLoaderService<DishesParameters> {

    constructor() {
        super('dishes');
    }
}

import {Component, OnInit} from '@angular/core';
import {AreaCompetitionLoaderService} from "./area-competition-loader.service";
import {ContextService} from "../../context/context.service";
import {filter, flatMap, tap} from "rxjs/operators";
import {AreaDecisionLoaderService} from "../team-decisions/area-decision/area-decision-loader.service";
import {forkJoin} from "rxjs";

@Component({
    selector: 'kiwi-area-competition',
    templateUrl: './area-competition.component.html',
    styleUrls: ['./area-competition.component.scss'],
    host: {'class': 'cell auto pageContainer grid-y'}
})
export class AreaCompetitionComponent implements OnInit {
    private areaCompetition: Object;
    private area: string;

    constructor(
        private readonly areaCompetitionLoaderService: AreaCompetitionLoaderService,
        private readonly contextService: ContextService,
        private readonly areaDecisionLoaderService: AreaDecisionLoaderService
    ) {
    }

    ngOnInit() {
        this.contextService.context.pipe(
            filter(context =>
                (context !== undefined &&
                    context.currentSessionId !== undefined &&
                    context.currentTeam !== undefined)),
            flatMap(context =>
                forkJoin(
                    this.areaCompetitionLoaderService
                        .loadAreaCompetition(context.currentSessionId, context.currentPeriod, context.currentTeam.teamNumber),
                    this.areaDecisionLoaderService.loadDecisionFromContext(context)
                )
            ),
            tap(([areaCompetition, areaDecision]) => {
                this.areaCompetition = areaCompetition;
                this.area = areaDecision.area;
            })
        ).subscribe();
    }

}

import {Component} from '@angular/core';
import {AbstractStudyComponent} from "../../abstract-study.component";
import {CategoryName} from "../../category-name";
import {StudiesNames} from "../../studies-names";
import {of} from "rxjs";

class ExceptionalResult {
    constructor(
        public readonly layoutValueSold: number,
        public readonly otherNonRecurringCharges: number,
        public readonly totalNonRecurringCharge: number,
        public readonly layoutSellingIncome: number,
        public readonly otherExceptionalIncomes: number,
        public readonly totalExceptionalIncome: number,
    ) {
    }
}

@Component({
    selector: 'kiwi-exceptional-result',
    templateUrl: './exceptional-result.component.html',
    styleUrls: ['./exceptional-result.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class ExceptionalResultComponent extends AbstractStudyComponent<ExceptionalResult> {

    constructor() {
        super(CategoryName.ACCOUNTS_DETAIL, of(StudiesNames.EXCEPTIONAL_RESULT));
    }
}

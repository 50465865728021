import {Component, OnDestroy} from '@angular/core';
import {YearsTeamsMarksLoaderService} from "./years-teams-marks-loader.service";
import {YearTeamsMarks} from "./year-teams-marks";
import {TeamsLoaderService} from "../../../team-decisions/period-completion/animator-decisions-closure/teams-loader.service";
import {ContextService} from "../../../../context/context.service";
import {Team} from "../../../../login/team";
import {AbstractEvaluationComponent} from "../abstract-evaluation-component";
import {tap} from "rxjs/operators";
import {Subscription} from 'rxjs';

@Component({
    selector: 'kiwi-marks',
    templateUrl: './marks.component.html',
    styleUrls: ['./marks.component.scss'],
    host: {'class': 'cell auto grid-y tabsContainer-content'}
})
export class MarksComponent extends AbstractEvaluationComponent implements OnDestroy {
    private teams: Team[];
    private yearsTeamsMarks: YearTeamsMarks[];
    private periodicity: string;
    private displayMarks: boolean = true;
    private contextSubscription: Subscription;

    constructor(
        private teamsLoaderService: TeamsLoaderService,
        private displayEvaluationLoaderService: YearsTeamsMarksLoaderService,
        private contextService: ContextService
    ) {
        super();
    }

    ngOnInit() {
        this.contextSubscription = this.contextService.context.subscribe(
            context => {
                if (context && context.currentSessionId && context.currentSession) {
                    this.periodicity = context.currentSession.baseMetaScenario.periodicity.toLowerCase();
                    this.markType.subscribe(
                        markType => {
                            if (markType !== '')
                                this.displayEvaluationLoaderService.loadYearsTeamsMarks(context, markType)
                                    .pipe(
                                        tap(yearsTeamsMarks => this.yearsTeamsMarks = yearsTeamsMarks),
                                        tap(yearsTeamsMarks => this.displayMarks = yearsTeamsMarks && yearsTeamsMarks[0].periodsMonths.length > 0 )
                                    ).subscribe()
                        });
                    this.teamsLoaderService.loadTeams(context)
                        .subscribe(
                            teams => this.teams = teams
                        )
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.contextSubscription.unsubscribe();
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LanguagesLoaderService {

  constructor(
      private readonly httpClient: HttpClient
  ) { }

    public loadLanguages(): Observable<string[]> {
        return this.httpClient.get<string[]>('/api/languages');
    }
}

import {NgModule} from '@angular/core';
import {MenuDecisionComponent} from "./menu-decision.component";
import {SalesPricesComponent} from "./sales-prices/sales-prices.component";
import {IngredientsPurchasingComponent} from "./ingredients-purchasing/ingredients-purchasing.component";
import {WinesPurchasingComponent} from "./wines-purchasing/wines-purchasing.component";
import {MenuDecisionRoutingModule} from "./menu-decision-routing.module";
import {GenericsModule} from "../generics/generics.module";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";
import {PipesModule} from "../../../pipes/pipes.module";
import {ComponentsModule} from "../../../components/components.module";
import {TabsModule} from "../../tabs/tabs.module";
import { AnimatorMenuMarkComponent } from './animator-menu-mark/animator-menu-mark.component';

@NgModule({
    declarations: [
        MenuDecisionComponent,
        SalesPricesComponent,
        IngredientsPurchasingComponent,
        WinesPurchasingComponent,
        AnimatorMenuMarkComponent
    ],
    imports: [
        MenuDecisionRoutingModule,
        GenericsModule,
        CommonModule,
        TranslateModule,
        FormsModule,
        PipesModule,
        ComponentsModule,
        TabsModule
    ],

    exports: [
        MenuDecisionComponent
    ]
})
export class MenuDecisionModule {
}

import {Component} from '@angular/core';
import {ConfirmationPopupComponent} from "../../../../confirmation-popup/confirmation-popup.component";

@Component({
    selector: 'kiwi-area-confirmation-popup',
    templateUrl: './area-confirmation-popup.component.html',
    styleUrls: ['./area-confirmation-popup.component.scss'],
    host: {'class': 'popupContainer'}
})
export class AreaConfirmationPopupComponent extends ConfirmationPopupComponent {
    public chosenArea: string;
    public chosenAreaImageClass: string;
}

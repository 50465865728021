import {StudiesNames} from "../studies-names";
import {QualityComponent} from "../internal-data/quality/quality.component";
import {StudyRouteDefinition} from '../study-route-definition';

export const qualityChildrenRoutes: StudyRouteDefinition[] = [
    new StudyRouteDefinition(
        StudiesNames.QUALITY,
        [{
            path:'',
            component: QualityComponent
        }]
    )
];

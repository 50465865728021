import {Component, forwardRef} from '@angular/core';
import {ControlContainer, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm} from '@angular/forms';
import {AbstractInputWithUnitComponent} from './abstract-input-with-unit.component';

@Component({
    selector: 'kiwi-percentage-input',
    templateUrl: './input-with-unit.component.html',
    styleUrls: ['./input-with-unit.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PercentageInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PercentageInputComponent),
            multi: true
        }
    ]
})

export class PercentageInputComponent extends AbstractInputWithUnitComponent {
    constructor() {
        super('%', 0.01);
    }

    writeValue(obj: number) {
        if (obj != null) {
            this.data = obj * 100;
        } else {
            this.data = obj;
        }
    }


    onChange(value: number) {
        this.data = value;
        this.onNgChange(value/100);
    }
}

import {Injectable} from '@angular/core';
import {AbstractMarkUpdaterService} from '../../marks/abstract-mark-updater.service';
import {AssignmentType} from '../../marks/assignement-type';

@Injectable({
  providedIn: 'root'
})
export class CommunicationMarkUpdaterService extends AbstractMarkUpdaterService {

  constructor() {
      super(AssignmentType.ADVERTISEMENT)
  }
}

import {ComponentFactoryResolver, Injectable} from '@angular/core';
import {ConfirmationPopupService} from "../../../confirmation-popup/confirmation-popup.service";
import {AreaConfirmationPopupComponent} from "./area-confirmation-popup/area-confirmation-popup.component";
import {forkJoin} from "rxjs";
import {map} from "rxjs/operators";
import {AreaDecision} from "./area-decision";

@Injectable({
  providedIn: 'root'
})
export class AreaConfirmationPopupService extends ConfirmationPopupService<AreaConfirmationPopupComponent>{
    constructor() {
        super(AreaConfirmationPopupComponent);
    }

    protected fillConfirmationPopupInstance(
        confirmationPopupInstance: AreaConfirmationPopupComponent,
        preparedPopup: AreaConfirmationPopupComponent
    ) {
        confirmationPopupInstance.chosenArea = preparedPopup.chosenArea;
        confirmationPopupInstance.chosenAreaImageClass = preparedPopup.chosenAreaImageClass;
    }

    showConfirmationPopup(
        chosenAreaLabel: string,
        confirmButtonAction: Function
    ) {
        let areaConfirmationPopupComponent = this.prepareConfirmationPopup(
            'decisions.area.popup.title',
            forkJoin(this.translateService.get('decisions.area.popup.confirmation.message.1'),
                this.translateService.get('decisions.area.popup.' + chosenAreaLabel),
                this.translateService.get('decisions.area.popup.confirmation.message.2'))
                .pipe(map(([message1, chosenArea, message2]) => { return message1 + chosenArea + message2})),
            confirmButtonAction
        );
        areaConfirmationPopupComponent.chosenArea = chosenAreaLabel;

        if(chosenAreaLabel === 'wealthy')
            areaConfirmationPopupComponent.chosenAreaImageClass = '_good';

        if(chosenAreaLabel === 'average')
            areaConfirmationPopupComponent.chosenAreaImageClass = '_medium';

        if(chosenAreaLabel === 'working.class')
            areaConfirmationPopupComponent.chosenAreaImageClass = '_bad';

        this.showPopup(areaConfirmationPopupComponent);
    }
}

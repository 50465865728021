import {Component} from '@angular/core';
import {ConfirmationPopupComponent} from "../../../../../confirmation-popup/confirmation-popup.component";

@Component({
    selector: 'kiwi-simulation-launch-popup',
    templateUrl: './simulation-launch-popup.component.html',
    styleUrls: ['./simulation-launch-popup.component.scss'],
    host: {'class': 'popupContainer'}
})
export class SimulationLaunchPopupComponent extends ConfirmationPopupComponent { // implements OnInit
    public period: string;
    public animatorTokensNumber: number;
    public simulationTokensCost: number;
    public simulationStatus: SimulationStatus = SimulationStatus.NOT_STARTED;
    public sessionIsDemonstration = false;
}

enum SimulationStatus {
    DONE = 'DONE',
    IN_PROGRESS = 'IN_PROGRESS',
    NOT_STARTED = 'NOT_STARTED'
}

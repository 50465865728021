import { Injectable } from '@angular/core';
import {AbstractSessionParametersUpdaterService} from '../../services/abstract-session-parameters-updater-service';
import {PerceivedQualityParameters} from './perceived-quality-parameters';

@Injectable({
  providedIn: 'root'
})
export class PerceivedQualityParametersUpdaterService extends AbstractSessionParametersUpdaterService<PerceivedQualityParameters>{

  constructor() {
      super('perceivedQuality');
  }
}

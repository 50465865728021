import {ComputeAbstractTypeRestaurantQuality} from "./compute-abstract-type-restaurant-quality";
import {StaffDecision} from "./staff-decision";

export abstract class ComputeHierarchyRestaurantQuality extends ComputeAbstractTypeRestaurantQuality {
    protected constructor(
        standardCoversNumber: number,
        previousPeriodCoversNumber: number,
        standardQuality: number,
        staffDecision: StaffDecision,
        standardCookWageBillStandardQuality: number,
        standardWaiterWageBillStandardQuality: number,
        maximalCookStaffQuality: number,
        maximalWaiterStaffQuality: number) {
        super(
            standardCoversNumber,
            previousPeriodCoversNumber,
            standardQuality,
            staffDecision,
            standardCookWageBillStandardQuality,
            standardWaiterWageBillStandardQuality,
            maximalCookStaffQuality,
            maximalWaiterStaffQuality
        );
    }

    protected abstract computeCooksHierarchyQuality(): number;

    protected abstract computeWaitersHierarchyQuality(): number;

    protected computeHierarchyReport(
        higherHierarchyLevelNumber: number, lowerHierarchyLevelNumber: number
    ): number {
        if (higherHierarchyLevelNumber < 1) {
            return 99999;
        }

        return lowerHierarchyLevelNumber / higherHierarchyLevelNumber;
    }

    protected computeHierarchyQualityBetweenTwoLevels(
        higherHierarchyLevelNumber: number, lowerHierarchyLevelNumber: number
    ): number {
        let hierarchyReport = this.computeHierarchyReport(
            higherHierarchyLevelNumber,
            lowerHierarchyLevelNumber
        );

        if (hierarchyReport <= 2) {
            return 80;
        }

        if (hierarchyReport <= 12) {
            return 96 - (8 * hierarchyReport)
        }
        return 0;
    }

    computeCookingQuality(): number {
        return ComputeHierarchyRestaurantQuality.computeTotalQuality(
            this.computeCookStaffQuality(),
            this.computeCooksHierarchyQuality());
    }

    computeCustomerCareQuality(): number {
        return ComputeHierarchyRestaurantQuality.computeTotalQuality(
            this.computeWaiterStaffQuality(),
            this.computeWaitersHierarchyQuality());
    }

    private static computeTotalQuality(
        staffQuality: number,
        hierarchyQuality: number
    ): number {
        return Number.parseFloat(Math.sqrt(staffQuality * hierarchyQuality).toFixed(2));
    }
}

import {Component} from '@angular/core';
import {PeriodsLoaderService} from '../../../app-main/periods-loader.service';
import {Context} from '../../../context/context';
import {Observable} from 'rxjs';
import {AbstractPeriodsSelectorComponent} from '../abstract-periods-selector.component';

@Component({
    selector: 'kiwi-all-periods-selector',
    templateUrl: '../abstract-periods-selector.component.html',
})
export class AllPeriodsSelectorComponent extends AbstractPeriodsSelectorComponent {
    constructor(
        private readonly periodsLoaderService: PeriodsLoaderService
    ) {
        super();
    }

    retrievePeriods(context: Context): Observable<string[]> {
        return this.periodsLoaderService.retrievePeriods(context);
    }

    computeSelectedPeriod(context: Context): string {
        return context.currentPeriod;
    }
}

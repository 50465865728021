import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true
        }
    ]
})
export class CheckboxComponent {
    @Input()
    public disabled: boolean;

    @Input()
    public checked: boolean;

    @Output()
    public valueChanged = new EventEmitter<boolean>();

    public onCheckedChange(checked: boolean): void {
        this.valueChanged.emit(checked);
        this.checked = checked;
    }
}

import {Component} from '@angular/core';
import {AbstractStudyComponent} from "../../abstract-study.component";
import {CategoryName} from "../../category-name";
import {StudiesNames} from "../../studies-names";
import {of} from "rxjs";
import {ActivatedRoute} from "@angular/router";

class ProfitLossAccounts {
    constructor(
        public readonly dishesTurnover: number,
        public readonly winesTurnover: number,
        public readonly otherOperatingProducts: number,
        public readonly totalOperatingProducts: number,

        public readonly ingredientsPurchaseCosts: number,
        public readonly wineBottlesPurchaseCosts: number,
        public readonly cellarStockVariation: number,
        public readonly externalLoads: number,
        public readonly grossSalaries: number,
        public readonly severancePay: number,
        public readonly socialCharges: number,
        public readonly depreciationCharges: number,
        public readonly totalOperatingCharges: number,

        public readonly totalOperatingResult: number,

        public readonly investmentInterest: number,
        public readonly otherFinancialProducts: number,
        public readonly totalFinancialProducts: number,

        public readonly loanInterest: number,
        public readonly overdraftCharges: number,
        public readonly otherFinancialCharges: number,
        public readonly totalFinancialCharges: number,

        public readonly continuingOperationsResult: number,

        public readonly layoutSellingIncome: number,
        public readonly otherExceptionalIncomes: number,
        public readonly totalExceptionalIncome: number,

        public readonly layoutPurchasingCost: number,
        public readonly otherNonRecurringCharges: number,
        public readonly totalNonRecurringCharges: number,

        public readonly exceptionalResult: number,

        public readonly netPreTaxResult: number,
        public readonly incomeTax: number,
        public readonly netResult: number,
    ) {}
}

@Component({
    selector: 'kiwi-profit-loss-accounts',
    templateUrl: './profit-loss-accounts.component.html',
    styleUrls: ['./profit-loss-accounts.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class ProfitLossAccountsComponent extends AbstractStudyComponent<ProfitLossAccounts> {
    constructor(private route: ActivatedRoute) {
        super(CategoryName.SYNTHESIS, of(StudiesNames.PROFIT_LOSS_ACCOUNTS), true, route.params);
    }
}

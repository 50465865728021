import { Injectable } from '@angular/core';
import {AbstractPeriodParametersLoaderService} from '../../../parameters/services/abstract-period-parameters-loader.service';
import {StaffMarksWeights} from './staff-marks-weights';

@Injectable({
  providedIn: 'root'
})
export class StaffMarksWeightsLoaderService extends AbstractPeriodParametersLoaderService<StaffMarksWeights>{

  constructor() {
      super('assignments/staff')
  }
}

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-introduction',
    templateUrl: './introduction.component.html',
    styleUrls: ['./introduction.component.scss'],
    host: {'class': 'cell auto pageContainer grid-y'}
})

export class IntroductionComponent implements OnInit {

    public currentStep = 0;
    public services = ['generalities', 'positioning', 'human-resources', 'rooms', 'menus'];
  constructor() { }

  ngOnInit() {

  }

  public setStep(index) {
        this.currentStep = index;
  }

}

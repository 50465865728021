import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'kiwi-parameters-tab-content-header',
    templateUrl: './parameters-tab-content-header.component.html',
    styleUrls: ['./parameters-tab-content-header.component.scss'],
    host: {class: 'cell shrink'}
})
export class ParametersTabContentHeaderComponent {
    @Input()
    titleLabelKey = '';

    @Input()
    saveDisabled = false;

    @Output()
    saved = new EventEmitter<boolean>();

    onSave(): void {
        this.saved.emit(true);
    }
}

import { Injectable } from '@angular/core';
import {AbstractTeamResultLoader} from "../../abstract-team-result-loader";

@Injectable({
  providedIn: 'root'
})
export class EffectiveMaintenanceBudgetLoaderService extends AbstractTeamResultLoader<number>{

  constructor() { super('quality/effectiveMaintenanceBudgetPerRoom') }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EvaluationsComponent} from "./evaluations.component";
import {EvaluationsMenuComponent} from "./evaluations-menu/evaluations-menu.component";
import {TranslateModule} from "@ngx-translate/core";
import {EvaluationsRoutingModule} from "./evaluations-routing.module";
import {EvaluationsGeneralInformationComponent} from './evaluations-general-information/evaluations-general-information.component';
import {MarksComponent} from './generic-evaluation/marks/marks.component';
import {EvaluationSeverityComponent} from './generic-evaluation/evaluation-severity/evaluation-severity.component';
import {ComponentsModule} from "../../components/components.module";
import {FormsModule} from "@angular/forms";
import {PipesModule} from "../../pipes/pipes.module";
import { GenericEvaluationComponent } from './generic-evaluation/generic-evaluation.component';
import {TabsModule} from "../tabs/tabs.module";
import { TreasuryMarkCriteriaComponent } from './criteria/treasury-mark-criteria/treasury-mark-criteria.component';
import { ProfitabilityMarkCriteriaComponent } from './criteria/profitability-mark-criteria/profitability-mark-criteria.component';
import { FinalMarkCriteriaComponent } from './criteria/final-mark-criteria/final-mark-criteria.component';
import { NotationInformationComponent } from './generic-evaluation/evaluation-severity/notation-information/notation-information.component';
import { NotationInformationMarkComponent } from './generic-evaluation/evaluation-severity/notation-information/notation-information-mark/notation-information-mark.component';
import {SaveBeforeDeactivatePopupComponent} from '../../components/save-before-deactivate-popup/save-before-deactivate-popup.component';
import { CustomerSatisfactionCriteriaComponent } from './criteria/customer-satisfaction-criteria/customer-satisfaction-criteria.component';
import { FinalMarkMarksComponent } from './final-mark-marks/final-mark-marks.component';
import { TreasuryCriteriaDaysNumberValidatorDirective } from './criteria/treasury-mark-criteria/treasury-criteria-days-number-validator.directive';
import { ProfitabilityCriteriaRatesValidatorDirective } from './criteria/profitability-mark-criteria/profitability-criteria-rates-validator.directive';

@NgModule({
    declarations: [
        EvaluationsComponent,
        EvaluationsMenuComponent,
        EvaluationsGeneralInformationComponent,
        MarksComponent,
        EvaluationSeverityComponent,
        GenericEvaluationComponent,
        TreasuryMarkCriteriaComponent,
        ProfitabilityMarkCriteriaComponent,
        FinalMarkCriteriaComponent,
        NotationInformationComponent,
        NotationInformationMarkComponent,
        CustomerSatisfactionCriteriaComponent,
        FinalMarkMarksComponent,
        TreasuryCriteriaDaysNumberValidatorDirective,
        ProfitabilityCriteriaRatesValidatorDirective
    ],
    imports: [
        CommonModule,
        TranslateModule,
        EvaluationsRoutingModule,
        ComponentsModule,
        FormsModule,
        PipesModule,
        TabsModule
    ],
    entryComponents: [
        SaveBeforeDeactivatePopupComponent
    ]

})
export class EvaluationsModule {

}

import {Component} from '@angular/core';
import {of} from 'rxjs';
import {AbstractStudyComponent} from '../../abstract-study.component';
import {StudiesNames} from '../../studies-names';
import {CategoryName} from '../../category-name';

class SalesDetail {
    constructor(
        public readonly aLaCarteSales: number,
        public readonly firstCourseMainCourseMenuSales: number,
        public readonly mainCourseDessertMenuSales: number,
        public readonly fullCourseSales: number,
        public readonly coversNumber: number,

        public readonly winesSales: Object,
        public readonly totalWinesSales: number,

        public readonly firstCoursesTotalSales: Object,
        public readonly mainCoursesTotalSales: Object,
        public readonly dessertsTotalSales: Object,
        public readonly totalDishesSales: number,

        public readonly firstCoursesALaCarteSales: Object,
        public readonly mainCoursesALaCarteSales: Object,
        public readonly dessertsALaCarteSales: Object,
        public readonly totalALaCarteSales: number
    ) {}
}

@Component({
    selector: 'kiwi-sales-details',
    templateUrl: './sales-details.component.html',
    styleUrls: ['./sales-details.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class SalesDetailsComponent extends AbstractStudyComponent<SalesDetail> {

    constructor() {
        super(CategoryName.SALES_DETAILS, of(StudiesNames.SALES_DETAILS));
    }
}

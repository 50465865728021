import {Injectable} from '@angular/core';
import {AbstractSessionParametersLoaderService} from "../abstract-session-parameters-loader.service";
import {StandingMenuParameters} from "./standing-menu-parameters";

@Injectable({
    providedIn: 'root'
})
export class StandingMenuParametersLoaderService extends AbstractSessionParametersLoaderService<StandingMenuParameters> {

    constructor() {
        super('standing/menu');
    }
}

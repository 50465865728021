import {Component} from '@angular/core';
import {TeamsDecisionsLoaderService} from "./teams-decisions-loader.service";
import {HttpClient} from "@angular/common/http";
import {TeamDecisionsClosureService} from "../../team-decisions-closure.service";
import {ContextService} from "../../../../context/context.service";
import {Team} from "../../../../login/team";
import {TeamsLoaderService} from "./teams-loader.service";
import {Context} from "../../../../context/context";

@Component({
    selector: 'kiwi-animator-decisions-closure',
    templateUrl: './animator-decisions-closure.component.html',
    styleUrls: ['./animator-decisions-closure.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class AnimatorDecisionsClosureComponent {
    private sessionId: string;
    private period: string;
    private teamsDecisions: Object;
    private teams: Team[];

    constructor(
        private httpClient: HttpClient,
        private contextService: ContextService,
        private teamsDecisionsLoaderService: TeamsDecisionsLoaderService,
        private teamsLoaderService: TeamsLoaderService,
        private teamDecisionsClosureService: TeamDecisionsClosureService
    ) {
    }

    ngOnInit() {
        this.contextService.context.subscribe(context => {
            if(!!context && !!context.currentPeriod) {
                this.sessionId = context.currentSessionId;
                this.period = context.currentSession.currentPeriod;
                this.loadTeamsDecisions();
                this.loadTeams(context);
            }
        });
    }

    loadTeamsDecisions() {
        this.teamsDecisionsLoaderService.loadTeamsDecisions(this.sessionId, this.period)
            .subscribe(teamsDecisions => {
                this.teamsDecisions = teamsDecisions
            });
    }

    loadTeams(context: Context) {
        this.teamsLoaderService.loadTeams(context)
            .subscribe(teams => this.teams = teams);
    }

    closeAllDecisions() {
        this.httpClient.put<Object>('/api/sessions/' + this.sessionId + '/periods/' + this.period + '/teamDecisions/finalize', null)
            .subscribe(() => {
                this.loadTeamsDecisions()
            });
    }

    closeDecision(teamNumber: number) {
        this.teamDecisionsClosureService.insertClosureStatus(
            this.sessionId,
            this.period,
            teamNumber,
            'ANIMATOR_CLOSURE'
        ).subscribe(() => this.loadTeamsDecisions());
    }

    openDecision(teamNumber: number, openStatus: string) {
        this.teamDecisionsClosureService.updateOpenStatus(
            this.sessionId,
            this.period,
            teamNumber,
            openStatus
        ).subscribe(() => this.loadTeamsDecisions());
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AbstractDecisionUpdaterService} from "../abstract-decision-updater.service";
import {NotificationService} from "../../../notification.service";

@Injectable({
    providedIn: 'root'
})
export class ArchetypeUpdaterService extends AbstractDecisionUpdaterService<string> {

    constructor() {
        super('archetype')
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Context} from "../../context/context";
import {Observable} from "rxjs";
import {Person, User} from "../../login/user";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class AnimatorsLoaderService {

    constructor(
        private readonly httpClient: HttpClient
    ) {
    }

    public loadMainAnimator(context: Context): Observable<User> {
        return this.httpClient.get<User>('/api/sessions/' + context.currentSessionId + '/mainAnimator');
    }

    public loadCoAnimators(context: Context): Observable<User[]> {
        return this.httpClient.get<User[]>('/api/sessions/' + context.currentSessionId + '/coAnimators');
    }
}

import {Component, Input} from '@angular/core';

@Component({
    selector: 'kiwi-notation-information-mark',
    templateUrl: './notation-information-mark.component.html',
    styleUrls: ['./notation-information-mark.component.scss']
})
export class NotationInformationMarkComponent {
    public displayedMark: string;

    @Input()
    set mark(mark: number) {
        this.displayedMark = mark.toFixed(2);
    }

    @Input()
    labelKey: string;

    @Input()
    maximumMark: number
}

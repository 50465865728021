import { Injectable } from '@angular/core';
import {AbstractSessionParametersLoaderService} from "../../../parameters/services/abstract-session-parameters-loader.service";
import {TreasuryEvaluationParameters} from "./treasury-evaluation-parameters";

@Injectable({
  providedIn: 'root'
})
export class TreasuryEvaluationParametersLoaderService extends AbstractSessionParametersLoaderService<TreasuryEvaluationParameters>{

  constructor() { super('evaluations/TREASURY_MARK/parameters') }
}

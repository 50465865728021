import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {NotificationService} from "../../notification.service";
import {isString} from "util";
import {LocatorService} from "../../locator.service";

@Injectable({
    providedIn: 'root'
})
export class AbstractDecisionUpdaterService<T> {
    private readonly httpClient: HttpClient;
    private readonly notificationService: NotificationService;

    constructor(
        private decisionType: string,
    ) {
        this.httpClient = LocatorService.injector.get(HttpClient);
        this.notificationService = LocatorService.injector.get(NotificationService);
    }

    saveDecision(session: string, period: string, teamNumber: number, decision: T): Observable<T> {
        let decisionSaveObservable: Observable<T>;
        const url = '/api/sessions/' + session +
            '/periods/' + period +
            '/teamDecisions/' + teamNumber +
            '/' + this.decisionType;

        if(isString(decision))
            decisionSaveObservable = this.httpClient.put<T>(
                url + '/' + decision,
                null);
        else
            decisionSaveObservable = this.httpClient.put<T>(
                url,
                decision
            );

        return decisionSaveObservable;
    }
}

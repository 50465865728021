import {Component, Input} from '@angular/core';
import {Color, Label} from 'ng2-charts';
import {ChartOptions, ChartType} from 'chart.js';

@Component({
    selector: 'kiwi-chart',
    templateUrl: './chart.component.html'
})
export class ChartComponent {
    @Input()
    public titleKey: string;

    @Input()
    public labelKey: string;

    @Input()
    public barChartLabels: Label[] = [];

    @Input()
    public barChartData: number[] = [];

    public barChartOptions: ChartOptions =
        {
            legend: {
                labels: {
                    fontColor: 'white',
                    fontSize: 14
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: 'white',
                        fontSize: 14
                    },
                    gridLines: {
                        color: 'rgba(255,255,255,0.1)'
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontColor: 'white',
                        fontSize: 14,
                        min: 0
                    },
                    gridLines: {
                        color: 'rgba(255,255,255,0.1)',
                        zeroLineColor: 'rgba(255,255,255,0.1)'
                    }
                }]
            }
        };

    @Input()
    public set options(options) {
        for (const optionKey in options) {
            this.barChartOptions[optionKey] = options[optionKey];
        }
    }

    public barChartType: ChartType = 'bar';
    public chartColors: Array<Color> = [
        {
            backgroundColor: 'rgba(250, 240, 220, 0.6)'
        }];
    public barChartLegend = true;
    public barChartPlugins = [];
}

import {Injectable} from '@angular/core';
import {AbstractMarkLoaderService} from '../../marks/abstract-mark-loader.service';
import {AssignmentType} from '../../marks/assignement-type';

@Injectable({
  providedIn: 'root'
})
export class AnimatorMenuMarkLoaderService extends AbstractMarkLoaderService {
  constructor() {
      super(AssignmentType.INGREDIENTS_PURCHASES);
  }
}

import {EventEmitter, Output} from '@angular/core';
import {AbstractPopupComponent} from "../abstract-popup-component";
import {Observable} from "rxjs";

export class ConfirmationPopupComponent extends AbstractPopupComponent {
    message: string | Observable<string>;
    confirmButtonMessage: string;
    cancelButtonMessage: string;
    title: string;

    @Output()
    confirmed = new EventEmitter();

    cancel() {
        this.close();
    }

    confirm() {
        this.confirmed.emit();
        this.close();
    }
}

import {Component} from '@angular/core';
import {SalesPricesLoaderService} from "../../../team-decisions/menu-decision/sales-prices/sales-prices-loader.service";
import {ContextService} from "../../../../context/context.service";
import {filter, switchMap} from "rxjs/operators";
import {CategoryName} from "../../category-name";
import {StudiesNames} from "../../studies-names";
import {Observable, of} from "rxjs";
import {MenuSalesPrices} from "../../../team-decisions/menu-decision/sales-prices/menu-sales-prices";
import {StudyTabParentComponent} from '../study-tab-parent/study-tab-parent.component';
import {Context} from '../../../../context/context';

@Component({
    templateUrl: '../study-tab-parent/study-tab-parent.component.html',
    styleUrls: ['../study-tab-parent/study-tab-parent.component.scss'],
    host: {'class': 'cell auto pageContainer grid-y'}
})
export class DishesSalesPricesComparisonComponent extends StudyTabParentComponent<MenuSalesPrices> {
    constructor(private salesPricesLoaderService: SalesPricesLoaderService) {
        super(of([]), StudiesNames.SALES_PRICES_COMPARISON);
    }

    protected fillTabs(study: MenuSalesPrices): void {
        this.fillTabsWith(study.firstCoursesSalesPrices);
        this.fillTabsWith(study.meatDishesSalesPrices);
        this.fillTabsWith(study.fishDishesSalesPrices);
        this.fillTabsWith(study.dessertsSalesPrices);
        this.fillTabsWith(study.winesSalesPrices);
        this.fillTabsWith({'FIRST_COURSE_MAIN_COURSE': 0, 'MAIN_COURSE_DESSERT': 0, 'FULL_COURSE': 0});
    }

    protected loadStudy(context: Context): Observable<MenuSalesPrices> {
        return this.salesPricesLoaderService.loadDecisionFromContext(context)
    }
}

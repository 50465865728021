import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-evaluations-general-information',
  templateUrl: './evaluations-general-information.component.html',
  styleUrls: ['./evaluations-general-information.component.scss'],
    host: {'class': 'cell auto grid-y cell-block-y blockContent'}
})
export class EvaluationsGeneralInformationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import {StudiesNames} from "../studies-names";
import {StudyRouteDefinition} from '../study-route-definition';
import {CommunicationStudyComponent} from '../communication-study/communication-study.component';

export const communicationStudyChildrenRoutes: StudyRouteDefinition[] = [
    new StudyRouteDefinition(
        StudiesNames.COMMUNICATION_PARAMETERS,
        [{path: '', component: CommunicationStudyComponent}]
    )
];

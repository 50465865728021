import {Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {flatMap, map, take} from 'rxjs/operators';
import {GuardResult} from '../team-decisions/guards/guard-result';
import {ContextService} from '../../context/context.service';
import {HttpClient} from '@angular/common/http';
import {ConnectedUserService} from '../../context/connected-user.service';

@Injectable({
    providedIn: 'root'
})
export class CanActivateSchedulePopupGuard {
    constructor(private readonly contextService: ContextService,
                private readonly connectedUserService: ConnectedUserService,
                private readonly httpClient: HttpClient) {
    }

    canActivate(): Observable<boolean> {
        return combineLatest(
            this.contextService.context,
            this.connectedUserService.isSimulationActivated(),
            this.connectedUserService.isRevertActivated()
        ).pipe(
            take(1),
            flatMap(([context, isSimulationActivated, isRevertActivated]) => {
                if (isSimulationActivated || isRevertActivated) {
                    return of(false);
                } else {
                    if (!!context && !!context.currentSessionId) {
                        return this.httpClient.get<GuardResult>('api/sessions/' + context.currentSessionId + '/canActivateSchedule')
                            .pipe(map(guardResult => guardResult.canActivate));
                    } else {
                        return of(false);
                    }
                }
            }),
        );
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Context} from '../../../context/context';
import {TeamsTotalMarks} from './teams-total-marks.model';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TeamsTotalMarksLoaderService {

    constructor(private readonly httpClient: HttpClient) {
    }

    public loadTeamsTotalMarks(context: Context): Observable<TeamsTotalMarks> {
        return this.httpClient.get<TeamsTotalMarks>(`/api/sessions/${context.currentSessionId}/periods/${context.currentPeriod}/evaluations/FINAL_MARK/marks`);
    }
}

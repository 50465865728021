import {Injectable} from '@angular/core';
import {AbstractDecisionLoaderService} from "../../abstract-decision-loader.service";
import {FinancialDecision} from "../financial-decision";

@Injectable({
    providedIn: 'root'
})
export class FinancialDecisionLoaderService extends AbstractDecisionLoaderService<FinancialDecision> {

    constructor() {
        super('financial');
    }
}

import {Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractInputWithUnitComponent} from './abstract-input-with-unit.component';

@Component({
    selector: 'kiwi-currency-input',
    templateUrl: './input-with-unit.component.html',
    styleUrls: ['./input-with-unit.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CurrencyInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CurrencyInputComponent),
            multi: true
        }
    ]
})
export class CurrencyInputComponent extends AbstractInputWithUnitComponent {
    constructor() {
        super('common.change.euros', 0.01);
    }
}

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'kiwi-tools',
    templateUrl: './tools.component.html',
    styleUrls: ['./tools.component.scss'],
    host: {'class': 'cell auto pageContainer grid-y'}
})
export class ToolsComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}

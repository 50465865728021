import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {NotificationService} from '../notification.service';
import {LocatorService} from '../locator.service';
import {ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';

export abstract class AbstractModificationComponent<T> {
    public dirty = false;
    protected readonly notificationService: NotificationService;

    protected constructor(
        private readonly saveSuccessTitleKey: string = '',
        private readonly saveSuccessMessageKey: string = ''
    ) {
        this.notificationService = LocatorService.injector.get(NotificationService);
    }

    getForm(): NgForm {
        return null;
    }

    private callSaveAndResetDirty(): Observable<T> {
        return this.callSave().pipe(
            tap(_ => this.dirty = false)
        );
    }

    public callSaveAndResetDirtyAndDisplayNotifications(): Observable<T> {
        return this.callSave().pipe(
            tap(_ => this.dirty = false),
            tap(() => this.onSuccessfulSave(),
                err => this.notificationService.error(err.error.labelKey))
        );
    }

    public save(): void {
        this.callSaveAndResetDirtyAndDisplayNotifications().subscribe();
    }

    protected onSuccessfulSave() {
        this.notificationService.success(this.saveSuccessTitleKey, this.saveSuccessMessageKey);
    }

    protected abstract callSave(): Observable<T>;

    public isFormInvalid(): boolean {
        const form = this.getForm();
        return form && form.invalid;
    }

    onValueUpdate() {
        this.dirty = true;
    }
}

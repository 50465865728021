import {Component} from '@angular/core';
import {GenericSessionParametersComponent} from '../../generic-parameters/generic-session-parameters.component';
import {StandingParameters} from '../../services/standing/standing-parameters';
import {StandingParametersLoaderService} from '../../services/standing/standing-parameters-loader.service';
import {StandingParametersUpdaterService} from './standing-parameters-updater.service';
import {genericParametersHost} from '../../generic-parameters/generic-parameters.component';

@Component({
    templateUrl: './global-standing-parameters.component.html',
    styleUrls: [
        './../../generic-parameters/generic-parameters.component.scss',
        './global-standing-parameters.component.scss'
    ],
    host: genericParametersHost
})
export class GlobalStandingParametersComponent extends GenericSessionParametersComponent<StandingParameters> {

    constructor(standingParametersLoaderService: StandingParametersLoaderService,
                standingParametersUpdaterService: StandingParametersUpdaterService) {
        super(standingParametersLoaderService, standingParametersUpdaterService, 'toast.parameters.quality.standing.global');
    }
}

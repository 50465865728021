import {Injectable} from '@angular/core';
import {AbstractDecisionUpdaterService} from "../../abstract-decision-updater.service";
import {MenuDishesPurchases} from "./menu-dishes-purchases";

@Injectable({
    providedIn: 'root'
})
export class MenuDishesPurchasesUpdaterService extends AbstractDecisionUpdaterService<MenuDishesPurchases> {

    constructor() {
        super('menuDishesPurchases');
    }
}

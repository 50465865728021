import { Injectable } from '@angular/core';
import {AbstractSessionParametersLoaderService} from "../abstract-session-parameters-loader.service";
import {StaffQualityParameters} from "./staff-quality-parameters";

@Injectable({
  providedIn: 'root'
})
export class StaffQualityParametersLoaderService extends AbstractSessionParametersLoaderService<StaffQualityParameters>{

  constructor() { super('quality/staff') }
}

import { Injectable } from '@angular/core';
import {AbstractPeriodParametersUpdaterService} from '../../services/abstract-period-parameters-updater-service';
import {DiningRoomsAndRentParameters} from './dining-rooms-and-rent-parameters';

@Injectable({
  providedIn: 'root'
})
export class DiningRoomsAndRentParametersUpdaterService extends AbstractPeriodParametersUpdaterService<DiningRoomsAndRentParameters>{

  constructor() {
      super('diningRoomsAndRent')
  }
}

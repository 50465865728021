import {Component} from '@angular/core';
import {CategoryName} from '../category-name';
import {StudiesNames} from '../studies-names';
import {of} from 'rxjs';
import {AbstractParametersStudy} from '../abstract-parameters-study.component';
import {StandingParameters} from '../../parameters/services/standing/standing-parameters';
import {StandingParametersLoaderService} from '../../parameters/services/standing/standing-parameters-loader.service';
import {ContextService} from '../../../context/context.service';

@Component({
    selector: 'kiwi-human-resources',
    templateUrl: './human-resources-parameters.component.html',
    styleUrls: ['./human-resources-parameters.component.scss'],
})
export class HumanResourcesParametersComponent extends AbstractParametersStudy {
    limitBetweenType1AndType2: number;
    limitBetweenType2AndType3: number;

    constructor(
        private readonly standingParametersLoaderService :StandingParametersLoaderService
    ) {
        super(CategoryName.HUMAN_RESOURCES_PARAMETERS, of(StudiesNames.HUMAN_RESOURCES_PARAMETERS));
    }


    ngOnInit() {
        super.ngOnInit();
        const context = this.contextService.context.getValue();
        this.standingParametersLoaderService.loadParameters(context)
            .subscribe(standingParameters => {
                this.limitBetweenType1AndType2 = standingParameters.limitBetweenType1AndType2;
                this.limitBetweenType2AndType3 = standingParameters.limitBetweenType2andType3;
            })
    }
}

import {MenuDecision} from "../menu-decision";

export class MenuDishesPurchases {
  constructor(
    public firstCoursesPurchases: Object,
    public meatDishesPurchases: Object,
    public fishDishesPurchases: Object,
    public dessertsPurchases: Object,

    public readonly minDishQuality: number,
    public readonly maxDishQuality: number
  ) {}

  static fromMenuDecision(menuDecision: MenuDecision): MenuDishesPurchases {
    return new MenuDishesPurchases(
      this.convertDishesObjectToPurchaseObject(menuDecision.firstCourses),
      this.convertDishesObjectToPurchaseObject(menuDecision.meatDishes),
      this.convertDishesObjectToPurchaseObject(menuDecision.fishDishes),
      this.convertDishesObjectToPurchaseObject(menuDecision.desserts),

      Object.values(menuDecision.firstCourses)[0].minQuality,
      Object.values(menuDecision.firstCourses)[0].maxQuality
    );
  }

  private static convertDishesObjectToPurchaseObject(dishes: Object): Object {
    let salesPrices = new Object();
    for(let entry of Object.entries(dishes))
      salesPrices[entry[0]] = {
        quality: entry[1].quality,
        purchasingPriceExcludingTax: entry[1].purchasingPriceExcludingTax
      };
    return salesPrices;
  }
}

import {Injectable} from '@angular/core';
import {AbstractPeriodParametersUpdaterService} from '../../services/abstract-period-parameters-updater-service';
import {ExternalLoadsParameters} from '../../services/period/external-loads/external-loads-parameters';

@Injectable({
    providedIn: 'root'
})
export class ExternalLoadsParametersUpdaterService extends AbstractPeriodParametersUpdaterService<ExternalLoadsParameters> {

    constructor() {
        super('externalLoads');
    }
}

import {Injectable} from '@angular/core';
import {AbstractSessionParametersLoaderService} from "../abstract-session-parameters-loader.service";
import {DiningRoomsParameters} from "./dining-rooms-parameters";

@Injectable({
    providedIn: 'root'
})
export class DiningRoomsParametersLoaderService extends AbstractSessionParametersLoaderService<DiningRoomsParameters> {

    constructor() {
        super('diningRooms')
    }

}

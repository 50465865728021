import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidationErrors} from '@angular/forms';

@Directive({
    selector: '[kiwiProfitabilityCriteriaRatesValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: ProfitabilityCriteriaRatesValidatorDirective, multi: true}]
})
export class ProfitabilityCriteriaRatesValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        const minimalMarkProfitabilityRate = control.get('minimalMarkProfitabilityRate');
        const averageMarkProfitabilityRate = control.get('averageMarkProfitabilityRate');
        const maximalMarkProfitabilityRate = control.get('maximalMarkProfitabilityRate');

        if (!minimalMarkProfitabilityRate || !averageMarkProfitabilityRate || !maximalMarkProfitabilityRate) {
            return null;
        }

        if (minimalMarkProfitabilityRate.value < averageMarkProfitabilityRate.value && averageMarkProfitabilityRate.value < maximalMarkProfitabilityRate.value) {
            return null;
        }

        return { rateOrder: true };
    }
}

import {Component, forwardRef, Input, ViewChild} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl, NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgModel,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {FormatNumberUtils} from './format-number-utils';

@Component({
    selector: 'kiwi-number-input',
    templateUrl: './input-with-unit/input-with-unit.component.html',
    styleUrls: ['./input-with-unit/input-with-unit.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true
        }
    ]
})

export class NumberInputComponent implements ControlValueAccessor, Validator {
    @Input()
    public disabled: boolean;

    @Input()
    max: number = undefined;

    @Input()
    min: number = undefined;

    @Input()
    name: string;

    public data: string | number;
    step: number = 1

    protected onNgChange: (value: number) => void;
    private onNgTouched: () => void;

    @ViewChild('input', {read: NgModel}) inputField: NgModel;

    registerOnValidatorChange(fn: () => void): void {
    }

    validate(control: FormControl): ValidationErrors | null {
        if (!this.inputField || this.inputField.valid) {
            return null;
        }

        return {'error': 'error'};
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    writeValue(obj) {
        this.data = obj;
    }

    onChange(value) {
        this.data = value;
        this.onNgChange(value);
    }
}

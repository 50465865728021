import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {User} from "../login/user";
import {PasswordChange} from "./password-change";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {NotificationService} from "../notification.service";

@Injectable({
    providedIn: 'root'
})
export class PasswordChangeService {

    constructor(
        private readonly httpClient: HttpClient,
        private readonly notificationService: NotificationService
    ) {
    }

    changePassword(userId: string, passwordChange: PasswordChange): Observable<boolean> {
        return this.httpClient.put<User>('/api/users/' + userId + '/password', passwordChange)
            .pipe(
                map(() => {
                        this.notificationService.success('toast.password.change.title', 'toast.password.change.success');
                        return true;
                    },
                    () => {
                        this.notificationService.error();
                        return false
                    })
            )
        ;
    }
}

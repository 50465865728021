export class SeverityDispersionParameters {
    constructor(
        public severity: number,
        public dispersion: number,

        private minSeverity: number,
        private maxSeverity: number,

        private minDispersion: number,
        private maxDispersion: number,

        public maxMark: number
    ) {
    }

    static buildNoMarksChangeParameters(severityDispersionParameters: SeverityDispersionParameters) {
        return new SeverityDispersionParameters(
            50,
            50,
            severityDispersionParameters.minSeverity,
            severityDispersionParameters.maxSeverity,
            severityDispersionParameters.minDispersion,
            severityDispersionParameters.maxDispersion,
            severityDispersionParameters.maxMark
        );
    }
}

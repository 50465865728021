import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SimulatedPeriod} from "./simulated-period";

@Injectable({
    providedIn: 'root'
})
export class SimulatedPeriodsLoaderService {

    constructor(
        private httpClient: HttpClient
    ) {
    }

    loadSimulatedPeriods(sessionId: string): Observable<SimulatedPeriod[]> {
      return this.httpClient.get<SimulatedPeriod[]>('/api/sessions/' + sessionId + '/simulatedPeriods');
    }

    loadSimulatedPeriod(sessionId: string, period: string): Observable<SimulatedPeriod> {
        return this.httpClient.get<SimulatedPeriod>('/api/sessions/' + sessionId + '/simulatedPeriods/' + period);
    }
}

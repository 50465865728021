import {AbstractPeriodParametersLoaderService} from '../services/abstract-period-parameters-loader.service';
import { Injectable } from '@angular/core';
import {StudiesPrices} from './studies-prices';

@Injectable({
    providedIn: 'root'
})
export class StudiesPricesLoaderService extends AbstractPeriodParametersLoaderService<StudiesPrices> {
    constructor() {
        super('studiesPrices');
    }
}

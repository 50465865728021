import {Component} from '@angular/core';
import {GenericSessionParametersComponent} from '../../generic-parameters/generic-session-parameters.component';
import {InitialBalanceSheetParameters} from '../../services/initial-balance-sheet/initial-balance-sheet-parameters';
import {InitialBalanceSheetParametersLoaderService} from '../../services/initial-balance-sheet/initial-balance-sheet-parameters-loader.service';
import {InitialBalanceSheetParametersUpdaterService} from '../../services/initial-balance-sheet/initial-balance-sheet-parameters-updater.service';
import {genericParametersHost} from '../../generic-parameters/generic-parameters.component';

@Component({
    templateUrl: './initial-balance-sheet-parameters.component.html',
    styleUrls: ['./../../generic-parameters/generic-parameters.component.scss'],
    host: genericParametersHost
})
export class InitialBalanceSheetParametersComponent extends GenericSessionParametersComponent<InitialBalanceSheetParameters> {
    constructor(
        initialBalanceSheetParametersLoaderService: InitialBalanceSheetParametersLoaderService,
        initialBalanceSheetParametersUpdaterService: InitialBalanceSheetParametersUpdaterService
    ) {
        super(initialBalanceSheetParametersLoaderService, initialBalanceSheetParametersUpdaterService, 'toast.parameters.financial.initial.balance.sheet.saved')
    }
}

import {Injectable} from '@angular/core';
import {ConfirmationPopupService} from "../../../confirmation-popup/confirmation-popup.service";
import {DecisionConfirmationPopupComponent} from "./decision-confirmation-popup/decision-confirmation-popup.component";
import {RestaurantTypeChange} from './restaurant-type-change.model';

@Injectable({
    providedIn: 'root'
})
export class DecisionConfirmationPopupService extends ConfirmationPopupService<DecisionConfirmationPopupComponent> {
    constructor() {
        super(DecisionConfirmationPopupComponent);
    }

    protected fillConfirmationPopupInstance(
        confirmationPopupInstance: DecisionConfirmationPopupComponent,
        preparedPopup: DecisionConfirmationPopupComponent
    ) {
        confirmationPopupInstance.restaurantTypeChange = preparedPopup.restaurantTypeChange;
    }

    public showConfirmationPopup(
        confirmButtonAction: Function,
        restaurantTypeChange: RestaurantTypeChange
    ): void {
        const decisionConfirmationPopup = this.prepareConfirmationPopup(
            '',
            '',
            confirmButtonAction
        );
        decisionConfirmationPopup.restaurantTypeChange = restaurantTypeChange;
        this.showPopup(decisionConfirmationPopup);
    }
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TeamDecisionsModule} from "./app-main/team-decisions/team-decisions.module";
import {LoginComponent} from "./login/login.component";
import {appMainChildrenRoutes} from "./app-main/app-main.module";
import {IsLoggedInGuard} from "./guard/is-logged-in.guard";
import {AppMainComponent} from "./app-main/app-main.component";
import {IntroductionComponent} from './app-main/introduction/introduction.component';

const appRoutes: Routes = [
    {
        path: 'public/intro/kiwi',
        component: IntroductionComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '',
        component: AppMainComponent,
        children: appMainChildrenRoutes,
        canActivateChild: [IsLoggedInGuard]
    },
    {
        path: '**',
        redirectTo: '/session/decisions',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes),
        TeamDecisionsModule
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {

}

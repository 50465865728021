import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {EvaluationsComponent} from "./evaluations.component";
import {
    EvaluationsGeneralInformationComponent
} from "./evaluations-general-information/evaluations-general-information.component";
import {MarksComponent} from "./generic-evaluation/marks/marks.component";
import {EvaluationSeverityComponent} from "./generic-evaluation/evaluation-severity/evaluation-severity.component";
import {GenericEvaluationComponent} from "./generic-evaluation/generic-evaluation.component";
import {CanDeactivateModificationComponentGuard} from '../guards/can-deactivate-modification-component.guard';
import {FinalMarkMarksComponent} from './final-mark-marks/final-mark-marks.component';
import {FinalMarkCriteriaComponent} from './criteria/final-mark-criteria/final-mark-criteria.component';
import {
    CustomerSatisfactionCriteriaComponent
} from './criteria/customer-satisfaction-criteria/customer-satisfaction-criteria.component';
import {TreasuryMarkCriteriaComponent} from './criteria/treasury-mark-criteria/treasury-mark-criteria.component';
import {
    ProfitabilityMarkCriteriaComponent
} from './criteria/profitability-mark-criteria/profitability-mark-criteria.component';

export const evaluationsRoutes: Routes = [
    {
        path: '',
        component: EvaluationsComponent,
        children: [
            {
                path: '',
                redirectTo: 'GENERAL_INFORMATION',
                pathMatch: 'full'
            },
            {
                path: 'GENERAL_INFORMATION',
                component: EvaluationsGeneralInformationComponent
            },
            {
                path: 'CUSTOMER_SATISFACTION_MARK',
                component: GenericEvaluationComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'marks',
                        pathMatch: 'full'
                    },
                    {
                        path: 'marks',
                        component: MarksComponent
                    },
                    {
                        path: 'severity',
                        component: EvaluationSeverityComponent,
                        canDeactivate: [CanDeactivateModificationComponentGuard]
                    },
                    {
                        path: 'criteria',
                        component: CustomerSatisfactionCriteriaComponent,
                        canDeactivate: [CanDeactivateModificationComponentGuard]
                    }
                ]
            },
            {
                path: 'TREASURY_MARK',
                component: GenericEvaluationComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'marks',
                        pathMatch: 'full'
                    },
                    {
                        path: 'marks',
                        component: MarksComponent
                    },
                    {
                        path: 'severity',
                        component: EvaluationSeverityComponent,
                        canDeactivate: [CanDeactivateModificationComponentGuard]
                    },
                    {
                        path: 'criteria',
                        component: TreasuryMarkCriteriaComponent,
                        canDeactivate: [CanDeactivateModificationComponentGuard]
                    }
                ]
            },
            {
                path: 'PROFITABILITY_MARK',
                component: GenericEvaluationComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'marks',
                        pathMatch: 'full'
                    },
                    {
                        path: 'marks',
                        component: MarksComponent
                    },
                    {
                        path: 'severity',
                        component: EvaluationSeverityComponent,
                        canDeactivate: [CanDeactivateModificationComponentGuard]
                    },
                    {
                        path: 'criteria',
                        component: ProfitabilityMarkCriteriaComponent,
                        canDeactivate: [CanDeactivateModificationComponentGuard]
                    }
                ]
            },
            {
                path: 'FINAL_MARK',
                component: GenericEvaluationComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'marks',
                        pathMatch: 'full'
                    },
                    {
                        path: 'marks',
                        component: FinalMarkMarksComponent
                    },
                    {
                        path: 'criteria',
                        component: FinalMarkCriteriaComponent,
                        canDeactivate: [CanDeactivateModificationComponentGuard]
                    }
                ]
            }
        ]
    }
];

@NgModule({
    exports: [RouterModule]
})
export class EvaluationsRoutingModule {
}

import {Injectable} from '@angular/core';
import {AbstractDecisionUpdaterService} from "../../abstract-decision-updater.service";
import {WinesPurchases} from "./wines-purchases";

@Injectable({
    providedIn: 'root'
})
export class WinesPurchasesUpdaterService extends AbstractDecisionUpdaterService<WinesPurchases> {

    constructor() {
        super('winesPurchases')
    }
}

import {Component} from '@angular/core';
import {AnimatorProductsAndCharges} from "./animator-products-and-charges";
import {Observable, of} from "rxjs";
import {Context} from "../../../../context/context";
import {Score} from "../../generics/score-image/score-image.component";
import {AnimatorProductsAndChargesDecisionLoaderService} from "./animator-products-and-charges-decision-loader.service";
import {AnimatorProductsAndChargesDecisionUpdaterService} from "./animator-products-and-charges-decision-updater.service";
import {AbstractDecisionComponent} from "../../abstract-decision-component";

@Component({
    selector: 'app-animator-products-and-charges-decision',
    templateUrl: './animator-products-and-charges-decision.component.html',
    styleUrls: ['./animator-products-and-charges-decision.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class AnimatorProductsAndChargesDecisionComponent extends AbstractDecisionComponent<AnimatorProductsAndCharges, any> {

    constructor(
        animatorProductsAndChargesLoaderService: AnimatorProductsAndChargesDecisionLoaderService,
        animatorProductsAndChargesUpdaterService: AnimatorProductsAndChargesDecisionUpdaterService
    ) {
        super(
            animatorProductsAndChargesLoaderService,
            animatorProductsAndChargesUpdaterService,
            [],
            'toast.financial.decision.animator.products.and.charges.message'
        );
    }

    protected computeDecisionQuality(scores: Score[], parameters: any): number {
        return 0;
    }

    protected loadParameters(context: Context): Observable<any> {
        return of(undefined);
    }

    protected updateScores(decision: AnimatorProductsAndCharges, parameters: any): void {
    }
}

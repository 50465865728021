import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CategoryName} from "../../../category-name";
import {map} from "rxjs/operators";
import {StudiesNames} from "../../../studies-names";
import {AbstractStudyComponent} from "../../../abstract-study.component";
import {CompetitiveIntelligenceStudy} from "../../competitive-intelligence-study";

@Component({
    selector: 'kiwi-dish-quality-comparison',
    templateUrl: './dish-quality-comparison.component.html',
    styleUrls: ['./dish-quality-comparison.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class DishQualityComparisonComponent extends AbstractStudyComponent<CompetitiveIntelligenceStudy>{
    constructor(route: ActivatedRoute) {
        super(CategoryName.COMPETITIVE_INTELLIGENCE,
            route.paramMap.pipe(
                map(paramMap => {
                    return StudiesNames.DISHES_QUALITY_COMPARISON + '/' + paramMap.get('dishName')
                })
            ));
    }
}

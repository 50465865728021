import { Injectable } from '@angular/core';
import {AbstractSessionParametersLoaderService} from '../../services/abstract-session-parameters-loader.service';
import {VATParameters} from '../../services/vat/vat-parameters';

@Injectable({
  providedIn: 'root'
})
export class VatParametersLoaderService extends AbstractSessionParametersLoaderService<VATParameters>{

  constructor() {
      super('VAT');
  }
}

import {Team} from "../login/team";
import {Session} from "../login/session";
import {RestaurantStandingInformation} from './restaurant-standing-information';

export class Context {
  constructor(
    public readonly currentSessionId: string,
    public readonly currentSessionName: string,
    public readonly currentMetaScenarioId: string,
    public currentPeriod: string,
    public readonly currentTeam: Team,
    public readonly currentSession: Session,
    public readonly currentRestaurantStandingInformation: RestaurantStandingInformation,
    public readonly currentLanguage: string,
    public readonly currentTeamArea: string
  ) {}
}

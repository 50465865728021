import {Component} from '@angular/core';
import {AbstractStudyComponent} from '../../abstract-study.component';
import {CategoryName} from '../../category-name';
import {StudiesNames} from '../../studies-names';
import {forkJoin, of} from 'rxjs';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {PeriodUtils} from '../../study-header/period-utils';
import {TranslateService} from '@ngx-translate/core';
import {ChartUtils} from '../../chart-utils';

class SalesHistory {
    constructor(public menusSalesHistories: ChartDataSets[],
                public periods: string[]
    ) {

    }
}

@Component({
    templateUrl: './sales-history.component.html',
    styleUrls: ['./sales-history.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class SalesHistoryComponent extends AbstractStudyComponent<SalesHistory> {
    public chartOptions: ChartOptions =
        {
            legend: {
                labels: {
                    fontSize: 14
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontSize: 14
                    }
                }],
                yAxes: [{
                    ticks: {
                        min: 0,
                        fontSize: 14
                    }
                }]
            }
        };

    public colorsRgbCodes: string[] = [
        '145, 79, 56',
        '90, 94, 107',
        '218, 143, 107',
        '149, 121, 106'
    ];

    public colors: string[] = this.colorsRgbCodes.map(rgbCode => this.buildRgbaCode(rgbCode, 1));

    public buildRgbaCode(rgbCode: string, opacity: number): string {
        return 'rgba(' + rgbCode + ', ' + opacity + ')';
    }

    constructor(private readonly translateService: TranslateService) {
        super(CategoryName.SALES_HISTORY, of(StudiesNames.SALES_HISTORY))
    }

    protected afterStudyLoad(study: SalesHistory) {
        study.menusSalesHistories.forEach((menuSalesHistory, index) => {
            menuSalesHistory.fill = false;
            menuSalesHistory.lineTension = 0.1;
            ChartUtils.customizeColor(menuSalesHistory, this.colors[index])
            this.translateService.get('menu.' + menuSalesHistory.label).subscribe(
                label => menuSalesHistory.label = label
            );
        });
        forkJoin(study.periods.map(period => PeriodUtils.computeDisplayedPeriod(period, this.translateService)))
            .subscribe(periods => this.study.periods = periods);
    }

    sumData(data: number[]): number {
        return data.reduce((data1, data2) => data1 + data2, 0);
    }
}

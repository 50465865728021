import {Injectable} from '@angular/core';
import {AbstractDecisionLoaderService} from "../../abstract-decision-loader.service";
import {Insurances} from "./insurances";

@Injectable({
    providedIn: 'root'
})
export class InsurancesLoaderService extends AbstractDecisionLoaderService<Insurances> {

    constructor() {
        super('financial/insurances');
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TeamDecisionsComponent} from './team-decisions.component';
import {FinancialDecisionComponent} from './financial-decision/financial-decision.component';
import {DiningRoomsDecisionComponent} from './dining-rooms-decision/dining-rooms-decision.component';
import {TeamDecisionsRoutingModule} from "./team-decisions-routing.module";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {MenuDecisionModule} from "./menu-decision/menu-decision.module";
import {GenericsModule} from "./generics/generics.module";
import {AreaDecisionComponent} from './area-decision/area-decision.component';
import {PipesModule} from "../../pipes/pipes.module";
import {AreaPortraitComponent} from './area-decision/area-portrait/area-portrait.component';
import {ComponentsModule} from "../../components/components.module";
import {ChartsModule} from "ng2-charts";
import {PlayerDecisionsClosureComponent} from './player-decisions-closure/player-decisions-closure.component';
import {PeriodCompletionComponent} from './period-completion/period-completion.component';
import {AnimatorDecisionsClosureComponent} from './period-completion/animator-decisions-closure/animator-decisions-closure.component';
import {SimulationLaunchComponent} from './period-completion/simulation-launch/simulation-launch.component';
import {ArchetypeDecisionComponent} from './archetype-decision/archetype-decision.component';
import {ContextModule} from "../../context/context.module";
import {RevertPeriodComponent} from './period-completion/revert-period/revert-period.component';
import {StudiesModule} from "../studies/studies.module";
import {CanActivateTeamDecisionsGuard} from "./guards/can-activate-team-decisions.guard";
import {TabsModule} from "../tabs/tabs.module";
import {AnimatorProductsAndChargesDecisionComponent} from './financial-decision/animator-products-and-charges-decision/animator-products-and-charges-decision.component';
import {PlayerFinancialDecisionComponent} from './financial-decision/player-financial-decision/player-financial-decision.component';
import {InsurancesDecisionComponent} from './financial-decision/insurances-decision/insurances-decision.component';
import {AreaConfirmationPopupComponent} from './area-decision/area-confirmation-popup/area-confirmation-popup.component';
import {ArchetypeConfirmationPopupComponent} from './archetype-decision/archetype-confirmation-popup/archetype-confirmation-popup.component';
import {DecisionConfirmationPopupComponent} from './check-restaurant-type/decision-confirmation-popup/decision-confirmation-popup.component';
import {SimulationLaunchPopupComponent} from './period-completion/simulation-launch/simulation-launch-popup/simulation-launch-popup.component';
import {CustomerDistributionParametersComponent} from './area-decision/area-portrait/customer-distribution-parameters/customer-distribution-parameters.component';
import {AreaRestaurantsRepartitionComponent} from './area-decision/area-portrait/area-restaurants-repartition/area-restaurants-repartition.component';
import {ChartComponent} from './area-decision/area-portrait/chart-component/chart.component';
import {SaveBeforeDeactivatePopupComponent} from '../../components/save-before-deactivate-popup/save-before-deactivate-popup.component';
import {CommunicationDecisionModule} from './communication-decision/communication-decision.module';
import {StaffDecisionModule} from './staff-decision/staff-decision.module';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import { SimulationPageComponent } from './period-completion/simulation-page/simulation-page.component';
import { RevertPageComponent } from './period-completion/revert-page/revert-page.component';

@NgModule({
    declarations: [
        TeamDecisionsComponent,
        FinancialDecisionComponent,
        DiningRoomsDecisionComponent,
        AreaDecisionComponent,
        AreaPortraitComponent,
        PlayerDecisionsClosureComponent,
        PeriodCompletionComponent,
        AnimatorDecisionsClosureComponent,
        SimulationLaunchComponent,
        ArchetypeDecisionComponent,
        RevertPeriodComponent,
        AnimatorProductsAndChargesDecisionComponent,
        PlayerFinancialDecisionComponent,
        InsurancesDecisionComponent,
        AreaConfirmationPopupComponent,
        ArchetypeConfirmationPopupComponent,
        DecisionConfirmationPopupComponent,
        SimulationLaunchPopupComponent,
        CustomerDistributionParametersComponent,
        AreaRestaurantsRepartitionComponent,
        ChartComponent,
        SimulationPageComponent,
        RevertPageComponent
    ],
    imports: [
        TranslateModule,
        FormsModule,
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        TeamDecisionsRoutingModule,
        MenuDecisionModule,
        GenericsModule,
        PipesModule,
        ComponentsModule,
        ChartsModule,
        ContextModule,
        StudiesModule,
        TabsModule,
        CommunicationDecisionModule,
        StaffDecisionModule,
        SlickCarouselModule
    ],
    exports: [
        TeamDecisionsComponent
    ],
    providers: [
        CanActivateTeamDecisionsGuard
    ],
    entryComponents: [
        AreaConfirmationPopupComponent,
        ArchetypeConfirmationPopupComponent,
        DecisionConfirmationPopupComponent,
        SimulationLaunchPopupComponent,
        SaveBeforeDeactivatePopupComponent
    ]
})
export class TeamDecisionsModule {
}

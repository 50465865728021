import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractPopupComponent} from "../../abstract-popup-component";

@Component({
  templateUrl: './forgotten-password-popup.component.html',
  styleUrls: ['./forgotten-password-popup.component.scss'],
  host: {'class': 'popupContainer'}
})
export class ForgottenPasswordPopupComponent extends AbstractPopupComponent {
  username: string = '';
  errorIsShown: boolean = false;

  @Output()
  passwordRegenerated = new EventEmitter<string>();

  constructor() {
    super();
  }

  regeneratePassword(username: string) {
    this.passwordRegenerated.emit(username);
  }

  public showError() {
    this.errorIsShown = true;
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {ChartDataSets} from "chart.js";

@Pipe({
    name: 'chartDataSetData'
})
export class ChartDataSetDataPipe implements PipeTransform {

    transform(chartDataSets: ChartDataSets[]): any {
        return chartDataSets.map(chartDataSet => {
            return chartDataSet.data[0]
        });
    }
}

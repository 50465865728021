import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TeamsLoaderService} from "./period-completion/animator-decisions-closure/teams-loader.service";
import {SessionLoaderService} from "../../login/session-loader.service";
import {ConnectedUserService} from "../../context/connected-user.service";
import {ContextService} from "../../context/context.service";
import {AreaDecisionLoaderService} from "./area-decision/area-decision-loader.service";
import {Subscription} from 'rxjs';
import {AreDecisionsClosedService} from '../are-decisions-closed.service';

class StudyLink {
    public readonly link: string;

    constructor(public readonly name: string) {
        this.link = 'studies/' + name;
    }
}

class StudyCategory {
    constructor(public readonly nameLabel: string, public readonly studiesLinks: StudyLink[]) {
    }
}

export const STUDIES_LEFT: StudyCategory[] = [
    new StudyCategory(
        'decisions.dashboard.studies.internal.data',
        [
            new StudyLink('SALES_DETAIL'),
            new StudyLink('SALES_HISTORY'),
            new StudyLink('POTENTIAL_MARKET_AND_SALES'),
            new StudyLink('QUALITY')
        ]
    ),
    new StudyCategory(
        'decisions.dashboard.studies.accountant',
        [
            new StudyLink('SYNTHESIS'),
            new StudyLink('ACCOUNTS_DETAIL')
        ])
];

export const STUDIES_RIGHT: StudyCategory[] = [
    new StudyCategory(
        'decisions.dashboard.studies.studies',
        [
            new StudyLink('COMMUNICATION_PARAMETERS'),
            new StudyLink('HUMAN_RESOURCES_PARAMETERS'),
            new StudyLink('DINING_ROOMS_PARAMETERS')
        ]
    ),
    new StudyCategory(
        'decisions.dashboard.studies.competitive.intelligence',
        [
            new StudyLink('COMPETITIVE_INTELLIGENCE'),
            new StudyLink('FINANCIAL_COMPARISONS')
        ]
    )];

@Component({
    selector: 'kiwi-team-decisions',
    templateUrl: './team-decisions.component.html',
    styleUrls: ['./team-decisions.component.scss'],
    host: {'class': 'cell auto grid-y'}
})

export class TeamDecisionsComponent implements OnInit, OnDestroy {
    private isAnimator: boolean = false;
    public isCurrentPeriodFirstPeriod = false;
    public readonly studiesCategoriesLeft = STUDIES_LEFT;
    public readonly studiesCategoriesRight = STUDIES_RIGHT;
    private userSubscription: Subscription;
    private contextSubscription: Subscription;
    private areDecisionsClosedSubscription: Subscription;
    public decisionsClosed = false;
    public sessionCurrentPeriod: String;

    constructor(
        private connectedUserService: ConnectedUserService,
        private areaDecisionLoaderService: AreaDecisionLoaderService,
        private route: ActivatedRoute,
        private teamsLoader: TeamsLoaderService,
        private sessionLoader: SessionLoaderService,
        private contextService: ContextService,
        private router: Router,
        private readonly areDecisionsClosedService: AreDecisionsClosedService
    ) {
    }

    ngOnInit(): void {
        this.userSubscription = this.connectedUserService.userEvents.subscribe(
            user => {
                if (user !== undefined) {
                    this.isAnimator = user.user.animator;
                    if (user.user.animator) {
                        this.route.paramMap.subscribe(params => {
                            if (params.get('id')) {
                                const genericSessionId = params.get('id');
                                this.sessionLoader.loadSessionByGenericSessionRef(genericSessionId)
                                    .subscribe(session => {
                                        localStorage.setItem('sessionId', session._id);
                                        this.contextService.updateCurrentSession(session);
                                        this.contextService.loadAnimatorCurrentContext(user);
                                        this.router.navigateByUrl('/session/decisions').catch();
                                    });
                            }
                        });
                    }
                }
            }
        );

        this.contextSubscription = this.contextService.context.subscribe(context => {
            this.isCurrentPeriodFirstPeriod = context.currentSession && context.currentSession.currentPeriod === '0101';
            if (context.currentSession) {
                this.sessionCurrentPeriod = context.currentSession.currentPeriod;
            }
        });

        this.areDecisionsClosedSubscription = this.areDecisionsClosedService.decisionsClosed.subscribe(
            decisionsClosed => this.decisionsClosed = decisionsClosed
        );
    }

    ngOnDestroy(): void {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.contextSubscription) {
            this.contextSubscription.unsubscribe();
        }
        if (this.areDecisionsClosedSubscription) {
            this.areDecisionsClosedSubscription.unsubscribe();
        }
    }
}

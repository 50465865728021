import {Component, OnInit} from '@angular/core';
import {AbstractPopupComponent} from "../../../abstract-popup-component";

@Component({
    selector: 'kiwi-support-popup',
    templateUrl: './support-popup.component.html',
    styleUrls: ['./support-popup.component.scss'],
    host: {'class': 'popupContainer'}
})
export class SupportPopupComponent extends AbstractPopupComponent {

    constructor() {
        super();
    }

}

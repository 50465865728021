import {Component, OnDestroy, OnInit} from '@angular/core';
import {SupportPopupService} from './support-popup/support-popup.service';
import {ConnectedUserService} from '../../context/connected-user.service';
import {ApplicationSettingsService} from '../../application-settings.service';
import {RankingMenuDisabledLoaderService} from '../ranking/ranking-menu-disabled-loader.service';
import {ContextService} from '../../context/context.service';
import {SchedulePopupService} from '../schedule-popup/schedule-popup.service';
import {MainMenuTab} from './main-menu-tab';
import {Router} from '@angular/router';
import {filter, flatMap, take} from 'rxjs/operators';
import {CanActivateSchedulePopupGuard} from '../schedule-popup/can-activate-schedule-popup.guard';
import {combineLatest, Subscription} from 'rxjs';
import {UnreadMessagesCountService} from './unread-messages-count.service';
import {IsNotSimulationOrRevertStartedGuard} from '../team-decisions/guards/is-not-simulation-or-revert-started-guard.service';

@Component({
    selector: 'kiwi-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnDestroy {
    public tabs: Array<MainMenuTab> = [];

    private unreadMessageCountSubscription: Subscription;
    public unreadMessageCount = 0;

    constructor(
        private supportPopupService: SupportPopupService,
        private connectedUserService: ConnectedUserService,
        private applicationSettingsService: ApplicationSettingsService,
        private readonly rankingMenuDisabledLoaderService: RankingMenuDisabledLoaderService,
        private readonly contextService: ContextService,
        private readonly schedulePopupService: SchedulePopupService,
        private readonly router: Router,
        private readonly canActivateSchedulePopupGuard: CanActivateSchedulePopupGuard,
        private readonly isNotSimulationStartedGuard: IsNotSimulationOrRevertStartedGuard,
        private readonly unreadMessagesCountService: UnreadMessagesCountService
    ) {

    }

    ngOnInit() {
        this.connectedUserService.userEvents.subscribe(
            user => {
                if (user) {
                    if (user.user.player) {
                        this.initPlayerTabs();
                    } else {
                        this.initAnimatorTabs();
                    }
                }
            }
        );

        this.router.events.subscribe(
            () => {
                this.selectCurrentTab();
            }
        );

        this.unreadMessageCountSubscription = this.unreadMessagesCountService.unreadMessageCount.subscribe(unreadMessageCount => {
            this.unreadMessageCount = unreadMessageCount;
        });
    }

    ngOnDestroy(): void {
        this.unreadMessageCountSubscription.unsubscribe();
    }

    private buildLinkTab(key: string, url: string, iconClass: string): MainMenuTab {
        return new MainMenuTab(
            key,
            () => this.router.navigateByUrl(url),
            iconClass,
            url
        );
    }

    private buildTeamsDecisionsTab(): MainMenuTab {
        return this.buildLinkTab(
            'navigation.menu.restaurant',
            '/session/decisions',
            'kiwi-restaurant'
        );
    }

    private buildRankingTab(): MainMenuTab {
        return this.buildLinkTab(
            'navigation.menu.ranking',
            '/ranking',
            'kiwi-ranking'
        );
    }

    private buildMailboxTab(): MainMenuTab {
        return this.buildLinkTab(
            'navigation.menu.messaging',
            '/mailbox',
            'kiwi-messaging'
        );
    }

    private buildIntroTab(): MainMenuTab {
        return this.buildLinkTab(
            'navigation.menu.intro',
            '/introduction',
            'kiwi-intro'
        );
    }

    private buildParametersTab(): MainMenuTab {
        return this.buildLinkTab(
            'navigation.menu.parameters',
            '/parameters',
            'kiwi-parameters'
        );
    }

    private buildEvaluationsTab(): MainMenuTab {
        return this.buildLinkTab(
            'navigation.menu.evaluations',
            '/evaluations',
            'kiwi-evaluation'
        );
    }

    private buildAccountTab(): MainMenuTab {
        return this.buildLinkTab(
            'navigation.menu.account',
            '/account',
            'kiwi-account'
        );
    }

    private buildScheduleTab(): MainMenuTab {
        return new MainMenuTab(
            'navigation.menu.schedule',
            () => this.showSchedulePopup(),
            'kiwi-schedule'
        );
    }

    private buildSupportTab(): MainMenuTab {
        return new MainMenuTab(
            'navigation.menu.support',
            () => this.showSupportPopup(),
            'kiwi-support'
        );
    }

    private buildSessionsManagerTab(genericSessionRef: string, sessionsManagerUrl: string) {
        return new MainMenuTab(
            'navigation.menu.sessions',
            () => this.redirectToSessionManager(genericSessionRef, sessionsManagerUrl),
            'kiwi-sessions'
        );
    }

    private initPlayerTabsWithRankingMenuDisabled(rankingMenuDisabled: boolean): void {
        const tabs = [];
        tabs.push(this.buildTeamsDecisionsTab());

        if (!rankingMenuDisabled) {
            tabs.push(this.buildRankingTab());
        }

        tabs.push(this.buildScheduleTab());
        tabs.push(this.buildMailboxTab());
        tabs.push(this.buildAccountTab());
        tabs.push(this.buildIntroTab());
        this.tabs = tabs;
        this.selectCurrentTab();
    }

    private initPlayerTabs(): void {
        this.contextService.context
            .pipe(
                filter(context => !!context.currentSessionId),
                take(1),
                flatMap(context => this.rankingMenuDisabledLoaderService.loadRankingMenuDisabled(context.currentSessionId))
            ).subscribe(rankingMenuDisabled => this.initPlayerTabsWithRankingMenuDisabled(rankingMenuDisabled));
    }

    private initAnimatorTabsUsingSessionsManagerUrlAndContext(genericSessionRef: string, sessionsManagerUrl: string): void {
        const tabs = [];
        tabs.push(this.buildSessionsManagerTab(genericSessionRef, sessionsManagerUrl));
        tabs.push(this.buildTeamsDecisionsTab());
        tabs.push(this.buildParametersTab());
        tabs.push(this.buildEvaluationsTab());
        tabs.push(this.buildRankingTab());
        tabs.push(this.buildMailboxTab());
        tabs.push(this.buildIntroTab());
        tabs.push(this.buildSupportTab());
        this.tabs = tabs;
        this.selectCurrentTab();
    }

    private initAnimatorTabs(): void {
        combineLatest(this.contextService.context, this.applicationSettingsService.loadSessionsManagerUrl())
            .pipe(
                filter(([context, _]) => context && context.currentSession !== null && context.currentSession !== undefined),
                take(1)
            ).subscribe(([context, sessionsManagerUrl]) => {
            this.initAnimatorTabsUsingSessionsManagerUrlAndContext(context.currentSession.genericSessionRef, sessionsManagerUrl);
        });
    }

    private selectCurrentTab(): void {
        this.onTabSelect(this.tabs.find(tab => tab.url !== null && this.router.url.includes(tab.url)));
    }

    private redirectToSessionManager(genericSessionRef: string, sessionsManagerUrl: string) {
        this.isNotSimulationStartedGuard.canActivate().subscribe(
            canActive => {
                if (canActive) {
                    window.open(sessionsManagerUrl + '#!/dashboard?session=' + genericSessionRef, '_self');
                }
            }
        );
    }

    private showSupportPopup(): void {
        this.isNotSimulationStartedGuard.canActivate().subscribe(
            canActive => {
                if (canActive) {
                    this.supportPopupService.showSupportPopup();
                }
            }
        );
    }

    private showSchedulePopup(): void {
        this.canActivateSchedulePopupGuard.canActivate().subscribe(
            canActive => {
                if (canActive) {
                    this.schedulePopupService.showSchedulePopup();
                }
            }
        );
    }

    onTabSelect(menuTab: MainMenuTab): void {
        if (!!menuTab) {
            this.tabs.forEach(tab => tab.isSelected = false);
            menuTab.isSelected = true;
        }
    }

    onTabClick(tab: MainMenuTab): void {
        if (!!tab.url) {
            this.onTabSelect(tab);
        }
        tab.onClick();
    }
}

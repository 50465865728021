import { Injectable } from '@angular/core';
import {AbstractDecisionUpdaterService} from "../../abstract-decision-updater.service";
import {Insurances} from "./insurances";

@Injectable({
  providedIn: 'root'
})
export class InsurancesUpdaterService extends AbstractDecisionUpdaterService<Insurances>{

  constructor() { super('financial/insurances'); }
}

import {Component} from '@angular/core';
import {Insurances} from "./insurances";
import {InsurancesParameters} from "../../../parameters/services/insurances/insurances-parameters";
import {InsurancesLoaderService} from "./insurances-loader.service";
import {InsurancesParametersLoaderService} from "../../../parameters/services/insurances/insurances-parameters-loader.service";
import {InsurancesUpdaterService} from "./insurances-updater.service";
import {Observable} from "rxjs";
import {Context} from "../../../../context/context";
import {Score} from "../../generics/score-image/score-image.component";
import {AbstractDecisionComponent} from "../../abstract-decision-component";

@Component({
    selector: 'kiwi-insurances-decision',
    templateUrl: './insurances-decision.component.html',
    styleUrls: ['./insurances-decision.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class InsurancesDecisionComponent extends AbstractDecisionComponent<Insurances, InsurancesParameters> {
    private totalInsurancesCost: number;

    constructor(
        private insurancesParametersLoader: InsurancesParametersLoaderService,
        insurancesLoader: InsurancesLoaderService,
        insurancesUpdater: InsurancesUpdaterService
    ) {
        super(
            insurancesLoader,
            insurancesUpdater,
            [],
            'toast.financial.decision.insurances.message',
            () => this.reloadTotalInsurancesCost()
        );
    }

    onLiabilityInsuranceTakenOutChange(value: boolean) {
        this.decision.liabilityInsuranceTakenOut = value;
        this.onDecisionUpdate();
    }

    onOperatingLossInsuranceTakenOutChange(value: boolean) {
        this.decision.operatingLossInsuranceTakenOut = value;
        this.onDecisionUpdate();
    }

    onPropertyInsuranceTakenOutChange(value: boolean) {
        this.decision.propertyInsuranceTakenOut = value;
        this.onDecisionUpdate();
    }

    protected computeDecisionQuality(scores: Score[], parameters: InsurancesParameters): number {
        return 0;
    }

    protected loadParameters(context: Context): Observable<InsurancesParameters> {
        return this.insurancesParametersLoader.loadParameters(context);
    }


    onDecisionUpdate() {
        this.onValueUpdate();
        this.reloadTotalInsurancesCost();
    }

    private reloadTotalInsurancesCost(): void {
        this.totalInsurancesCost = InsurancesDecisionComponent.computeTotalInsurancesCost(this.decision, this.parameters);
    }

    private static computeTotalInsurancesCost(decision: Insurances, parameters: InsurancesParameters): number {
        let total = 0;

        if(decision.liabilityInsuranceTakenOut)
            total += parameters.liabilityInsuranceCost;
        if(decision.operatingLossInsuranceTakenOut)
            total += parameters.operatingLossInsuranceCost;
        if(decision.propertyInsuranceTakenOut)
            total += parameters.propertyInsuranceCost;

        if(decision.liabilityInsuranceTakenOut && decision.operatingLossInsuranceTakenOut && decision.propertyInsuranceTakenOut)
            total -= parameters.discount;

        return total;
    }

    protected updateScores(decision: Insurances, parameters: InsurancesParameters): void {
    }
}

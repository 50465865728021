import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContextService} from "../../../context/context.service";
import {SessionEventsLoaderService} from "../../../session-events-loader.service";
import {tap} from "rxjs/operators";
import {PeriodUtils} from "../../studies/study-header/period-utils";
import {interval, Subscription} from "rxjs";

class TimeSpan {
    public days: number;
    public hours: number;
    public minutes: number;
    public seconds: number;

    constructor(milliseconds: number) {
        this.days = Math.floor(milliseconds / 86400000);
        milliseconds = milliseconds % 86400000;
        this.hours = Math.floor(milliseconds / 3600000);
        milliseconds = milliseconds % 3600000;
        this.minutes = Math.floor(milliseconds / 60000);
        milliseconds = milliseconds % 60000;
        this.seconds = Math.floor(milliseconds / 1000);
    }

}

@Component({
    selector: 'kiwi-period-time',
    templateUrl: './period-time.component.html',
    styleUrls: ['./period-time.component.scss']
})
export class PeriodTimeComponent implements OnInit, OnDestroy {
    private timeRemaining: string;
    private daysRemaining: number;
    private display: boolean = false;
    private currentPeriodEventEndDate: Date;
    private contextSubscription: Subscription;
    private timeSubscription: Subscription;

    constructor(
        private contextService: ContextService,
        private sessionEventsLoaderService: SessionEventsLoaderService
    ) {
    }

    ngOnInit() {
        this.contextSubscription = this.contextService.context
            .subscribe(context => {
                if (context && context.currentSession) {
                    const month = PeriodUtils.computeMonthFromPeriod(
                        context.currentSession.currentPeriod,
                        context.currentSession.baseMetaScenario.periodicity
                    );

                    this.sessionEventsLoaderService.loadSessionEvents(context)
                        .pipe(
                            tap(events => {
                                let found = false;
                                let i = 0;
                                while (!found && i < events.length)
                                    if (events[i].eventId == month)
                                        found = true;
                                    else
                                        i++;

                                if (found) {
                                    this.currentPeriodEventEndDate = new Date(events[i].endDate);
                                }

                                this.display = found;
                            })
                        )
                        .subscribe()
                }
            });

        this.timeSubscription = interval(1000)
            .subscribe(() => {
                if (this.display && this.currentPeriodEventEndDate) {
                    const timeSpanInMillis = this.currentPeriodEventEndDate.getTime() - Date.now();
                    if(timeSpanInMillis <= 0) {
                        this.display = false;
                    }
                    const timeSpan = new TimeSpan(timeSpanInMillis);
                    this.daysRemaining = timeSpan.days;
                    this.timeRemaining = PeriodTimeComponent.formatTimeSpan(timeSpan);
                }
            });
    }

    static formatNumber(n: number): string {
        if(n < 10) {
            return '0' + n;
        }

        return '' + n;
    }

    ngOnDestroy(): void {
        this.contextSubscription.unsubscribe()
        this.timeSubscription.unsubscribe();
    }

    private static formatTimeSpanFields(firstField: number, remainingFields: number[]): string {
        return firstField + ':' + remainingFields
            .map(n => this.formatNumber(n))
            .join(':');
    }

    private static formatTimeSpan(timeSpan: TimeSpan): string {
        if(timeSpan.hours > 0) {
            return PeriodTimeComponent.formatTimeSpanFields(timeSpan.hours, [timeSpan.minutes, timeSpan.seconds]);
        }

        if(timeSpan.minutes > 0) {
            return PeriodTimeComponent.formatTimeSpanFields(timeSpan.minutes, [timeSpan.seconds]);
        }

        return timeSpan.seconds.toString();
    }
}

import {ComputeHierarchyRestaurantQuality} from "./compute-hierarchy-restaurant-quality";
import {StaffDecision} from "./staff-decision";
import {StaffQualityParameters} from "../../../parameters/services/quality/staff-quality-parameters";

export class ComputeType3RestaurantStaffQuality extends ComputeHierarchyRestaurantQuality {
    constructor(
        staffDecision: StaffDecision,
        staffQualityParameters: StaffQualityParameters,
        previousPeriodCoversNumber: number
    ) {
        super(staffQualityParameters.standardCoversNumber,
            previousPeriodCoversNumber,
            80,
            staffDecision,
            staffQualityParameters.standardCookWageBillQuality80,
            staffQualityParameters.standardWaiterWageBillQuality80,
            staffQualityParameters.maximalCookingStaffQualityType3Restaurant,
            staffQualityParameters.maximalCustomerCareStaffQualityType3Restaurant);
    }


    protected computeCooksHierarchyQuality(): number {
        return this.computeHierarchyQuality(
            this.staffDecision.headChefNumber,
            this.staffDecision.cookNumber,
            this.staffDecision.commisChefNumber,
        );
    }

    protected computeWaitersHierarchyQuality(): number {
        return this.computeHierarchyQuality(
            this.staffDecision.maitreDHotelNumber,
            this.staffDecision.headWaiterNumber,
            this.staffDecision.waiterNumber,
        );
    }

    private computeHierarchyQuality(
        employeeTypeANumber: number, employeeTypeBNumber: number, employeeTypeCNumber: number
    ): number {
        return (this.computeHierarchyQualityBetweenTwoLevels(employeeTypeANumber, employeeTypeBNumber)
            + this.computeHierarchyQualityBetweenTwoLevels(employeeTypeBNumber, employeeTypeCNumber)) / 2;
    }
}

import {Component, Input} from '@angular/core';
import {AreaPortrait} from '../../../../parameters/services/area-portrait/area-portrait';
import {Label} from 'ng2-charts';
import {RestaurantType} from '../../../staff-decision/player-staff-decision/restaurant-type';
import {AbstractChartParentComponent} from '../chart-component/abstract-chart-parent.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'kiwi-area-restaurants-repartition',
    templateUrl: './area-restaurants-repartition.component.html',
    styleUrls: ['./area-restaurants-repartition.component.scss']
})
export class AreaRestaurantsRepartitionComponent extends AbstractChartParentComponent{
    public restaurantsNumber: number;
    private readonly labels: Label[] = [RestaurantType.TYPE_1, RestaurantType.TYPE_2, RestaurantType.TYPE_3];

    @Input()
    set areaPortrait(areaPortrait: AreaPortrait) {
        this.restaurantsNumber = areaPortrait.restaurantsNumber;
        const restaurantsNumberByType = areaPortrait.restaurantsNumberByType;

        let data: number[] = [];

        for (let label of this.labels) {
            // @ts-ignore
            const restaurantsNumber = restaurantsNumberByType[label];

            if (!!restaurantsNumber) {
                data.push(restaurantsNumber);
            } else {
                data.push(0);
            }
        }

        this.translateService.get(this.labels.map(label => 'common.restaurant.type.' + label.toString())).subscribe(
            translatedLabels => {
                let labels = [];
                for(let label in translatedLabels) {
                    labels.push(translatedLabels[label]);
                }
                this.barChartLabels = labels;
            }
        );
        this.barChartData = data;
    }

    constructor(private readonly translateService: TranslateService) {
        super();
    }
}

import { Injectable } from '@angular/core';
import {AbstractSessionParametersUpdaterService} from '../../services/abstract-session-parameters-updater-service';
import {StaffQualityParameters} from '../../services/quality/staff-quality-parameters';

@Injectable({
  providedIn: 'root'
})
export class StaffQualityParametersUpdaterService extends AbstractSessionParametersUpdaterService<StaffQualityParameters>{

  constructor() {
      super('quality/staff')
  }
}

import {Component} from '@angular/core';
import {AbstractStudyComponent} from '../../../abstract-study.component';
import {CompetitiveIntelligenceStudy} from '../../competitive-intelligence-study';
import {CategoryName} from '../../../category-name';
import {StudiesNames} from '../../../studies-names';
import {of} from 'rxjs';

@Component({
    templateUrl: './equity-comparison.component.html',
    host: {'class': 'cell auto grid-y'}
})
export class EquityComparisonComponent extends AbstractStudyComponent<CompetitiveIntelligenceStudy> {

    constructor() {
        super(CategoryName.FINANCIAL_COMPARISONS, of(StudiesNames.EQUITY_COMPARISON))
    }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContextService} from '../../../../../context/context.service';
import {Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Component({
    selector: 'kiwi-closed-decisions-banner',
    templateUrl: './closed-decisions-banner.component.html',
    styleUrls: ['./closed-decisions-banner.component.scss']
})
export class ClosedDecisionsBannerComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    private currentPeriod: string;

    constructor(private readonly contextService: ContextService) {
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.subscription = this.contextService.context
            .pipe(
                filter(context => !!context.currentPeriod),
                map(context => context.currentPeriod)
            )
            .subscribe(currentPeriod => {
                this.currentPeriod = currentPeriod
            });
    }
}

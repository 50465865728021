import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss']
})
export class AddButtonComponent {

    @Input()
    public disabled = false;

    @Input()
    public labelKey: string;

    @Output()
    public click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    public onClick(event: MouseEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
        if (!this.disabled) {
            this.click.emit(event);
        }
    }
}

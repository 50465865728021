import {Component} from '@angular/core';
import {StudiesNames} from "../../studies-names";
import {WinesQualitiesLoaderService} from "../../../team-decisions/menu-decision/wines-purchasing/wines-qualities-loader.service";
import {Observable, of} from "rxjs";
import {StudyTabParentComponent} from '../study-tab-parent/study-tab-parent.component';
import {Context} from '../../../../context/context';

@Component({
    templateUrl: '../study-tab-parent/study-tab-parent.component.html',
    styleUrls: ['../study-tab-parent/study-tab-parent.component.scss'],
    host: {'class': 'cell auto pageContainer grid-y'}
})
export class WinesQualityComparisonComponent extends StudyTabParentComponent<Object> {
    constructor(private readonly winesQualitiesLoaderService: WinesQualitiesLoaderService) {
        super(of([]), StudiesNames.WINES_QUALITY_COMPARISON);
    }

    protected fillTabs(study: Object): void {
        this.fillTabsWith(study);
    }

    protected loadStudy(context: Context): Observable<Object> {
        return this.winesQualitiesLoaderService.loadTeamResultFromContext(context);
    }
}

import { Injectable } from '@angular/core';
import {AbstractSessionParametersLoaderService} from "../abstract-session-parameters-loader.service";
import {CommunicationParameters} from "./communication-parameters";

@Injectable({
  providedIn: 'root'
})
export class CommunicationParametersLoaderService extends AbstractSessionParametersLoaderService<CommunicationParameters>{

  constructor() {
      super('communication');
  }
}

import {Injectable} from '@angular/core';
import {CommunicationDecision} from "./communication-decision";
import {AbstractDecisionUpdaterService} from "../../abstract-decision-updater.service";

@Injectable({
    providedIn: 'root'
})
export class CommunicationDecisionUpdaterService extends AbstractDecisionUpdaterService<CommunicationDecision> {
    constructor() {
        super('communication');
    }
}

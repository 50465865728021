import {StaffDecision} from "./staff-decision";
import {StaffQualityParameters} from "../../../parameters/services/quality/staff-quality-parameters";
import {ComputeAbstractTypeRestaurantQuality} from "./compute-abstract-type-restaurant-quality";

export class ComputeType1RestaurantStaffQuality extends ComputeAbstractTypeRestaurantQuality {
    constructor(
        staffDecision: StaffDecision,
        staffQualityParameters: StaffQualityParameters,
        previousPeriodCoverNumber: number
    ) {
        super(
            staffQualityParameters.standardCoversNumber,
            previousPeriodCoverNumber,
            25,
            staffDecision,
            staffQualityParameters.standardCookWageBillQuality25,
            staffQualityParameters.standardWaiterWageBillQuality25,
            staffQualityParameters.maximalCookingStaffQualityType1Restaurant,
            staffQualityParameters.maximalCustomerCareStaffQualityType1Restaurant
        )
    }

    computeCookingQuality(): number {
        return this.computeCookStaffQuality();
    }

    computeCustomerCareQuality(): number {
        return this.computeWaiterStaffQuality();
    }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Context} from "./context";
import {Team} from "../login/team";
import {SessionLoaderService} from "../login/session-loader.service";
import {Player} from "../login/user";
import {Session} from "../login/session";
import {TeamsLoaderService} from "../app-main/team-decisions/period-completion/animator-decisions-closure/teams-loader.service";
import {ConnectedUser} from "./connected-user";
import {RestaurantStandingInformation} from './restaurant-standing-information';
import {RestaurantStandingInformationLoaderService} from './restaurant-standing-information-loader.service';
import {AreaDecisionLoaderService} from '../app-main/team-decisions/area-decision/area-decision-loader.service';

@Injectable()
export class ContextService {
    public readonly context: BehaviorSubject<Context>;
    private currentSessionId: string;
    private currentMetaScenarioId: string;
    private currentPeriod: string;
    private currentTeam: Team;
    private currentSession: Session;
    private currentRestaurantStandingInformation: RestaurantStandingInformation;
    private currentLanguage: string = 'fr_FR';
    private currentArea: string;
    private currentSessionName: string;

    constructor(
        private sessionLoaderService: SessionLoaderService,
        private teamsLoaderService: TeamsLoaderService,
        private readonly restaurantStandingInformationLoaderService: RestaurantStandingInformationLoaderService,
        private readonly areaDecisionLoaderService: AreaDecisionLoaderService
    ) {
        this.context = new BehaviorSubject<Context>(
            new Context(
                this.currentSessionId,
                this.currentSessionName,
                this.currentMetaScenarioId,
                this.currentPeriod,
                this.currentTeam,
                this.currentSession,
                this.currentRestaurantStandingInformation,
                this.currentLanguage,
                this.currentArea
            ));
    }

    public loadPlayerCurrentContext(connectedUser: ConnectedUser) {
        return this.sessionLoaderService.loadTeamSession((connectedUser.user as Player).teamRef)
            .subscribe(
                session => {
                    this.updateCurrentSession(session);
                    this.teamsLoaderService.loadTeam((connectedUser.user as Player).teamRef)
                        .subscribe(team => {
                            this.updateCurrentTeam(team);
                        });
                }
            );
    }

    public loadAnimatorCurrentContext(connectedUser: ConnectedUser) {
        return this.sessionLoaderService.loadSessionById(localStorage.getItem('sessionId'))
            .subscribe(session => {
                this.updateCurrentSession(session);
                this.updateCurrentTeam(session.teams[0]);
            });
    }

    updateCurrentRestaurantStandingInformation(restaurantStandingInformation: RestaurantStandingInformation) {
        this.currentRestaurantStandingInformation = restaurantStandingInformation;
        this.updateContext();
    }

    updateCurrentLanguage(language: string) {
        this.currentLanguage = language;
        this.updateContext();
    }

    updateCurrentSession(session: Session) {
        this.currentSessionId = session._id;
        this.currentSessionName = session.name;
        this.currentSession = session;
        this.currentMetaScenarioId = session.baseMetaScenario.metaScenarioRef;
        this.currentPeriod = session.currentPeriod;
        this.updateContext();
    }

    updateCurrentPeriodId(newCurrentPeriodId: string): void {
        this.currentPeriod = newCurrentPeriodId;
        this.updateContext();
    }

    updateCurrentSessionCurrentPeriod(newCurrentPeriodId: string): void {
        this.currentSession.currentPeriod = newCurrentPeriodId;
        this.currentPeriod = newCurrentPeriodId;
        this.updateContext();
    }

    reloadRestaurantStandingInformation(): void {
        if(!!this.currentTeam && !!this.currentSession.currentPeriod && !!this.currentSessionId) {
            this.restaurantStandingInformationLoaderService.retrieveData(
                this.currentTeam.teamNumber,
                this.currentSession.currentPeriod,
                this.currentSessionId
            ).subscribe(restaurantStandingInformation =>
                this.updateCurrentRestaurantStandingInformation(restaurantStandingInformation)
            );
        } else {
            this.updateCurrentRestaurantStandingInformation(null);
        }
    }

    reloadCurrentTeamArea(): void {
        if(!!this.currentSessionId && !!this.currentPeriod && !!this.currentTeam && !!this.currentTeam.teamNumber) {
            this.areaDecisionLoaderService.loadDecision(
                this.currentSessionId,
                this.currentPeriod,
                this.currentTeam.teamNumber
            ).subscribe(areaDecision => {
                this.updateCurrentArea(areaDecision.area);
            });
        }
    }

    updateCurrentTeam(newCurrentTeam: Team): void {
        this.currentTeam = newCurrentTeam;
        this.reloadCurrentTeamArea();
        this.reloadRestaurantStandingInformation();
    }

    updateCurrentArea(area: string): void {
        this.currentArea = area;
        this.updateContext();
    }

    private updateContext() {
        this.context.next(new Context(
            this.currentSessionId,
            this.currentSessionName,
            this.currentMetaScenarioId,
            this.currentPeriod,
            this.currentTeam,
            this.currentSession,
            this.currentRestaurantStandingInformation,
            this.currentLanguage,
            this.currentArea
        ));
    }
}


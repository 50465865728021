import {Injectable} from '@angular/core';
import {AbstractDecisionLoaderService} from "../abstract-decision-loader.service";
import {DiningRoomsDecision} from "./dining-rooms-decision";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DiningRoomsDecisionLoaderService extends AbstractDecisionLoaderService<DiningRoomsDecision> {

  constructor() {
    super('diningRooms');
  }
}

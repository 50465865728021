import {Injectable} from '@angular/core';
import {AbstractPopupService} from "../../../abstract-popup.service";
import {SupportPopupComponent} from "./support-popup.component";

@Injectable({
    providedIn: 'root'
})
export class SupportPopupService extends AbstractPopupService<SupportPopupComponent> {

    constructor() {
        super(SupportPopupComponent);
    }

    showSupportPopup() {
        this.showPopup(new SupportPopupComponent());
    }

    protected fillPopupInstance(popupInstance: SupportPopupComponent, preparedPopup: SupportPopupComponent) {

    }
}

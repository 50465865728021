import {Directive, forwardRef, Input, SimpleChanges} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, Validators} from '@angular/forms';

const MAX_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => KiwiMaxValidator),
    multi: true
};

@Directive({
    selector: '[kiwiMax]',
    providers: [MAX_VALIDATOR]
})
export class KiwiMaxValidator implements Validator {
    private validator: ValidatorFn;

    @Input()
    set kiwiMax(max: number) {
        this.validator = KiwiMaxValidator.buildValidator(max);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!this.validator) {
            return null;
        }

        return this.validator(control);
    }

    private static buildValidator(max: any) {
        return (control: AbstractControl): { [key: string]: any } => {
            if (max == undefined || max === '') {
                return null;
            }

            const value = control.value;

            if (!value) {
                return null;
            }

            return parseFloat(value) <= max ? null : {actualValue: value, requiredValue: max, max: true};
        };
    }
}

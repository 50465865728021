export enum StudiesNames {
    BALANCE_SHEET = 'BALANCE_SHEET',
    PROFIT_LOSS_ACCOUNTS = 'PROFIT_LOSS_ACCOUNTS',
    CASH_FLOW_STATEMENT = 'CASH_FLOW_STATEMENT',

    TURNOVER = 'TURNOVER',
    PURCHASES = 'PURCHASES',
    WINE_STOCKS = 'WINE_STOCKS',
    EXTERNAL_LOADS_DETAILS = 'EXTERNAL_LOADS_DETAILS',
    HUMAN_RESOURCES = 'HUMAN_RESOURCES',
    EXCEPTIONAL_RESULT = 'EXCEPTIONAL_RESULT',
    FINANCIAL_RESULT = 'FINANCIAL_RESULT',
    TAXES = 'TAXES',
    DEPRECIATION_CHARGES = 'DEPRECIATION_CHARGES',

    MARKET_SHARES = 'MARKET_SHARES',
    ADVERTISEMENT_BUDGET_COMPARISON = 'ADVERTISEMENT_BUDGET_COMPARISON',
    COOKING_QUALITY_COMPARISON = 'COOKING_QUALITY_COMPARISON',
    CUSTOMER_CARE_QUALITY_COMPARISON = 'CUSTOMER_CARE_QUALITY_COMPARISON',
    LAYOUT_QUALITY_COMPARISON = 'LAYOUT_QUALITY_COMPARISON',
    MAINTENANCE_QUALITY_COMPARISON = 'MAINTENANCE_QUALITY_COMPARISON',
    SALES_PRICES_COMPARISON = 'SALES_PRICES_COMPARISON',
    DISHES_QUALITY_COMPARISON = 'DISHES_QUALITY_COMPARISON',
    WINES_QUALITY_COMPARISON = 'WINES_QUALITY_COMPARISON',
    AREA_COMPETITION = 'AREA_COMPETITION',

    EQUITY_COMPARISON = 'EQUITY_COMPARISON',
    ACCUMULATED_NET_RESULT_COMPARISON = 'ACCUMULATED_NET_RESULT_COMPARISON',

    HUMAN_RESOURCES_PARAMETERS = 'HUMAN_RESOURCES_PARAMETERS',
    COMMUNICATION_PARAMETERS = 'COMMUNICATION_PARAMETERS',
    DINING_ROOMS_PARAMETERS = 'DINING_ROOMS_PARAMETERS',
    QUALITY = 'QUALITY',

    SALES_DETAILS = 'SALES_DETAIL',
    POTENTIAL_MARKET_AND_SALES = 'POTENTIAL_MARKET_AND_SALES',
    SALES_HISTORY = 'SALES_HISTORY',
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {SalesPricesComponent} from "./sales-prices/sales-prices.component";
import {IngredientsPurchasingComponent} from "./ingredients-purchasing/ingredients-purchasing.component";
import {WinesPurchasingComponent} from "./wines-purchasing/wines-purchasing.component";
import {MenuDecisionComponent} from "./menu-decision.component";
import {CanDeactivateModificationComponentGuard} from '../../guards/can-deactivate-modification-component.guard';
import {AnimatorMenuMarkComponent} from './animator-menu-mark/animator-menu-mark.component';
import {CanActivateAnimatorGuard} from '../guards/can-activate-animator.guard';

export const menuDecisionRoutes: Routes = [
  {
    path: '',
    component: MenuDecisionComponent,
    children: [
      {
        path: '',
        redirectTo: 'sales-prices',
        pathMatch: 'full'
      },
      {
        path: 'sales-prices',
        component: SalesPricesComponent,
        canDeactivate: [CanDeactivateModificationComponentGuard]
      },

      {
        path: 'ingredients-purchasing',
        component: IngredientsPurchasingComponent,
        canDeactivate: [CanDeactivateModificationComponentGuard]
      },

      {
        path: 'wines-purchasing',
        component: WinesPurchasingComponent,
        canDeactivate: [CanDeactivateModificationComponentGuard]
      },

      {
        path: 'mark',
        component: AnimatorMenuMarkComponent,
        canActivate: [CanActivateAnimatorGuard],
        canDeactivate: [CanDeactivateModificationComponentGuard]
      }
    ]
  }
];

@NgModule({
  exports: [RouterModule]
})
export class MenuDecisionRoutingModule {
}

import {Component, OnInit} from '@angular/core';
import {SimulatedPeriodsLoaderService} from './simulated-periods-loader.service';
import {SimulatedPeriod} from './simulated-period';
import {ContextService} from '../../../../context/context.service';
import {HttpClient} from '@angular/common/http';
import {Session} from '../../../../login/session';

@Component({
    selector: 'app-revert-period',
    templateUrl: './revert-period.component.html',
    styleUrls: ['./revert-period.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class RevertPeriodComponent implements OnInit {
    private simulatedPeriods: SimulatedPeriod[];

    constructor(
        private simulatedPeriodsLoaderService: SimulatedPeriodsLoaderService,
        private contextService: ContextService,
        private httpClient: HttpClient
    ) {
    }

    ngOnInit() {
        this.contextService.context.subscribe(
            context => {
                this.simulatedPeriodsLoaderService.loadSimulatedPeriods(context.currentSessionId)
                    .subscribe(simulatedPeriods => this.simulatedPeriods = simulatedPeriods.sort(this.sorterSimulatedPeriods));
            }
        );
    }

    revertPeriod(period: string): void {
        const context = this.contextService.context.getValue();
        this.httpClient.put<Session>('/api/sessions/' + context.currentSessionId + '/periods/' + period + '/revert', null)
            .subscribe(session => this.contextService.updateCurrentSession(session));
    }

    private sorterSimulatedPeriods(
        simulatedPeriod1: SimulatedPeriod,
        simulatedPeriod2: SimulatedPeriod
    ) {
        if (simulatedPeriod1.period < simulatedPeriod2.period) {
            return 1;
        }

        if (simulatedPeriod1.period > simulatedPeriod2.period) {
            return -1;
        }
        return 0;
    }
}

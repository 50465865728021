import {HttpClient} from "@angular/common/http";
import {LocatorService} from "../locator.service";
import {Context} from "../context/context";
import {Observable, of} from "rxjs";

export abstract class AbstractTeamResultLoader<T> {
    private httpClient: HttpClient;

    protected constructor(
        private resultType: string
    ) {
        this.httpClient = LocatorService.injector.get(HttpClient);
    }

    loadTeamResultFromContext(context: Context, period? :string): Observable<T> {
        if(!period) {
            period = context.currentPeriod;
        }

        if(period === "0012")
            return of(null);

        if(period === context.currentSession.currentPeriod) {
            return of(null);
        }

        if(!context.currentTeam) {
            return of(null);
        }

        const url = '/api/sessions/' + context.currentSessionId +
            '/periods/' + period +
            '/simulation/result/teams/' + context.currentTeam.teamNumber + '/' + this.resultType;
        return this.httpClient.get<T>(url);
    }
}

import { Injectable } from '@angular/core';
import {DisplayStudy} from "../display-study";
import {HttpClient} from "@angular/common/http";
import {NotificationService} from "../../../notification.service";
import {AbstractDecisionUpdaterService} from "../../team-decisions/abstract-decision-updater.service";
import {Context} from "../../../context/context";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class StudyPurchaseDecisionUpdaterService extends AbstractDecisionUpdaterService<DisplayStudy> {

  constructor() {
      super('studies');
  }

  public purchaseStudy(context: Context, study: DisplayStudy): Observable<DisplayStudy> {
      study['period'] = context.currentPeriod;
      return this.saveDecision(
          context.currentSessionId,
          context.currentSession.currentPeriod,
          context.currentTeam.teamNumber,
          study
      );
  }
}

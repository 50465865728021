import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Context} from "../../context/context";
import {Observable} from "rxjs";
import {ReceivedMessage} from "./received-message";

@Injectable({
    providedIn: 'root'
})
export class ReceivedMessageUpdaterService {

    constructor(private readonly httpClient: HttpClient) {
    }

    public deleteMessage(context: Context, messageId: string): Observable<ReceivedMessage> {
        return this.httpClient.delete<ReceivedMessage>('api/sessions/' + context.currentSessionId + '/messages/' + messageId);
    }

    public markMessageAsRead(context: Context, messageIndex: number): Observable<void> {
        return this.httpClient.put<void>('api/sessions/' + context.currentSessionId + '/messages/' + messageIndex + '/markAsRead', {});
    }
}

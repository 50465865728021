import {StudiesNames} from '../studies-names';
import {
    EquityComparisonComponent
} from '../competitive-intelligence/financial-comparisons/equity-comparison/equity-comparison.component';
import {
    AccumulatedNetResultComparisonComponent
} from '../competitive-intelligence/financial-comparisons/accumulated-net-result-comparison/accumulated-net-result-comparison.component';
import {StudyRouteDefinition} from '../study-route-definition';

export const financialComparisonsChildrenRoutes: StudyRouteDefinition[] = [
    new StudyRouteDefinition(StudiesNames.EQUITY_COMPARISON,
        [{path: '', component: EquityComparisonComponent}]
    ),
    new StudyRouteDefinition(StudiesNames.ACCUMULATED_NET_RESULT_COMPARISON,
        [{path: '', component: AccumulatedNetResultComparisonComponent}]
    )
];

import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    InitialBalanceSheetParametersLoaderService
} from '../../../parameters/services/initial-balance-sheet/initial-balance-sheet-parameters-loader.service';
import {ContextService} from '../../../../context/context.service';
import {Subscription} from 'rxjs';
import {filter, flatMap} from 'rxjs/operators';

@Component({
    selector: 'app-generalities',
    templateUrl: './generalities.component.html',
    styleUrls: ['./generalities.component.scss'],
    host: {'class': 'cell auto grid-y cell-block-y blockContent'}
})
export class GeneralitiesComponent implements OnInit, OnDestroy {
    private contextSubscription: Subscription;
    public initialCapital: number;

    constructor(
        private readonly contextService: ContextService,
        private readonly initialBalanceSheetParametersLoaderService: InitialBalanceSheetParametersLoaderService
    ) {
    }

    ngOnInit() {
        this.contextSubscription = this.contextService.context.pipe(
            filter(context => context !== null && context !== undefined && context.currentSessionId !== undefined),
            flatMap(context => this.initialBalanceSheetParametersLoaderService.loadParameters(context))
        ).subscribe(
            initialBalanceSheetParameters => this.initialCapital = initialBalanceSheetParameters.initialCapital
        );
    }

    ngOnDestroy(): void {
        this.contextSubscription.unsubscribe();
    }
}

import {StudiesNames} from '../studies-names';
import {ExternalLoadsDetailComponent} from './external-loads-detail/external-loads-detail.component';
import {TurnoverComponent} from './turnover/turnover.component';
import {PurchasesStudyComponent} from './purchases-study/purchases-study.component';
import {HumanResourcesStudyComponent} from './human-resources/human-resources-study.component';
import {ExceptionalResultComponent} from "./exceptional-result/exceptional-result.component";
import {FinancialResultComponent} from "./financial-result/financial-result.component";
import {TaxesStudyComponent} from "./taxes-study/taxes-study.component";
import {DepreciationChargesStudyComponent} from './depreciation-charges-study/depreciation-charges-study.component';
import {WineStocksComponent} from "./wine-stocks/wine-stocks.component";
import {StudyRouteDefinition} from '../study-route-definition';

export const accountsDetailsChildrenRoutes: StudyRouteDefinition[] = [
    new StudyRouteDefinition(
        StudiesNames.EXTERNAL_LOADS_DETAILS,
        [{
            path: '',
            component: ExternalLoadsDetailComponent
        }]
    ),
    new StudyRouteDefinition(
        StudiesNames.TURNOVER,
        [{
            path: '',
            component: TurnoverComponent
        }]
    ),
    new StudyRouteDefinition(
        StudiesNames.PURCHASES,
        [{
            path: '',
            component: PurchasesStudyComponent
        }]
    ),
    new StudyRouteDefinition(
        StudiesNames.WINE_STOCKS,
        [{
            path: '',
            component: WineStocksComponent
        }]
    ),
    new StudyRouteDefinition(
        StudiesNames.HUMAN_RESOURCES,
        [{
            path: '',
            component: HumanResourcesStudyComponent
        }]
    ),
    new StudyRouteDefinition(
        StudiesNames.EXCEPTIONAL_RESULT,
        [{
            path: '',
            component: ExceptionalResultComponent
        }]
    ),
    new StudyRouteDefinition(
        StudiesNames.FINANCIAL_RESULT,
        [{
            path: '',
            component: FinancialResultComponent
        }]
    ),
    new StudyRouteDefinition(
        StudiesNames.TAXES,
        [{
            path: '',
            component: TaxesStudyComponent
        }]
    ),
    new StudyRouteDefinition(
        StudiesNames.DEPRECIATION_CHARGES,
        [{
            path: '',
            component: DepreciationChargesStudyComponent
        }]
    ),
];

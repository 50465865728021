import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RestaurantParametersComponent} from './restaurant-parameters.component';
import {DiningRoomsParametersComponent} from './dining-rooms-parameters/dining-rooms-parameters.component';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {TabsModule} from '../../tabs/tabs.module';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentsModule} from '../../../components/components.module';
import {ParametersComponentsModule} from '../components/parameters-components.module';
import {IngredientsPurchaseParametersComponent} from './ingredients-purchase-parameters/ingredients-purchase-parameters.component';
import {SalesRepartitionParametersComponent} from './sales-repartition-parameters/sales-repartition-parameters.component';

export const restaurantParametersRoutes: Routes = [
    {
        path: '',
        component: RestaurantParametersComponent,
        children: [
            {
                path: '',
                redirectTo: 'dining-rooms',
                pathMatch: 'full'
            },
            {
                path: 'dining-rooms',
                component: DiningRoomsParametersComponent
            },
            {
                path: 'ingredients-purchase',
                component: IngredientsPurchaseParametersComponent
            },
            {
                path: 'sales-repartition',
                component: SalesRepartitionParametersComponent
            }
        ]
    }
]

@NgModule({
    declarations: [RestaurantParametersComponent, DiningRoomsParametersComponent, IngredientsPurchaseParametersComponent, SalesRepartitionParametersComponent],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        TabsModule,
        ComponentsModule,
        ParametersComponentsModule
    ]
})
export class RestaurantParametersModule {
}

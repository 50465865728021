import {Component} from '@angular/core';
import {AbstractDecisionComponent} from '../../abstract-decision-component';
import {Score} from '../../generics/score-image/score-image.component';
import {Context} from '../../../../context/context';
import {CommunicationMarkLoaderService} from './communication-mark-loader.service';
import {CommunicationMarkUpdaterService} from './communication-mark-updater.service';
import {CommunicationMarkWeightLoaderService} from './communication-mark-weight-loader.service';
import {Observable} from 'rxjs';

@Component({
    templateUrl: './animator-communication-task-mark-decision.component.html',
    styleUrls: ['./animator-communication-task-mark-decision.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class AnimatorCommunicationTaskMarkDecisionComponent extends AbstractDecisionComponent<number, number> {
    constructor(communicationMarkLoaderService: CommunicationMarkLoaderService,
                communicationMarkUpdaterService: CommunicationMarkUpdaterService,
                private readonly markWeightLoaderService: CommunicationMarkWeightLoaderService) {
        super(
            communicationMarkLoaderService,
            communicationMarkUpdaterService,
            [],
            'decisions.animator.mark.saved'
        );
    }

    protected computeDecisionQuality(scores: Score[], parameters: number): number {
        return 0;
    }

    protected loadParameters(context: Context): Observable<number> {
        return this.markWeightLoaderService.loadParameters(context);
    }

    protected updateScores(decision: number, parameters: number): void {
    }

}

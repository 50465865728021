import {GenericParametersComponent} from './generic-parameters.component';

export abstract class GenericSessionAndPeriodParametersComponent<T> extends GenericParametersComponent<T> {
    arePeriodParametersReadonly(): boolean {
        const context = this.context;
        return context && context.currentSession && context.currentPeriod !== context.currentSession.currentPeriod;
    }

    areSessionParametersReadonly(): boolean {
        const context = this.context;
        return context && context.currentSession.currentPeriod > '0101';
    }
}

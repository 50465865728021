import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Route} from '@angular/router';
import {teamDecisionsRoutes} from './team-decisions/team-decisions-routing.module';
import {TeamDecisionsModule} from './team-decisions/team-decisions.module';
import {TeamDecisionsComponent} from './team-decisions/team-decisions.component';
import {AccountComponent} from './account/account.component';
import {StudiesModule} from './studies/studies.module';
import {MailBoxComponent} from './mail-box/mail-box.component';
import {ToolsComponent} from './tools/tools.component';
import {RankingComponent} from './ranking/ranking.component';
import {evaluationsRoutes} from './evaluations/evaluations-routing.module';
import {CanActivateAccountGuard} from './account/can-activate-account.guard';
import {CanActivateRankingGuard} from './ranking/can-activate-ranking.guard';
import {AreaCompetitionComponent} from './area-competition/area-competition.component';
import {parametersRoutes} from './parameters/parameters.module';
import {IntroductionComponent} from './introduction/introduction.component';
import {GeneralitiesComponent} from './introduction/services/generalities/generalities.component';
import {TranslateModule} from '@ngx-translate/core';
import {PositioningComponent} from './introduction/services/positioning/positioning.component';
import {HumanResourcesComponent} from './introduction/services/human-resources/human-resources.component';
import {RoomsComponent} from './introduction/services/rooms/rooms.component';
import {MenusComponent} from './introduction/services/menus/menus.component';
import {ComponentsModule} from '../components/components.module';
import {SessionBlockedComponent} from './session-blocked/session-blocked.component';
import {CanActivateMailBoxGuard} from './mail-box/can-activate-mail-box.guard';
import {sessionBlockedRoute} from './session-locked-for-user.service';
import {IsNotSimulationOrRevertStartedGuard} from "./team-decisions/guards/is-not-simulation-or-revert-started-guard.service";
import {IsSimulationStartedGuard} from './team-decisions/guards/is-simulation-started.guard';
import {SimulationPageComponent} from './team-decisions/period-completion/simulation-page/simulation-page.component';
import {IsRevertStartedGuard} from './team-decisions/guards/is-revert-started.guard';
import {RevertPageComponent} from './team-decisions/period-completion/revert-page/revert-page.component';

export const appMainChildrenRoutes: Array<Route> = [
    {
        path: '',
        canActivate: [IsNotSimulationOrRevertStartedGuard],
        redirectTo: 'session/decisions',
        pathMatch: 'full'
    },
    {
        path: 'session/decisions',
        children: teamDecisionsRoutes
    },
    {
        path: 'session/:id',
        canActivate: [IsNotSimulationOrRevertStartedGuard],
        component: TeamDecisionsComponent
    },
    {
        path: 'account',
        component: AccountComponent,
        canActivate: [CanActivateAccountGuard, IsNotSimulationOrRevertStartedGuard]
    },
    {
        path: 'introduction',
        canActivate: [IsNotSimulationOrRevertStartedGuard],
        component: IntroductionComponent

    },
    {
        path: 'mailbox',
        component: MailBoxComponent,
        canActivate: [CanActivateMailBoxGuard, IsNotSimulationOrRevertStartedGuard]
    },
    {
        path: 'tools',
        canActivate: [IsNotSimulationOrRevertStartedGuard],
        component: ToolsComponent
    },
    {
        path: 'ranking',
        component: RankingComponent,
        canActivate: [CanActivateRankingGuard, IsNotSimulationOrRevertStartedGuard]
    },
    {
        path: 'evaluations',
        canActivate: [IsNotSimulationOrRevertStartedGuard],
        children: evaluationsRoutes
    },
    {
        path: 'parameters',
        canActivate: [IsNotSimulationOrRevertStartedGuard],
        children: parametersRoutes
    },
    {
        path: 'areaCompetition',
        canActivate: [IsNotSimulationOrRevertStartedGuard],
        component: AreaCompetitionComponent
    },
    {
        path: 'simulation',
        canActivate: [IsSimulationStartedGuard],
        component: SimulationPageComponent
    },
    {
        path: 'revert/:period',
        canActivate: [IsRevertStartedGuard],
        component: RevertPageComponent
    },
    {
        path: sessionBlockedRoute,
        canActivate: [IsNotSimulationOrRevertStartedGuard],
        component: SessionBlockedComponent
    }
];

@NgModule({
    declarations: [
        GeneralitiesComponent,
        PositioningComponent,
        HumanResourcesComponent,
        RoomsComponent,
        MenusComponent,
        SessionBlockedComponent
    ],
    exports: [
        GeneralitiesComponent,
        PositioningComponent,
        HumanResourcesComponent,
        RoomsComponent,
        MenusComponent
    ],
    imports: [
        CommonModule,
        TeamDecisionsModule,
        StudiesModule,
        TranslateModule,
        ComponentsModule
    ]
})
export class AppMainModule {
}

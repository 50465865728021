import {StudiesNames} from '../studies-names';
import {
    AdvertisementBudgetComparisonComponent
} from '../competitive-intelligence/advertisement-budget-comparison/advertisement-budget-comparison.component';
import {AreaCompetitionComponent} from '../../area-competition/area-competition.component';
import {
    CookingQualityComparisonComponent
} from '../competitive-intelligence/cooking-quality-comparison/cooking-quality-comparison.component';
import {
    CustomerCareQualityComparisonComponent
} from '../competitive-intelligence/customer-care-quality-comparison/customer-care-quality-comparison.component';
import {
    LayoutQualityComparisonComponent
} from '../competitive-intelligence/layout-quality-comparison/layout-quality-comparison.component';
import {
    MaintenanceQualityComparisonComponent
} from '../competitive-intelligence/maintenance-quality-comparison/maintenance-quality-comparison.component';
import {
    DishesSalesPricesComparisonComponent
} from '../competitive-intelligence/dishes-sales-prices-comparison/dishes-sales-prices-comparison.component';
import {
    DishSalesPricesComparisonComponent
} from '../competitive-intelligence/dishes-sales-prices-comparison/dish-sales-prices-comparison/dish-sales-prices-comparison.component';
import {
    DishesQualityComparisonComponent
} from '../competitive-intelligence/dishes-quality-comparison/dishes-quality-comparison.component';
import {
    DishQualityComparisonComponent
} from '../competitive-intelligence/dishes-quality-comparison/dish-quality-comparison/dish-quality-comparison.component';
import {
    WinesQualityComparisonComponent
} from '../competitive-intelligence/wines-quality-comparison/wines-quality-comparison.component';
import {
    WineQualityComparisonComponent
} from '../competitive-intelligence/wines-quality-comparison/wine-quality-comparison/wine-quality-comparison.component';
import {MarketSharesComponent} from '../competitive-intelligence/market-shares/market-shares.component';
import {StudyRouteDefinition} from '../study-route-definition';

export const competitiveIntelligenceChildrenRoutes: StudyRouteDefinition[] = [
    new StudyRouteDefinition(
        StudiesNames.ADVERTISEMENT_BUDGET_COMPARISON,
        [{
            path: '',
            component: AdvertisementBudgetComparisonComponent
        }]
    ),
    new StudyRouteDefinition(
        StudiesNames.AREA_COMPETITION,
        [{
            path: '',
            component: AreaCompetitionComponent
        }]
    ),
    new StudyRouteDefinition(
        StudiesNames.COOKING_QUALITY_COMPARISON, [{
            path: '',
            component: CookingQualityComparisonComponent
        }]),
    new StudyRouteDefinition(
        StudiesNames.CUSTOMER_CARE_QUALITY_COMPARISON, [{
            path: '',
            component: CustomerCareQualityComparisonComponent
        }]),
    new StudyRouteDefinition(
        StudiesNames.LAYOUT_QUALITY_COMPARISON, [{
            path: '',
            component: LayoutQualityComparisonComponent
        }]),
    new StudyRouteDefinition(
        StudiesNames.MAINTENANCE_QUALITY_COMPARISON, [{
            path: '',
            component: MaintenanceQualityComparisonComponent
        }]),
    new StudyRouteDefinition(StudiesNames.SALES_PRICES_COMPARISON,
        [{
            path: '',
            component: DishesSalesPricesComparisonComponent,
            children: [
                {
                    path: ':dishName',
                    component: DishSalesPricesComparisonComponent,
                },
            ]
        }]),
    new StudyRouteDefinition(
        StudiesNames.DISHES_QUALITY_COMPARISON,
        [{
            path: '',
            component: DishesQualityComparisonComponent,
            children: [
                {
                    path: ':dishName',
                    component: DishQualityComparisonComponent,
                },
            ]
        }]
    ),
    new StudyRouteDefinition(StudiesNames.WINES_QUALITY_COMPARISON,
        [{
            path: '',
            component: WinesQualityComparisonComponent,
            children: [
                {
                    path: ':dishName',
                    component: WineQualityComparisonComponent,
                },
            ]
        }]
    ),
    new StudyRouteDefinition(StudiesNames.MARKET_SHARES,
        [{
            path: '',
            redirectTo: 'monthly',
            pathMatch: 'full'
        }, {
            path: ':periodicity',
            component: MarketSharesComponent
        }]
    ),
];

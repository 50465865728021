import {Component, OnInit} from '@angular/core';
import {TeamDecisionsLoaderService} from "../team-decisions-loader.service";
import {DecisionStatusData} from "../team-decisions";
import {ContextService} from "../../../context/context.service";
import {Context} from "../../../context/context";
import {TeamDecisionsClosureService} from "../team-decisions-closure.service";
import {Team} from "../../../login/team";
import {NotificationService} from '../../../notification.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-player-decisions-closure',
    templateUrl: './player-decisions-closure.component.html',
    styleUrls: ['./player-decisions-closure.component.scss'],
    host: {'class': 'cell auto grid-y decisionContainer _decision'}
})
export class PlayerDecisionsClosureComponent implements OnInit{
    private statusData: DecisionStatusData;
    private session: string;
    private period: string;
    private team: Team;

    constructor(
        private teamDecisionsLoaderService: TeamDecisionsLoaderService,
        private decisionClosureService: TeamDecisionsClosureService,
        private contextService: ContextService,
        private readonly notificationService: NotificationService,
        private readonly router: Router
    ) {
    }

    ngOnInit(): void {
        this.contextService.context.subscribe(
            context => {
                this.session = context.currentSessionId;
                this.period = context.currentSession.currentPeriod;
                this.team = context.currentTeam;
                if (this.session && this.period && this.team) {
                    this.loadDecision(new Context(context.currentSessionId,
                        context.currentSessionName,
                        context.currentMetaScenarioId,
                        context.currentSession.currentPeriod,
                        context.currentTeam,
                        context.currentSession,
                        context.currentRestaurantStandingInformation,
                        context.currentLanguage,
                        context.currentTeamArea));
                }
            }
        );
    }

    closeDecision() {
        this.decisionClosureService.insertClosureStatus(
            this.session,
            this.period,
            this.team.teamNumber,
            'TEAM_CLOSURE'
        ).subscribe(
            teamDecisions => {
                this.statusData = this.decisionClosureService.completeClosureLogs(teamDecisions.statusData);
                this.notificationService.success(
                    'toast.player.decisions.closure.title',
                    'toast.player.decisions.closure.close.success.message'
                );
            }
        );
    }

    cancelClosure() {
        this.decisionClosureService.updateOpenStatus(
            this.session,
            this.period,
            this.team.teamNumber,
            'IN_PROGRESS'
        ).subscribe(
            teamDecisions => {
                this.statusData = this.decisionClosureService.completeClosureLogs(teamDecisions.statusData);
                this.notificationService.success(
                    'toast.player.decisions.closure.title',
                    'toast.player.decisions.closure.reopen.success.message'
                );
                this.router.navigateByUrl('sessions/decisions').catch();
            }
        );
    }

    protected loadDecision(context: Context): void {
        this.teamDecisionsLoaderService.loadDecisionFromContext(context)
            .subscribe(teamDecisions => this.statusData = teamDecisions.statusData);
    }
}

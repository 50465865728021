import { Injectable } from '@angular/core';
import {AbstractPeriodParametersUpdaterService} from '../../services/abstract-period-parameters-updater-service';
import {AssignmentsParameters} from './assignments-parameters';

@Injectable({
  providedIn: 'root'
})
export class AssignmentsParametersUpdaterService extends AbstractPeriodParametersUpdaterService<AssignmentsParameters>{

  constructor() {
      super('assignments');
  }
}

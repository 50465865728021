import {Routes} from '@angular/router';
import {CommunicationParametersComponent} from './communication-parameters/communication-parameters.component';
import {GlobalStandingParametersComponent} from './global-standing-parameters/global-standing-parameters.component';
import {PotentialMarketParametersComponent} from './potential-market-parameters/potential-market-parameters.component';
import {MenuStandingParametersComponent} from './menu-standing-parameters/menu-standing-parameters.component';

export const standingParametersChildrenRoutes: Routes = [
    {
        path: 'global',
        component: GlobalStandingParametersComponent
    },
    {
        path: 'potential-market',
        component: PotentialMarketParametersComponent
    },
    {
        path: 'communication',
        component: CommunicationParametersComponent
    },
    {
        path: 'menu',
        component: MenuStandingParametersComponent
    }
];

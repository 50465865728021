import {CheckRestaurantTypeService} from "./check-restaurant-type.service";
import {map} from "rxjs/operators";
import {AbstractDecisionComponent} from "../abstract-decision-component";
import {AbstractDecisionLoaderService} from "../abstract-decision-loader.service";
import {AbstractDecisionUpdaterService} from "../abstract-decision-updater.service";
import {Score} from "../generics/score-image/score-image.component";
import {DecisionConfirmationPopupService} from "./decision-confirmation-popup.service";
import {LocatorService} from "../../../locator.service";
import {RestaurantStandingInformationLoaderService} from '../../../context/restaurant-standing-information-loader.service';

export abstract class CheckRestaurantTypeDecisionComponent<T, U> extends AbstractDecisionComponent<T, U> {
    private readonly checkRestaurantTypeService: CheckRestaurantTypeService<T>;
    private readonly decisionConfirmationPopupService: DecisionConfirmationPopupService;
    private readonly restaurantStandingInformationLoaderService: RestaurantStandingInformationLoaderService;

    protected constructor(
        decisionLoader: AbstractDecisionLoaderService<T>,
        decisionUpdater: AbstractDecisionUpdaterService<T>,
        scores: Score[],
        decisionType: string,
        savedSuccessfullyKey: string,
        doAfterDecisionLoaded?: Function
    ) {
        super(decisionLoader, decisionUpdater, scores, savedSuccessfullyKey, doAfterDecisionLoaded);
        this.checkRestaurantTypeService = new CheckRestaurantTypeService<T>(decisionType);
        this.decisionConfirmationPopupService = LocatorService.injector.get(DecisionConfirmationPopupService);
        this.restaurantStandingInformationLoaderService = LocatorService.injector.get(RestaurantStandingInformationLoaderService);
    }

    saveRestaurantTypeChangeDecision(): void {
        this.callSaveAndResetDirtyAndDisplayNotifications()
            .subscribe(() => {
                    this.contextService.reloadRestaurantStandingInformation()
                }
            );
    }

    save() {
        this.checkRestaurantType();
    }

    public checkRestaurantType() {
        this.checkRestaurantTypeService.checkRestaurantTypeChange(
            this.session,
            this.period,
            this.team.teamNumber,
            this.decision)
            .pipe(
                map(restaurantTypeChange => {
                        if (restaurantTypeChange.currentType !== restaurantTypeChange.targetType) {
                            this.decisionConfirmationPopupService.showConfirmationPopup(
                                () => this.saveRestaurantTypeChangeDecision(),
                                restaurantTypeChange
                            );
                        } else {
                            this.saveRestaurantTypeChangeDecision();
                        }
                    }
                )
            ).subscribe();
    }
}

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'kiwi-decision-content',
  templateUrl: './decision-content.component.html',
  styleUrls: ['./decision-content.component.scss'],
  host: {'class': 'cell auto grid-y'}
})
export class DecisionContentComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}

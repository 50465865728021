import {EventEmitter, Output} from "@angular/core";

export abstract class AbstractPopupComponent {
  @Output()
  closed = new EventEmitter();

  public close() {
    this.closed.emit();
  }
}

import {Injectable} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {tap} from "rxjs/operators";
import {forkJoin} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private readonly decisionsSavedToastTitleKey = 'toast.decision.saved.title';

    constructor(
        private toastrService: ToastrService,
        private translateService: TranslateService
    ) {
    }

    public errors(messages: Array<string>): void {
        console.log(messages);
        this.error('<ul>' + messages.map(message => '<li>' + message + '</li>').join('') + '</ul>');
    }

    public error(messageKey?: string): void {
        if (!messageKey)
            messageKey = 'toast.save.error';

        this.translateService.get(messageKey.replace('\n', '')).pipe(tap(message => this.toastrService.error(message)))
            .subscribe();
    }

    public decisionsSavedSuccessfully(messageKey: string): void {
        this.success(this.decisionsSavedToastTitleKey, messageKey);
    }

    public success(titleKey: string, messageKey: string): void {
        forkJoin(
            this.translateService.get(titleKey),
            this.translateService.get(messageKey)
        ).pipe(tap(([title, message]) =>
            this.toastrService.success(message, title, {
            timeOut: 5000,
            closeButton: false
            })
        )).subscribe();
    }

    public warning(message: string): void {
        this.toastrService.warning(message);
    }

    public info(message: string): void {
        this.toastrService.info(message);
    }
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TeamDecisionsComponent} from './team-decisions.component';
import {FinancialDecisionComponent} from './financial-decision/financial-decision.component';
import {DiningRoomsDecisionComponent} from './dining-rooms-decision/dining-rooms-decision.component';
import {menuDecisionRoutes} from './menu-decision/menu-decision-routing.module';
import {AreaDecisionComponent} from './area-decision/area-decision.component';
import {periodCompletionRoutes} from './period-completion/period-completion-routing.module';
import {ArchetypeDecisionComponent} from './archetype-decision/archetype-decision.component';
import {PlayerDecisionsClosureComponent} from './player-decisions-closure/player-decisions-closure.component';
import {studiesRoutes} from '../studies/studies.module';
import {CanActivateTeamDecisionsGuard} from './guards/can-activate-team-decisions.guard';
import {
    AnimatorProductsAndChargesDecisionComponent
} from './financial-decision/animator-products-and-charges-decision/animator-products-and-charges-decision.component';
import {
    PlayerFinancialDecisionComponent
} from './financial-decision/player-financial-decision/player-financial-decision.component';
import {CanActivateAnimatorGuard} from './guards/can-activate-animator.guard';
import {InsurancesDecisionComponent} from './financial-decision/insurances-decision/insurances-decision.component';
import {CanDeactivateModificationComponentGuard} from '../guards/can-deactivate-modification-component.guard';
import {staffDecisionRoute} from './staff-decision/staff-decision-route';
import {communicationDecisionRoute} from './communication-decision/communication-decision-route';
import {CanActivateTeamDecisionsHomeGuard} from './guards/can-activate-team-decisions-home-guard';
import {SimulationPageComponent} from './period-completion/simulation-page/simulation-page.component';
import {IsSimulationStartedGuard} from './guards/is-simulation-started.guard';
import {IsNotSimulationOrRevertStartedGuard} from './guards/is-not-simulation-or-revert-started-guard.service';
import {IsRevertStartedGuard} from './guards/is-revert-started.guard';
import {RevertPageComponent} from './period-completion/revert-page/revert-page.component';


export const teamDecisionsRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: TeamDecisionsComponent,
                canActivate: [CanActivateTeamDecisionsHomeGuard, IsNotSimulationOrRevertStartedGuard]
            },
            {
                path: 'financial',
                canActivate: [CanActivateTeamDecisionsGuard, IsNotSimulationOrRevertStartedGuard],
                component: FinancialDecisionComponent,
                children: [
                    {
                        path: '',
                        component: PlayerFinancialDecisionComponent,
                        canDeactivate: [CanDeactivateModificationComponentGuard]
                    },
                    {
                        path: 'animatorProductsAndCharges',
                        component: AnimatorProductsAndChargesDecisionComponent,
                        canActivate: [CanActivateAnimatorGuard],
                        canDeactivate: [CanDeactivateModificationComponentGuard]
                    },
                    {
                        path: 'insurances',
                        component: InsurancesDecisionComponent,
                        canDeactivate: [CanDeactivateModificationComponentGuard]
                    }
                ]
            },
            {
                path: 'dining-rooms',
                component: DiningRoomsDecisionComponent,
                canActivate: [CanActivateTeamDecisionsGuard, IsNotSimulationOrRevertStartedGuard],
                canDeactivate: [CanDeactivateModificationComponentGuard]
            },
            communicationDecisionRoute,
            staffDecisionRoute,
            {
                path: 'menu',
                canActivate: [IsNotSimulationOrRevertStartedGuard],
                canActivateChild: [CanActivateTeamDecisionsGuard],
                children: menuDecisionRoutes
            },

            {
                path: 'area',
                canActivate: [IsNotSimulationOrRevertStartedGuard],
                component: AreaDecisionComponent
            },

            {
                path: 'archetypes',
                canActivate: [IsNotSimulationOrRevertStartedGuard],
                component: ArchetypeDecisionComponent
            },

            {
                path: 'period-completion',
                canActivate: [IsNotSimulationOrRevertStartedGuard],
                canActivateChild: [CanActivateTeamDecisionsGuard],
                children: periodCompletionRoutes
            },

            {
                path: 'player-decisions-closure',
                component: PlayerDecisionsClosureComponent,
                canActivate: [CanActivateTeamDecisionsGuard, IsNotSimulationOrRevertStartedGuard]
            },

            {
                path: 'studies',
                canActivate: [IsNotSimulationOrRevertStartedGuard],
                canActivateChild: [CanActivateTeamDecisionsGuard],
                children: studiesRoutes
            }
        ]
    }
];

@NgModule({
    exports: [RouterModule]
})

export class TeamDecisionsRoutingModule {

}

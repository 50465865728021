import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MessageCardComponent} from "./message-card/message-card.component";
import {MessagesListComponent} from "./messages-list/messages-list.component";
import {MailBoxComponent} from "./mail-box.component";
import {ComponentsModule} from "../../components/components.module";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";
import { AddressBookComponent } from './address-book/address-book.component';
import { NewMessageComponent } from './new-message/new-message.component';
import { MessageContentComponent } from './message-content/message-content.component';
import {CKEditorModule} from "ng2-ckeditor";

@NgModule({
    declarations: [
        MailBoxComponent,
        MessageCardComponent,
        MessagesListComponent,
        AddressBookComponent,
        NewMessageComponent,
        MessageContentComponent,
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        TranslateModule,
        FormsModule,
        CKEditorModule
    ]
})
export class MailBoxModule {
}

import {Component} from '@angular/core';
import {AbstractStudyComponent} from '../../abstract-study.component';
import {of} from 'rxjs';
import {CategoryName} from '../../category-name';

interface DepreciationStudy {
    readonly furnitureDepreciationList: RestaurantEquipmentDepreciationStudy[],
    readonly tablewareDepreciationList: RestaurantEquipmentDepreciationStudy[],
    readonly totalFurnitureDepreciation: TotalDepreciationDetail,
    readonly totalTablewareDepreciation: TotalDepreciationDetail,
    readonly totalDepreciationCharges: TotalDepreciationDetail
}

interface RestaurantEquipmentDepreciationStudy {
    readonly roomNumber: number,
    readonly ageInMonths: number,
    readonly initialValue: number,
    readonly depreciation: number,
    readonly totalDepreciation: number,
    readonly currentValue: number
}


interface TotalDepreciationDetail {
    readonly initialValue: number,
    readonly depreciation: number,
    readonly totalDepreciation: number,
    readonly currentValue: number
}

@Component({
    templateUrl: './depreciation-charges-study.component.html',
    styleUrls: ['./depreciation-charges-study.component.scss'],
    host: {'class': 'cell auto grid-y'}

})
export class DepreciationChargesStudyComponent extends AbstractStudyComponent<DepreciationStudy> {
    constructor() {
        super(CategoryName.ACCOUNTS_DETAIL, of('DEPRECIATION_CHARGES'));
    }
}

import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ContextService} from '../../../../context/context.service';
import {Session} from '../../../../login/session';
import {SimulatedPeriodsLoaderService} from '../revert-period/simulated-periods-loader.service';
import {SimulatedPeriod} from '../revert-period/simulated-period';
import {tap} from 'rxjs/operators';
import {ConnectedUserService} from '../../../../context/connected-user.service';
import {Observable} from 'rxjs';
import {SimulationLaunchPopupService} from './simulation-launch-popup.service';
import {AllDecisionsAreClosedLoaderService} from './all-decisions-are-closed-loader.service';

@Component({
    selector: 'kiwi-simulation-launch',
    templateUrl: './simulation-launch.component.html',
    styleUrls: ['./simulation-launch.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class SimulationLaunchComponent implements OnInit {
    private sessionId: string;
    private period: string;
    private simulatedPeriods: SimulatedPeriod[];
    private simulationLaunchButtonActive: boolean = false;

    constructor(
        private httpClient: HttpClient,
        private contextService: ContextService,
        private simulatedPeriodsLoaderService: SimulatedPeriodsLoaderService,
        private connectedUserService: ConnectedUserService,
        private readonly simulationLaunchPopupService: SimulationLaunchPopupService,
        private readonly allDecisionsAreClosedLoaderService: AllDecisionsAreClosedLoaderService
    ) {
    }

    ngOnInit(): void {
        this.contextService.context.subscribe(context => {
            if (context.currentSession) {
                this.sessionId = context.currentSessionId;
                this.period = context.currentSession.currentPeriod;
                this.simulatedPeriodsLoaderService.loadSimulatedPeriods(this.sessionId)
                    .subscribe(
                        simulatedPeriods => this.simulatedPeriods = simulatedPeriods
                    );
                this.allDecisionsAreClosedLoaderService.loadAllDecisionsAreClosed(
                    context.currentSessionId,
                    context.currentPeriod
                ).subscribe(
                    allDecisionsAreClosed => this.simulationLaunchButtonActive = allDecisionsAreClosed
                );
            }
        });
    }

    showSimulationConfirmationPopup() {
        const currentSession = this.contextService.context.getValue().currentSession;

        this.simulationLaunchPopupService.showSimulationLaunchPopup(
            this.period,
            this.connectedUserService.animatorTokenBalance.getValue(),
           currentSession.baseMetaScenario.unitDecisionTokenCost * currentSession.teamCount,
            currentSession._id,
            currentSession.demonstration,
            () => this.launchSimulation()
        );
    }

    launchSimulation(): Observable<Session> {
        return this.httpClient.put<Session>('/api/sessions/' + this.sessionId + '/periods/' + this.period + '/simulation', null)
            .pipe(
                tap(session => {
                        this.contextService.updateCurrentSession(session);
                        this.connectedUserService.loadAnimatorTokenBalance(this.connectedUserService.userEvents.getValue().user);
                    }
                ));
    }
}

import {BrowserModule} from '@angular/platform-browser';
import {Injector, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {HeaderComponent} from './app-main/header/header.component';
import {FooterComponent} from './footer/footer.component';
import {TeamDecisionsModule} from "./app-main/team-decisions/team-decisions.module";
import {HttpClient, HttpClientModule, HttpHeaders} from "@angular/common/http";
import {registerLocaleData} from "@angular/common";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/fr';
import {FormsModule} from "@angular/forms";
import {MainMenuComponent} from './app-main/menu/main-menu.component';
import {AppMainComponent} from './app-main/app-main.component';
import {ForgottenPasswordPopupComponent} from './login/forgotten-password-popup/forgotten-password-popup.component';
import {SupportPopupComponent} from "./app-main/menu/support-popup/support-popup.component";
import {ComponentsModule} from "./components/components.module";
import {ChartsModule} from "ng2-charts";
import {ContextModule} from "./context/context.module";
import {ToastrModule} from "ngx-toastr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {LocatorService} from "./locator.service";
import {AccountComponent} from "./app-main/account/account.component";
import {IsLoggedInGuard} from "./guard/is-logged-in.guard";
import {SchedulePopupComponent} from './app-main/schedule-popup/schedule-popup.component';
import {ToolsComponent} from './app-main/tools/tools.component';
import {RankingComponent} from './app-main/ranking/ranking.component';
import {PipesModule} from "./pipes/pipes.module";
import {EvaluationsModule} from "./app-main/evaluations/evaluations.module";
import {MailBoxModule} from "./app-main/mail-box/mail-box.module";
import {PeriodTimeComponent} from "./app-main/header/period-time/period-time.component";
import {PasswordChangePopupComponent} from './password-change-popup/password-change-popup.component';
import {AreaCompetitionComponent} from "./app-main/area-competition/area-competition.component";
import {RestaurantTypeRivalsComponent} from "./app-main/area-competition/restaurant-type-rivals/restaurant-type-rivals.component";
import {AreaCompetitionHeaderComponent} from "./app-main/area-competition/area-competition-header/area-competition-header.component";
import {ParametersModule} from './app-main/parameters/parameters.module';
import {IntroductionComponent} from "./app-main/introduction/introduction.component";
import {AppMainModule} from "./app-main/app-main.module";

export function translateLoaderFactory(http: HttpClient) {
    return {
        getTranslation(lang: string): any {
            return http.get('/api/i18n/labels.json',
                {headers: new HttpHeaders({'Accept-Language': lang})});
        }
    };
}

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HeaderComponent,
        PeriodTimeComponent,
        FooterComponent,
        MainMenuComponent,
        AppMainComponent,
        ForgottenPasswordPopupComponent,
        SupportPopupComponent,
        AccountComponent,
        IntroductionComponent,
        SchedulePopupComponent,
        ToolsComponent,
        RankingComponent,
        PasswordChangePopupComponent,
        AreaCompetitionComponent,
        RestaurantTypeRivalsComponent,
        AreaCompetitionHeaderComponent
    ],
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot({
            enableHtml: true,
            positionClass: "toast-bottom-right",
            closeButton: true
        }),
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        TeamDecisionsModule,
        ComponentsModule,
        ChartsModule,
        ContextModule,
        PipesModule,
        EvaluationsModule,
        MailBoxModule,
        ParametersModule,
        AppMainModule
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'fr-FR'},
        TranslateService,
        IsLoggedInGuard
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        ForgottenPasswordPopupComponent,
        SupportPopupComponent,
        PasswordChangePopupComponent,
        SchedulePopupComponent
    ]
})
export class AppModule {
    constructor(private injector: Injector) {
        LocatorService.injector = this.injector;
    }
}

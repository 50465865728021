import {Component, OnInit} from '@angular/core';
import {ConnectedUserService} from "../../context/connected-user.service";
import {ConnectedUser} from "../../context/connected-user";
import {TranslateService} from "@ngx-translate/core";
import {LanguagesLoaderService} from "./languages-loader.service";
import {UserUpdaterService} from "./user-updater.service";
import {PasswordChangePopupService} from "../../password-change-popup/password-change-popup.service";
import {map, tap} from "rxjs/operators";
import {PlayerUpdateDto} from '../../login/user';

@Component({
    selector: 'kiwi-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
    host: {'class': 'cell auto pageContainer grid-y'}
})
export class AccountComponent implements OnInit {
    player: PlayerUpdateDto;
    languages: String[];
    private playerId: string;
    public login: string;

    constructor(
        private connectedUserService: ConnectedUserService,
        private translateService: TranslateService,
        private languagesLoaderService: LanguagesLoaderService,
        private userUpdaterService: UserUpdaterService,
        private passwordChangePopupService: PasswordChangePopupService
    ) {
    }

    ngOnInit() {
        this.connectedUserService.userEvents.subscribe(
            connectedUser => {
                this.player = PlayerUpdateDto.fromConnectedUser(connectedUser);
                this.playerId = connectedUser.user._id;
                this.login = connectedUser.name;
            }
        );
        this.languagesLoaderService.loadLanguages().subscribe(
            languages => this.languages = languages
        );
    }

    onLanguageChange(): void {
        this.translateService.use(this.player.language).subscribe();
    }

    saveUser(): void {
        this.userUpdaterService.savePlayer(this.playerId, this.player).subscribe(
            user => this.player = PlayerUpdateDto.fromUser(user)
        );
    }

    onChangePassword(): void {
        this.connectedUserService.userEvents.pipe(
            map(user => user.user._id),
            tap(userId => this.passwordChangePopupService.showPasswordChangePopup(userId))
        ).subscribe();
    }
}

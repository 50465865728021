import {RestaurantEquipment, RoomResult} from './dining-rooms-decision';

export class ComputeLayoutSellingIncomeUtils {
    private static computeRestaurantEquipmentSellingIncome(restaurantEquipment: RestaurantEquipment, roomsToRenew: number) {
        if (!RestaurantEquipment.hasQualityChanged(restaurantEquipment)) {
            return 0;
        }

        return restaurantEquipment.currentValuePerRoom * roomsToRenew;
    }

    static computeLayoutSellingIncome(
        restaurantEquipment: RestaurantEquipment,
        currentRoomsNumber: number,
        previousPeriodRoomsResults: RoomResult[],
        restaurantEquipmentResultGetter: Function
    ) {
        let layoutSellingIncome = 0;
        const previousRoomsNumber = previousPeriodRoomsResults.length;
        const roomsDifference = previousRoomsNumber - currentRoomsNumber;

        if (roomsDifference > 0) {
            layoutSellingIncome = layoutSellingIncome + previousPeriodRoomsResults
                .slice(0, roomsDifference)
                .map(roomResult => restaurantEquipmentResultGetter(roomResult).currentValue)
                .reduce((sum, roomValue) => sum + roomValue, 0);
        }

        const roomsToRenew = previousRoomsNumber < currentRoomsNumber ? previousRoomsNumber : currentRoomsNumber;
        layoutSellingIncome = layoutSellingIncome + ComputeLayoutSellingIncomeUtils.computeRestaurantEquipmentSellingIncome(restaurantEquipment, roomsToRenew);

        return layoutSellingIncome;
    }
}

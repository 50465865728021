import {Component, EventEmitter, Input, Output, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TreasuryEvaluationParameters} from "./treasury-evaluation-parameters";
import {AbstractEvaluationComponent} from '../../generic-evaluation/abstract-evaluation-component';
import {TreasuryEvaluationParametersLoaderService} from './treasury-evaluation-parameters-loader.service';
import {AbstractDecisionComponent} from '../../../team-decisions/abstract-decision-component';
import {AbstractModificationComponent} from '../../../../components/abstract-modification.component';
import {TreasuryEvaluationParametersUpdaterService} from './treasury-evaluation-parameters-updater.service';
import {Observable, Subscription} from 'rxjs';
import {ContextService} from '../../../../context/context.service';
import {filter, flatMap} from 'rxjs/operators';
import {NgForm} from '@angular/forms';

@Component({
    selector: 'kiwi-treasury-mark-criteria',
    templateUrl: './treasury-mark-criteria.component.html',
    styleUrls: ['./treasury-mark-criteria.component.scss'],
    host: {'class': 'cell auto tabsContainer-content'}
})
export class TreasuryMarkCriteriaComponent extends AbstractModificationComponent<TreasuryEvaluationParameters> implements OnInit, OnDestroy {
    private subscription: Subscription;

    @ViewChild('treasuryMarkCriteriaForm', {read: NgForm})
    treasuryMarkCriteriaForm: NgForm;

    getForm(): NgForm {
        return this.treasuryMarkCriteriaForm;
    }

    constructor(
        private readonly contextService: ContextService,
        private readonly treasuryEvaluationParametersLoaderService: TreasuryEvaluationParametersLoaderService,
        private readonly treasuryEvaluationParametersUpdaterService: TreasuryEvaluationParametersUpdaterService
    ) {
        super(
            'evaluations.criteria.save.toast.title',
            'evaluations.criteria.saved.successfully'
        );
    }

    ngOnInit(): void {
        this.subscription = this.contextService.context.pipe(
            filter(context => !!context && !!context.currentSessionId),
            flatMap(context => this.treasuryEvaluationParametersLoaderService.loadParameters(context))
        ).subscribe(treasuryEvaluationParameters => this.treasuryEvaluationParameters = treasuryEvaluationParameters);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    treasuryEvaluationParameters: TreasuryEvaluationParameters;

    protected callSave(): Observable<TreasuryEvaluationParameters> {
        return this.treasuryEvaluationParametersUpdaterService.updateParameters(this.contextService.context.getValue(), this.treasuryEvaluationParameters);
    }
}

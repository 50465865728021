export class RestaurantEquipment {
    constructor(
        private initialValuePerRoom: number,
        public initialQuality: number,
        public newQuality: number,
        public currentQuality: number,
        public currentValuePerRoom: number,
        public newValuePerRoom,
    private readonly minQuality: number,
        private readonly maxQuality: number
    ) {
    }

    static newRestaurantFromRestaurantEquipment(restaurantEquipment: RestaurantEquipment) {
        return new RestaurantEquipment(
            0,
            0,
            0,
            0,
            0,
            0,

            restaurantEquipment.minQuality,
            restaurantEquipment.maxQuality
        );
    }

    static fromRestaurantEquipment(restaurantEquipment: RestaurantEquipment): RestaurantEquipment {
        return new RestaurantEquipment(
            restaurantEquipment.initialValuePerRoom,
            restaurantEquipment.initialQuality,
            restaurantEquipment.newQuality,
            restaurantEquipment.currentQuality,
            restaurantEquipment.currentValuePerRoom,
            restaurantEquipment.newValuePerRoom,

            restaurantEquipment.minQuality,
            restaurantEquipment.maxQuality
        )
    }

    static hasQualityChanged(restaurantEquipment: RestaurantEquipment): boolean {
        return restaurantEquipment.newQuality !== restaurantEquipment.currentQuality;
    }
}

export class LayoutDecision {
    constructor(
        public furniture: RestaurantEquipment,
        public tableware: RestaurantEquipment,
        public layoutTotalQuality: number,
    ) {
    }
}

export class MaintenanceDecision {
    constructor(
        public maintenanceBudgetPerRoom: number,
        public maintenanceQuality: number
    ) {
    }
}

export class DiningRoomsDecision {
    constructor(
        public maintenanceDecision: MaintenanceDecision,
        public layoutDecision: LayoutDecision,
        public seatingCapacity: number,
        public roomsNumber: number,
        public previousPeriodRoomsResults: RoomResult[]
    ) {
    }
}

export interface RoomResult {
    tableware: RestaurantEquipmentResult;
    furniture: RestaurantEquipmentResult;
}

export interface RestaurantEquipmentResult {
    age: number;
    initialValue: number;
    depreciation: number;
    totalDepreciation: number;
    currentValue: number;
    initialQuality: number;
    currentQuality: number;
}

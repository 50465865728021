import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QualityParametersComponent} from './quality-parameters.component';
import {PerceivedQualityParametersComponent} from './perceived-quality-parameters/perceived-quality-parameters.component';
import {StaffQualityParametersComponent} from './staff-quality-parameters/staff-quality-parameters.component';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {ParametersComponentsModule} from '../components/parameters-components.module';
import {TabsModule} from '../../tabs/tabs.module';
import {ComponentsModule} from '../../../components/components.module';
import {AssignmentsWeightsParametersComponent} from './assignments-weight/assignments-weights-parameters.component';
import {qualityParametersChildrenRoutes} from './quality-parameters-children-routes';

export const qualityParametersRoutes: Routes = [
    {
        path: '',
        component: QualityParametersComponent,
        children: ([
            {
                path: '',
                redirectTo: qualityParametersChildrenRoutes[0].path,
                pathMatch: 'full'
            }
        ] as Routes).concat(qualityParametersChildrenRoutes)
    }
]

@NgModule({
    declarations: [
        QualityParametersComponent,
        PerceivedQualityParametersComponent,
        StaffQualityParametersComponent,
        AssignmentsWeightsParametersComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        ParametersComponentsModule,
        TabsModule,
        ComponentsModule
    ]
})
export class QualityParametersModule { }

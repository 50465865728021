import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {TeamDecisionsLoaderService} from "../team-decisions-loader.service";
import {TeamCacheService} from '../../../team-cache.service';
import {Context} from '../../../context/context';

@Injectable({
    providedIn: 'root'
})
export class HasChosenArchetypeService extends TeamCacheService<boolean> {

    constructor(
        private teamDecisionsLoaderService: TeamDecisionsLoaderService
    ) {
        super()
    }

    public hasChosenArchetype(): Observable<boolean> {
        return this.getFromCache(archetypeChosen => !archetypeChosen);
    }

    protected loadFromBack(context: Context): Observable<boolean> {
        return this.teamDecisionsLoaderService.loadDecisionFromContext(context).pipe(map(teamDecisions => teamDecisions.archetypeChosen));
    }
}

import {Injectable} from '@angular/core';
import {AbstractDecisionLoaderService} from "../../../abstract-decision-loader.service";
import {StaffDecision} from "../staff-decision";

@Injectable({
    providedIn: 'root'
})
export class StaffDecisionLoaderService extends AbstractDecisionLoaderService<StaffDecision> {

    constructor() {
        super('staff');
    }
}

import {Component} from '@angular/core';
import {AbstractStudyComponent} from "../../abstract-study.component";
import {CategoryName} from "../../category-name";
import {of} from "rxjs";
import {StudiesNames} from "../../studies-names";

class Taxes {
    constructor(
        public readonly grossResult: number,
        public readonly incomeTax: number,
        public readonly taxableIncome: number,
        public readonly retainedEarnings: number,

        public readonly dishesTurnoverExcludingTaxes: number,
        public readonly winesTurnoverExcludingTaxes: number,
        public readonly dishesTurnoverTaxes: number,
        public readonly winesTurnoverTaxes: number,
        public readonly otherOperatingProductsExcludingTaxes: number,
        public readonly otherOperatingProductsTaxes: number,
        public readonly layoutSellingIncomeExcludingTaxes: number,
        public readonly layoutSellingIncomeTaxes: number,
        public readonly incomeTotalExcludingTaxes: number,
        public readonly incomeTotalTaxes: number,

        public readonly winesPurchasesExcludingTaxes: number,
        public readonly ingredientsPurchasesExcludingTaxes: number,
        public readonly winesPurchasesTaxes: number,
        public readonly ingredientsPurchasesTaxes: number,
        public readonly layoutPurchaseCostExcludingTaxes: number,
        public readonly layoutPurchaseCostTaxes: number,
        public readonly externalLoadsExcludingTaxes: number,
        public readonly externalLoadsTaxes: number,
        public readonly otherOperatingChargesExcludingTaxes: number,
        public readonly otherOperatingChargesTaxes: number,
        public readonly chargesTotalExcludingTaxes: number,
        public readonly chargesTotalTaxes: number,
    ) {
    }
}

@Component({
    selector: 'kiwi-taxes-study',
    templateUrl: './taxes-study.component.html',
    styleUrls: ['./taxes-study.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class TaxesStudyComponent extends AbstractStudyComponent<Taxes> {

    constructor() {
        super(CategoryName.ACCOUNTS_DETAIL, of(StudiesNames.TAXES));
    }
}

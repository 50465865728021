import {Component} from '@angular/core';
import {AbstractDecisionComponent} from '../../abstract-decision-component';
import {AnimatorMenuMarkLoaderService} from './animator-menu-mark-loader.service';
import {AnimatorMenuMarkUpdaterService} from './animator-menu-mark-updater.service';
import {Context} from '../../../../context/context';
import {Observable, of} from 'rxjs';
import {Score} from '../../generics/score-image/score-image.component';
import {MenuMarkWeightLoaderService} from './menu-mark-weight-loader.service';

@Component({
    templateUrl: './animator-menu-mark.component.html',
    styleUrls: ['./animator-menu-mark.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class AnimatorMenuMarkComponent extends AbstractDecisionComponent<number, number> {
    constructor(animatorMenuMarkLoaderService:AnimatorMenuMarkLoaderService,
                animatorMenuMarkUpdaterService:AnimatorMenuMarkUpdaterService,
                private readonly markWeightLoaderService: MenuMarkWeightLoaderService) {
        super(
            animatorMenuMarkLoaderService,
            animatorMenuMarkUpdaterService,
            [],
            'decisions.animator.mark.saved'
        );
    }

    protected computeDecisionQuality(scores: Score[], parameters: number): number {
        return 0;
    }

    protected loadParameters(context: Context): Observable<number> {
        return this.markWeightLoaderService.loadParameters(context);
    }

    protected updateScores(decision: number, parameters: number): void {
    }

}

import {Md5} from "ts-md5";

export class PasswordChange {
    public readonly oldPassword: string;
    public readonly newPassword: string;

    constructor(
        oldPassword: string,
        newPassword: string
    ) {
        this.oldPassword = Md5.hashStr(oldPassword) as string;
        this.newPassword = Md5.hashStr(newPassword) as string;
    }
}

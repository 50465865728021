import {HttpClient} from "@angular/common/http";
import {LocatorService} from "../../locator.service";
import {Context} from "../../context/context";
import {Observable} from "rxjs";
import {Message} from "./message";
import {map} from "rxjs/operators";

export class MessagesLoader {
    private httpClient: HttpClient;

    constructor(private messagesType: string) {
        this.httpClient = LocatorService.injector.get(HttpClient);
    }

    public loadMessages(context: Context): Observable<Message[]> {
        return this.httpClient.get<Message[]>('api/sessions/' + context.currentSessionId + '/messages/' + this.messagesType)
            .pipe(map(messages => messages.reverse()));
    }
}

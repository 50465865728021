import { Injectable } from '@angular/core';
import {AbstractSessionParametersUpdaterService} from "../../../parameters/services/abstract-session-parameters-updater-service";
import {ProfitabilityEvaluationParameters} from "./profitability-evaluation-parameters";

@Injectable({
  providedIn: 'root'
})
export class ProfitabilityEvaluationParametersUpdaterService extends AbstractSessionParametersUpdaterService<ProfitabilityEvaluationParameters>{

  constructor() { super('evaluations/PROFITABILITY_MARK/parameters'); }
}

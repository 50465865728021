import {Component} from '@angular/core';
import {AbstractDecisionComponent} from '../../abstract-decision-component';
import {StaffMarks} from './staff-marks';
import {StaffMarksWeights} from './staff-marks-weights';
import {StaffMarksLoaderService} from './staff-marks-loader.service';
import {StaffMarksUpdaterService} from './staff-marks-updater.service';
import {Score} from '../../generics/score-image/score-image.component';
import {Context} from '../../../../context/context';
import {Observable} from 'rxjs';
import {StaffMarksWeightsLoaderService} from './staff-marks-weights-loader.service';

@Component({
    templateUrl: './animator-staff-marks.component.html',
    styleUrls: ['./animator-staff-marks.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class AnimatorStaffMarksComponent extends AbstractDecisionComponent<StaffMarks, StaffMarksWeights> {
    constructor(
        staffMarksLoaderService: StaffMarksLoaderService,
        staffMarksUpdaterService: StaffMarksUpdaterService,
        private readonly staffMarksWeightsLoaderService: StaffMarksWeightsLoaderService
    ) {
        super(staffMarksLoaderService, staffMarksUpdaterService, [], 'decisions.animator.mark.saved');
    }

    protected computeDecisionQuality(scores: Score[], parameters: StaffMarksWeights): number {
        return 0;
    }

    protected loadParameters(context: Context): Observable<StaffMarksWeights> {
        return this.staffMarksWeightsLoaderService.loadParameters(context);
    }

    protected updateScores(decision: StaffMarks, parameters: StaffMarksWeights): void {
    }
}

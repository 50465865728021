import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, interval} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from '../../context/context.service';
import {filter, map, switchMap, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UnreadMessagesCountService {
    public readonly unreadMessageCount = new BehaviorSubject(0);

    constructor(
        private readonly httpClient: HttpClient,
        private readonly contextService: ContextService
    ) {
        this.startPollingUnreadMessagesCount();
    }

    public reloadUnreadMessagesCount() {
        const currentSessionId = this.contextService.context.getValue().currentSessionId;
        this.httpClient.get<number>('api/sessions/' + currentSessionId + '/messages/unreadCount')
            .subscribe(unreadMessageCount => this.unreadMessageCount.next(unreadMessageCount));
    }

    private startPollingUnreadMessagesCount() {
        // Retrieve unread messages count for the first time.
        // Must do it without interval because interval starts emitting after
        // one period has passed
        this.contextService.context.pipe(
            filter(context => !!context && !!context.currentSessionId),
            take(1),
            map(context => context.currentSessionId),
            switchMap(currentSessionId =>
                this.httpClient.get<number>('api/sessions/' + currentSessionId + '/messages/unreadCount')
            )
        ).subscribe(unreadMessageCount => this.unreadMessageCount.next(unreadMessageCount));

        // Start polling
        combineLatest(interval(60000), this.contextService.context)
            .pipe(
                map(([_, context]) => context),
                filter(context => !!context && !!context.currentSessionId),
                map(context => context.currentSessionId),
                switchMap(currentSessionId =>
                    this.httpClient.get<number>('api/sessions/' + currentSessionId + '/messages/unreadCount')
                )
            ).subscribe(unreadMessageCount => this.unreadMessageCount.next(unreadMessageCount));
    }
}

import {Component} from '@angular/core';
import {GenericSessionParametersComponent} from '../../generic-parameters/generic-session-parameters.component';
import {IngredientsPurchaseParameters} from './ingredients-purchase-parameters';
import {IngredientsPurchaseParametersLoaderService} from './ingredients-purchase-parameters-loader.service';
import {IngredientsPurchaseParametersUpdaterService} from './ingredients-purchase-parameters-updater.service';
import {genericParametersHost} from '../../generic-parameters/generic-parameters.component';

@Component({
    templateUrl: './ingredients-purchase-parameters.component.html',
    styleUrls: ['./../../generic-parameters/generic-parameters.component.scss'],
    host: genericParametersHost
})
export class IngredientsPurchaseParametersComponent extends GenericSessionParametersComponent<IngredientsPurchaseParameters> {
    constructor(
        ingredientsPurchaseParametersLoaderService: IngredientsPurchaseParametersLoaderService,
        ingredientsPurchaseParametersUpdaterService: IngredientsPurchaseParametersUpdaterService
    ) {
        super(
            ingredientsPurchaseParametersLoaderService,
            ingredientsPurchaseParametersUpdaterService,
            'toast.parameters.restaurant.ingredients.purchase.saved'
        )
    }
}

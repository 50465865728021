import {Component, Input} from '@angular/core';
import {NotationInformation} from '../notation-information';

@Component({
    selector: 'kiwi-notation-information',
    templateUrl: './notation-information.component.html',
    styleUrls: ['./notation-information.component.scss']
})
export class NotationInformationComponent {
    @Input()
    notationInformation: NotationInformation;

    @Input()
    titleLabelKey: string;

    @Input()
    minimumLabelKey: string;

    @Input()
    averageLabelKey: string;

    @Input()
    maximumLabelKey: string;

    @Input()
    maximumMark: number;
}

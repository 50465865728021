import { Injectable } from '@angular/core';
import {AbstractDecisionUpdaterService} from "../../abstract-decision-updater.service";
import {AnimatorProductsAndCharges} from "./animator-products-and-charges";

@Injectable({
  providedIn: 'root'
})
export class AnimatorProductsAndChargesDecisionUpdaterService extends AbstractDecisionUpdaterService<AnimatorProductsAndCharges>{
  constructor() {
      super('financial/animatorProductsAndCharges')
  }
}

import { Injectable } from '@angular/core';
import {AbstractDecisionUpdaterService} from '../../abstract-decision-updater.service';
import {StaffMarks} from './staff-marks';

@Injectable({
  providedIn: 'root'
})
export class StaffMarksUpdaterService extends AbstractDecisionUpdaterService<StaffMarks>{

  constructor() {
      super('assignments/staff')
  }
}

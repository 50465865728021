import {Injectable} from '@angular/core';
import {AbstractPopupService} from "../abstract-popup.service";
import {PasswordChangePopupComponent} from "./password-change-popup.component";
import {NotificationService} from "../notification.service";
import {PasswordChangeService} from "./password-change.service";
import {ConnectedUserService} from '../context/connected-user.service';
import {filter, map, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PasswordChangePopupService extends AbstractPopupService<PasswordChangePopupComponent> {
    constructor(
        private readonly notificationService: NotificationService,
        private readonly passwordChangeService: PasswordChangeService,
        private readonly connectedUserService: ConnectedUserService
    ) {
        super(PasswordChangePopupComponent);
    }

    public showPasswordChangePopupIfNeeded() {
        this.connectedUserService.userEvents
            .pipe(
                filter(currentConnectedUser => !!currentConnectedUser && !!currentConnectedUser.user),
                take(1),
                map(currentConnectedUser => currentConnectedUser.user)
            ).subscribe(user => {
            if (
                user.displayChangePassword &&
                user.passwordGenerated
            ) {
                user.displayChangePassword = false;
                this.showPasswordChangePopup(user._id,
                    'popup.change.generated.password.description');
            }
        })
    }

    public showPasswordChangePopup(userId: string, descriptionKey?: string) {
        if (!descriptionKey)
            descriptionKey = 'popup.change.password.description';

        const passwordChangePopupComponent = new PasswordChangePopupComponent(this.notificationService, this.passwordChangeService);
        passwordChangePopupComponent.userId = userId;
        passwordChangePopupComponent.descriptionKey = descriptionKey;
        this.showPopup(passwordChangePopupComponent);
    }

    protected fillPopupInstance(popupInstance: PasswordChangePopupComponent, preparedPopup: PasswordChangePopupComponent) {
        popupInstance.userId = preparedPopup.userId;
        popupInstance.descriptionKey = preparedPopup.descriptionKey;
    }
}

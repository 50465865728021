import {StudiesNames} from '../studies-names';
import {SalesDetailsComponent} from '../internal-data/sales-details/sales-details.component';
import {StudyRouteDefinition} from '../study-route-definition';

export const salesDetailsChildrenRoutes: StudyRouteDefinition[] = [
    new StudyRouteDefinition(
        StudiesNames.SALES_DETAILS,
        [{
            path: '',
            component: SalesDetailsComponent
        }]
    )
];

import {LayoutDecision, MaintenanceDecision} from './dining-rooms-decision';
import {DiningRoomsDecisionComponentParameters} from './dining-rooms-decision.component';

export function computeMaintenanceQuality(maintenanceDecision: MaintenanceDecision, parameters: DiningRoomsDecisionComponentParameters): number {
    let standardMaintenanceBudgetPerRoom = parameters.standardMaintenanceBudgetPerRoom;

    if (standardMaintenanceBudgetPerRoom === null)
        standardMaintenanceBudgetPerRoom = maintenanceDecision.maintenanceBudgetPerRoom;

    const currentPeriodEffectiveMaintenanceBudgetPerRoom =
        (maintenanceDecision.maintenanceBudgetPerRoom + parameters.previousPeriodEffectiveMaintenanceBudgetPerRoom) / 2;
    const maintenanceQuality =
        100 - (100 * standardMaintenanceBudgetPerRoom /
            (2 * currentPeriodEffectiveMaintenanceBudgetPerRoom + standardMaintenanceBudgetPerRoom));

    return Math.round(maintenanceQuality * 100) / 100;
}

export function computeLayoutTotalQuality(layoutDecision: LayoutDecision): number {
    return (layoutDecision.tableware.newQuality + layoutDecision.furniture.newQuality) / 2;
}

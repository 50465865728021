import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

const SESSION_PERIODICITY = {
    MONTHLY: 1,
    QUARTERLY: 3,
    HALF_YEARLY: 6,
    YEARLY: 12
};

export class PeriodUtils {

    constructor() {
    }

    static computePreviousPeriod(period: string, periodicity: string): string {
        return this.computePeriodFromMonth(this.computeMonthFromPeriod(period, periodicity) - 1, periodicity);
    }

    static computeMonthFromPeriod(period: string, periodicity: string): number {
        if (!period || period.length !== 4) {
            return -1;
        }

        const year = parseInt(period.substring(0, 2));
        const month = parseInt(period.substring(2));
        const nbMonthsInPeriod = SESSION_PERIODICITY[periodicity];

        return year * 12 + (month - 1) * nbMonthsInPeriod + 1;
    }

    static computePeriodFromMonth(monthNumber: number, periodicity: string): string {
        if (monthNumber <= 0) {
            return null;
        }

        let years = Math.floor(monthNumber / 12);
        const months = monthNumber - (years * 12);
        const nbMonthsInPeriod = SESSION_PERIODICITY[periodicity];
        let period = Math.ceil(months / nbMonthsInPeriod);

        if (period === 0) {
            years--;
            period = 12 / nbMonthsInPeriod;
        }

        return "" + ((years > 9) ? years : ("0" + years)) + ((period > 9) ? period : ("0" + period));
    }

    static computeDisplayedPeriod(period: string, translateService: TranslateService): Observable<string> {
        const year = parseInt(period.substring(0, 2));
        const month = parseInt(period.substring(2, 4));
        const yearKey = 'common.year';
        const monthLabelKey = 'common.monthly.' + month

        return translateService.get([yearKey, monthLabelKey]).pipe(
            map((translations) => {
                return translations[yearKey] + ' ' + year + ' ' + translations[monthLabelKey]
            })
        );
    }
}

import {Injectable} from '@angular/core';
import {AbstractDecisionUpdaterService} from "../../abstract-decision-updater.service";
import {FinancialDecision} from "../financial-decision";

@Injectable({
    providedIn: 'root'
})
export class FinancialDecisionUpdaterService extends AbstractDecisionUpdaterService<FinancialDecision> {

    constructor() {
        super( 'financial');
    }
}

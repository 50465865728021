import {Injectable} from '@angular/core';
import {MenuDecisionFormatterService} from "../menu-decision-formatter.service";
import {MenuDishesPurchases} from "./menu-dishes-purchases";
import {AbstractDecisionLoaderService} from "../../abstract-decision-loader.service";

@Injectable({
    providedIn: 'root'
})
export class MenuDishesPurchasesLoaderService extends AbstractDecisionLoaderService<MenuDishesPurchases> {
    constructor(
        private menuDecisionFormatterService: MenuDecisionFormatterService
    ) {
        super('menu',
            (jsonData) => {
                return MenuDishesPurchases.fromMenuDecision(menuDecisionFormatterService.formatMenuDecision(jsonData))
            })
    }
}

import { Injectable } from '@angular/core';
import {AbstractPeriodParametersLoaderService} from '../../services/abstract-period-parameters-loader.service';
import {AssignmentsParameters} from './assignments-parameters';

@Injectable({
  providedIn: 'root'
})
export class AssignmentsParametersLoaderService extends AbstractPeriodParametersLoaderService<AssignmentsParameters>{

  constructor() {
      super('assignments');
  }
}

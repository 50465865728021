import { Injectable } from '@angular/core';
import {MenuDecision} from "./menu-decision";

@Injectable({
  providedIn: 'root'
})
export class MenuDecisionFormatterService {

  constructor() { }

  formatMenuDecision(jsonData): MenuDecision {
      let menuDecision = jsonData as MenuDecision;
      menuDecision.firstCourses = menuDecision.firstCourses;
      menuDecision.mainCourses = menuDecision.mainCourses;
      menuDecision.meatDishes = this.filterMeatDishes(menuDecision.mainCourses);
      menuDecision.fishDishes = this.filterFishDishes(menuDecision.mainCourses);
      menuDecision.desserts = menuDecision.desserts;
      menuDecision.wines = menuDecision.wines;
      return menuDecision;
  }

    private filterMeatDishes(mainCourses: Object): Object {
        return this.filterMainCourses('MEAT', mainCourses);
    }

    private filterFishDishes(mainCourses: Object): Object {
        return this.filterMainCourses('FISH', mainCourses);
    }

    private filterMainCourses(type: string, mainCourses: Object): Object {
        const filtered = Object.create(null);
        Object.keys(mainCourses).forEach((dishName) => {
            if (dishName.includes(type))
                filtered[dishName] = mainCourses[dishName];
        });

        return filtered;
    }
}

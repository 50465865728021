import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ConnectedUserService} from '../../context/connected-user.service';

@Component({
    selector: 'kiwi-animator-select-team',
    templateUrl: './animator-select-team.component.html',
    styleUrls: ['./animator-select-team.component.scss']
})
export class AnimatorSelectTeamComponent implements OnInit, OnDestroy {
    public isAnimator = false;
    private connectedUserServiceSubscription: Subscription;

    constructor(private readonly connectedUserService: ConnectedUserService) {
    }

    ngOnInit(): void {
        this.connectedUserServiceSubscription = this.connectedUserService.userEvents.subscribe(
            user => {
                if (user) {
                    this.isAnimator = user.user.animator;
                }
            }
        );
    }

    ngOnDestroy(): void {
        if (this.connectedUserServiceSubscription) {
            this.connectedUserServiceSubscription.unsubscribe();
        }
    }
}

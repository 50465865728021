import { Injectable } from '@angular/core';
import {AbstractSessionParametersUpdaterService} from '../../services/abstract-session-parameters-updater-service';
import {InsurancesParameters} from '../../services/insurances/insurances-parameters';

@Injectable({
  providedIn: 'root'
})
export class InsurancesParametersUpdaterService extends AbstractSessionParametersUpdaterService<InsurancesParameters>{

  constructor() {
      super('insurances');
  }
}

import {Injectable} from '@angular/core';
import {AbstractDecisionLoaderService} from '../../../abstract-decision-loader.service';
import {CommunicationDecision} from '../communication-decision';

@Injectable({
  providedIn: 'root'
})
export class CommunicationDecisionLoaderService extends AbstractDecisionLoaderService<CommunicationDecision> {

  constructor() {
    super( 'communication')
  }
}

import {Component} from '@angular/core';
import {CategoryName} from '../category-name';
import {StudiesNames} from '../studies-names';
import {of} from 'rxjs';
import {AbstractStudyComponent} from "../abstract-study.component";
import {DiningRoomParameters} from "./dining-room-parameters";

@Component({
    selector: 'kiwi-dining-room-parameters',
    templateUrl: './dining-room-parameters.component.html',
    styleUrls: ['./dining-room-parameters.component.scss'],
    host: {'class': 'cell auto pageContainer grid-y'}
})
export class DiningRoomParametersComponent extends AbstractStudyComponent<DiningRoomParameters> {
    constructor() {
        super(CategoryName.DINING_ROOMS_PARAMETERS, of(StudiesNames.DINING_ROOMS_PARAMETERS));
    }
}

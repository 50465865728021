import {User} from "../../login/user";

export class Message {
    constructor(
        public content: string,
        public subject: string,
        public sentDate: Date,
        public author: User,
        public _id: string
    ) {
    }
}

import {Component} from '@angular/core';
import {GenericSessionParametersComponent} from '../../generic-parameters/generic-session-parameters.component';
import {PerceivedQualityParameters} from './perceived-quality-parameters';
import {PerceivedQualityParametersLoaderService} from './perceived-quality-parameters-loader.service';
import {PerceivedQualityParametersUpdaterService} from './perceived-quality-parameters-updater.service';
import {genericParametersHost} from '../../generic-parameters/generic-parameters.component';

@Component({
    templateUrl: './perceived-quality-parameters.component.html',
    styleUrls: [
        './../../generic-parameters/generic-parameters.component.scss',
        './perceived-quality-parameters.component.scss'
    ],
    host: genericParametersHost
})
export class PerceivedQualityParametersComponent extends GenericSessionParametersComponent<PerceivedQualityParameters> {

    constructor(perceivedQualityParametersLoaderService: PerceivedQualityParametersLoaderService,
                perceivedQualityParametersUpdaterService: PerceivedQualityParametersUpdaterService) {
        super(
            perceivedQualityParametersLoaderService,
            perceivedQualityParametersUpdaterService,
            'toast.parameters.quality.perceived.quality.saved'
        );
    }
}

import {Component, Input} from '@angular/core';
import {ContextService} from "../../../context/context.service";


@Component({
    selector: 'kiwi-select-period',
    templateUrl: './select-period.component.html',
    styleUrls: ['./select-period.component.scss']
})
export class SelectPeriodComponent {
    @Input() periods: string[];
    @Input() currentPeriod: string;

    constructor(
        private contextService: ContextService
    ) {
    }

    onPeriodChange(newPeriod: string) {
        if (this.contextService.context.getValue().currentPeriod !== newPeriod)
            this.contextService.updateCurrentPeriodId(newPeriod);
    }
}

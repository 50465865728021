import {Component} from '@angular/core';
import {AbstractStudyComponent} from "../../../abstract-study.component";
import {CompetitiveIntelligenceStudy} from "../../competitive-intelligence-study";
import {CategoryName} from "../../../category-name";
import {StudiesNames} from "../../../studies-names";
import {map} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'kiwi-wine-quality-comparison',
    templateUrl: './wine-quality-comparison.component.html',
    styleUrls: ['./wine-quality-comparison.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class WineQualityComparisonComponent extends AbstractStudyComponent<CompetitiveIntelligenceStudy> {

    constructor(route: ActivatedRoute) {
        super(CategoryName.COMPETITIVE_INTELLIGENCE,
            route.paramMap.pipe(
                map(paramMap => {
                    return StudiesNames.WINES_QUALITY_COMPARISON + '/' + paramMap.get('dishName')
                })
            ));
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Session} from "./session";

@Injectable({
    providedIn: 'root'
})
export class SessionLoaderService {

    constructor(
        private httpClient: HttpClient
    ) {
    }

    loadTeamSession(teamId: string): Observable<Session> {
        return this.httpClient.get<Session>('/api/teams/' + teamId + '/currentSession');
    }

    loadSessionByGenericSessionRef(genericSessionRef: string): Observable<Session> {
        return this.httpClient.get<Session>('/api/genericSessions/' + genericSessionRef + '/arkheSessions');
    }

    loadSessionById(sessionId: string): Observable<Session> {
        return this.httpClient.get<Session>('/api/arkheSessions/' + sessionId);
    }
}

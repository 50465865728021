import {OnDestroy, OnInit} from '@angular/core';
import {ContextService} from '../../../context/context.service';
import {Subscription} from 'rxjs';
import {Context} from '../../../context/context';
import {AbstractParametersLoaderService} from '../services/abstract-parameters-loader.service';
import {LocatorService} from '../../../locator.service';
import {filter, switchMap, tap} from 'rxjs/operators';

export abstract class GenericReadonlyParametersComponent<T> implements OnInit, OnDestroy {
    parameters: T;
    private readonly contextService: ContextService;
    private readonly subscription: Subscription;

    context: Context;

    protected constructor(
        private readonly parametersLoader: AbstractParametersLoaderService<T>
    ) {
        this.contextService = LocatorService.injector.get(ContextService);
    }

    ngOnInit(): void {
        this.contextService.context.pipe(
            filter(context => !!context.currentSession),
            tap(context => this.context = context),
            switchMap(context => this.parametersLoader.loadParameters(context))
        ).subscribe(parameters => this.parameters = parameters);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}

import { Injectable } from '@angular/core';
import {MessagesLoader} from "./messages-loader";

@Injectable({
  providedIn: 'root'
})
export class SentMessagesLoaderService extends MessagesLoader{

  constructor() {
      super('sent');
  }
}

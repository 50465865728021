import {Injectable} from '@angular/core';
import {AbstractPeriodParametersLoaderService} from "./abstract-period-parameters-loader.service";
import {WinesParameters} from "./wines-parameters";
import {AbstractSessionParametersLoaderService} from './abstract-session-parameters-loader.service';

@Injectable({
    providedIn: 'root'
})
export class WinesParametersLoaderService extends AbstractSessionParametersLoaderService<WinesParameters> {

    constructor() {
        super('wines');
    }
}

import {of} from "rxjs";
import {TabParentComponent} from '../../../../components/tab-parent/tab-parent.component';
import {Tab} from '../../../tabs/tab';
import {ConnectedUserService} from '../../../../context/connected-user.service';
import {LocatorService} from '../../../../locator.service';
import {filter, flatMap} from 'rxjs/operators';

export class TabParentDecisionComponent extends TabParentComponent {
    currentComponent;
    private readonly connectedUserService: ConnectedUserService;

    constructor(tabs: Tab[],
                public readonly titleLabelKey: string,
                private readonly animatorDecisionTabKey: string,
                private readonly animatorDecisionTabUrl: string,
    ) {
        super(of(tabs));
        this.connectedUserService = LocatorService.injector.get(ConnectedUserService);
    }

    onRouterActivate(component) {
        this.currentComponent = component;
    }

    onSave() {
        this.currentComponent.save();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.connectedUserService.userEvents.pipe(
            filter(user => user && user.user && user.user.animator),
            flatMap(_ => this.tabs)
        ).subscribe(
            tabs => tabs.push(new Tab(this.animatorDecisionTabKey,
                this.animatorDecisionTabUrl, false))
        );
    }
}

import {Component} from '@angular/core';
import {TabParentComponent, tabParentHost} from '../../../components/tab-parent/tab-parent.component';
import {Tab} from '../../tabs/tab';
import {of} from 'rxjs';

@Component({
    templateUrl: './../../../components/tab-parent/tab-parent.component.html',
    styleUrls: [],
    host: tabParentHost
})
export class FinancialParametersComponent extends TabParentComponent {
    private static readonly tabs = ['initial-balance-sheet', 'human-resources-and-taxes', 'external-loads', 'vat', 'insurances']
        .map(tabName => new Tab('parameters.financial.' + tabName, 'parameters/financial/' + tabName, false))

    constructor() {
        super(of(FinancialParametersComponent.tabs));
    }
}

import {Component} from '@angular/core';
import {AbstractStudyComponent} from "../../abstract-study.component";
import {CategoryName} from "../../category-name";
import {StudiesNames} from "../../studies-names";
import {of} from "rxjs";

class ExternalLoadsDetail {
    constructor(
        public readonly consumablesFixedPart: number,
        public readonly consumablesCostTurnoverRate: number,
        public readonly consumablesVariablePart: number,
        public readonly consumablesTotalCharges: number,

        public readonly travelExpensesFixedPart: number,
        public readonly travelExpensesRate: number,
        public readonly travelExpensesVariablePart: number,
        public readonly travelExpensesTotalCharges: number,

        public readonly postalFixedPart: number,
        public readonly postalChargesRate: number,
        public readonly postalVariablePart: number,
        public readonly postalTotalCharges: number,

        public readonly totalMixedCharges: number,

        public readonly maintenanceExpenses: number,
        public readonly advertisementExpenses: number,
        public readonly rent: number,
        public readonly studiesPurchaseExpenses: number,
        public readonly insurancesTotalCost: number,

        public readonly totalOtherCharges: number,
        public readonly totalTurnoverExcludingTax: number,

        public readonly totalExternalLoads: number
    ) {
    }
}

@Component({
    selector: 'kiwi-external-loads-detail',
    templateUrl: './external-loads-detail.component.html',
    styleUrls: ['./external-loads-detail.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class ExternalLoadsDetailComponent extends AbstractStudyComponent<ExternalLoadsDetail> {

    constructor() {
        super(CategoryName.ACCOUNTS_DETAIL, of(StudiesNames.EXTERNAL_LOADS_DETAILS));
    }

}

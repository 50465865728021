import {Component} from '@angular/core';
import {QualityDetails} from './quality-details';
import {of} from 'rxjs';
import {AbstractStudyComponent} from '../../abstract-study.component';
import {StudiesNames} from '../../studies-names';
import {CategoryName} from '../../category-name';

@Component({
    selector: 'kiwi-quality',
    templateUrl: './quality.component.html',
    styleUrls: ['./quality.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class QualityComponent extends AbstractStudyComponent<QualityDetails> {
    constructor() {
        super(CategoryName.QUALITY, of(StudiesNames.QUALITY));
  }
}

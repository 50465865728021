import {Injectable} from '@angular/core';
import {AbstractPeriodParametersLoaderService} from "../../abstract-period-parameters-loader.service";
import { RentParameters } from './rent-parameters';

@Injectable({
    providedIn: 'root'
})
export class RentParametersLoaderService extends AbstractPeriodParametersLoaderService<RentParameters> {

    constructor() {
        super('rent');
    }
}

import {Component, OnDestroy} from '@angular/core';
import {TabParentComponent} from "../../../components/tab-parent/tab-parent.component";
import {Tab} from "../../tabs/tab";
import {ActivatedRoute, ParamMap, UrlSegment} from "@angular/router";
import {combineAll, map, take} from "rxjs/operators";
import {AbstractEvaluationComponent} from "./abstract-evaluation-component";
import {forkJoin, Observable, Subscription, combineLatest} from "rxjs";
import {EvaluationSeverityComponent} from './evaluation-severity/evaluation-severity.component';
import {MarksComponent} from './marks/marks.component';

@Component({
    selector: 'kiwi-generic-evaluation',
    templateUrl: './generic-evaluation.component.html',
    styleUrls: ['./generic-evaluation.component.scss'],
    host: {'class': 'cell auto grid-y tabsContainer'}
})
export class GenericEvaluationComponent extends TabParentComponent implements OnDestroy {
    private subscription: Subscription;

    private static extractMarkType(paramMap: ParamMap, url: UrlSegment[]): string {
        const markTypeFromParamMap = paramMap.get('markType');
        if(markTypeFromParamMap) {
            return markTypeFromParamMap;
        }

        return url[0].path;
    }

    private static extractMarkTypeFromActivatedRoute(activatedRoute: ActivatedRoute): Observable<string> {
        return combineLatest(activatedRoute.paramMap, activatedRoute.url).pipe(
            map(([paramMap, url]) => GenericEvaluationComponent.extractMarkType(paramMap, url)),
            take(1)
        );
    }

    constructor(
        private activatedRoute: ActivatedRoute
    ) {
        super(
            GenericEvaluationComponent.extractMarkTypeFromActivatedRoute(activatedRoute).pipe(
                map(markType => {
                    let tabs = new Array<Tab>();

                    tabs.push(new Tab('evaluations.generic.tabs.marks', '/evaluations/' + markType + '/marks', true));
                    tabs.push(new Tab('evaluations.generic.tabs.criteria', '/evaluations/' + markType + '/criteria', false));

                    if (GenericEvaluationComponent.displaySeverityDispersionTab(markType))
                        tabs.push(new Tab('evaluations.generic.tabs.severity', '/evaluations/' + markType + '/severity', false));

                    return tabs;
                })
            )
        );
    }

    private retrieveMarkType(): Observable<string> {
        return GenericEvaluationComponent.extractMarkTypeFromActivatedRoute(this.activatedRoute);
    }

    private static displaySeverityDispersionTab(markType: string): boolean {
        return (markType && markType !== 'FINAL_MARK');
    }

    onRouterActivate(component: any) {
        this.subscription = this.retrieveMarkType()
            .subscribe(markType => {
                if (component instanceof MarksComponent || component instanceof EvaluationSeverityComponent) {
                    GenericEvaluationComponent.updateMarkType(component, markType);
                }
            });
    }

    private static updateMarkType(component: MarksComponent | EvaluationSeverityComponent, markType: string): void {
        if (component.markType && markType !== component.markType.getValue()) {
            component.markType.next(markType);
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
